import { Badge } from './badge';

interface SongCoverProps {
  cover: string;
  title: string;
  newRelease: boolean;
  streamCount: string;
}

export const SongCover = ({
  cover,
  newRelease,
  title,
  streamCount,
}: SongCoverProps) => {
  return (
    <div
      className="rounded-[20px] overflow-hidden relative flex bg-no-repeat bg-center bg-cover justify-end pt-[56%]"
      style={{
        backgroundImage: `url(${
          !cover.length ? '/assets/default_cover.png' : cover
        })`,
      }}
    >
      <div className="rounded-[20px] bg-grey-darkest/80 mx-6 mb-10 w-full py-8 px-8 box-border">
        <div className="flex items-center mb-4">
          {newRelease && <Badge className="mr-2">Uusi</Badge>}
          <h3 className="text-sm tracking-[0.32px]">{title}</h3>
        </div>
        <h4 className="font-robotoMono text-[28px] font-light">
          {streamCount}
        </h4>
      </div>
    </div>
  );
};
