import { AdminHeader } from '@/components/partials/admin-header';
import { tracksAtom } from '@/services/store/releases/tracks';
import { ReleaseTrack } from '@/services/store/releases/types';
import { useNavigate, useParams } from '@tanstack/react-router';
import { useAtom } from 'jotai';
import { TrackForm } from '../forms/track';
import {
  useGetArtistListQuery,
  useListCollaboratorsQuery,
} from '@/gql/generated-types';
import { ADMIN_CREATE_RELEASE } from '@/services/router/routes';
import { SchemaType, validateReleaseFormValues } from '../util/validation';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
export function EditNonSavedTrackView() {
  const { trackId } = useParams({ strict: false });
  const [tracks, setTracks] = useAtom(tracksAtom);
  const { data: artistList } = useGetArtistListQuery();
  const { data: collaboratorList } = useListCollaboratorsQuery();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [formErrors, setFormErrors] = useState<string[]>([]);
  const artistSelectObject = artistList?.listArtists?.map(artist => {
    return {
      value: artist?.id,
      label: artist?.name,
    };
  });

  const collaboratorSelectObject = collaboratorList?.listCollaborators?.map(
    collaborator => {
      return {
        value: collaborator?.id,
        label: collaborator?.name,
        role: collaborator?.type,
      };
    },
  );

  const selectedTrack = tracks.find(
    (track: ReleaseTrack) => track.id === Number(trackId),
  );

  function handleTrackCreate(values: ReleaseTrack) {
    const validation = validateReleaseFormValues({
      values,
      schema: SchemaType.TRACK,
    });

    if (typeof validation !== 'boolean') {
      setFormErrors(Object.keys(validation));
    } else {
      const otherTracks = tracks.filter(
        (track: ReleaseTrack) => track.id !== Number(trackId),
      );
      setFormErrors([]);
      setTracks(() => [...otherTracks, values]);

      navigate({ to: ADMIN_CREATE_RELEASE });
    }
  }

  return (
    <div className="container space-y-12">
      <AdminHeader title={t('adminDM.createTrack.editTitle')} />
      <TrackForm
        collaborators={collaboratorSelectObject}
        edit
        artists={artistSelectObject}
        values={selectedTrack}
        onSubmit={handleTrackCreate}
        errors={formErrors}
      />
    </div>
  );
}
