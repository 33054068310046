import { cn } from '@/lib/utils';
import { PropsWithChildren, useLayoutEffect, useRef } from 'react';

interface SitckyHeaderProps extends PropsWithChildren {
  header?: string;
  backLink?: React.ReactNode;
}

export const StickyHeader = (props: SitckyHeaderProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const header = useRef<HTMLDivElement>(null);
  useLayoutEffect(() => {
    const top = ref.current?.getBoundingClientRect().top;

    const onScroll = () => {
      const refClassList = ['pt-2', 'text-center'];
      if (top && top < window.scrollY) {
        ref.current?.classList.add(...refClassList);
        header.current?.classList.add('hidden');
      } else {
        ref.current?.classList.remove(...refClassList);
        header.current?.classList.remove('hidden');
      }
    };

    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  const adminContainer = document.getElementById('adminMain');
  return (
    <div
      className={cn(
        'sticky top-0 z-50 items-center grid grid-cols-1 md:grid-cols-2 space-y-6 lg:space-y-0',
        adminContainer && 'static',
      )}
      ref={ref}
    >
      <div className="order-1 lg:col-span-1">
        <div ref={header}>{props.backLink}</div>
        {header && (
          <h1 className="h1" ref={header}>
            {props.header}
          </h1>
        )}
      </div>

      <div className="lg:text-right  order-3 md:order-2 lg:col-span-1">
        {props.children}
      </div>
    </div>
  );
};
