import { Link, useRouterState } from '@tanstack/react-router';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '../ui/accordion';
import { useTranslation } from 'react-i18next';
import {
  ADMIN_ARTIST,
  ADMIN_COLLABORATORS,
  ADMIN_CONTRACTS,
  ADMIN_CREATE_ARTIST,
  ADMIN_CREATE_COLLABORATOR,
  ADMIN_CREATE_CONTRACT,
  ADMIN_CREATE_PAYMENT,
  ADMIN_CREATE_RELEASE,
  ADMIN_CREATE_USER,
  ADMIN_LOG_EXPENSE,
  ADMIN_NON_MATCHED,
  ADMIN_PAYMENTS,
  ADMIN_RELEASES,
  ADMIN_UPLOAD_ROYALTY_STATEMENT,
  ADMIN_USERS,
} from '@/services/router/routes';
import { PropsWithChildren } from 'react';
import { cn } from '@/lib/utils';
import { SignOutButton } from '@clerk/clerk-react';
import { Button } from '../ui/button';

interface AdminNavLinkProps extends PropsWithChildren {
  active?: boolean;
  to: string;
  params?: Record<string, string | number>;
}

const AdminNavLink = (props: AdminNavLinkProps) => {
  return (
    <Link
      to={props.to}
      params={props.params}
      className={cn(
        'hover:text-yellow-dark font-medium',
        props.active && 'text-yellow-dark hover:text-bone',
      )}
    >
      {props.children}
    </Link>
  );
};

export const AdminSideNav = () => {
  const { t } = useTranslation();
  const router = useRouterState();
  const state = router.location.pathname;

  const artistLinks: string[] = [
    ADMIN_ARTIST,
    ADMIN_CONTRACTS,
    ADMIN_RELEASES,
    ADMIN_PAYMENTS,
  ];

  const dataLinks: string[] = [
    ADMIN_CREATE_ARTIST,
    ADMIN_CREATE_COLLABORATOR,
    ADMIN_CREATE_USER,
    ADMIN_CREATE_PAYMENT,
    ADMIN_LOG_EXPENSE,
    ADMIN_CREATE_CONTRACT,
    ADMIN_CREATE_RELEASE,
  ];

  return (
    <div className="flex flex-col h-full w-full justify-between">
      <div>
        <div className="p-10">
          <Link to="/admin">
            <img src="/assets/pme-logo.svg" alt="PME Compass" />
          </Link>
        </div>
        <div>
          <div className="px-10 font-medium py-4">
            <AdminNavLink to={'/'} active={state === '/admin/'}>
              {t('adminNav.frontpage')}
            </AdminNavLink>
          </div>
          <Accordion
            type="single"
            collapsible
            defaultValue={
              artistLinks.includes(state)
                ? 'artists'
                : dataLinks.includes(state)
                  ? 'data-management'
                  : ''
            }
          >
            <AccordionItem value="artists">
              <AccordionTrigger className="px-10">
                {t('adminNav.artists')}
              </AccordionTrigger>
              <AccordionContent className="px-14 py-5 bg-black/60">
                <nav className="flex flex-col space-y-6">
                  <AdminNavLink
                    to={ADMIN_ARTIST}
                    active={state === ADMIN_ARTIST}
                  >
                    {t('adminNav.artists')}
                  </AdminNavLink>
                  <AdminNavLink
                    to={ADMIN_CONTRACTS}
                    active={state === ADMIN_CONTRACTS}
                  >
                    {t('adminNav.contracts')}
                  </AdminNavLink>
                  <AdminNavLink
                    to={ADMIN_RELEASES}
                    active={state === ADMIN_RELEASES}
                  >
                    {t('adminNav.releases')}
                  </AdminNavLink>
                  <AdminNavLink
                    to={ADMIN_COLLABORATORS}
                    active={state === ADMIN_COLLABORATORS}
                  >
                    {t('adminNav.collaborators')}
                  </AdminNavLink>
                  <AdminNavLink to={ADMIN_USERS} active={state === ADMIN_USERS}>
                    {t('adminNav.users')}
                  </AdminNavLink>
                  <AdminNavLink
                    to={ADMIN_NON_MATCHED}
                    active={state === ADMIN_NON_MATCHED}
                  >
                    {t('adminNav.nonMatched')}
                  </AdminNavLink>
                  <AdminNavLink
                    to={ADMIN_PAYMENTS}
                    active={state === ADMIN_PAYMENTS}
                  >
                    {t('adminNav.payments')}
                  </AdminNavLink>
                </nav>
              </AccordionContent>
            </AccordionItem>
            <AccordionItem value="data-management">
              <AccordionTrigger className="px-10">
                {t('adminNav.dataManagement')}
              </AccordionTrigger>
              <AccordionContent className="px-14 py-5 bg-black/60 w-full">
                <nav className="flex flex-col space-y-6">
                  <AdminNavLink
                    to={ADMIN_CREATE_RELEASE}
                    active={state === ADMIN_CREATE_RELEASE}
                  >
                    {t('adminNav.newRelease')}
                  </AdminNavLink>
                  <AdminNavLink
                    to={ADMIN_CREATE_USER}
                    active={state === ADMIN_CREATE_USER}
                  >
                    {t('adminNav.newUser')}
                  </AdminNavLink>
                  <AdminNavLink
                    to={ADMIN_CREATE_ARTIST}
                    active={state === ADMIN_CREATE_ARTIST}
                  >
                    {t('adminNav.newArtist')}
                  </AdminNavLink>
                  <AdminNavLink
                    to={ADMIN_CREATE_COLLABORATOR}
                    active={state === ADMIN_CREATE_COLLABORATOR}
                  >
                    {t('adminNav.newCollab')}
                  </AdminNavLink>
                  <AdminNavLink
                    to={ADMIN_LOG_EXPENSE}
                    active={state === ADMIN_LOG_EXPENSE}
                  >
                    {t('adminNav.newExpense')}
                  </AdminNavLink>
                  <AdminNavLink
                    to={ADMIN_CREATE_PAYMENT}
                    active={state === ADMIN_CREATE_PAYMENT}
                  >
                    {t('adminNav.newPayment')}
                  </AdminNavLink>
                  <AdminNavLink
                    to={ADMIN_UPLOAD_ROYALTY_STATEMENT}
                    active={state === ADMIN_UPLOAD_ROYALTY_STATEMENT}
                  >
                    {t('adminNav.uploadStatement')}
                  </AdminNavLink>
                  <AdminNavLink
                    to={ADMIN_CREATE_CONTRACT}
                    active={state === ADMIN_CREATE_CONTRACT}
                  >
                    {t('adminNav.newContract')}
                  </AdminNavLink>
                </nav>
              </AccordionContent>
            </AccordionItem>
          </Accordion>
        </div>
      </div>
      <div className="p-10">
        <SignOutButton>
          <Button className="w-full">{t('logout')}</Button>
        </SignOutButton>
      </div>
    </div>
  );
};
