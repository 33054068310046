import {
  AdminMegaViewDataTable,
  AdminMegaViewItem,
  columns,
} from '@/components/data-tables/admin-mega-view';
import { ViewError } from '@/components/errors/view-error';
import { ViewLoader } from '@/components/loaders/view-loader';
import { AdminHeader } from '@/components/partials/admin-header';

import { useGetArtistOverviewQuery } from '@/gql/generated-types';
import { useTranslation } from 'react-i18next';

export default function Admin() {
  const { t } = useTranslation();
  const { loading, error, data } = useGetArtistOverviewQuery({
    variables: {
      costCenter: '',
    },
  });

  if (error) {
    return <ViewError />;
  }

  const tableData: AdminMegaViewItem[] =
    data?.artistOverview?.map((artist, index) => {
      return {
        id: artist?.artistId ?? index,
        name: artist?.artistName ?? '',
        ytd: artist?.incomeYTD,
        latestReleaseDate: artist?.latestReleaseDate
          ? new Date(artist.latestReleaseDate)
          : null,
        nextReleaseDate: artist?.nextReleaseDate
          ? new Date(artist.nextReleaseDate)
          : null,
        totalStreams: artist?.totalStreamsLast30Days,
        revenueStreams: artist?.over30secStreamsLast30Days,
        contractStatus: `${artist?.tracksFromContract}/${artist?.contractLength}`,
      };
    }) ?? [];

  return (
    <>
      <AdminHeader title={t('adminShared.title')} />
      {loading && <ViewLoader />}
      {!loading && data && (
        <AdminMegaViewDataTable columns={columns} data={tableData} />
      )}
    </>
  );
}
