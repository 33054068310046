import { RadialCard } from '@/components/ui/radial-card';
import { Skeleton } from '@/components/ui/skeleton';
import { Table, TableBody, TableCell, TableRow } from '@/components/ui/table';
import { useGetArtistExpenseDetailsQuery } from '@/gql/generated-types';
import { arrayOfStringsToSum, cn } from '@/lib/utils';
import { format } from 'date-fns';
import { fi } from 'date-fns/locale';
import { useTranslation } from 'react-i18next';
import { ErrorWhileFetchingDataForSeason } from './states';

type ExpensesTotalProps = {
  artistId: number;
  startDate: string;
  endDate: string;
};

function ExpensesTotalSkeleton() {
  return (
    <div className="space-y-2">
      <Skeleton className="w-full h-[50px]" />
      <Skeleton className="w-full h-[50px]" />
      <Skeleton className="w-full h-[50px]" />
    </div>
  );
}
export function ExpensesTotal(props: ExpensesTotalProps) {
  const { loading, error, data } = useGetArtistExpenseDetailsQuery({
    variables: {
      startDate: props.startDate,
      endDate: props.endDate,
      artistId: props.artistId,
    },
  });
  const { t } = useTranslation();
  const expenseListTotal = data?.artistExpenseDetails?.map(
    expense => expense?.amount,
  );

  return (
    <>
      <h4 className="h4">{t('artistPages.balance.expenses')}</h4>
      {loading && <ExpensesTotalSkeleton />}
      {!loading && error && <ErrorWhileFetchingDataForSeason />}
      {!loading && data && (
        <>
          <Table className="border-collapse">
            <TableBody>
              {data?.artistExpenseDetails?.map((item, index) => (
                <TableRow key={index} variant={index % 2 !== 0 ? 'odd' : null}>
                  <TableCell>
                    {t(
                      `artistPages.balance.${item?.category}` || 'unspecified',
                    )}
                  </TableCell>
                  <TableCell className="text-right">
                    -
                    {new Intl.NumberFormat('fi-FI', {
                      style: 'currency',
                      currency: 'EUR',
                    }).format(item?.amount)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <RadialCard bodyClassName="text-center space-y-6" variant="grey">
            <p className="font-bold text-xs">
              {t('artistPages.balance.expensesFrom', {
                start: format(new Date(props.startDate), 'd.LLLL', {
                  locale: fi,
                }),
                end: format(new Date(props.endDate), 'd.LLLL', { locale: fi }),
              })}
            </p>
            <p
              className={cn(
                'font-bold font-mono text-xl',
                arrayOfStringsToSum(expenseListTotal) !== 0 && 'text-red-500',
              )}
            >
              {arrayOfStringsToSum(expenseListTotal) !== 0 ? '-' : null}
              {new Intl.NumberFormat('fi-FI', {
                style: 'currency',
                currency: 'EUR',
              }).format(arrayOfStringsToSum(expenseListTotal))}
            </p>
          </RadialCard>
        </>
      )}
    </>
  );
}
