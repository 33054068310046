import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  SortingState,
  getSortedRowModel,
  ColumnFiltersState,
  getFilteredRowModel,
  useReactTable,
} from '@tanstack/react-table';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '../ui/table';
import { useState } from 'react';
import { ArrowUpDown } from 'lucide-react';
import { Input } from '@/components/ui/input';

import { cn } from '@/lib/utils';
import { format } from 'date-fns';
import { fi } from 'date-fns/locale';
import { ARTIST_BASE } from '../../services/router/routes';
import { Link } from '@tanstack/react-router';
import { TranslatedMessage } from '../ui/translated-message';
import { useTranslation } from 'react-i18next';

export type AdminMegaViewItem = {
  id: number;
  name: string;
  ytd: number;
  latestReleaseDate: Date | null;
  nextReleaseDate: Date | null;
  revenueStreams: number;
  totalStreams: number;
  contractStatus: string;
};

interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
}

export const columns: ColumnDef<AdminMegaViewItem>[] = [
  {
    accessorKey: 'name',
    header: ({ column }) => {
      return (
        <span
          className={cn(
            'flex items-center cursor-pointer',
            column.getIsSorted() && 'text-yellow-dark',
          )}
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          <TranslatedMessage translationKey={'adminMegaViewTable.name'} />
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </span>
      );
    },
  },
  {
    accessorKey: 'latestReleaseDate',
    cell: value => {
      return (
        <>
          {value.cell.getValue() !== null
            ? format(new Date(value.cell.getValue() as Date), 'dd.MM.yyyy', {
              locale: fi,
            })
            : '-'}
        </>
      );
    },
    header: ({ column }) => {
      return (
        <span
          className={cn(
            'flex items-center cursor-pointer',
            column.getIsSorted() && 'text-yellow-dark',
          )}
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          <TranslatedMessage translationKey="adminMegaViewTable.latestRelease" />
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </span>
      );
    },
  },
  {
    accessorKey: 'nextReleaseDate',
    cell: value => {
      return (
        <>
          {value.cell.getValue() !== null
            ? format(new Date(value.cell.getValue() as Date), 'dd.MM.yyyy', {
              locale: fi,
            })
            : '-'}
        </>
      );
    },
    header: ({ column }) => {
      return (
        <span
          className={cn(
            'flex items-center cursor-pointer',
            column.getIsSorted() && 'text-yellow-dark',
          )}
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          <TranslatedMessage translationKey="adminMegaViewTable.nextRelease" />
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </span>
      );
    },
  },
  {
    accessorKey: 'totalStreams',
    header: ({ column }) => {
      return (
        <span
          className={cn(
            'flex items-center cursor-pointer',
            column.getIsSorted() && 'text-yellow-dark',
          )}
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          <TranslatedMessage translationKey="adminMegaViewTable.totalStreams" />
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </span>
      );
    },
    cell: value => {
      return (
        <>

          {new Intl.NumberFormat('fi-FI').format(
            Number(value.cell.getValue()),
          )
          }
        </>
      );
    },
  },
  {
    accessorKey: 'revenueStreams',
    header: ({ column }) => {
      return (
        <span
          className={cn(
            'flex items-center cursor-pointer',
            column.getIsSorted() && 'text-yellow-dark',
          )}
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          <TranslatedMessage translationKey="adminMegaViewTable.royaltyStreams" />
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </span>
      );
    },
    cell: value => {
      return (
        <>
          {
            new Intl.NumberFormat('fi-FI').format(
              Number(value.cell.getValue()),
            )
          }
        </>
      );
    },
  },
  {
    accessorKey: 'ytd',
    header: ({ column }) => {
      return (
        <span
          className={cn(
            'flex items-center cursor-pointer',
            column.getIsSorted() && 'text-yellow-dark',
          )}
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          <TranslatedMessage translationKey="adminMegaViewTable.incomeYTD" />
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </span>
      );
    },
    cell: value => {
      return (
        <>
          {
            new Intl.NumberFormat('fi-FI', { style: "currency", currency: "EUR", maximumFractionDigits: 2 }).format(
              Number(value.cell.getValue()),
            )
          }
        </>
      );
    }
  },
  {
    accessorKey: 'contractStatus',
    header: () => {
      return <TranslatedMessage translationKey="adminMegaViewTable.contract" />;
    },
  },
];

export function AdminMegaViewDataTable<TData, TValue>({
  columns,
  data,
}: DataTableProps<TData, TValue>) {
  const [sorting, setSorting] = useState<SortingState>([]);
  const { t } = useTranslation();
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    onColumnFiltersChange: setColumnFilters,
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      sorting,
      columnFilters,
    },
  });

  return (
    <div>
      <div className="flex items-center py-4 self-end justify-start">
        <Input
          placeholder={t('adminMegaViewTable.searchForArtistPlaceholder')}
          value={(table.getColumn('name')?.getFilterValue() as string) ?? ''}
          onChange={event =>
            table.getColumn('name')?.setFilterValue(event.target.value)
          }
          className="max-w-full lg:max-w-[25%]"
        />
      </div>
      <Table className="border-collapse">
        <TableHeader className="text-left">
          {table.getHeaderGroups().map(headerGroup => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map(header => {
                return (
                  <TableHead key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                  </TableHead>
                );
              })}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map((row, index) => (
              <TableRow
                key={row.id}
                data-state={row.getIsSelected() && 'selected'}
                variant={index % 2 === 0 ? 'odd' : null}
              >
                {row.getVisibleCells().map(cell => {
                  return (
                    <TableCell key={cell.id}>
                      {cell.column.id === 'name' ? (
                        <Link
                          to={ARTIST_BASE}
                          className="hover:text-yellow-dark underline"
                          params={{
                            artistId: (
                              cell.row.original as AdminMegaViewItem
                            ).id.toString(),
                          }}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext(),
                          )}
                        </Link>
                      ) : (
                        flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )
                      )}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={columns.length} className="h-24 text-center">
                Ei tuloksia
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
}
