import { Toaster } from '@/components/ui/toaster';
import { ClerkProvider, SignedIn, SignedOut } from '@clerk/clerk-react';
import { Outlet } from '@tanstack/react-router';
import { Nav } from './components/partials/nav';
import {
  clerkAppearance,
  getClerkLocalizations,
} from './services/authentication/clerk';
import { ApolloProviderWrapper } from './providers/apollo';
import SignInPage from './views/sign-in';
import { AdminProtection } from './providers/admin';
import { useTranslation } from 'react-i18next';
import { IdleProvider } from './providers/idle';

if (!process.env.REACT_APP_CLERK_PUBLISHABLE_KEY) {
  throw new Error('Missing Publishable Key');
}
const clerkPubKey = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;

export default function RootLayout() {
  const { i18n } = useTranslation();



  return (
    <ClerkProvider
      publishableKey={clerkPubKey}
      appearance={clerkAppearance}
      // TODO: Figure out how to dynamically handle localization on clerk
      localization={getClerkLocalizations(i18n.language)}
    >
      <IdleProvider>
        <ApolloProviderWrapper>
          <SignedIn>
            <AdminProtection>
              <main className="min-h-full mb-9">
                <Nav />
                <div className="mt-10 main-container">
                  <Outlet />
                </div>
              </main>
              <Toaster />
            </AdminProtection>
          </SignedIn>
          <SignedOut>
            <SignInPage />
          </SignedOut>
        </ApolloProviderWrapper>
      </IdleProvider>
    </ClerkProvider>
  );
}
