import { Link } from '@tanstack/react-router';
import { ChevronLeft } from 'lucide-react';
import { buttonVariants } from '../ui/button';
import { cn } from '@/lib/utils';

interface ActionLink {
  link: string;
  label: string;
}

export type AdminHeader = {
  title: string;
  actionLink?: ActionLink;
  goBackLink?: ActionLink;
  subTitle?: string;
  badge?: React.ReactNode;
};

export function AdminHeader(props: AdminHeader) {
  return (
    <header className="lg:flex space-y-8 lg:space-y-0 justify-between items-center">
      <div className="space-y-4">
        {props.goBackLink && (
          <Link
            to={props.goBackLink.link}
            className={buttonVariants({ variant: 'link', size: 'link' })}
          >
            <ChevronLeft className="mr-2" /> {props.goBackLink.label}
          </Link>
        )}
        <div className={cn('space-y-0', props.subTitle && 'space-y-2')}>
          {props.badge}
          <h1 className="h1-small-mobile md:h1">{props.title}</h1>
          {props.subTitle && <h2 className="h2-mono">{props.subTitle}</h2>}
        </div>
      </div>
      <div className="basis-1/4 lg:justify-end lg:text-right">
        {props.actionLink && (
          <Link
            to={props.actionLink.link}
            className={buttonVariants({ variant: 'default' })}
          >
            {props.actionLink.label}
          </Link>
        )}
      </div>
    </header>
  );
}
