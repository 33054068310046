import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from '@/components/ui/form';
import { CheckCircle, PlusCircle, Upload } from 'lucide-react';
import { useDropzone } from 'react-dropzone';
import { useForm } from 'react-hook-form';
import * as z from 'zod';
import { statementFormSchema } from './schema';
import { zodResolver } from '@hookform/resolvers/zod';
import { RadialCard } from '@/components/ui/radial-card';
import {
  SelectItem,
  Select,
  SelectContent,
  SelectTrigger,
} from '@/components/ui/select';
import { NumberInput } from '@/components/ui/number-input';
import { Button } from '@/components/ui/button';
import { cn } from '@/lib/utils';
import { useTranslation } from 'react-i18next';

export interface SubmitFormResponse {
  fileName: File;
  dsp: string;
  month: number;
  year: number;
}

type UploadRoyaltyStatementFormProps = {
  onSubmit: (values: SubmitFormResponse) => void;
  onDspSelect: (value: string) => void;
};

export function UploadRoyaltyStatementForm(
  props: UploadRoyaltyStatementFormProps,
) {
  const { t } = useTranslation();

  const form = useForm<z.infer<typeof statementFormSchema>>({
    resolver: zodResolver(statementFormSchema),
  });

  function handleFileUpload(values: z.infer<typeof statementFormSchema>) {
    props.onSubmit(values);
  }

  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    onDropAccepted: file => {
      // file inputs return an array, but we only allow singular uploads which makes using index safe
      form.setValue('fileName', file[0]);
    },
  });

  const file = form.watch('fileName');
  const fileState = form.getFieldState('fileName');

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(handleFileUpload)}
        className="space-y-12"
      >
        <RadialCard
          variant={!file ? 'grey' : 'green'}
          className={cn(fileState.invalid && 'border-2 border-red-500')}
        >
          <div
            className="flex flex-col space-y-4 items-center cursor-pointer text-center justify-center"
            {...getRootProps()}
          >
            <input {...getInputProps()} id="file_upload" />
            {!file ? <PlusCircle /> : <CheckCircle />}
            {!file && <p>{t('adminDM.statement.chooseFile')}</p>}
            {file && <p>{file.name}</p>}
          </div>
        </RadialCard>
        <div className="grid grid-cols-2 gap-12">
          <FormField
            control={form.control}
            name="month"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t('adminDM.statement.month')}</FormLabel>
                <FormControl>
                  <NumberInput
                    autoComplete="off"
                    value={field.value}
                    onInputValueChange={value => field.onChange(value)}
                  />
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="year"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t('adminDM.statement.year')}</FormLabel>
                <FormControl>
                  <NumberInput
                    autoComplete="off"
                    value={field.value}
                    onInputValueChange={value => field.onChange(value)}
                  />
                </FormControl>
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="dsp"
            render={({ field }) => (
              <FormItem>
                <FormLabel>DSP</FormLabel>
                <Select
                  onValueChange={value => {
                    field.onChange(value);
                    props.onDspSelect(value);
                  }}
                  value={field.value}
                >
                  <FormControl>
                    <SelectTrigger>
                      {field.value
                        ? t(field.value)
                        : t('adminDM.statement.selectDSP')}
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    <SelectItem value="FUGA">Fuga</SelectItem>

                    <SelectItem value="MERLIN_SPOTIFY">
                      Merlin Spotify
                    </SelectItem>
                    <SelectItem value="MERLIN_DEEZER">Merlin Deezer</SelectItem>
                  </SelectContent>
                </Select>
              </FormItem>
            )}
          />
        </div>
        <Button>
          <Upload className="w-4 h-4 mr-2" />{' '}
          {t('adminDM.statement.uploadFile')}
        </Button>
      </form>
    </Form>
  );
}
