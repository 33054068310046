import { Ref, forwardRef, useImperativeHandle, useState } from 'react';
import { CollaboratorRoyaltyFormItemHandle } from './royalty-split';
import { FormContainer } from '../container';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
} from '@/components/ui/select';
import { useTranslation } from 'react-i18next';
import { NumberInput } from '@/components/ui/number-input';
import { SchemaType, validateReleaseFormValues } from '../../util/validation';
import { CollaboratorRole } from '@/gql/generated-types';
import { Input } from '@/components/ui/input';

export interface RoyaltyFormItemProps {
  selectedArtist: {
    royaltyRules: {
      paymentAmount: number;
      recoupableAmount: number;
      royaltyPercentage: number;
      royaltyType: string;
      recoupableType?: string;
    };
    role: CollaboratorRole;
    collaboratorId: number;
  };
  artistName: string;
}

type CollaboratorFormState =
  RoyaltyFormItemProps['selectedArtist']['royaltyRules'] & {
    role: CollaboratorRole;
  };

const RoyaltyFormItem = (
  props: RoyaltyFormItemProps,
  ref: Ref<CollaboratorRoyaltyFormItemHandle>,
) => {
  const { t } = useTranslation();
  const [formErrors, setFormErrors] = useState<string[]>([]);
  const [formValues, setFormValues] = useState<CollaboratorFormState>({
    ...props.selectedArtist.royaltyRules,
    role: props.selectedArtist.role,
  });
  useImperativeHandle(
    ref,
    () => {
      return {
        getFormData() {
          // Filter out role from validation because it cant be empty
          const { role, royaltyPercentage, ...rest } = formValues;
          const validation = validateReleaseFormValues({
            values: {
              ...rest,
              royaltyPercentage: royaltyPercentage.toString(),
            },
            schema: SchemaType.ROYALTY,
          });

          if (typeof validation !== 'boolean') {
            setFormErrors(Object.keys(validation));
            return false;
          } else {
            setFormErrors([]);
            return {
              collaboratorId: props.selectedArtist?.collaboratorId,
              role: formValues.role,
              royaltyRules: {
                paymentAmount: formValues.paymentAmount,
                recoupableAmount: formValues.recoupableAmount,
                royaltyPercentage: formValues.royaltyPercentage,
                royaltyType: formValues.royaltyType,
                recoupableType: formValues.recoupableType
                  ? formValues.recoupableType
                  : undefined,
              },
            };
          }
        },
      };
    },
    [formValues, props.selectedArtist.collaboratorId],
  );

  function handleInputOnChange(
    key: keyof CollaboratorFormState,
    value: string | number,
  ) {
    setFormValues(prevState => ({
      ...prevState,
      [key]: value,
    }));
  }

  return (
    <div className="p-4 md:p-8 space-y-4">
      <div>
        <h4 className="font-bold text-xl">{props.artistName}</h4>
      </div>
      <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
        <FormContainer
          label={t('trackForm.advancedPayment')}
          error={formErrors.includes('paymentAmount')}
        >
          <NumberInput
            value={formValues.paymentAmount}
            onInputValueChange={e => handleInputOnChange('paymentAmount', e)}
            autoComplete="off"
          />
        </FormContainer>
        <FormContainer
          label={t('trackForm.recoupable')}
          error={formErrors.includes('recoupableAmount')}
        >
          <NumberInput
            value={formValues.recoupableAmount}
            onInputValueChange={e => handleInputOnChange('recoupableAmount', e)}
            autoComplete="off"
          />
        </FormContainer>
        <FormContainer
          label={t('trackForm.royaltyPct')}
          error={formErrors.includes('royaltyPercentage')}
        >
          <Input
            value={formValues.royaltyPercentage.toString()}
            autoComplete="off"
            type="number"
            onWheel={event => event.currentTarget.blur()}
            onChange={e => {
              handleInputOnChange('royaltyPercentage', e.target.value);
            }}
          />
        </FormContainer>
        <FormContainer label={t('trackForm.royaltyType')}>
          <Select
            onValueChange={value => handleInputOnChange('royaltyType', value)}
            value={formValues.royaltyType}
          >
            <SelectTrigger>{t(formValues.royaltyType)}</SelectTrigger>

            <SelectContent>
              <SelectItem value="ACCOUNTING_PRICE">
                {t('trackForm.ACCOUNTING_PRICE')}
              </SelectItem>
              <SelectItem value="ARTIST_SHARE">
                {t('trackForm.ARTIST_SHARE')}
              </SelectItem>
            </SelectContent>
          </Select>
        </FormContainer>
        <FormContainer label={t('trackForm.recoupType')}>
          <Select
            onValueChange={value =>
              handleInputOnChange('recoupableType', value)
            }
            value={formValues.recoupableType ?? ''}
          >
            <SelectTrigger>
              {t(`trackForm.${formValues.recoupableType}`) ?? ''}
            </SelectTrigger>

            <SelectContent>
              <SelectItem value="NO_LIMIT">
                {t('trackForm.NO_LIMIT')}
              </SelectItem>
              <SelectItem value="PAY_WHEN_ALL_RECOUPED">
                {t('trackForm.PAY_WHEN_ALL_RECOUPED')}
              </SelectItem>
              <SelectItem value="PAY_WHEN_SELF_RECOUPED">
                {t('trackForm.PAY_WHEN_SELF_RECOUPED')}
              </SelectItem>
            </SelectContent>
          </Select>
        </FormContainer>
        <FormContainer label={t('trackForm.role')}>
          <Select
            onValueChange={value => handleInputOnChange('role', value)}
            value={formValues.role}
          >
            <SelectTrigger>{t(formValues.role)}</SelectTrigger>

            <SelectContent>
              <SelectItem value={CollaboratorRole.Producer}>
                {t('trackForm.PRODUCER')}
              </SelectItem>
              <SelectItem value={CollaboratorRole.Songwriter}>
                {t('trackForm.SONGWRITER')}
              </SelectItem>
              <SelectItem value={CollaboratorRole.ThirdParty}>
                {t('trackForm.THIRD_PARTY')}
              </SelectItem>
            </SelectContent>
          </Select>
        </FormContainer>
      </div>
    </div>
  );
};

export default forwardRef(RoyaltyFormItem);
