import { GetArtistContractsQuery } from '@/gql/generated-types';
import { format } from 'date-fns';
import { fi } from 'date-fns/locale';

export function formatDateText(date: string) {
  if (!date) return '';
  return format(new Date(date), 'd.LLLL', { locale: fi });
}

interface DateRange {
  start: Date;
  end: Date;
}

export function generateDateRange(
  startMonth: string,
  endMonth: string,
  year: string,
): DateRange {
  // Ensure start month is before or equal to end month

  // Create start and end dates
  const startDate = new Date(Number(year), Number(startMonth) - 1, 1);
  const endDate = new Date(
    Number(year) + Math.floor((Number(startMonth) + Number(endMonth) - 2) / 12),
    (Number(endMonth) - 1) % 12,
    1,
  );

  return { start: startDate, end: endDate };
}

export function GetLatestContractBySigningDate(
  results: GetArtistContractsQuery | undefined,
) {
  if (
    results == null ||
    typeof results === 'undefined' ||
    results?.contracts === undefined ||
    results?.contracts === null
  ) {
    return null;
  }

  let oldestObject = results?.contracts![0];

  // Iterate through the rest of the objects
  for (const obj of results.contracts) {
    const currentDate = new Date(obj?.dateSigned);
    const oldestDate = new Date(oldestObject?.dateSigned);

    if (currentDate < oldestDate) {
      oldestObject = obj;
    }
  }

  return oldestObject;
}

export function calculateSumWithReset(
  start: number,
  increment: number,
  limit: number,
) {
  let sum = start;

  sum += increment;

  if (sum > limit) {
    const remaining = sum - limit;

    sum = 1 + remaining - 1;
  }

  // Ensure the result is never 0
  if (sum === 0) {
    sum = 1;
  }

  return sum;
}

export function getLastDayOfMonth(month: number) {
  // Ensure the month is within a valid range (1 to 12)
  if (month < 1 || month > 12) {
    throw new Error('Invalid month');
  }

  // Create a date object for the first day of the next month
  const nextMonthFirstDay = new Date(new Date().getFullYear(), month, 1);

  // Subtract one day to get the last day of the given month
  const lastDayOfMonth = new Date(Number(nextMonthFirstDay) - 1);

  return lastDayOfMonth.getDate();
}

export type RoyaltyPeriod = {
  index: number;
  start: {
    day: number;
    month: number;
    year: number;
  };
  end: {
    day: number;
    month: number;
    year: number;
  };
};

function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined;
}

interface DateObject {
  day: number;
  month: number;
  year: number;
}

interface Option {
  index: number;
  start: DateObject;
  end: DateObject;
}

export function generateOptionsFromRoyaltyPeriods(
  years: number[],
  periods: number[],
): Option[] {
  const options: Option[] = [];
  const currentDate = new Date();

  let index = 0;

  for (let i = 0; i < years.length; i++) {
    for (let j = 0; j < periods.length; j++) {
      const startMonth = periods[j];
      const endMonth = periods[(j + 1) % periods.length];
      const currentYear = years[i];
      const nextYear = endMonth > startMonth ? currentYear : currentYear + 1;
      const endDateMonth = endMonth === 1 ? 12 : endMonth - 1;
      const endDateYear = endMonth === 1 ? nextYear - 1 : nextYear;

      const start: DateObject = {
        day: 1,
        month: startMonth,
        year: currentYear,
      };

      // Skip periods where the start date is in the future
      const startDate = new Date(start.year, start.month - 1, start.day);
      if (startDate > currentDate) {
        continue;
      }

      const end: DateObject = {
        day: new Date(nextYear, endMonth - 1, 0).getDate(), // Last day of the endMonth-1
        month: endDateMonth,
        year: endDateYear,
      };

      options.push({
        index: index++,
        start,
        end,
      });
    }
  }

  return options;
}

// TODO: Remove this function as it's not used anymore. It's kept for reference.
export function generateOptionsFromRoyaltyPeriodsDeprecated(
  years: number[],
  periods: number[],
): RoyaltyPeriod[] {
  // To have unique id for each item
  let runningIndex = 0;

  const mappedPeriods: (RoyaltyPeriod | null)[] = years
    .map((year, i) => {
      runningIndex += i;

      return periods?.map((period, index, arr) => {
        const t = new Date(year, period - 1, 1);

        if (t >= new Date()) {
          return null;
        }

        runningIndex += index;

        return {
          index: runningIndex,
          start: {
            day: 1,
            month: period,
            year,
          },
          end: {
            day: getLastDayOfMonth(
              calculateSumWithReset(period, 12 / arr.length, 12),
            ),
            // prettier-ignore
            month: calculateSumWithReset(period, (12 / arr.length - 1), 12),
            // prettier-ignore
            year: (calculateSumWithReset(period, (12 / periods.length), 12) - 1) < period ? year + 1 : year,
          },
        };
      });
    })
    .flat();

  const filteredPeriods: RoyaltyPeriod[] = mappedPeriods.filter(notEmpty);

  return filteredPeriods;
}

export function generateYearsArray(startDate: Date): number[] {
  const currentYear = new Date().getFullYear();
  const startYear = new Date(startDate).getFullYear();

  const yearsArray: number[] = Array.from(
    { length: currentYear - startYear + 1 },
    (_, index) => startYear + index,
  );

  return yearsArray;
}

export function findObjectByDate(objects: RoyaltyPeriod[], currentDate: Date) {
  for (let i = 0; i < objects.length; i++) {
    const startDate = new Date(
      objects[i].start.year,
      objects[i].start.month - 1,
      objects[i].start.day,
    );
    const endDate = new Date(
      objects[i].end.year,
      objects[i].end.month - 1,
      objects[i].end.day,
    );

    if (currentDate >= startDate && currentDate <= endDate) {
      return objects[i].index;
    }
  }

  return null;
}

export function isDateBetween(startDate: Date, endDate: Date, checkDate: Date) {
  const startMillis = startDate.getTime();
  const endMillis = endDate.getTime();
  const checkMillis = checkDate.getTime();

  // Check if the checkDate is within the range
  return checkMillis >= startMillis && checkMillis <= endMillis;
}

export function getDateAfter100Days(fromDate: Date): Date {
  const toDate = new Date(fromDate);
  toDate.setDate(toDate.getDate() + 100);
  return toDate;
}

export function checkForContractValidity(
  contracts: GetArtistContractsQuery['contracts'],
) {
  if (typeof contracts === 'undefined' || contracts === null) {
    return false;
  } else {
    for (const obj of contracts) {
      if (obj?.royaltyPeriod?.length === 0) {
        return false;
      }
    }
  }

  return true;
}
