import { ViewError } from '@/components/errors/view-error';
import { ViewLoader } from '@/components/loaders/view-loader';
import { AdminHeader } from '@/components/partials/admin-header';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { useListCollaboratorsQuery } from '@/gql/generated-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import { useMutation } from '@apollo/client';
import { toast } from '@/components/ui/use-toast';
import { CollaboratorTable } from './components/collaborator-table';
import { DELETE_COLLABORATOR } from '@/graphql/mutations';
import { useSearch } from '@tanstack/react-router';

export default function AdminCollaborators() {
  const { t } = useTranslation();
  const { name } = useSearch({ strict: false });
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState<number | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>(name ?? '');
  const { loading, error, data, refetch } = useListCollaboratorsQuery({
    fetchPolicy: 'no-cache',
  });

  const [deleteCollaborator] = useMutation(DELETE_COLLABORATOR, {
    onError: _ => {
      toast({
        variant: 'destructive',
        title: t('adminArtist.collaborators.deleteErrorTitle'),
        description: t('adminArtist.collaborators.deleteErrorDescription'),
      });
    },
    onCompleted: _ => {
      refetch();
      toast({
        title: t('adminArtist.collaborators.deleteSucccessTitle'),
        description: t('adminArtist.collaborators.deleteSuccessDescription'),
      });
      setOpen(false);
      setSelected(null);
    },
  });

  if (error) {
    return <ViewError />;
  }

  return (
    <>
      <AdminHeader title={t('adminArtist.collaborators.title')} />
      {loading && <ViewLoader />}
      {!loading && data && (
        <>
          <div className="space-y-4 md:space-y-0">
            <div className="space-y-4 lg:max-w-[25%] min-w-[25%] max-w-full">
              <Input
                type="text"
                value={searchTerm}
                placeholder={t(
                  'adminArtist.collaborators.searchForCollaborators',
                )}
                className="text-grey-darkest placeholder:text-grey-dark font-roboto border-0 px-2 w-full"
                onChange={event => setSearchTerm(event.target.value)}
              />
            </div>
          </div>
          <div>
            <CollaboratorTable
              onDeleteAction={id => {
                setSelected(id);
                setOpen(true);
              }}
              data={data?.listCollaborators?.filter(collab =>
                collab?.name?.toLowerCase().includes(searchTerm.toLowerCase()),
              )}
            />
          </div>
          <Dialog
            open={open}
            onOpenChange={open => {
              if (!open) {
                setSelected(null);
              }
              setOpen(open);
            }}
          >
            <DialogContent className="space-y-6 max-h-[800px] overflow-scroll">
              <DialogHeader>
                <DialogTitle className="text-xl font-medium">
                  {t('adminArtist.collaborators.dialogTitle')}
                </DialogTitle>
                <DialogDescription>
                  {t('adminArtist.collaborators.dialogDescription')}
                </DialogDescription>
              </DialogHeader>

              <div className="flex justify-between">
                <Button
                  type="button"
                  size="sm"
                  variant="outline"
                  onClick={() => setOpen(false)}
                >
                  {t('adminArtist.collaborators.cancel')}
                </Button>
                <Button
                  type="button"
                  size="sm"
                  variant="destructive"
                  onClick={() =>
                    deleteCollaborator({
                      variables: {
                        deleteCollaboratorId: selected,
                      },
                    })
                  }
                >
                  {t('adminArtist.collaborators.delete')}
                </Button>
              </div>
            </DialogContent>
          </Dialog>
        </>
      )}
    </>
  );
}
