import { clsx, type ClassValue } from 'clsx';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function isDefined<T>(argument: T | undefined): argument is T {
  return argument !== undefined;
}

export function sumIncome(numbers: number[] | undefined) {
  if (!numbers) return 0;
  return numbers.reduce((sum, num) => {
    // Ensure the number is non-negative
    const nonNegativeNum = Math.max(num, 0);

    // Add the non-negative number to the sum
    return sum + nonNegativeNum;
  }, 0);
}

export function arrayOfStringsToSum(arr: string[] | undefined): number {
  if (!arr) return 0;

  return arr.reduce((sum, num) => sum + parseFloat(num), 0);
}
