import { useIdleTimer } from 'react-idle-timer';
import { PropsWithChildren } from 'react';
import { useAuth } from '@clerk/clerk-react';

export function IdleProvider(props: PropsWithChildren) {
  const timeout = 1000 * 60 * 60 * 2;

  const { isSignedIn, signOut } = useAuth();

  const onIdle = () => {
    if (isSignedIn) {
      signOut();
    }
  };

  useIdleTimer({
    onIdle,
    timeout,
    crossTab: true,
    leaderElection: true,
    syncTimers: 1000,
    throttle: 1000,
    startOnMount: false,
  });

  return <>{props.children}</>;
}
