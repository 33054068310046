import { ADMIN_PATH, ARTIST_PATH } from '@/lib/constants';
import { isUserAdmin } from '@/services/authentication/userRole';
import { SignIn, useSession } from '@clerk/clerk-react';
import { ActiveSessionResource } from '@clerk/types';

export default function SignInPage() {
  const { session } = useSession();
  const isAdmin: boolean = isUserAdmin(session as ActiveSessionResource);
  function getRedirectUrl() {
    if (session) {
      if (isAdmin) {
        return ADMIN_PATH;
      }

      return ARTIST_PATH;
    }

    return '/';
  }

  return (
    <main className={'flex min-h-screen flex-col items-center p-24'}>
      <SignIn path="/sign-in" afterSignInUrl={getRedirectUrl()} />
    </main>
  );
}
