import { GetAssetsReferencesQuery } from '@/gql/generated-types';
import {
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  Table,
} from '@/components/ui/table';
import { IsrcForm } from '../forms/isrc';
import { useTranslation } from 'react-i18next';
interface RoyaltyTableProps {
  data: GetAssetsReferencesQuery['assetReferences'] | undefined;
  onSubmit: (data: { isrc: string; id: number }) => void;
}

export const RoyaltyTable = (props: RoyaltyTableProps) => {
  const { t } = useTranslation();
  if (props.data?.length === 0 || typeof props.data === 'undefined') {
    return <p>{t('nonMatched.noResults')}</p>;
  }
  return (
    <>
      {' '}
      <Table className="border-collapse">
        <TableHeader>
          <TableRow className="text-left">
            <TableHead>{t('nonMatched.artist')}</TableHead>
            <TableHead>{t('nonMatched.assetTitle')}</TableHead>
            <TableHead>{t('nonMatched.assetReference')}</TableHead>
            <TableHead>{t('nonMatched.isrc')}</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {props.data?.map((d, index) => (
            <TableRow variant={index % 2 === 0 ? 'odd' : null} key={d?.id}>
              <TableCell>{d?.assetArtist}</TableCell>
              <TableCell>{d?.assetTitle}</TableCell>
              <TableCell>{d?.assetReference}</TableCell>
              <TableCell>
                <IsrcForm
                  onSubmit={isrc => props.onSubmit({ isrc, id: Number(d?.id) })}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
};
