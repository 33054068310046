import { useTranslation } from 'react-i18next';

interface TranslatedMessageProps {
  translationKey: string;
}

export const TranslatedMessage = (props: TranslatedMessageProps) => {
  const { t } = useTranslation();
  return <>{t(props.translationKey)}</>;
};
