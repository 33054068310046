import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import fiJSON from './locale/fi.json';
export const defaultNS = 'fi';
i18n.use(initReactI18next).init({
  debug: true,
  resources: {
    fi: fiJSON,
  },
  defaultNS,
  lng: 'fi',
});
