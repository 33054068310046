import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  BigInt: { input: any; output: any; }
  DateTime: { input: any; output: any; }
  Decimal: { input: any; output: any; }
};

/** An Artist can be a person or a group. */
export type Artist = {
  __typename?: 'Artist';
  active: Scalars['Boolean']['output'];
  contracts: Array<ArtistContract>;
  costCenter: Scalars['String']['output'];
  /**
   * An artist can be deleted if they have no tracks, aren't featured on any tracks,
   *     and have no contracts. This field returns a boolean indicating whether the the conditions are true.
   */
  deletable: Scalars['Boolean']['output'];
  expenses: Array<Expense>;
  id: Scalars['Int']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  incomeArtists: Array<IncomeArtist>;
  name: Scalars['String']['output'];
  payment: Array<Payment>;
  persons: Array<CompassPerson>;
  trackArtistRoyalty: Array<TrackArtistRoyalty>;
};

/** The balance of an artist aka how much can they bill from the label. */
export type ArtistBalance = {
  __typename?: 'ArtistBalance';
  artistId?: Maybe<Scalars['Int']['output']>;
  balance?: Maybe<Scalars['Decimal']['output']>;
};

export type ArtistContract = {
  __typename?: 'ArtistContract';
  artist: Artist;
  copyrightC?: Maybe<Scalars['String']['output']>;
  copyrightP?: Maybe<Scalars['String']['output']>;
  dateSigned: Scalars['DateTime']['output'];
  /** A contract can be deleted if no track references it. This field returns a boolean indicating whether the the condition is true. */
  deletable?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['Int']['output'];
  /** How many tracks are included in the contract */
  lengthTracks: Scalars['Int']['output'];
  masterOwnership: Scalars['Int']['output'];
  nonCompete: Scalars['Boolean']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  /** Number of options to extend the contract */
  numberOfOptions: Scalars['Int']['output'];
  /** Financial producer's aka the label's share of Gramex royalties */
  producerGramexShare: Scalars['Int']['output'];
  royaltyPeriod: Array<Scalars['Int']['output']>;
  royaltySharePercentage: Scalars['Decimal']['output'];
  royaltyShareType: RoyaltyType;
  tracks: Array<Track>;
  type: ContractType;
};

/** Represents one row of the artist overview table */
export type ArtistOverview = {
  __typename?: 'ArtistOverview';
  artist: Artist;
  artistId: Scalars['Int']['output'];
  artistName: Scalars['String']['output'];
  /** Total number of tracks in the latest contract */
  contractLength: Scalars['Int']['output'];
  costCenter: Scalars['String']['output'];
  /** Income Year to Date */
  incomeYTD?: Maybe<Scalars['Decimal']['output']>;
  /** The most recent release date in the past */
  latestReleaseDate?: Maybe<Scalars['DateTime']['output']>;
  /** The nearest release date in the future */
  nextReleaseDate?: Maybe<Scalars['DateTime']['output']>;
  /** Stream count for plays lasting over 30 seconds in the past 30 days */
  over30secStreamsLast30Days?: Maybe<Scalars['BigInt']['output']>;
  /** Total stream count in the past 30 days */
  totalStreamsLast30Days?: Maybe<Scalars['BigInt']['output']>;
  /** Tracks spent from the latest contract */
  tracksFromContract: Scalars['Int']['output'];
};

export type ArtistRoyaltyRule = {
  artistId: Scalars['Int']['input'];
  royaltyPercentage: Scalars['Decimal']['input'];
  royaltyType: RoyaltyType;
};

/** Artist id, display name and royalty share (x / 100) */
export type ArtistRoyaltyShare = {
  __typename?: 'ArtistRoyaltyShare';
  amountEuro: Scalars['Decimal']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type ByAge = {
  __typename?: 'ByAge';
  streams0to17?: Maybe<Scalars['BigInt']['output']>;
  streams18to22?: Maybe<Scalars['BigInt']['output']>;
  streams23to27?: Maybe<Scalars['BigInt']['output']>;
  streams28to34?: Maybe<Scalars['BigInt']['output']>;
  streams35to44?: Maybe<Scalars['BigInt']['output']>;
  streams45to59?: Maybe<Scalars['BigInt']['output']>;
  streams60to150?: Maybe<Scalars['BigInt']['output']>;
  streamsUnknown?: Maybe<Scalars['BigInt']['output']>;
  total?: Maybe<Scalars['BigInt']['output']>;
};

export type ByDsp = {
  __typename?: 'ByDsp';
  amazon?: Maybe<Scalars['BigInt']['output']>;
  deezer?: Maybe<Scalars['BigInt']['output']>;
  itunes?: Maybe<Scalars['BigInt']['output']>;
  soundcloud?: Maybe<Scalars['BigInt']['output']>;
  spotify?: Maybe<Scalars['BigInt']['output']>;
  total?: Maybe<Scalars['BigInt']['output']>;
  youtube?: Maybe<Scalars['BigInt']['output']>;
  youtube_music?: Maybe<Scalars['BigInt']['output']>;
};

export type ByGender = {
  __typename?: 'ByGender';
  female?: Maybe<Scalars['BigInt']['output']>;
  male?: Maybe<Scalars['BigInt']['output']>;
  other?: Maybe<Scalars['BigInt']['output']>;
  total?: Maybe<Scalars['BigInt']['output']>;
  unknown?: Maybe<Scalars['BigInt']['output']>;
};

export type ByPlayLength = {
  __typename?: 'ByPlayLength';
  streamsOver30?: Maybe<Scalars['BigInt']['output']>;
  streamsSub30?: Maybe<Scalars['BigInt']['output']>;
  total?: Maybe<Scalars['BigInt']['output']>;
};

/** A person or an entity outside of the label's roster. */
export type Collaborator = {
  __typename?: 'Collaborator';
  active: Scalars['Boolean']['output'];
  contracts: Array<CollaboratorContract>;
  costCenter?: Maybe<Scalars['String']['output']>;
  deletable: Scalars['Boolean']['output'];
  expense: Array<Expense>;
  id: Scalars['Int']['output'];
  incomeCollaborator: Array<IncomeCollaborator>;
  name: Scalars['String']['output'];
  person?: Maybe<CompassPerson>;
  trackCollaboratorRoyalty: Array<TrackCollaboratorRoyalty>;
  tracks: Array<TrackCollaborators>;
  type?: Maybe<CollaboratorRole>;
};

export type CollaboratorContract = {
  __typename?: 'CollaboratorContract';
  collaborator: Collaborator;
  dateSigned?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  role: CollaboratorRole;
  royaltySharePercentage?: Maybe<Scalars['Decimal']['output']>;
  royaltyShareType?: Maybe<RoyaltyType>;
};

export enum CollaboratorRole {
  Producer = 'PRODUCER',
  Songwriter = 'SONGWRITER',
  ThirdParty = 'THIRD_PARTY'
}

export type CollaboratorRoyaltyRule = {
  collaboratorId: Scalars['Int']['input'];
  royaltyPercentage: Scalars['Decimal']['input'];
  royaltyType: RoyaltyType;
};

/** Collaborator id, display name and royalty share (x / 100) */
export type CollaboratorRoyaltyShare = {
  __typename?: 'CollaboratorRoyaltyShare';
  amountEuro: Scalars['Decimal']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

/** Limited set of a person's information. For privacy reasons,\nfull details are stored in ContractPerson. */
export type CompassPerson = {
  __typename?: 'CompassPerson';
  artists: Array<Artist>;
  contractPerson: ContractPerson;
  id: Scalars['Int']['output'];
  user?: Maybe<User>;
};

/** Full Person information is stored in ContractPerson, limited set in CompassPerson */
export type ContractPerson = {
  __typename?: 'ContractPerson';
  compassPerson?: Maybe<CompassPerson>;
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  gramexNumber?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  lastName: Scalars['String']['output'];
  telephone: Scalars['String']['output'];
};

export enum ContractType {
  JointVenture = 'JOINT_VENTURE',
  Licensing = 'LICENSING',
  Master = 'MASTER',
  Other = 'OTHER'
}

export type CopyTracksAsPromoInput = {
  /** ID of the track to copy data from */
  id: Scalars['Int']['input'];
  /** Create or connect to an existing ISRC code. Type will be 'PROMO' */
  isrc: Scalars['String']['input'];
};

export type CreateDuplicateReleaseInput = {
  catalogNumber: Scalars['String']['input'];
  releaseDate: Scalars['DateTime']['input'];
  /** ID of the release to copy data from */
  releaseId: Scalars['Int']['input'];
  title: Scalars['String']['input'];
  upcNumber: Scalars['BigInt']['input'];
};

export type CreateIsrcInput = {
  isrc: Scalars['String']['input'];
  type?: InputMaybe<IsrcType>;
};

export type CreatePaymentInput = {
  amount: Scalars['Decimal']['input'];
  artistId: Scalars['Int']['input'];
  comment?: InputMaybe<Scalars['String']['input']>;
  date: Scalars['DateTime']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
};

export type CreatePromoReleaseInput = {
  catalogNumber: Scalars['String']['input'];
  releaseDate: Scalars['DateTime']['input'];
  /** ID of the release to copy data from */
  releaseId: Scalars['Int']['input'];
  tracks: Array<CopyTracksAsPromoInput>;
  upcNumber: Scalars['BigInt']['input'];
};

export type CreateTrackArtistInput = {
  artistId: Scalars['Int']['input'];
  royaltyRules?: InputMaybe<Array<CreateTrackArtistRoyaltyInput>>;
  type: TrackArtistType;
};

/** Input track royalty rules for a given artist. */
export type CreateTrackArtistRoyaltyInput = {
  paymentAmount: Scalars['Int']['input'];
  recoupableAmount: Scalars['Int']['input'];
  recoupableType?: InputMaybe<RecoupableType>;
  royaltyPercentage: Scalars['Decimal']['input'];
  royaltyType: RoyaltyType;
};

export type CreateTrackCollaboratorInput = {
  collaboratorId: Scalars['Int']['input'];
  role: CollaboratorRole;
  royaltyRules: Array<CreateTrackCollaboratorRoyaltyInput>;
};

/** Input track royalty rules for a given artist. */
export type CreateTrackCollaboratorRoyaltyInput = {
  paymentAmount?: InputMaybe<Scalars['Int']['input']>;
  recoupableAmount?: InputMaybe<Scalars['Int']['input']>;
  recoupableType?: InputMaybe<RecoupableType>;
  royaltyPercentage?: InputMaybe<Scalars['Decimal']['input']>;
  royaltyType?: InputMaybe<RoyaltyType>;
};

export enum Dsp {
  Amazon = 'AMAZON',
  AmazonAds = 'AMAZON_ADS',
  AmazonPrime = 'AMAZON_PRIME',
  AmazonUnlimited = 'AMAZON_UNLIMITED',
  AppleMusic = 'APPLE_MUSIC',
  Beatsource = 'BEATSOURCE',
  Bugs = 'BUGS',
  Deezer = 'DEEZER',
  Imusica = 'IMUSICA',
  Itunes = 'ITUNES',
  ItunesMatch = 'ITUNES_MATCH',
  LineMusic = 'LINE_MUSIC',
  Melon = 'MELON',
  Napster = 'NAPSTER',
  Naver = 'NAVER',
  Qobuz = 'QOBUZ',
  Spotify = 'SPOTIFY',
  Tidal = 'TIDAL',
  Unknown = 'UNKNOWN',
  YoutubeAdSupported = 'YOUTUBE_AD_SUPPORTED',
  YoutubeMusic = 'YOUTUBE_MUSIC'
}

export type DspEntry = {
  __typename?: 'DspEntry';
  count?: Maybe<Scalars['BigInt']['output']>;
  dspId?: Maybe<Scalars['Int']['output']>;
  dspName?: Maybe<Scalars['String']['output']>;
};

/** Error key and message for royalty validation */
export type ErrorOrWarning = {
  __typename?: 'ErrorOrWarning';
  key: RoyaltyValidationErrorKey;
  message: Scalars['String']['output'];
};

export type Expense = {
  __typename?: 'Expense';
  amount: Scalars['Decimal']['output'];
  artist: Artist;
  category?: Maybe<ExpenseCategory>;
  date: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  poNumber: Scalars['String']['output'];
  taggedCollaborators: Array<Collaborator>;
};

export enum ExpenseCategory {
  Marketing = 'MARKETING',
  MusicProduction = 'MUSIC_PRODUCTION',
  Other = 'OTHER',
  VideoProduction = 'VIDEO_PRODUCTION'
}

export type ExpenseDetailsAndTotal = {
  __typename?: 'ExpenseDetailsAndTotal';
  /** List of expenses */
  details: Array<Maybe<Expense>>;
  /** Total sum of expenses */
  total: Scalars['Decimal']['output'];
};

export type IncomeArtist = {
  __typename?: 'IncomeArtist';
  amountEuro: Scalars['Decimal']['output'];
  amountOriginal: Scalars['Decimal']['output'];
  artistId: Scalars['Int']['output'];
  currencyOriginal: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  isrc?: Maybe<IsrcType>;
  originCountryCodeIso3166: Scalars['String']['output'];
  periodEndDate: Scalars['String']['output'];
  periodStartDate: Scalars['String']['output'];
  saleType?: Maybe<SaleType>;
  source?: Maybe<Dsp>;
  type?: Maybe<IncomeType>;
};

export type IncomeArtistAggregated = {
  __typename?: 'IncomeArtistAggregated';
  incomeByCountryAndDsp?: Maybe<Array<Maybe<IncomeByCountryAndDsp>>>;
  totalIncome?: Maybe<Scalars['Decimal']['output']>;
};

export type IncomeArtistByTrack = {
  __typename?: 'IncomeArtistByTrack';
  income?: Maybe<Scalars['Decimal']['output']>;
  track?: Maybe<TrackAndId>;
};

export type IncomeByCountryAndDsp = {
  __typename?: 'IncomeByCountryAndDsp';
  amountEuro?: Maybe<Scalars['Decimal']['output']>;
  originCountryCodeIso3166?: Maybe<Scalars['String']['output']>;
  source?: Maybe<Scalars['String']['output']>;
};

export type IncomeCollaborator = {
  __typename?: 'IncomeCollaborator';
  amountEuro: Scalars['Decimal']['output'];
  amountOriginal: Scalars['Decimal']['output'];
  collaborator: Collaborator;
  createdAt: Scalars['DateTime']['output'];
  currencyOriginal: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  isrc: Isrc;
  originCountryCodeIso3166: Scalars['String']['output'];
  periodEndDate: Scalars['String']['output'];
  periodStartDate: Scalars['String']['output'];
  run?: Maybe<RoyaltyStatementRun>;
  saleType: SaleType;
  source: Dsp;
  type: IncomeType;
  updatedAt: Scalars['DateTime']['output'];
};

export type IncomePme = {
  __typename?: 'IncomePme';
  amountEuro: Scalars['Decimal']['output'];
  amountOriginal: Scalars['Decimal']['output'];
  currencyOriginal: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  originCountryCodeIso3166: Scalars['String']['output'];
  periodEndDate: Scalars['String']['output'];
  periodStartDate: Scalars['String']['output'];
  saleType?: Maybe<SaleType>;
  source?: Maybe<Dsp>;
  type?: Maybe<IncomeType>;
};

export type IncomeTotals = {
  __typename?: 'IncomeTotals';
  expenses: Scalars['Decimal']['output'];
  income: Scalars['Decimal']['output'];
  payments: Scalars['Decimal']['output'];
  pmeShare: Scalars['Decimal']['output'];
};

export type IncomeTotalsV2 = {
  __typename?: 'IncomeTotalsV2';
  /** Total income for the artist */
  artistIncome: Scalars['Decimal']['output'];
  /** Total income for the collaborators on artist's tracks */
  collaboratorIncome: Scalars['Decimal']['output'];
  expenses: ExpenseDetailsAndTotal;
  /** Total income for the featured artists on artist's tracks */
  featuredArtistIncome: Scalars['Decimal']['output'];
  /** Payments made to the artist */
  payments: Scalars['Decimal']['output'];
  /** Total income for the PME */
  pmeIncome: Scalars['Decimal']['output'];
  /** Total recoupments for the artist */
  recoupments: Scalars['Decimal']['output'];
  /** Total revenue before any deductions */
  toplineRevenue: Scalars['Decimal']['output'];
};

export enum IncomeType {
  Gramex = 'GRAMEX',
  Promo = 'PROMO',
  Royalty = 'ROYALTY'
}

export type Isrc = {
  __typename?: 'Isrc';
  id: Scalars['Int']['output'];
  incomeArtist: Array<IncomeArtist>;
  isrc: Scalars['String']['output'];
  tracks: Array<Track>;
};

export enum IsrcType {
  Alternative = 'ALTERNATIVE',
  Original = 'ORIGINAL',
  Promo = 'PROMO'
}

export type Mutation = {
  __typename?: 'Mutation';
  /**
   * Create a duplicate release. All data is copied from the original release, except for the title,
   *       UPC code, catalog number, release date, and YouTube asset reference.
   */
  createDuplicateRelease: Release;
  createPayment: Payment;
  /**
   * Create a promo release. All data is copied from the original release, except for the title,
   *       UPC code, catalog number, release date, and YouTube asset reference, as well as Track ISRC codes.
   */
  createPromoRelease: Release;
  deleteArtist: Artist;
  deleteArtistContract: ArtistContract;
  deleteCollaborator: Collaborator;
  deletePayment: Payment;
  deleteUser: User;
  /** Start processing all unmatched royalties. */
  processRoyalties: Scalars['String']['output'];
  /** Update a single track with an ISRC code. The code should already exist in the database. */
  updateUnmatchedTrack: UnmatchedTrack;
  /**
   * Upsert an artist. When creating an artist, it should be linked to one or multiple existing CompassPersons.
   *         As is, this mutation does not support creating new CompassPersons or updating the linked CompassPersons.
   */
  upsertArtist: Artist;
  upsertArtistContract: ArtistContract;
  upsertCollaborator: Collaborator;
  upsertExpense: Expense;
  /**
   * Upsert a release. Tracks can be created but NOT updated via this mutation.
   *         Assumption is that we should not delete tracks after creation.
   *         If provided UPC does not exist, a new one will be created.
   */
  upsertRelease: Release;
  upsertTrack: Track;
  /**
   * Create or update a user.
   *       If the user with email already exists, we update the existing Clerk metadata,
   *       as well as CompassPerson and ContractPerson.
   */
  upsertUser: User;
};


export type MutationCreateDuplicateReleaseArgs = {
  input: CreateDuplicateReleaseInput;
};


export type MutationCreatePaymentArgs = {
  input: CreatePaymentInput;
};


export type MutationCreatePromoReleaseArgs = {
  input: CreatePromoReleaseInput;
};


export type MutationDeleteArtistArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteArtistContractArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteCollaboratorArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeletePaymentArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteUserArgs = {
  id: Scalars['Int']['input'];
};


export type MutationUpdateUnmatchedTrackArgs = {
  input: UpdateUnmatchedTrackInput;
};


export type MutationUpsertArtistArgs = {
  input: UpsertArtistInput;
};


export type MutationUpsertArtistContractArgs = {
  input: UpsertArtistContractInput;
};


export type MutationUpsertCollaboratorArgs = {
  input: UpsertCollaboratorInput;
};


export type MutationUpsertExpenseArgs = {
  input: UpsertExpenseInput;
};


export type MutationUpsertReleaseArgs = {
  input: UpsertReleaseInput;
};


export type MutationUpsertTrackArgs = {
  input: UpsertTrackInput;
};


export type MutationUpsertUserArgs = {
  input: UpsertUserInput;
};

export type Payment = {
  __typename?: 'Payment';
  amount: Scalars['Decimal']['output'];
  artist: Artist;
  comment?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  createdBy: User;
  date: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  title?: Maybe<Scalars['String']['output']>;
};

export enum ProductType {
  Digital = 'DIGITAL',
  PhysicalCd = 'PHYSICAL_CD',
  PhysicalVinyl = 'PHYSICAL_VINYL'
}

export type Query = {
  __typename?: 'Query';
  /**
   * Fetch the total number of streams for all artists.
   *       Included are tracks where the artist is the "main" artist and a song is played for at least 30 seconds.
   *       Provide a start and end date to filter by date.
   */
  allArtistStreamTotals?: Maybe<Array<Maybe<StreamsForArtist>>>;
  artist?: Maybe<Artist>;
  /**
   * Fetch the balance for an artist. The balance is calculated as follows:
   *       (Income from last ended billing season) - Recoupable payments - Expenses - Payments
   */
  artistBalance?: Maybe<ArtistBalance>;
  artistExpense?: Maybe<Expense>;
  artistExpenseDetails?: Maybe<Array<Maybe<Expense>>>;
  /** @deprecated Use artistIncomeTotalsV2 instead. */
  artistIncomeTotals?: Maybe<IncomeTotals>;
  artistIncomeTotalsV2?: Maybe<IncomeTotalsV2>;
  artistIncomes?: Maybe<Array<Maybe<IncomeArtist>>>;
  artistIncomesAggregated?: Maybe<IncomeArtistAggregated>;
  /** @deprecated Deprected and to be removed unless a new need arises. Use artistIncomeByTrack instead. */
  artistIncomesByTrack?: Maybe<Array<Maybe<IncomeArtistByTrack>>>;
  artistOverview?: Maybe<Array<Maybe<ArtistOverview>>>;
  /** Fetch all payments made to an artist. */
  artistPayments?: Maybe<Array<Maybe<Payment>>>;
  artistRecoupments?: Maybe<Scalars['Decimal']['output']>;
  /** Get total revenue for each track by an artist. Sorted by revenue DESC. */
  artistRevenueByTrack?: Maybe<Array<Maybe<RevenueArtistByTrack>>>;
  artistsByEmail?: Maybe<Array<Maybe<Artist>>>;
  /**
   * Fetch all tracks from royalty reports which haven't been matched to a track in the database yet.
   *       To match a track to an ISRC code, use the 'updateUnmatchedTrack' mutation.
   *       To only fetch tracks without an ISRC, use the 'unprocessedOnly' argument.
   */
  assetReferences?: Maybe<Array<Maybe<UnmatchedTrack>>>;
  /** Fetch all contracts. Optionally filter by artistId or contractId. */
  contracts?: Maybe<Array<Maybe<ArtistContract>>>;
  getPreSignedUrl?: Maybe<Scalars['String']['output']>;
  getRoyaltyStatementRuns?: Maybe<Array<Maybe<RoyaltyStatementRun>>>;
  /**
   * Fetch the total number of streams for an artist, grouped by date.
   *       Included are tracks where the artist is the "main" artist and a song is played for at least 30 seconds.
   *       Provide a start and end date to filter by date, and a track ID to fetch data for a specific track.
   *       Gaps between start and end date will be filled with 0 values.
   *       Also, if there are gaps in the data in the database (as in dates missing), they are also filled.
   */
  getTotalStreamsByDate?: Maybe<Array<Maybe<StreamsForArtistByDate>>>;
  listArtists?: Maybe<Array<Maybe<Artist>>>;
  /** Fetch all collaborators. Optionally filter by id. */
  listCollaborators?: Maybe<Array<Maybe<Collaborator>>>;
  listUsers?: Maybe<Array<Maybe<User>>>;
  /** Returns the currently logged in user */
  me?: Maybe<User>;
  persons?: Maybe<Array<Maybe<CompassPerson>>>;
  release?: Maybe<Release>;
  /** Fetch all releases. Optionally filter by artistId or releaseId. */
  releases?: Maybe<Array<Maybe<Release>>>;
  /** Get an artist's streaming numbers. Provide 'trackId' to filter by track. */
  streamCount?: Maybe<StreamsForArtistAggregated>;
  /**
   * Fetch the total number of streams for an artist.
   *       Included are tracks where the artist is the "main" artist and a song is played for at least 30 seconds.,
   *       Provide a start and end date to filter by date.
   */
  streamsForArtist?: Maybe<Scalars['Int']['output']>;
  /**
   * Fetch the total number of streams for an artist, grouped by track.
   *       Included are tracks where the artist is the "main" artist and a song is played for at least 30 seconds.
   *       Provide a start and end date to filter by date.
   */
  streamsForArtistByTrack?: Maybe<Array<Maybe<StreamsForArtistByTrack>>>;
  track?: Maybe<Track>;
  /** Fetch all tracks. Optionally filter by artistId or releaseId or both. */
  tracks?: Maybe<Array<Maybe<Track>>>;
  /**
   * Validate royalty share rules.
   *       The validation checks that all shares are positive and warns about possible zero shares.
   *       The 'pmeShare' is the share for the label.
   */
  validateRoyaltyRules?: Maybe<RoyaltyValidationResult>;
};


export type QueryAllArtistStreamTotalsArgs = {
  costCenter?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['String']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryArtistArgs = {
  id: Scalars['Int']['input'];
};


export type QueryArtistBalanceArgs = {
  artistId: Scalars['Int']['input'];
};


export type QueryArtistExpenseArgs = {
  id: Scalars['Int']['input'];
};


export type QueryArtistExpenseDetailsArgs = {
  artistId: Scalars['Int']['input'];
  endDate?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['String']['input']>;
};


export type QueryArtistIncomeTotalsArgs = {
  artistId: Scalars['Int']['input'];
  endDate?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['String']['input']>;
};


export type QueryArtistIncomeTotalsV2Args = {
  artistId: Scalars['Int']['input'];
  endDate?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['String']['input']>;
};


export type QueryArtistIncomesArgs = {
  id: Scalars['Int']['input'];
};


export type QueryArtistIncomesAggregatedArgs = {
  id: Scalars['Int']['input'];
};


export type QueryArtistIncomesByTrackArgs = {
  artistId: Scalars['Int']['input'];
  endDate?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['String']['input']>;
};


export type QueryArtistOverviewArgs = {
  costCenter?: InputMaybe<Scalars['String']['input']>;
};


export type QueryArtistPaymentsArgs = {
  artistId?: InputMaybe<Scalars['Int']['input']>;
  endDate?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['String']['input']>;
};


export type QueryArtistRecoupmentsArgs = {
  artistId: Scalars['Int']['input'];
};


export type QueryArtistRevenueByTrackArgs = {
  artistId: Scalars['Int']['input'];
  endDate?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['String']['input']>;
};


export type QueryArtistsByEmailArgs = {
  email: Scalars['String']['input'];
};


export type QueryAssetReferencesArgs = {
  take?: InputMaybe<Scalars['Int']['input']>;
  unprocessedOnly?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryContractsArgs = {
  artistId?: InputMaybe<Scalars['Int']['input']>;
  contractId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetPreSignedUrlArgs = {
  dsp: RoyaltySourceProvider;
  fileName: Scalars['String']['input'];
  month: Scalars['Int']['input'];
  uploadType: UploadType;
  year: Scalars['Int']['input'];
};


export type QueryGetRoyaltyStatementRunsArgs = {
  royaltyProvider: RoyaltySourceProvider;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetTotalStreamsByDateArgs = {
  artistId: Scalars['Int']['input'];
  endDate?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['String']['input']>;
  trackId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryListArtistsArgs = {
  costCenter?: InputMaybe<Scalars['String']['input']>;
};


export type QueryListCollaboratorsArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryReleaseArgs = {
  id: Scalars['Int']['input'];
};


export type QueryReleasesArgs = {
  artistId?: InputMaybe<Scalars['Int']['input']>;
  releaseId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryStreamCountArgs = {
  artistId: Scalars['Int']['input'];
  endDate?: InputMaybe<Scalars['String']['input']>;
  groupBy?: InputMaybe<StreamCountGroupBy>;
  startDate?: InputMaybe<Scalars['String']['input']>;
  trackId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryStreamsForArtistArgs = {
  artistId: Scalars['Int']['input'];
  endDate?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['String']['input']>;
};


export type QueryStreamsForArtistByTrackArgs = {
  artistId: Scalars['Int']['input'];
  endDate?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['String']['input']>;
  trackId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryTrackArgs = {
  id: Scalars['Int']['input'];
};


export type QueryTracksArgs = {
  artistId?: InputMaybe<Scalars['Int']['input']>;
  releaseId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryValidateRoyaltyRulesArgs = {
  artistRules: Array<ArtistRoyaltyRule>;
  collaboratorRules: Array<CollaboratorRoyaltyRule>;
};

export enum RecoupableType {
  NoLimit = 'NO_LIMIT',
  PayWhenAllRecouped = 'PAY_WHEN_ALL_RECOUPED',
  PayWhenSelfRecouped = 'PAY_WHEN_SELF_RECOUPED'
}

export type Release = {
  __typename?: 'Release';
  catalogNumber: Scalars['String']['output'];
  coverArt?: Maybe<Scalars['String']['output']>;
  /** The original release that this is a DUPLICATE of */
  duplicateOf?: Maybe<Release>;
  duplicateReleases: Array<Release>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  productType?: Maybe<ProductType>;
  promoAssets?: Maybe<Scalars['String']['output']>;
  /** The original release that this is a PROMO of */
  promoOf?: Maybe<Release>;
  promoReleases: Array<Release>;
  releaseArtists: Array<Artist>;
  releaseDate: Scalars['DateTime']['output'];
  releaseType?: Maybe<ReleaseType>;
  tracks: Array<Track>;
  upc?: Maybe<Upc>;
  version?: Maybe<Scalars['String']['output']>;
};

export enum ReleaseType {
  Album = 'ALBUM',
  Ep = 'EP',
  Single = 'SINGLE'
}

export type RevenueArtistByTrack = {
  __typename?: 'RevenueArtistByTrack';
  revenue: Scalars['Decimal']['output'];
  track?: Maybe<TrackAndId>;
};

export enum RoyaltySourceProvider {
  Fuga = 'FUGA',
  MerlinDeezer = 'MERLIN_DEEZER',
  MerlinSpotify = 'MERLIN_SPOTIFY'
}

/** Info on when a royalty statement file was uploaded and processed */
export type RoyaltyStatementRun = {
  __typename?: 'RoyaltyStatementRun';
  createdAt: Scalars['DateTime']['output'];
  fileName: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  periodEndDate: Scalars['DateTime']['output'];
  periodStartDate: Scalars['DateTime']['output'];
  processedAt?: Maybe<Scalars['DateTime']['output']>;
  royaltySource: RoyaltySourceProvider;
};

export enum RoyaltyType {
  AccountingPrice = 'ACCOUNTING_PRICE',
  ArtistShare = 'ARTIST_SHARE'
}

export enum RoyaltyValidationErrorKey {
  NegativeShare = 'NEGATIVE_SHARE',
  ZeroShare = 'ZERO_SHARE'
}

/**
 * Artist, collaborator and label royalty shares and possible validation errors.
 *     The result is how an imaginary 100 euros income would be distributed between all parties.
 *     Warnings are to be taken into account but do not prevent the royalty statement from being processed:
 *     e.g. a zero share is a valid scenario.
 */
export type RoyaltyValidationResult = {
  __typename?: 'RoyaltyValidationResult';
  artistShares: Array<Maybe<ArtistRoyaltyShare>>;
  collaboratorShares: Array<Maybe<CollaboratorRoyaltyShare>>;
  errors: Array<Maybe<ErrorOrWarning>>;
  pmeShare: Scalars['Decimal']['output'];
  warnings: Array<Maybe<ErrorOrWarning>>;
};

export enum SaleType {
  Download = 'DOWNLOAD',
  Other = 'OTHER',
  Stream = 'STREAM',
  Unknown = 'UNKNOWN',
  VideoStreamAudio = 'VIDEO_STREAM_AUDIO',
  VideoStreamAudioVideo = 'VIDEO_STREAM_AUDIO_VIDEO'
}

export enum StreamCountGroupBy {
  Age = 'age',
  Dsp = 'dsp',
  Gender = 'gender',
  PlayLength = 'playLength'
}

/** Streams for all artists */
export type StreamsForArtist = {
  __typename?: 'StreamsForArtist';
  costCenter?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  streams: Scalars['BigInt']['output'];
};

/**
 * Aggregated stream counts for artist. Grouped by listener gender,
 *   age group, or play length.
 */
export type StreamsForArtistAggregated = ByAge | ByDsp | ByGender | ByPlayLength;

/** Streams for artist by date */
export type StreamsForArtistByDate = {
  __typename?: 'StreamsForArtistByDate';
  date: Scalars['DateTime']['output'];
  streams: Scalars['BigInt']['output'];
};

/** Streams for artist by track */
export type StreamsForArtistByTrack = {
  __typename?: 'StreamsForArtistByTrack';
  streamsByDsp?: Maybe<Array<Maybe<DspEntry>>>;
  totalStreams?: Maybe<Scalars['BigInt']['output']>;
  trackCoverArt?: Maybe<Scalars['String']['output']>;
  trackId?: Maybe<Scalars['Int']['output']>;
  trackName?: Maybe<Scalars['String']['output']>;
};

export type Track = {
  __typename?: 'Track';
  contract: ArtistContract;
  /** The original track that this is a DUPLICATE of */
  duplicateOf?: Maybe<Track>;
  /** The tracks that are duplicates of this track */
  duplicateTracks: Array<Track>;
  gramexLabelPercentage: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  isrc: Isrc;
  /** @deprecated Use `isrc` instead. A track can only have one ISRC code. */
  isrcs?: Maybe<Array<Maybe<Isrc>>>;
  masterAudio?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  order: Scalars['Int']['output'];
  /** The original track that this is a PROMO of */
  promoOf?: Maybe<Track>;
  /** The tracks that are promos of this track */
  promoTracks: Array<Track>;
  release: Release;
  trackArtistRoyalty: Array<TrackArtistRoyalty>;
  trackArtists: Array<TrackArtist>;
  trackCollaboratorRoyalty: Array<TrackCollaboratorRoyalty>;
  trackCollaborators: Array<TrackCollaborators>;
  version?: Maybe<Scalars['String']['output']>;
  volume: Scalars['Int']['output'];
  youtubeAssetReference?: Maybe<Scalars['String']['output']>;
};

export type TrackAndId = {
  __typename?: 'TrackAndId';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  releaseCoverArt?: Maybe<Scalars['String']['output']>;
};

export type TrackArtist = {
  __typename?: 'TrackArtist';
  artist: Artist;
  artistId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  track: Track;
  trackId: Scalars['Int']['output'];
  type?: Maybe<TrackArtistType>;
};

export type TrackArtistRoyalty = {
  __typename?: 'TrackArtistRoyalty';
  artist: Artist;
  artistId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  paymentAmount?: Maybe<Scalars['Int']['output']>;
  recoupableAmount?: Maybe<Scalars['Int']['output']>;
  recoupableType?: Maybe<RecoupableType>;
  royaltyPercentage: Scalars['Decimal']['output'];
  royaltyType: RoyaltyType;
  track: Track;
};

export enum TrackArtistType {
  Featured = 'FEATURED',
  Main = 'MAIN'
}

/** Royalty sharing rules for Collaborators on a track.\nThese are intentionally loose to support a wide variety of use cases:\nsome collaborators might have a fixed fee, some might have a percentage. */
export type TrackCollaboratorRoyalty = {
  __typename?: 'TrackCollaboratorRoyalty';
  collaborator: Collaborator;
  id: Scalars['Int']['output'];
  paymentAmount?: Maybe<Scalars['Int']['output']>;
  recoupableAmount?: Maybe<Scalars['Int']['output']>;
  recoupableType?: Maybe<RecoupableType>;
  royaltyPercentage?: Maybe<Scalars['Decimal']['output']>;
  royaltyType?: Maybe<RoyaltyType>;
  track: Track;
};

export type TrackCollaborators = {
  __typename?: 'TrackCollaborators';
  collaborator: Collaborator;
  id: Scalars['Int']['output'];
  role: CollaboratorRole;
  track: Track;
};

/** Table to store info about royalty lines that could not be matched to an existing ISRC codes. */
export type UnmatchedTrack = {
  __typename?: 'UnmatchedTrack';
  /** aka track artist. We use the same terminology that is used in royalty reports */
  assetArtist: Scalars['String']['output'];
  /** An asset reference should exist */
  assetReference: Scalars['String']['output'];
  /** aka track title */
  assetTitle: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  incomeType: IncomeType;
  isrc?: Maybe<Isrc>;
  /** Platform - an enum might be in order */
  source: Scalars['String']['output'];
  /** All the parsed royalty lines that have this unknown ISRC code */
  unpaidRoyalties: Array<UnpaidRoyalty>;
};

/** The royalty lines that could not be matched to an existing ISRC codes.\nStore everything in parsed format, so that it can be easily processed later.\nFetch ISRC code from UnmatchedTracks table to allocate the income to correct track. */
export type UnpaidRoyalty = {
  __typename?: 'UnpaidRoyalty';
  country: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  dsp?: Maybe<Dsp>;
  endDate: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  payableAmount: Scalars['Decimal']['output'];
  payableCurrency: Scalars['String']['output'];
  /** Has this case been already handled? Don't delete from this table for tracing purposes */
  processedAt?: Maybe<Scalars['DateTime']['output']>;
  quantity: Scalars['String']['output'];
  saleType?: Maybe<SaleType>;
  startDate: Scalars['String']['output'];
  unmatchedTrack: UnmatchedTrack;
};

export type Upc = {
  __typename?: 'Upc';
  activated?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  upcNumber: Scalars['BigInt']['output'];
};

/** Update an existing unmatched track with ISRC id. This data shouldn't be necessary to be updated with any other information. */
export type UpdateUnmatchedTrackInput = {
  id: Scalars['Int']['input'];
  /** Create or connect to an existing ISRC code. */
  isrc: Scalars['String']['input'];
};

export enum UploadType {
  Royalty = 'ROYALTY'
}

export type UpsertArtistContractInput = {
  artistId: Scalars['Int']['input'];
  copyrightC?: InputMaybe<Scalars['String']['input']>;
  copyrightP?: InputMaybe<Scalars['String']['input']>;
  dateSigned: Scalars['DateTime']['input'];
  id?: Scalars['Int']['input'];
  lengthTracks: Scalars['Int']['input'];
  masterOwnership?: Scalars['Int']['input'];
  nonCompete: Scalars['Boolean']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  numberOfOptions: Scalars['Int']['input'];
  producerGramexShare?: Scalars['Int']['input'];
  /** The starting months of royalty periods; e.g.. [4, 10] would indicate April and October.  */
  royaltyPeriod: Array<Scalars['Int']['input']>;
  royaltySharePercentage: Scalars['Decimal']['input'];
  royaltyShareType: RoyaltyType;
  type: ContractType;
  typeDescription?: InputMaybe<Scalars['String']['input']>;
};

export type UpsertArtistInput = {
  active?: Scalars['Boolean']['input'];
  costCenter: Scalars['String']['input'];
  id?: Scalars['Int']['input'];
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  persons: Array<Scalars['Int']['input']>;
};

export type UpsertCollaboratorInput = {
  active: Scalars['Boolean']['input'];
  costCenter?: InputMaybe<Scalars['String']['input']>;
  id?: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  personId?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<CollaboratorRole>;
};

/** Create or update an Expense. */
export type UpsertExpenseInput = {
  amount: Scalars['Decimal']['input'];
  artistId: Scalars['Int']['input'];
  category: ExpenseCategory;
  date: Scalars['DateTime']['input'];
  description: Scalars['String']['input'];
  id?: Scalars['Int']['input'];
  poNumber: Scalars['String']['input'];
  /**
   * Collaborators to tag on the expense.
   *         This is still WIP: should these be artists or collaborators from outside the label?
   */
  taggedCollaborators?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type UpsertReleaseInput = {
  catalogNumber: Scalars['String']['input'];
  coverArt?: InputMaybe<Scalars['String']['input']>;
  id?: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  productType: ProductType;
  promoAssets?: InputMaybe<Scalars['String']['input']>;
  releaseArtists: Array<Scalars['Int']['input']>;
  releaseDate: Scalars['DateTime']['input'];
  releaseType: ReleaseType;
  tracks?: InputMaybe<Array<UpsertTrackInput>>;
  upcNumber: Scalars['BigInt']['input'];
  version?: InputMaybe<Scalars['String']['input']>;
};

export type UpsertTrackInput = {
  contractId: Scalars['Int']['input'];
  gramexLabelPercentage: Scalars['Int']['input'];
  id?: Scalars['Int']['input'];
  /** Create or connect to an existing ISRC code. */
  isrc: Scalars['String']['input'];
  /** The type of ISRC, if creating a new one. Defaults' to 'ORIGINAL'. */
  isrcType?: IsrcType;
  masterAudio?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  order: Scalars['Int']['input'];
  releaseId?: Scalars['Int']['input'];
  trackArtists: Array<CreateTrackArtistInput>;
  trackCollaborators: Array<CreateTrackCollaboratorInput>;
  version?: InputMaybe<Scalars['String']['input']>;
  volume?: Scalars['Int']['input'];
  youtubeAssetReference?: InputMaybe<Scalars['String']['input']>;
};

/** Create or update a user's information. */
export type UpsertUserInput = {
  artistIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  gramexNumber?: InputMaybe<Scalars['Int']['input']>;
  /** This we might not even need as email can act as a unique identifier */
  id?: Scalars['Int']['input'];
  lastName: Scalars['String']['input'];
  role: UserRole;
  telephone: Scalars['String']['input'];
};

/** Data related to login, persona and role. */
export type User = {
  __typename?: 'User';
  clerkId: Scalars['String']['output'];
  email: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  payment: Array<Payment>;
  person?: Maybe<CompassPerson>;
  role?: Maybe<UserRole>;
};

export enum UserRole {
  Admin = 'ADMIN',
  Artist = 'ARTIST'
}

export type UpsertReleaseMutationVariables = Exact<{
  input: UpsertReleaseInput;
}>;


export type UpsertReleaseMutation = { __typename?: 'Mutation', upsertRelease: { __typename?: 'Release', id: number, catalogNumber: string, coverArt?: string | null, name: string, productType?: ProductType | null, promoAssets?: string | null, releaseDate: any, releaseType?: ReleaseType | null, version?: string | null, tracks: Array<{ __typename?: 'Track', name: string, isrcs?: Array<{ __typename?: 'Isrc', isrc: string } | null> | null, trackArtists: Array<{ __typename?: 'TrackArtist', artistId: number }> }>, upc?: { __typename?: 'Upc', id: number, upcNumber: any, activated?: any | null } | null } };

export type UpsertArtistContractMutationVariables = Exact<{
  input: UpsertArtistContractInput;
}>;


export type UpsertArtistContractMutation = { __typename?: 'Mutation', upsertArtistContract: { __typename?: 'ArtistContract', id: number } };

export type UpsertUserMutationVariables = Exact<{
  input: UpsertUserInput;
}>;


export type UpsertUserMutation = { __typename?: 'Mutation', upsertUser: { __typename?: 'User', id: number } };

export type UpserArtistMutationVariables = Exact<{
  input: UpsertArtistInput;
}>;


export type UpserArtistMutation = { __typename?: 'Mutation', upsertArtist: { __typename?: 'Artist', id: number, name: string } };

export type UpsertExpenseMutationVariables = Exact<{
  input: UpsertExpenseInput;
}>;


export type UpsertExpenseMutation = { __typename?: 'Mutation', upsertExpense: { __typename?: 'Expense', id: number } };

export type UpsertTrackMutationVariables = Exact<{
  input: UpsertTrackInput;
}>;


export type UpsertTrackMutation = { __typename?: 'Mutation', upsertTrack: { __typename?: 'Track', id: number, release: { __typename?: 'Release', id: number } } };

export type CreateDuplicateReleaseMutationVariables = Exact<{
  input: CreateDuplicateReleaseInput;
}>;


export type CreateDuplicateReleaseMutation = { __typename?: 'Mutation', createDuplicateRelease: { __typename?: 'Release', id: number } };

export type CreatePromoReleaseMutationVariables = Exact<{
  input: CreatePromoReleaseInput;
}>;


export type CreatePromoReleaseMutation = { __typename?: 'Mutation', createPromoRelease: { __typename?: 'Release', id: number } };

export type CreatePaymentMutationVariables = Exact<{
  input: CreatePaymentInput;
}>;


export type CreatePaymentMutation = { __typename?: 'Mutation', createPayment: { __typename?: 'Payment', id: number, artist: { __typename?: 'Artist', id: number } } };

export type DeletePaymentMutationVariables = Exact<{
  deletePaymentId: Scalars['Int']['input'];
}>;


export type DeletePaymentMutation = { __typename?: 'Mutation', deletePayment: { __typename?: 'Payment', id: number } };

export type CreateCollaboratorMutationVariables = Exact<{
  input: UpsertCollaboratorInput;
}>;


export type CreateCollaboratorMutation = { __typename?: 'Mutation', upsertCollaborator: { __typename?: 'Collaborator', id: number, name: string } };

export type DeleteArtistMutationVariables = Exact<{
  deleteArtistId: Scalars['Int']['input'];
}>;


export type DeleteArtistMutation = { __typename?: 'Mutation', deleteArtist: { __typename?: 'Artist', id: number } };

export type DeleteCollaboratorMutationVariables = Exact<{
  deleteCollaboratorId: Scalars['Int']['input'];
}>;


export type DeleteCollaboratorMutation = { __typename?: 'Mutation', deleteCollaborator: { __typename?: 'Collaborator', id: number } };

export type DeleteArtistContractMutationVariables = Exact<{
  deleteArtistContractId: Scalars['Int']['input'];
}>;


export type DeleteArtistContractMutation = { __typename?: 'Mutation', deleteArtistContract: { __typename?: 'ArtistContract', id: number } };

export type DeleteUserMutationVariables = Exact<{
  deleteUserId: Scalars['Int']['input'];
}>;


export type DeleteUserMutation = { __typename?: 'Mutation', deleteUser: { __typename?: 'User', id: number } };

export type ProcessRoyaltiesMutationVariables = Exact<{ [key: string]: never; }>;


export type ProcessRoyaltiesMutation = { __typename?: 'Mutation', processRoyalties: string };

export type UpdateUnmatchedTrackMutationVariables = Exact<{
  input: UpdateUnmatchedTrackInput;
}>;


export type UpdateUnmatchedTrackMutation = { __typename?: 'Mutation', updateUnmatchedTrack: { __typename?: 'UnmatchedTrack', id: number } };

export type GetArtistByEmailQueryVariables = Exact<{
  email: Scalars['String']['input'];
}>;


export type GetArtistByEmailQuery = { __typename?: 'Query', artistsByEmail?: Array<{ __typename?: 'Artist', name: string, id: number, imageUrl?: string | null } | null> | null };

export type GetArtistProfileQueryVariables = Exact<{
  artistId: Scalars['Int']['input'];
}>;


export type GetArtistProfileQuery = { __typename?: 'Query', artist?: { __typename?: 'Artist', active: boolean, costCenter: string, imageUrl?: string | null, id: number, name: string, persons: Array<{ __typename?: 'CompassPerson', id: number }> } | null };

export type GetArtistListQueryVariables = Exact<{ [key: string]: never; }>;


export type GetArtistListQuery = { __typename?: 'Query', listArtists?: Array<{ __typename?: 'Artist', active: boolean, costCenter: string, id: number, name: string, imageUrl?: string | null, deletable: boolean, persons: Array<{ __typename?: 'CompassPerson', id: number, user?: { __typename?: 'User', email: string, id: number } | null }> } | null> | null };

export type GetCompassPersonsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCompassPersonsQuery = { __typename?: 'Query', persons?: Array<{ __typename?: 'CompassPerson', id: number, contractPerson: { __typename?: 'ContractPerson', lastName: string, firstName: string } } | null> | null };

export type GetArtistIncomeTotalsQueryVariables = Exact<{
  artistId: Scalars['Int']['input'];
  startDate?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetArtistIncomeTotalsQuery = { __typename?: 'Query', artistIncomeTotals?: { __typename?: 'IncomeTotals', expenses: any, income: any, pmeShare: any } | null };

export type GetArtistIncomeTotalsV2QueryVariables = Exact<{
  artistId: Scalars['Int']['input'];
  startDate?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetArtistIncomeTotalsV2Query = { __typename?: 'Query', artistIncomeTotalsV2?: { __typename?: 'IncomeTotalsV2', toplineRevenue: any, artistIncome: any, collaboratorIncome: any, featuredArtistIncome: any, pmeIncome: any, payments: any, recoupments: any, expenses: { __typename?: 'ExpenseDetailsAndTotal', total: any, details: Array<{ __typename?: 'Expense', id: number, amount: any, description: string } | null> } } | null };

export type GetArtistBalanceQueryVariables = Exact<{
  artistId: Scalars['Int']['input'];
}>;


export type GetArtistBalanceQuery = { __typename?: 'Query', artistBalance?: { __typename?: 'ArtistBalance', artistId?: number | null, balance?: any | null } | null };

export type GetArtistRevenueByTrackQueryVariables = Exact<{
  artistId: Scalars['Int']['input'];
  startDate?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetArtistRevenueByTrackQuery = { __typename?: 'Query', artistRevenueByTrack?: Array<{ __typename?: 'RevenueArtistByTrack', revenue: any, track?: { __typename?: 'TrackAndId', id?: number | null, name?: string | null, releaseCoverArt?: string | null } | null } | null> | null };

export type GetArtistIncomesByTrackQueryVariables = Exact<{
  artistId: Scalars['Int']['input'];
  startDate?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetArtistIncomesByTrackQuery = { __typename?: 'Query', artistIncomesByTrack?: Array<{ __typename?: 'IncomeArtistByTrack', income?: any | null, track?: { __typename?: 'TrackAndId', id?: number | null, name?: string | null, releaseCoverArt?: string | null } | null } | null> | null };

export type GetArtistExpenseDetailsQueryVariables = Exact<{
  artistId: Scalars['Int']['input'];
  startDate?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetArtistExpenseDetailsQuery = { __typename?: 'Query', artistExpenseDetails?: Array<{ __typename?: 'Expense', amount: any, category?: ExpenseCategory | null } | null> | null };

export type GetArtistContractsQueryVariables = Exact<{
  artistId: Scalars['Int']['input'];
}>;


export type GetArtistContractsQuery = { __typename?: 'Query', contracts?: Array<{ __typename?: 'ArtistContract', id: number, dateSigned: any, royaltyPeriod: Array<number>, royaltySharePercentage: any, royaltyShareType: RoyaltyType, deletable?: boolean | null, type: ContractType, artist: { __typename?: 'Artist', name: string } } | null> | null };

export type GetReleasesQueryVariables = Exact<{
  artistId?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetReleasesQuery = { __typename?: 'Query', releases?: Array<{ __typename?: 'Release', catalogNumber: string, coverArt?: string | null, id: number, name: string, releaseType?: ReleaseType | null, releaseDate: any, duplicateOf?: { __typename?: 'Release', id: number } | null, promoOf?: { __typename?: 'Release', id: number } | null, releaseArtists: Array<{ __typename?: 'Artist', name: string }> } | null> | null };

export type GetReleaseByIdQueryVariables = Exact<{
  releaseId: Scalars['Int']['input'];
}>;


export type GetReleaseByIdQuery = { __typename?: 'Query', release?: { __typename?: 'Release', id: number, name: string, notes?: string | null, catalogNumber: string, coverArt?: string | null, productType?: ProductType | null, promoAssets?: string | null, releaseDate: any, releaseType?: ReleaseType | null, version?: string | null, duplicateOf?: { __typename?: 'Release', id: number } | null, promoOf?: { __typename?: 'Release', id: number } | null, tracks: Array<{ __typename?: 'Track', id: number, name: string, notes?: string | null, youtubeAssetReference?: string | null, masterAudio?: string | null, gramexLabelPercentage: number, volume: number, order: number, version?: string | null, isrcs?: Array<{ __typename?: 'Isrc', id: number, isrc: string } | null> | null, trackArtists: Array<{ __typename?: 'TrackArtist', id: number, artistId: number, type?: TrackArtistType | null }>, contract: { __typename?: 'ArtistContract', id: number, artist: { __typename?: 'Artist', id: number } }, trackCollaborators: Array<{ __typename?: 'TrackCollaborators', role: CollaboratorRole, id: number, collaborator: { __typename?: 'Collaborator', id: number, name: string } }>, trackCollaboratorRoyalty: Array<{ __typename?: 'TrackCollaboratorRoyalty', id: number, paymentAmount?: number | null, recoupableAmount?: number | null, royaltyPercentage?: any | null, royaltyType?: RoyaltyType | null, recoupableType?: RecoupableType | null, collaborator: { __typename?: 'Collaborator', type?: CollaboratorRole | null, id: number } }>, trackArtistRoyalty: Array<{ __typename?: 'TrackArtistRoyalty', id: number, artistId: number, paymentAmount?: number | null, recoupableAmount?: number | null, royaltyPercentage: any, recoupableType?: RecoupableType | null, royaltyType: RoyaltyType }> }>, upc?: { __typename?: 'Upc', upcNumber: any } | null, releaseArtists: Array<{ __typename?: 'Artist', id: number, name: string, active: boolean, costCenter: string, imageUrl?: string | null }> } | null };

export type GetArtistOverviewQueryVariables = Exact<{
  costCenter?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetArtistOverviewQuery = { __typename?: 'Query', artistOverview?: Array<{ __typename?: 'ArtistOverview', artistId: number, artistName: string, costCenter: string, latestReleaseDate?: any | null, nextReleaseDate?: any | null, over30secStreamsLast30Days?: any | null, totalStreamsLast30Days?: any | null, incomeYTD?: any | null, tracksFromContract: number, contractLength: number, artist: { __typename?: 'Artist', name: string } } | null> | null };

export type GetContractsQueryVariables = Exact<{
  artistId?: InputMaybe<Scalars['Int']['input']>;
  contractId?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetContractsQuery = { __typename?: 'Query', contracts?: Array<{ __typename?: 'ArtistContract', id: number, type: ContractType, lengthTracks: number, numberOfOptions: number, masterOwnership: number, producerGramexShare: number, copyrightP?: string | null, copyrightC?: string | null, notes?: string | null, royaltySharePercentage: any, royaltyShareType: RoyaltyType, deletable?: boolean | null, royaltyPeriod: Array<number>, nonCompete: boolean, dateSigned: any, artist: { __typename?: 'Artist', name: string, id: number } } | null> | null };

export type GetStreamsForArtistQueryVariables = Exact<{
  artistId: Scalars['Int']['input'];
  startDate?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetStreamsForArtistQuery = { __typename?: 'Query', streamsForArtist?: number | null };

export type GetStreamsQueryVariables = Exact<{
  artistId: Scalars['Int']['input'];
  endDate?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['String']['input']>;
  trackId?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetStreamsQuery = { __typename?: 'Query', streamsForArtistByTrack?: Array<{ __typename?: 'StreamsForArtistByTrack', totalStreams?: any | null, trackCoverArt?: string | null, trackId?: number | null, trackName?: string | null, streamsByDsp?: Array<{ __typename?: 'DspEntry', dspName?: string | null, dspId?: number | null, count?: any | null } | null> | null } | null> | null };

export type GetStreamsByCategoryQueryVariables = Exact<{
  artistId: Scalars['Int']['input'];
  trackId?: InputMaybe<Scalars['Int']['input']>;
  startDate?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['String']['input']>;
  groupBy?: InputMaybe<StreamCountGroupBy>;
}>;


export type GetStreamsByCategoryQuery = { __typename?: 'Query', streamCount?: { __typename?: 'ByAge', total?: any | null, streams0to17?: any | null, streams18to22?: any | null, streams23to27?: any | null, streams28to34?: any | null, streams35to44?: any | null, streams45to59?: any | null, streams60to150?: any | null, streamsUnknown?: any | null } | { __typename?: 'ByDsp', total?: any | null, amazon?: any | null, deezer?: any | null, itunes?: any | null, soundcloud?: any | null, spotify?: any | null, youtube?: any | null, youtube_music?: any | null } | { __typename?: 'ByGender', total?: any | null, male?: any | null, female?: any | null, other?: any | null, unknown?: any | null } | { __typename?: 'ByPlayLength', total?: any | null, streamsSub30?: any | null, streamsOver30?: any | null } | null };

export type GetStreamsByDateQueryVariables = Exact<{
  artistId: Scalars['Int']['input'];
  trackId?: InputMaybe<Scalars['Int']['input']>;
  startDate?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetStreamsByDateQuery = { __typename?: 'Query', getTotalStreamsByDate?: Array<{ __typename?: 'StreamsForArtistByDate', date: any, streams: any } | null> | null };

export type GetTrackByIdQueryVariables = Exact<{
  trackId: Scalars['Int']['input'];
}>;


export type GetTrackByIdQuery = { __typename?: 'Query', track?: { __typename?: 'Track', id: number, name: string, youtubeAssetReference?: string | null, masterAudio?: string | null, gramexLabelPercentage: number, volume: number, order: number, notes?: string | null, version?: string | null, release: { __typename?: 'Release', id: number }, isrcs?: Array<{ __typename?: 'Isrc', isrc: string } | null> | null, trackArtists: Array<{ __typename?: 'TrackArtist', artistId: number, type?: TrackArtistType | null, artist: { __typename?: 'Artist', name: string } }>, trackCollaborators: Array<{ __typename?: 'TrackCollaborators', role: CollaboratorRole, id: number, collaborator: { __typename?: 'Collaborator', id: number, name: string } }>, contract: { __typename?: 'ArtistContract', id: number, artist: { __typename?: 'Artist', id: number } }, trackCollaboratorRoyalty: Array<{ __typename?: 'TrackCollaboratorRoyalty', id: number, paymentAmount?: number | null, recoupableAmount?: number | null, royaltyPercentage?: any | null, royaltyType?: RoyaltyType | null, recoupableType?: RecoupableType | null, collaborator: { __typename?: 'Collaborator', type?: CollaboratorRole | null, id: number } }>, trackArtistRoyalty: Array<{ __typename?: 'TrackArtistRoyalty', id: number, artistId: number, royaltyType: RoyaltyType, paymentAmount?: number | null, recoupableAmount?: number | null, royaltyPercentage: any, recoupableType?: RecoupableType | null }> } | null };

export type GetPresignedUrlQueryVariables = Exact<{
  fileName: Scalars['String']['input'];
  month: Scalars['Int']['input'];
  year: Scalars['Int']['input'];
  uploadType: UploadType;
  dsp: RoyaltySourceProvider;
}>;


export type GetPresignedUrlQuery = { __typename?: 'Query', getPreSignedUrl?: string | null };

export type GetStatementsRunsQueryVariables = Exact<{
  royaltyProvider: RoyaltySourceProvider;
  take?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetStatementsRunsQuery = { __typename?: 'Query', getRoyaltyStatementRuns?: Array<{ __typename?: 'RoyaltyStatementRun', periodStartDate: any, periodEndDate: any, fileName: string, royaltySource: RoyaltySourceProvider, processedAt?: any | null, createdAt: any, id: number } | null> | null };

export type GetPaymentsQueryVariables = Exact<{
  artistId?: InputMaybe<Scalars['Int']['input']>;
  startDate?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetPaymentsQuery = { __typename?: 'Query', artistPayments?: Array<{ __typename?: 'Payment', id: number, title?: string | null, comment?: string | null, amount: any, date: any, createdAt: any, artist: { __typename?: 'Artist', id: number, name: string }, createdBy: { __typename?: 'User', id: number, email: string } } | null> | null };

export type ListCollaboratorsQueryVariables = Exact<{
  listCollaboratorsId?: InputMaybe<Scalars['Int']['input']>;
}>;


export type ListCollaboratorsQuery = { __typename?: 'Query', listCollaborators?: Array<{ __typename?: 'Collaborator', id: number, name: string, type?: CollaboratorRole | null, active: boolean, deletable: boolean } | null> | null };

export type ValidateRoyaltyRulesQueryVariables = Exact<{
  artistRules: Array<ArtistRoyaltyRule> | ArtistRoyaltyRule;
  collaboratorRules: Array<CollaboratorRoyaltyRule> | CollaboratorRoyaltyRule;
}>;


export type ValidateRoyaltyRulesQuery = { __typename?: 'Query', validateRoyaltyRules?: { __typename?: 'RoyaltyValidationResult', pmeShare: any, artistShares: Array<{ __typename?: 'ArtistRoyaltyShare', id: number, name: string, amountEuro: any } | null>, collaboratorShares: Array<{ __typename?: 'CollaboratorRoyaltyShare', id: number, name: string, amountEuro: any } | null>, errors: Array<{ __typename?: 'ErrorOrWarning', key: RoyaltyValidationErrorKey, message: string } | null>, warnings: Array<{ __typename?: 'ErrorOrWarning', key: RoyaltyValidationErrorKey, message: string } | null> } | null };

export type ListUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type ListUsersQuery = { __typename?: 'Query', listUsers?: Array<{ __typename?: 'User', id: number, email: string, clerkId: string, role?: UserRole | null, person?: { __typename?: 'CompassPerson', contractPerson: { __typename?: 'ContractPerson', firstName: string, lastName: string } } | null } | null> | null };

export type GetAssetsReferencesQueryVariables = Exact<{
  take?: InputMaybe<Scalars['Int']['input']>;
  unprocessedOnly?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type GetAssetsReferencesQuery = { __typename?: 'Query', assetReferences?: Array<{ __typename?: 'UnmatchedTrack', id: number, assetTitle: string, assetReference: string, assetArtist: string, incomeType: IncomeType, source: string, createdAt: any, isrc?: { __typename?: 'Isrc', isrc: string } | null, unpaidRoyalties: Array<{ __typename?: 'UnpaidRoyalty', unmatchedTrack: { __typename?: 'UnmatchedTrack', id: number } }> } | null> | null };


export const UpsertReleaseDocument = gql`
    mutation UpsertRelease($input: UpsertReleaseInput!) {
  upsertRelease(input: $input) {
    id
    catalogNumber
    coverArt
    name
    productType
    promoAssets
    releaseDate
    releaseType
    tracks {
      isrcs {
        isrc
      }
      name
      trackArtists {
        artistId
      }
    }
    upc {
      id
      upcNumber
      activated
    }
    version
  }
}
    `;
export type UpsertReleaseMutationFn = Apollo.MutationFunction<UpsertReleaseMutation, UpsertReleaseMutationVariables>;

/**
 * __useUpsertReleaseMutation__
 *
 * To run a mutation, you first call `useUpsertReleaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertReleaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertReleaseMutation, { data, loading, error }] = useUpsertReleaseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertReleaseMutation(baseOptions?: Apollo.MutationHookOptions<UpsertReleaseMutation, UpsertReleaseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertReleaseMutation, UpsertReleaseMutationVariables>(UpsertReleaseDocument, options);
      }
export type UpsertReleaseMutationHookResult = ReturnType<typeof useUpsertReleaseMutation>;
export type UpsertReleaseMutationResult = Apollo.MutationResult<UpsertReleaseMutation>;
export type UpsertReleaseMutationOptions = Apollo.BaseMutationOptions<UpsertReleaseMutation, UpsertReleaseMutationVariables>;
export const UpsertArtistContractDocument = gql`
    mutation UpsertArtistContract($input: UpsertArtistContractInput!) {
  upsertArtistContract(input: $input) {
    id
  }
}
    `;
export type UpsertArtistContractMutationFn = Apollo.MutationFunction<UpsertArtistContractMutation, UpsertArtistContractMutationVariables>;

/**
 * __useUpsertArtistContractMutation__
 *
 * To run a mutation, you first call `useUpsertArtistContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertArtistContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertArtistContractMutation, { data, loading, error }] = useUpsertArtistContractMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertArtistContractMutation(baseOptions?: Apollo.MutationHookOptions<UpsertArtistContractMutation, UpsertArtistContractMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertArtistContractMutation, UpsertArtistContractMutationVariables>(UpsertArtistContractDocument, options);
      }
export type UpsertArtistContractMutationHookResult = ReturnType<typeof useUpsertArtistContractMutation>;
export type UpsertArtistContractMutationResult = Apollo.MutationResult<UpsertArtistContractMutation>;
export type UpsertArtistContractMutationOptions = Apollo.BaseMutationOptions<UpsertArtistContractMutation, UpsertArtistContractMutationVariables>;
export const UpsertUserDocument = gql`
    mutation UpsertUser($input: UpsertUserInput!) {
  upsertUser(input: $input) {
    id
  }
}
    `;
export type UpsertUserMutationFn = Apollo.MutationFunction<UpsertUserMutation, UpsertUserMutationVariables>;

/**
 * __useUpsertUserMutation__
 *
 * To run a mutation, you first call `useUpsertUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertUserMutation, { data, loading, error }] = useUpsertUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertUserMutation(baseOptions?: Apollo.MutationHookOptions<UpsertUserMutation, UpsertUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertUserMutation, UpsertUserMutationVariables>(UpsertUserDocument, options);
      }
export type UpsertUserMutationHookResult = ReturnType<typeof useUpsertUserMutation>;
export type UpsertUserMutationResult = Apollo.MutationResult<UpsertUserMutation>;
export type UpsertUserMutationOptions = Apollo.BaseMutationOptions<UpsertUserMutation, UpsertUserMutationVariables>;
export const UpserArtistDocument = gql`
    mutation UpserArtist($input: UpsertArtistInput!) {
  upsertArtist(input: $input) {
    id
    name
  }
}
    `;
export type UpserArtistMutationFn = Apollo.MutationFunction<UpserArtistMutation, UpserArtistMutationVariables>;

/**
 * __useUpserArtistMutation__
 *
 * To run a mutation, you first call `useUpserArtistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpserArtistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upserArtistMutation, { data, loading, error }] = useUpserArtistMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpserArtistMutation(baseOptions?: Apollo.MutationHookOptions<UpserArtistMutation, UpserArtistMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpserArtistMutation, UpserArtistMutationVariables>(UpserArtistDocument, options);
      }
export type UpserArtistMutationHookResult = ReturnType<typeof useUpserArtistMutation>;
export type UpserArtistMutationResult = Apollo.MutationResult<UpserArtistMutation>;
export type UpserArtistMutationOptions = Apollo.BaseMutationOptions<UpserArtistMutation, UpserArtistMutationVariables>;
export const UpsertExpenseDocument = gql`
    mutation UpsertExpense($input: UpsertExpenseInput!) {
  upsertExpense(input: $input) {
    id
  }
}
    `;
export type UpsertExpenseMutationFn = Apollo.MutationFunction<UpsertExpenseMutation, UpsertExpenseMutationVariables>;

/**
 * __useUpsertExpenseMutation__
 *
 * To run a mutation, you first call `useUpsertExpenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertExpenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertExpenseMutation, { data, loading, error }] = useUpsertExpenseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertExpenseMutation(baseOptions?: Apollo.MutationHookOptions<UpsertExpenseMutation, UpsertExpenseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertExpenseMutation, UpsertExpenseMutationVariables>(UpsertExpenseDocument, options);
      }
export type UpsertExpenseMutationHookResult = ReturnType<typeof useUpsertExpenseMutation>;
export type UpsertExpenseMutationResult = Apollo.MutationResult<UpsertExpenseMutation>;
export type UpsertExpenseMutationOptions = Apollo.BaseMutationOptions<UpsertExpenseMutation, UpsertExpenseMutationVariables>;
export const UpsertTrackDocument = gql`
    mutation UpsertTrack($input: UpsertTrackInput!) {
  upsertTrack(input: $input) {
    id
    release {
      id
    }
  }
}
    `;
export type UpsertTrackMutationFn = Apollo.MutationFunction<UpsertTrackMutation, UpsertTrackMutationVariables>;

/**
 * __useUpsertTrackMutation__
 *
 * To run a mutation, you first call `useUpsertTrackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertTrackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertTrackMutation, { data, loading, error }] = useUpsertTrackMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertTrackMutation(baseOptions?: Apollo.MutationHookOptions<UpsertTrackMutation, UpsertTrackMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertTrackMutation, UpsertTrackMutationVariables>(UpsertTrackDocument, options);
      }
export type UpsertTrackMutationHookResult = ReturnType<typeof useUpsertTrackMutation>;
export type UpsertTrackMutationResult = Apollo.MutationResult<UpsertTrackMutation>;
export type UpsertTrackMutationOptions = Apollo.BaseMutationOptions<UpsertTrackMutation, UpsertTrackMutationVariables>;
export const CreateDuplicateReleaseDocument = gql`
    mutation CreateDuplicateRelease($input: CreateDuplicateReleaseInput!) {
  createDuplicateRelease(input: $input) {
    id
  }
}
    `;
export type CreateDuplicateReleaseMutationFn = Apollo.MutationFunction<CreateDuplicateReleaseMutation, CreateDuplicateReleaseMutationVariables>;

/**
 * __useCreateDuplicateReleaseMutation__
 *
 * To run a mutation, you first call `useCreateDuplicateReleaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDuplicateReleaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDuplicateReleaseMutation, { data, loading, error }] = useCreateDuplicateReleaseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDuplicateReleaseMutation(baseOptions?: Apollo.MutationHookOptions<CreateDuplicateReleaseMutation, CreateDuplicateReleaseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDuplicateReleaseMutation, CreateDuplicateReleaseMutationVariables>(CreateDuplicateReleaseDocument, options);
      }
export type CreateDuplicateReleaseMutationHookResult = ReturnType<typeof useCreateDuplicateReleaseMutation>;
export type CreateDuplicateReleaseMutationResult = Apollo.MutationResult<CreateDuplicateReleaseMutation>;
export type CreateDuplicateReleaseMutationOptions = Apollo.BaseMutationOptions<CreateDuplicateReleaseMutation, CreateDuplicateReleaseMutationVariables>;
export const CreatePromoReleaseDocument = gql`
    mutation CreatePromoRelease($input: CreatePromoReleaseInput!) {
  createPromoRelease(input: $input) {
    id
  }
}
    `;
export type CreatePromoReleaseMutationFn = Apollo.MutationFunction<CreatePromoReleaseMutation, CreatePromoReleaseMutationVariables>;

/**
 * __useCreatePromoReleaseMutation__
 *
 * To run a mutation, you first call `useCreatePromoReleaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePromoReleaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPromoReleaseMutation, { data, loading, error }] = useCreatePromoReleaseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePromoReleaseMutation(baseOptions?: Apollo.MutationHookOptions<CreatePromoReleaseMutation, CreatePromoReleaseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePromoReleaseMutation, CreatePromoReleaseMutationVariables>(CreatePromoReleaseDocument, options);
      }
export type CreatePromoReleaseMutationHookResult = ReturnType<typeof useCreatePromoReleaseMutation>;
export type CreatePromoReleaseMutationResult = Apollo.MutationResult<CreatePromoReleaseMutation>;
export type CreatePromoReleaseMutationOptions = Apollo.BaseMutationOptions<CreatePromoReleaseMutation, CreatePromoReleaseMutationVariables>;
export const CreatePaymentDocument = gql`
    mutation CreatePayment($input: CreatePaymentInput!) {
  createPayment(input: $input) {
    id
    artist {
      id
    }
  }
}
    `;
export type CreatePaymentMutationFn = Apollo.MutationFunction<CreatePaymentMutation, CreatePaymentMutationVariables>;

/**
 * __useCreatePaymentMutation__
 *
 * To run a mutation, you first call `useCreatePaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPaymentMutation, { data, loading, error }] = useCreatePaymentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePaymentMutation(baseOptions?: Apollo.MutationHookOptions<CreatePaymentMutation, CreatePaymentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePaymentMutation, CreatePaymentMutationVariables>(CreatePaymentDocument, options);
      }
export type CreatePaymentMutationHookResult = ReturnType<typeof useCreatePaymentMutation>;
export type CreatePaymentMutationResult = Apollo.MutationResult<CreatePaymentMutation>;
export type CreatePaymentMutationOptions = Apollo.BaseMutationOptions<CreatePaymentMutation, CreatePaymentMutationVariables>;
export const DeletePaymentDocument = gql`
    mutation DeletePayment($deletePaymentId: Int!) {
  deletePayment(id: $deletePaymentId) {
    id
  }
}
    `;
export type DeletePaymentMutationFn = Apollo.MutationFunction<DeletePaymentMutation, DeletePaymentMutationVariables>;

/**
 * __useDeletePaymentMutation__
 *
 * To run a mutation, you first call `useDeletePaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePaymentMutation, { data, loading, error }] = useDeletePaymentMutation({
 *   variables: {
 *      deletePaymentId: // value for 'deletePaymentId'
 *   },
 * });
 */
export function useDeletePaymentMutation(baseOptions?: Apollo.MutationHookOptions<DeletePaymentMutation, DeletePaymentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePaymentMutation, DeletePaymentMutationVariables>(DeletePaymentDocument, options);
      }
export type DeletePaymentMutationHookResult = ReturnType<typeof useDeletePaymentMutation>;
export type DeletePaymentMutationResult = Apollo.MutationResult<DeletePaymentMutation>;
export type DeletePaymentMutationOptions = Apollo.BaseMutationOptions<DeletePaymentMutation, DeletePaymentMutationVariables>;
export const CreateCollaboratorDocument = gql`
    mutation CreateCollaborator($input: UpsertCollaboratorInput!) {
  upsertCollaborator(input: $input) {
    id
    name
  }
}
    `;
export type CreateCollaboratorMutationFn = Apollo.MutationFunction<CreateCollaboratorMutation, CreateCollaboratorMutationVariables>;

/**
 * __useCreateCollaboratorMutation__
 *
 * To run a mutation, you first call `useCreateCollaboratorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCollaboratorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCollaboratorMutation, { data, loading, error }] = useCreateCollaboratorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCollaboratorMutation(baseOptions?: Apollo.MutationHookOptions<CreateCollaboratorMutation, CreateCollaboratorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCollaboratorMutation, CreateCollaboratorMutationVariables>(CreateCollaboratorDocument, options);
      }
export type CreateCollaboratorMutationHookResult = ReturnType<typeof useCreateCollaboratorMutation>;
export type CreateCollaboratorMutationResult = Apollo.MutationResult<CreateCollaboratorMutation>;
export type CreateCollaboratorMutationOptions = Apollo.BaseMutationOptions<CreateCollaboratorMutation, CreateCollaboratorMutationVariables>;
export const DeleteArtistDocument = gql`
    mutation DeleteArtist($deleteArtistId: Int!) {
  deleteArtist(id: $deleteArtistId) {
    id
  }
}
    `;
export type DeleteArtistMutationFn = Apollo.MutationFunction<DeleteArtistMutation, DeleteArtistMutationVariables>;

/**
 * __useDeleteArtistMutation__
 *
 * To run a mutation, you first call `useDeleteArtistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteArtistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteArtistMutation, { data, loading, error }] = useDeleteArtistMutation({
 *   variables: {
 *      deleteArtistId: // value for 'deleteArtistId'
 *   },
 * });
 */
export function useDeleteArtistMutation(baseOptions?: Apollo.MutationHookOptions<DeleteArtistMutation, DeleteArtistMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteArtistMutation, DeleteArtistMutationVariables>(DeleteArtistDocument, options);
      }
export type DeleteArtistMutationHookResult = ReturnType<typeof useDeleteArtistMutation>;
export type DeleteArtistMutationResult = Apollo.MutationResult<DeleteArtistMutation>;
export type DeleteArtistMutationOptions = Apollo.BaseMutationOptions<DeleteArtistMutation, DeleteArtistMutationVariables>;
export const DeleteCollaboratorDocument = gql`
    mutation DeleteCollaborator($deleteCollaboratorId: Int!) {
  deleteCollaborator(id: $deleteCollaboratorId) {
    id
  }
}
    `;
export type DeleteCollaboratorMutationFn = Apollo.MutationFunction<DeleteCollaboratorMutation, DeleteCollaboratorMutationVariables>;

/**
 * __useDeleteCollaboratorMutation__
 *
 * To run a mutation, you first call `useDeleteCollaboratorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCollaboratorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCollaboratorMutation, { data, loading, error }] = useDeleteCollaboratorMutation({
 *   variables: {
 *      deleteCollaboratorId: // value for 'deleteCollaboratorId'
 *   },
 * });
 */
export function useDeleteCollaboratorMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCollaboratorMutation, DeleteCollaboratorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCollaboratorMutation, DeleteCollaboratorMutationVariables>(DeleteCollaboratorDocument, options);
      }
export type DeleteCollaboratorMutationHookResult = ReturnType<typeof useDeleteCollaboratorMutation>;
export type DeleteCollaboratorMutationResult = Apollo.MutationResult<DeleteCollaboratorMutation>;
export type DeleteCollaboratorMutationOptions = Apollo.BaseMutationOptions<DeleteCollaboratorMutation, DeleteCollaboratorMutationVariables>;
export const DeleteArtistContractDocument = gql`
    mutation DeleteArtistContract($deleteArtistContractId: Int!) {
  deleteArtistContract(id: $deleteArtistContractId) {
    id
  }
}
    `;
export type DeleteArtistContractMutationFn = Apollo.MutationFunction<DeleteArtistContractMutation, DeleteArtistContractMutationVariables>;

/**
 * __useDeleteArtistContractMutation__
 *
 * To run a mutation, you first call `useDeleteArtistContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteArtistContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteArtistContractMutation, { data, loading, error }] = useDeleteArtistContractMutation({
 *   variables: {
 *      deleteArtistContractId: // value for 'deleteArtistContractId'
 *   },
 * });
 */
export function useDeleteArtistContractMutation(baseOptions?: Apollo.MutationHookOptions<DeleteArtistContractMutation, DeleteArtistContractMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteArtistContractMutation, DeleteArtistContractMutationVariables>(DeleteArtistContractDocument, options);
      }
export type DeleteArtistContractMutationHookResult = ReturnType<typeof useDeleteArtistContractMutation>;
export type DeleteArtistContractMutationResult = Apollo.MutationResult<DeleteArtistContractMutation>;
export type DeleteArtistContractMutationOptions = Apollo.BaseMutationOptions<DeleteArtistContractMutation, DeleteArtistContractMutationVariables>;
export const DeleteUserDocument = gql`
    mutation DeleteUser($deleteUserId: Int!) {
  deleteUser(id: $deleteUserId) {
    id
  }
}
    `;
export type DeleteUserMutationFn = Apollo.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      deleteUserId: // value for 'deleteUserId'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, options);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const ProcessRoyaltiesDocument = gql`
    mutation ProcessRoyalties {
  processRoyalties
}
    `;
export type ProcessRoyaltiesMutationFn = Apollo.MutationFunction<ProcessRoyaltiesMutation, ProcessRoyaltiesMutationVariables>;

/**
 * __useProcessRoyaltiesMutation__
 *
 * To run a mutation, you first call `useProcessRoyaltiesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProcessRoyaltiesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [processRoyaltiesMutation, { data, loading, error }] = useProcessRoyaltiesMutation({
 *   variables: {
 *   },
 * });
 */
export function useProcessRoyaltiesMutation(baseOptions?: Apollo.MutationHookOptions<ProcessRoyaltiesMutation, ProcessRoyaltiesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProcessRoyaltiesMutation, ProcessRoyaltiesMutationVariables>(ProcessRoyaltiesDocument, options);
      }
export type ProcessRoyaltiesMutationHookResult = ReturnType<typeof useProcessRoyaltiesMutation>;
export type ProcessRoyaltiesMutationResult = Apollo.MutationResult<ProcessRoyaltiesMutation>;
export type ProcessRoyaltiesMutationOptions = Apollo.BaseMutationOptions<ProcessRoyaltiesMutation, ProcessRoyaltiesMutationVariables>;
export const UpdateUnmatchedTrackDocument = gql`
    mutation UpdateUnmatchedTrack($input: UpdateUnmatchedTrackInput!) {
  updateUnmatchedTrack(input: $input) {
    id
  }
}
    `;
export type UpdateUnmatchedTrackMutationFn = Apollo.MutationFunction<UpdateUnmatchedTrackMutation, UpdateUnmatchedTrackMutationVariables>;

/**
 * __useUpdateUnmatchedTrackMutation__
 *
 * To run a mutation, you first call `useUpdateUnmatchedTrackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUnmatchedTrackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUnmatchedTrackMutation, { data, loading, error }] = useUpdateUnmatchedTrackMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUnmatchedTrackMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUnmatchedTrackMutation, UpdateUnmatchedTrackMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUnmatchedTrackMutation, UpdateUnmatchedTrackMutationVariables>(UpdateUnmatchedTrackDocument, options);
      }
export type UpdateUnmatchedTrackMutationHookResult = ReturnType<typeof useUpdateUnmatchedTrackMutation>;
export type UpdateUnmatchedTrackMutationResult = Apollo.MutationResult<UpdateUnmatchedTrackMutation>;
export type UpdateUnmatchedTrackMutationOptions = Apollo.BaseMutationOptions<UpdateUnmatchedTrackMutation, UpdateUnmatchedTrackMutationVariables>;
export const GetArtistByEmailDocument = gql`
    query GetArtistByEmail($email: String!) {
  artistsByEmail(email: $email) {
    name
    id
    imageUrl
  }
}
    `;

/**
 * __useGetArtistByEmailQuery__
 *
 * To run a query within a React component, call `useGetArtistByEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArtistByEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArtistByEmailQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useGetArtistByEmailQuery(baseOptions: Apollo.QueryHookOptions<GetArtistByEmailQuery, GetArtistByEmailQueryVariables> & ({ variables: GetArtistByEmailQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetArtistByEmailQuery, GetArtistByEmailQueryVariables>(GetArtistByEmailDocument, options);
      }
export function useGetArtistByEmailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetArtistByEmailQuery, GetArtistByEmailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetArtistByEmailQuery, GetArtistByEmailQueryVariables>(GetArtistByEmailDocument, options);
        }
export function useGetArtistByEmailSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetArtistByEmailQuery, GetArtistByEmailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetArtistByEmailQuery, GetArtistByEmailQueryVariables>(GetArtistByEmailDocument, options);
        }
export type GetArtistByEmailQueryHookResult = ReturnType<typeof useGetArtistByEmailQuery>;
export type GetArtistByEmailLazyQueryHookResult = ReturnType<typeof useGetArtistByEmailLazyQuery>;
export type GetArtistByEmailSuspenseQueryHookResult = ReturnType<typeof useGetArtistByEmailSuspenseQuery>;
export type GetArtistByEmailQueryResult = Apollo.QueryResult<GetArtistByEmailQuery, GetArtistByEmailQueryVariables>;
export const GetArtistProfileDocument = gql`
    query GetArtistProfile($artistId: Int!) {
  artist(id: $artistId) {
    active
    costCenter
    imageUrl
    id
    name
    persons {
      id
    }
  }
}
    `;

/**
 * __useGetArtistProfileQuery__
 *
 * To run a query within a React component, call `useGetArtistProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArtistProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArtistProfileQuery({
 *   variables: {
 *      artistId: // value for 'artistId'
 *   },
 * });
 */
export function useGetArtistProfileQuery(baseOptions: Apollo.QueryHookOptions<GetArtistProfileQuery, GetArtistProfileQueryVariables> & ({ variables: GetArtistProfileQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetArtistProfileQuery, GetArtistProfileQueryVariables>(GetArtistProfileDocument, options);
      }
export function useGetArtistProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetArtistProfileQuery, GetArtistProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetArtistProfileQuery, GetArtistProfileQueryVariables>(GetArtistProfileDocument, options);
        }
export function useGetArtistProfileSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetArtistProfileQuery, GetArtistProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetArtistProfileQuery, GetArtistProfileQueryVariables>(GetArtistProfileDocument, options);
        }
export type GetArtistProfileQueryHookResult = ReturnType<typeof useGetArtistProfileQuery>;
export type GetArtistProfileLazyQueryHookResult = ReturnType<typeof useGetArtistProfileLazyQuery>;
export type GetArtistProfileSuspenseQueryHookResult = ReturnType<typeof useGetArtistProfileSuspenseQuery>;
export type GetArtistProfileQueryResult = Apollo.QueryResult<GetArtistProfileQuery, GetArtistProfileQueryVariables>;
export const GetArtistListDocument = gql`
    query GetArtistList {
  listArtists {
    active
    costCenter
    id
    name
    imageUrl
    deletable
    persons {
      id
      user {
        email
        id
      }
    }
  }
}
    `;

/**
 * __useGetArtistListQuery__
 *
 * To run a query within a React component, call `useGetArtistListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArtistListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArtistListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetArtistListQuery(baseOptions?: Apollo.QueryHookOptions<GetArtistListQuery, GetArtistListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetArtistListQuery, GetArtistListQueryVariables>(GetArtistListDocument, options);
      }
export function useGetArtistListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetArtistListQuery, GetArtistListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetArtistListQuery, GetArtistListQueryVariables>(GetArtistListDocument, options);
        }
export function useGetArtistListSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetArtistListQuery, GetArtistListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetArtistListQuery, GetArtistListQueryVariables>(GetArtistListDocument, options);
        }
export type GetArtistListQueryHookResult = ReturnType<typeof useGetArtistListQuery>;
export type GetArtistListLazyQueryHookResult = ReturnType<typeof useGetArtistListLazyQuery>;
export type GetArtistListSuspenseQueryHookResult = ReturnType<typeof useGetArtistListSuspenseQuery>;
export type GetArtistListQueryResult = Apollo.QueryResult<GetArtistListQuery, GetArtistListQueryVariables>;
export const GetCompassPersonsDocument = gql`
    query GetCompassPersons {
  persons {
    id
    contractPerson {
      lastName
      firstName
    }
  }
}
    `;

/**
 * __useGetCompassPersonsQuery__
 *
 * To run a query within a React component, call `useGetCompassPersonsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompassPersonsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompassPersonsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCompassPersonsQuery(baseOptions?: Apollo.QueryHookOptions<GetCompassPersonsQuery, GetCompassPersonsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCompassPersonsQuery, GetCompassPersonsQueryVariables>(GetCompassPersonsDocument, options);
      }
export function useGetCompassPersonsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompassPersonsQuery, GetCompassPersonsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCompassPersonsQuery, GetCompassPersonsQueryVariables>(GetCompassPersonsDocument, options);
        }
export function useGetCompassPersonsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetCompassPersonsQuery, GetCompassPersonsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCompassPersonsQuery, GetCompassPersonsQueryVariables>(GetCompassPersonsDocument, options);
        }
export type GetCompassPersonsQueryHookResult = ReturnType<typeof useGetCompassPersonsQuery>;
export type GetCompassPersonsLazyQueryHookResult = ReturnType<typeof useGetCompassPersonsLazyQuery>;
export type GetCompassPersonsSuspenseQueryHookResult = ReturnType<typeof useGetCompassPersonsSuspenseQuery>;
export type GetCompassPersonsQueryResult = Apollo.QueryResult<GetCompassPersonsQuery, GetCompassPersonsQueryVariables>;
export const GetArtistIncomeTotalsDocument = gql`
    query GetArtistIncomeTotals($artistId: Int!, $startDate: String, $endDate: String) {
  artistIncomeTotals(
    artistId: $artistId
    startDate: $startDate
    endDate: $endDate
  ) {
    expenses
    income
    pmeShare
  }
}
    `;

/**
 * __useGetArtistIncomeTotalsQuery__
 *
 * To run a query within a React component, call `useGetArtistIncomeTotalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArtistIncomeTotalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArtistIncomeTotalsQuery({
 *   variables: {
 *      artistId: // value for 'artistId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useGetArtistIncomeTotalsQuery(baseOptions: Apollo.QueryHookOptions<GetArtistIncomeTotalsQuery, GetArtistIncomeTotalsQueryVariables> & ({ variables: GetArtistIncomeTotalsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetArtistIncomeTotalsQuery, GetArtistIncomeTotalsQueryVariables>(GetArtistIncomeTotalsDocument, options);
      }
export function useGetArtistIncomeTotalsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetArtistIncomeTotalsQuery, GetArtistIncomeTotalsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetArtistIncomeTotalsQuery, GetArtistIncomeTotalsQueryVariables>(GetArtistIncomeTotalsDocument, options);
        }
export function useGetArtistIncomeTotalsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetArtistIncomeTotalsQuery, GetArtistIncomeTotalsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetArtistIncomeTotalsQuery, GetArtistIncomeTotalsQueryVariables>(GetArtistIncomeTotalsDocument, options);
        }
export type GetArtistIncomeTotalsQueryHookResult = ReturnType<typeof useGetArtistIncomeTotalsQuery>;
export type GetArtistIncomeTotalsLazyQueryHookResult = ReturnType<typeof useGetArtistIncomeTotalsLazyQuery>;
export type GetArtistIncomeTotalsSuspenseQueryHookResult = ReturnType<typeof useGetArtistIncomeTotalsSuspenseQuery>;
export type GetArtistIncomeTotalsQueryResult = Apollo.QueryResult<GetArtistIncomeTotalsQuery, GetArtistIncomeTotalsQueryVariables>;
export const GetArtistIncomeTotalsV2Document = gql`
    query GetArtistIncomeTotalsV2($artistId: Int!, $startDate: String, $endDate: String) {
  artistIncomeTotalsV2(
    artistId: $artistId
    startDate: $startDate
    endDate: $endDate
  ) {
    toplineRevenue
    artistIncome
    collaboratorIncome
    featuredArtistIncome
    pmeIncome
    expenses {
      total
      details {
        id
        amount
        description
      }
    }
    payments
    recoupments
  }
}
    `;

/**
 * __useGetArtistIncomeTotalsV2Query__
 *
 * To run a query within a React component, call `useGetArtistIncomeTotalsV2Query` and pass it any options that fit your needs.
 * When your component renders, `useGetArtistIncomeTotalsV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArtistIncomeTotalsV2Query({
 *   variables: {
 *      artistId: // value for 'artistId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useGetArtistIncomeTotalsV2Query(baseOptions: Apollo.QueryHookOptions<GetArtistIncomeTotalsV2Query, GetArtistIncomeTotalsV2QueryVariables> & ({ variables: GetArtistIncomeTotalsV2QueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetArtistIncomeTotalsV2Query, GetArtistIncomeTotalsV2QueryVariables>(GetArtistIncomeTotalsV2Document, options);
      }
export function useGetArtistIncomeTotalsV2LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetArtistIncomeTotalsV2Query, GetArtistIncomeTotalsV2QueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetArtistIncomeTotalsV2Query, GetArtistIncomeTotalsV2QueryVariables>(GetArtistIncomeTotalsV2Document, options);
        }
export function useGetArtistIncomeTotalsV2SuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetArtistIncomeTotalsV2Query, GetArtistIncomeTotalsV2QueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetArtistIncomeTotalsV2Query, GetArtistIncomeTotalsV2QueryVariables>(GetArtistIncomeTotalsV2Document, options);
        }
export type GetArtistIncomeTotalsV2QueryHookResult = ReturnType<typeof useGetArtistIncomeTotalsV2Query>;
export type GetArtistIncomeTotalsV2LazyQueryHookResult = ReturnType<typeof useGetArtistIncomeTotalsV2LazyQuery>;
export type GetArtistIncomeTotalsV2SuspenseQueryHookResult = ReturnType<typeof useGetArtistIncomeTotalsV2SuspenseQuery>;
export type GetArtistIncomeTotalsV2QueryResult = Apollo.QueryResult<GetArtistIncomeTotalsV2Query, GetArtistIncomeTotalsV2QueryVariables>;
export const GetArtistBalanceDocument = gql`
    query GetArtistBalance($artistId: Int!) {
  artistBalance(artistId: $artistId) {
    artistId
    balance
  }
}
    `;

/**
 * __useGetArtistBalanceQuery__
 *
 * To run a query within a React component, call `useGetArtistBalanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArtistBalanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArtistBalanceQuery({
 *   variables: {
 *      artistId: // value for 'artistId'
 *   },
 * });
 */
export function useGetArtistBalanceQuery(baseOptions: Apollo.QueryHookOptions<GetArtistBalanceQuery, GetArtistBalanceQueryVariables> & ({ variables: GetArtistBalanceQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetArtistBalanceQuery, GetArtistBalanceQueryVariables>(GetArtistBalanceDocument, options);
      }
export function useGetArtistBalanceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetArtistBalanceQuery, GetArtistBalanceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetArtistBalanceQuery, GetArtistBalanceQueryVariables>(GetArtistBalanceDocument, options);
        }
export function useGetArtistBalanceSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetArtistBalanceQuery, GetArtistBalanceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetArtistBalanceQuery, GetArtistBalanceQueryVariables>(GetArtistBalanceDocument, options);
        }
export type GetArtistBalanceQueryHookResult = ReturnType<typeof useGetArtistBalanceQuery>;
export type GetArtistBalanceLazyQueryHookResult = ReturnType<typeof useGetArtistBalanceLazyQuery>;
export type GetArtistBalanceSuspenseQueryHookResult = ReturnType<typeof useGetArtistBalanceSuspenseQuery>;
export type GetArtistBalanceQueryResult = Apollo.QueryResult<GetArtistBalanceQuery, GetArtistBalanceQueryVariables>;
export const GetArtistRevenueByTrackDocument = gql`
    query GetArtistRevenueByTrack($artistId: Int!, $startDate: String, $endDate: String) {
  artistRevenueByTrack(
    artistId: $artistId
    startDate: $startDate
    endDate: $endDate
  ) {
    revenue
    track {
      id
      name
      releaseCoverArt
    }
  }
}
    `;

/**
 * __useGetArtistRevenueByTrackQuery__
 *
 * To run a query within a React component, call `useGetArtistRevenueByTrackQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArtistRevenueByTrackQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArtistRevenueByTrackQuery({
 *   variables: {
 *      artistId: // value for 'artistId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useGetArtistRevenueByTrackQuery(baseOptions: Apollo.QueryHookOptions<GetArtistRevenueByTrackQuery, GetArtistRevenueByTrackQueryVariables> & ({ variables: GetArtistRevenueByTrackQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetArtistRevenueByTrackQuery, GetArtistRevenueByTrackQueryVariables>(GetArtistRevenueByTrackDocument, options);
      }
export function useGetArtistRevenueByTrackLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetArtistRevenueByTrackQuery, GetArtistRevenueByTrackQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetArtistRevenueByTrackQuery, GetArtistRevenueByTrackQueryVariables>(GetArtistRevenueByTrackDocument, options);
        }
export function useGetArtistRevenueByTrackSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetArtistRevenueByTrackQuery, GetArtistRevenueByTrackQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetArtistRevenueByTrackQuery, GetArtistRevenueByTrackQueryVariables>(GetArtistRevenueByTrackDocument, options);
        }
export type GetArtistRevenueByTrackQueryHookResult = ReturnType<typeof useGetArtistRevenueByTrackQuery>;
export type GetArtistRevenueByTrackLazyQueryHookResult = ReturnType<typeof useGetArtistRevenueByTrackLazyQuery>;
export type GetArtistRevenueByTrackSuspenseQueryHookResult = ReturnType<typeof useGetArtistRevenueByTrackSuspenseQuery>;
export type GetArtistRevenueByTrackQueryResult = Apollo.QueryResult<GetArtistRevenueByTrackQuery, GetArtistRevenueByTrackQueryVariables>;
export const GetArtistIncomesByTrackDocument = gql`
    query GetArtistIncomesByTrack($artistId: Int!, $startDate: String, $endDate: String) {
  artistIncomesByTrack(
    artistId: $artistId
    startDate: $startDate
    endDate: $endDate
  ) {
    income
    track {
      id
      name
      releaseCoverArt
    }
  }
}
    `;

/**
 * __useGetArtistIncomesByTrackQuery__
 *
 * To run a query within a React component, call `useGetArtistIncomesByTrackQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArtistIncomesByTrackQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArtistIncomesByTrackQuery({
 *   variables: {
 *      artistId: // value for 'artistId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useGetArtistIncomesByTrackQuery(baseOptions: Apollo.QueryHookOptions<GetArtistIncomesByTrackQuery, GetArtistIncomesByTrackQueryVariables> & ({ variables: GetArtistIncomesByTrackQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetArtistIncomesByTrackQuery, GetArtistIncomesByTrackQueryVariables>(GetArtistIncomesByTrackDocument, options);
      }
export function useGetArtistIncomesByTrackLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetArtistIncomesByTrackQuery, GetArtistIncomesByTrackQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetArtistIncomesByTrackQuery, GetArtistIncomesByTrackQueryVariables>(GetArtistIncomesByTrackDocument, options);
        }
export function useGetArtistIncomesByTrackSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetArtistIncomesByTrackQuery, GetArtistIncomesByTrackQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetArtistIncomesByTrackQuery, GetArtistIncomesByTrackQueryVariables>(GetArtistIncomesByTrackDocument, options);
        }
export type GetArtistIncomesByTrackQueryHookResult = ReturnType<typeof useGetArtistIncomesByTrackQuery>;
export type GetArtistIncomesByTrackLazyQueryHookResult = ReturnType<typeof useGetArtistIncomesByTrackLazyQuery>;
export type GetArtistIncomesByTrackSuspenseQueryHookResult = ReturnType<typeof useGetArtistIncomesByTrackSuspenseQuery>;
export type GetArtistIncomesByTrackQueryResult = Apollo.QueryResult<GetArtistIncomesByTrackQuery, GetArtistIncomesByTrackQueryVariables>;
export const GetArtistExpenseDetailsDocument = gql`
    query GetArtistExpenseDetails($artistId: Int!, $startDate: String, $endDate: String) {
  artistExpenseDetails(
    artistId: $artistId
    startDate: $startDate
    endDate: $endDate
  ) {
    amount
    category
  }
}
    `;

/**
 * __useGetArtistExpenseDetailsQuery__
 *
 * To run a query within a React component, call `useGetArtistExpenseDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArtistExpenseDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArtistExpenseDetailsQuery({
 *   variables: {
 *      artistId: // value for 'artistId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useGetArtistExpenseDetailsQuery(baseOptions: Apollo.QueryHookOptions<GetArtistExpenseDetailsQuery, GetArtistExpenseDetailsQueryVariables> & ({ variables: GetArtistExpenseDetailsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetArtistExpenseDetailsQuery, GetArtistExpenseDetailsQueryVariables>(GetArtistExpenseDetailsDocument, options);
      }
export function useGetArtistExpenseDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetArtistExpenseDetailsQuery, GetArtistExpenseDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetArtistExpenseDetailsQuery, GetArtistExpenseDetailsQueryVariables>(GetArtistExpenseDetailsDocument, options);
        }
export function useGetArtistExpenseDetailsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetArtistExpenseDetailsQuery, GetArtistExpenseDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetArtistExpenseDetailsQuery, GetArtistExpenseDetailsQueryVariables>(GetArtistExpenseDetailsDocument, options);
        }
export type GetArtistExpenseDetailsQueryHookResult = ReturnType<typeof useGetArtistExpenseDetailsQuery>;
export type GetArtistExpenseDetailsLazyQueryHookResult = ReturnType<typeof useGetArtistExpenseDetailsLazyQuery>;
export type GetArtistExpenseDetailsSuspenseQueryHookResult = ReturnType<typeof useGetArtistExpenseDetailsSuspenseQuery>;
export type GetArtistExpenseDetailsQueryResult = Apollo.QueryResult<GetArtistExpenseDetailsQuery, GetArtistExpenseDetailsQueryVariables>;
export const GetArtistContractsDocument = gql`
    query GetArtistContracts($artistId: Int!) {
  contracts(artistId: $artistId) {
    id
    dateSigned
    royaltyPeriod
    royaltySharePercentage
    royaltyShareType
    deletable
    type
    artist {
      name
    }
  }
}
    `;

/**
 * __useGetArtistContractsQuery__
 *
 * To run a query within a React component, call `useGetArtistContractsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArtistContractsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArtistContractsQuery({
 *   variables: {
 *      artistId: // value for 'artistId'
 *   },
 * });
 */
export function useGetArtistContractsQuery(baseOptions: Apollo.QueryHookOptions<GetArtistContractsQuery, GetArtistContractsQueryVariables> & ({ variables: GetArtistContractsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetArtistContractsQuery, GetArtistContractsQueryVariables>(GetArtistContractsDocument, options);
      }
export function useGetArtistContractsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetArtistContractsQuery, GetArtistContractsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetArtistContractsQuery, GetArtistContractsQueryVariables>(GetArtistContractsDocument, options);
        }
export function useGetArtistContractsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetArtistContractsQuery, GetArtistContractsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetArtistContractsQuery, GetArtistContractsQueryVariables>(GetArtistContractsDocument, options);
        }
export type GetArtistContractsQueryHookResult = ReturnType<typeof useGetArtistContractsQuery>;
export type GetArtistContractsLazyQueryHookResult = ReturnType<typeof useGetArtistContractsLazyQuery>;
export type GetArtistContractsSuspenseQueryHookResult = ReturnType<typeof useGetArtistContractsSuspenseQuery>;
export type GetArtistContractsQueryResult = Apollo.QueryResult<GetArtistContractsQuery, GetArtistContractsQueryVariables>;
export const GetReleasesDocument = gql`
    query GetReleases($artistId: Int) {
  releases(artistId: $artistId) {
    catalogNumber
    coverArt
    id
    name
    releaseType
    releaseDate
    duplicateOf {
      id
    }
    promoOf {
      id
    }
    releaseArtists {
      name
    }
  }
}
    `;

/**
 * __useGetReleasesQuery__
 *
 * To run a query within a React component, call `useGetReleasesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReleasesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReleasesQuery({
 *   variables: {
 *      artistId: // value for 'artistId'
 *   },
 * });
 */
export function useGetReleasesQuery(baseOptions?: Apollo.QueryHookOptions<GetReleasesQuery, GetReleasesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReleasesQuery, GetReleasesQueryVariables>(GetReleasesDocument, options);
      }
export function useGetReleasesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReleasesQuery, GetReleasesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReleasesQuery, GetReleasesQueryVariables>(GetReleasesDocument, options);
        }
export function useGetReleasesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetReleasesQuery, GetReleasesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetReleasesQuery, GetReleasesQueryVariables>(GetReleasesDocument, options);
        }
export type GetReleasesQueryHookResult = ReturnType<typeof useGetReleasesQuery>;
export type GetReleasesLazyQueryHookResult = ReturnType<typeof useGetReleasesLazyQuery>;
export type GetReleasesSuspenseQueryHookResult = ReturnType<typeof useGetReleasesSuspenseQuery>;
export type GetReleasesQueryResult = Apollo.QueryResult<GetReleasesQuery, GetReleasesQueryVariables>;
export const GetReleaseByIdDocument = gql`
    query GetReleaseById($releaseId: Int!) {
  release(id: $releaseId) {
    id
    name
    duplicateOf {
      id
    }
    promoOf {
      id
    }
    notes
    tracks {
      id
      name
      notes
      youtubeAssetReference
      masterAudio
      gramexLabelPercentage
      volume
      order
      version
      isrcs {
        id
        isrc
      }
      trackArtists {
        id
        artistId
        type
      }
      contract {
        id
        artist {
          id
        }
      }
      trackCollaborators {
        role
        id
        collaborator {
          id
          name
        }
      }
      trackCollaboratorRoyalty {
        id
        collaborator {
          type
          id
        }
        paymentAmount
        recoupableAmount
        royaltyPercentage
        royaltyType
        recoupableType
      }
      trackArtistRoyalty {
        id
        artistId
        paymentAmount
        recoupableAmount
        royaltyPercentage
        recoupableType
        royaltyType
      }
    }
    catalogNumber
    coverArt
    productType
    promoAssets
    releaseDate
    releaseType
    upc {
      upcNumber
    }
    version
    releaseArtists {
      id
      name
      active
      costCenter
      imageUrl
    }
  }
}
    `;

/**
 * __useGetReleaseByIdQuery__
 *
 * To run a query within a React component, call `useGetReleaseByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReleaseByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReleaseByIdQuery({
 *   variables: {
 *      releaseId: // value for 'releaseId'
 *   },
 * });
 */
export function useGetReleaseByIdQuery(baseOptions: Apollo.QueryHookOptions<GetReleaseByIdQuery, GetReleaseByIdQueryVariables> & ({ variables: GetReleaseByIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReleaseByIdQuery, GetReleaseByIdQueryVariables>(GetReleaseByIdDocument, options);
      }
export function useGetReleaseByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReleaseByIdQuery, GetReleaseByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReleaseByIdQuery, GetReleaseByIdQueryVariables>(GetReleaseByIdDocument, options);
        }
export function useGetReleaseByIdSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetReleaseByIdQuery, GetReleaseByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetReleaseByIdQuery, GetReleaseByIdQueryVariables>(GetReleaseByIdDocument, options);
        }
export type GetReleaseByIdQueryHookResult = ReturnType<typeof useGetReleaseByIdQuery>;
export type GetReleaseByIdLazyQueryHookResult = ReturnType<typeof useGetReleaseByIdLazyQuery>;
export type GetReleaseByIdSuspenseQueryHookResult = ReturnType<typeof useGetReleaseByIdSuspenseQuery>;
export type GetReleaseByIdQueryResult = Apollo.QueryResult<GetReleaseByIdQuery, GetReleaseByIdQueryVariables>;
export const GetArtistOverviewDocument = gql`
    query GetArtistOverview($costCenter: String) {
  artistOverview(costCenter: $costCenter) {
    artistId
    artist {
      name
    }
    artistName
    costCenter
    latestReleaseDate
    nextReleaseDate
    over30secStreamsLast30Days
    totalStreamsLast30Days
    incomeYTD
    tracksFromContract
    contractLength
  }
}
    `;

/**
 * __useGetArtistOverviewQuery__
 *
 * To run a query within a React component, call `useGetArtistOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArtistOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArtistOverviewQuery({
 *   variables: {
 *      costCenter: // value for 'costCenter'
 *   },
 * });
 */
export function useGetArtistOverviewQuery(baseOptions?: Apollo.QueryHookOptions<GetArtistOverviewQuery, GetArtistOverviewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetArtistOverviewQuery, GetArtistOverviewQueryVariables>(GetArtistOverviewDocument, options);
      }
export function useGetArtistOverviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetArtistOverviewQuery, GetArtistOverviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetArtistOverviewQuery, GetArtistOverviewQueryVariables>(GetArtistOverviewDocument, options);
        }
export function useGetArtistOverviewSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetArtistOverviewQuery, GetArtistOverviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetArtistOverviewQuery, GetArtistOverviewQueryVariables>(GetArtistOverviewDocument, options);
        }
export type GetArtistOverviewQueryHookResult = ReturnType<typeof useGetArtistOverviewQuery>;
export type GetArtistOverviewLazyQueryHookResult = ReturnType<typeof useGetArtistOverviewLazyQuery>;
export type GetArtistOverviewSuspenseQueryHookResult = ReturnType<typeof useGetArtistOverviewSuspenseQuery>;
export type GetArtistOverviewQueryResult = Apollo.QueryResult<GetArtistOverviewQuery, GetArtistOverviewQueryVariables>;
export const GetContractsDocument = gql`
    query GetContracts($artistId: Int, $contractId: Int) {
  contracts(artistId: $artistId, contractId: $contractId) {
    id
    type
    lengthTracks
    numberOfOptions
    masterOwnership
    producerGramexShare
    copyrightP
    copyrightC
    notes
    royaltySharePercentage
    royaltyShareType
    deletable
    royaltyPeriod
    nonCompete
    dateSigned
    artist {
      name
      id
    }
  }
}
    `;

/**
 * __useGetContractsQuery__
 *
 * To run a query within a React component, call `useGetContractsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractsQuery({
 *   variables: {
 *      artistId: // value for 'artistId'
 *      contractId: // value for 'contractId'
 *   },
 * });
 */
export function useGetContractsQuery(baseOptions?: Apollo.QueryHookOptions<GetContractsQuery, GetContractsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetContractsQuery, GetContractsQueryVariables>(GetContractsDocument, options);
      }
export function useGetContractsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContractsQuery, GetContractsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetContractsQuery, GetContractsQueryVariables>(GetContractsDocument, options);
        }
export function useGetContractsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetContractsQuery, GetContractsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetContractsQuery, GetContractsQueryVariables>(GetContractsDocument, options);
        }
export type GetContractsQueryHookResult = ReturnType<typeof useGetContractsQuery>;
export type GetContractsLazyQueryHookResult = ReturnType<typeof useGetContractsLazyQuery>;
export type GetContractsSuspenseQueryHookResult = ReturnType<typeof useGetContractsSuspenseQuery>;
export type GetContractsQueryResult = Apollo.QueryResult<GetContractsQuery, GetContractsQueryVariables>;
export const GetStreamsForArtistDocument = gql`
    query GetStreamsForArtist($artistId: Int!, $startDate: String, $endDate: String) {
  streamsForArtist(artistId: $artistId, startDate: $startDate, endDate: $endDate)
}
    `;

/**
 * __useGetStreamsForArtistQuery__
 *
 * To run a query within a React component, call `useGetStreamsForArtistQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStreamsForArtistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStreamsForArtistQuery({
 *   variables: {
 *      artistId: // value for 'artistId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useGetStreamsForArtistQuery(baseOptions: Apollo.QueryHookOptions<GetStreamsForArtistQuery, GetStreamsForArtistQueryVariables> & ({ variables: GetStreamsForArtistQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStreamsForArtistQuery, GetStreamsForArtistQueryVariables>(GetStreamsForArtistDocument, options);
      }
export function useGetStreamsForArtistLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStreamsForArtistQuery, GetStreamsForArtistQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStreamsForArtistQuery, GetStreamsForArtistQueryVariables>(GetStreamsForArtistDocument, options);
        }
export function useGetStreamsForArtistSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetStreamsForArtistQuery, GetStreamsForArtistQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetStreamsForArtistQuery, GetStreamsForArtistQueryVariables>(GetStreamsForArtistDocument, options);
        }
export type GetStreamsForArtistQueryHookResult = ReturnType<typeof useGetStreamsForArtistQuery>;
export type GetStreamsForArtistLazyQueryHookResult = ReturnType<typeof useGetStreamsForArtistLazyQuery>;
export type GetStreamsForArtistSuspenseQueryHookResult = ReturnType<typeof useGetStreamsForArtistSuspenseQuery>;
export type GetStreamsForArtistQueryResult = Apollo.QueryResult<GetStreamsForArtistQuery, GetStreamsForArtistQueryVariables>;
export const GetStreamsDocument = gql`
    query GetStreams($artistId: Int!, $endDate: String, $startDate: String, $trackId: Int) {
  streamsForArtistByTrack(
    artistId: $artistId
    endDate: $endDate
    startDate: $startDate
    trackId: $trackId
  ) {
    streamsByDsp {
      dspName
      dspId
      count
    }
    totalStreams
    trackCoverArt
    trackId
    trackName
  }
}
    `;

/**
 * __useGetStreamsQuery__
 *
 * To run a query within a React component, call `useGetStreamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStreamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStreamsQuery({
 *   variables: {
 *      artistId: // value for 'artistId'
 *      endDate: // value for 'endDate'
 *      startDate: // value for 'startDate'
 *      trackId: // value for 'trackId'
 *   },
 * });
 */
export function useGetStreamsQuery(baseOptions: Apollo.QueryHookOptions<GetStreamsQuery, GetStreamsQueryVariables> & ({ variables: GetStreamsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStreamsQuery, GetStreamsQueryVariables>(GetStreamsDocument, options);
      }
export function useGetStreamsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStreamsQuery, GetStreamsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStreamsQuery, GetStreamsQueryVariables>(GetStreamsDocument, options);
        }
export function useGetStreamsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetStreamsQuery, GetStreamsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetStreamsQuery, GetStreamsQueryVariables>(GetStreamsDocument, options);
        }
export type GetStreamsQueryHookResult = ReturnType<typeof useGetStreamsQuery>;
export type GetStreamsLazyQueryHookResult = ReturnType<typeof useGetStreamsLazyQuery>;
export type GetStreamsSuspenseQueryHookResult = ReturnType<typeof useGetStreamsSuspenseQuery>;
export type GetStreamsQueryResult = Apollo.QueryResult<GetStreamsQuery, GetStreamsQueryVariables>;
export const GetStreamsByCategoryDocument = gql`
    query GetStreamsByCategory($artistId: Int!, $trackId: Int, $startDate: String, $endDate: String, $groupBy: StreamCountGroupBy) {
  streamCount(
    artistId: $artistId
    trackId: $trackId
    startDate: $startDate
    endDate: $endDate
    groupBy: $groupBy
  ) {
    ... on ByAge {
      total
      streams0to17
      streams18to22
      streams23to27
      streams28to34
      streams35to44
      streams45to59
      streams60to150
      streamsUnknown
    }
    ... on ByGender {
      total
      male
      female
      other
      unknown
    }
    ... on ByPlayLength {
      total
      streamsSub30
      streamsOver30
    }
    ... on ByDsp {
      total
      amazon
      deezer
      itunes
      soundcloud
      spotify
      youtube
      youtube_music
    }
  }
}
    `;

/**
 * __useGetStreamsByCategoryQuery__
 *
 * To run a query within a React component, call `useGetStreamsByCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStreamsByCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStreamsByCategoryQuery({
 *   variables: {
 *      artistId: // value for 'artistId'
 *      trackId: // value for 'trackId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      groupBy: // value for 'groupBy'
 *   },
 * });
 */
export function useGetStreamsByCategoryQuery(baseOptions: Apollo.QueryHookOptions<GetStreamsByCategoryQuery, GetStreamsByCategoryQueryVariables> & ({ variables: GetStreamsByCategoryQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStreamsByCategoryQuery, GetStreamsByCategoryQueryVariables>(GetStreamsByCategoryDocument, options);
      }
export function useGetStreamsByCategoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStreamsByCategoryQuery, GetStreamsByCategoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStreamsByCategoryQuery, GetStreamsByCategoryQueryVariables>(GetStreamsByCategoryDocument, options);
        }
export function useGetStreamsByCategorySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetStreamsByCategoryQuery, GetStreamsByCategoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetStreamsByCategoryQuery, GetStreamsByCategoryQueryVariables>(GetStreamsByCategoryDocument, options);
        }
export type GetStreamsByCategoryQueryHookResult = ReturnType<typeof useGetStreamsByCategoryQuery>;
export type GetStreamsByCategoryLazyQueryHookResult = ReturnType<typeof useGetStreamsByCategoryLazyQuery>;
export type GetStreamsByCategorySuspenseQueryHookResult = ReturnType<typeof useGetStreamsByCategorySuspenseQuery>;
export type GetStreamsByCategoryQueryResult = Apollo.QueryResult<GetStreamsByCategoryQuery, GetStreamsByCategoryQueryVariables>;
export const GetStreamsByDateDocument = gql`
    query GetStreamsByDate($artistId: Int!, $trackId: Int, $startDate: String, $endDate: String) {
  getTotalStreamsByDate(
    artistId: $artistId
    trackId: $trackId
    startDate: $startDate
    endDate: $endDate
  ) {
    date
    streams
  }
}
    `;

/**
 * __useGetStreamsByDateQuery__
 *
 * To run a query within a React component, call `useGetStreamsByDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStreamsByDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStreamsByDateQuery({
 *   variables: {
 *      artistId: // value for 'artistId'
 *      trackId: // value for 'trackId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useGetStreamsByDateQuery(baseOptions: Apollo.QueryHookOptions<GetStreamsByDateQuery, GetStreamsByDateQueryVariables> & ({ variables: GetStreamsByDateQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStreamsByDateQuery, GetStreamsByDateQueryVariables>(GetStreamsByDateDocument, options);
      }
export function useGetStreamsByDateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStreamsByDateQuery, GetStreamsByDateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStreamsByDateQuery, GetStreamsByDateQueryVariables>(GetStreamsByDateDocument, options);
        }
export function useGetStreamsByDateSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetStreamsByDateQuery, GetStreamsByDateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetStreamsByDateQuery, GetStreamsByDateQueryVariables>(GetStreamsByDateDocument, options);
        }
export type GetStreamsByDateQueryHookResult = ReturnType<typeof useGetStreamsByDateQuery>;
export type GetStreamsByDateLazyQueryHookResult = ReturnType<typeof useGetStreamsByDateLazyQuery>;
export type GetStreamsByDateSuspenseQueryHookResult = ReturnType<typeof useGetStreamsByDateSuspenseQuery>;
export type GetStreamsByDateQueryResult = Apollo.QueryResult<GetStreamsByDateQuery, GetStreamsByDateQueryVariables>;
export const GetTrackByIdDocument = gql`
    query GetTrackById($trackId: Int!) {
  track(id: $trackId) {
    id
    name
    youtubeAssetReference
    masterAudio
    gramexLabelPercentage
    volume
    order
    notes
    version
    release {
      id
    }
    isrcs {
      isrc
    }
    trackArtists {
      artistId
      type
      artist {
        name
      }
    }
    trackCollaborators {
      role
      id
      collaborator {
        id
        name
      }
    }
    contract {
      id
      artist {
        id
      }
    }
    trackCollaboratorRoyalty {
      id
      collaborator {
        type
        id
      }
      paymentAmount
      recoupableAmount
      royaltyPercentage
      royaltyType
      recoupableType
    }
    trackArtistRoyalty {
      id
      artistId
      royaltyType
      paymentAmount
      recoupableAmount
      royaltyPercentage
      recoupableType
    }
  }
}
    `;

/**
 * __useGetTrackByIdQuery__
 *
 * To run a query within a React component, call `useGetTrackByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTrackByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTrackByIdQuery({
 *   variables: {
 *      trackId: // value for 'trackId'
 *   },
 * });
 */
export function useGetTrackByIdQuery(baseOptions: Apollo.QueryHookOptions<GetTrackByIdQuery, GetTrackByIdQueryVariables> & ({ variables: GetTrackByIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTrackByIdQuery, GetTrackByIdQueryVariables>(GetTrackByIdDocument, options);
      }
export function useGetTrackByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTrackByIdQuery, GetTrackByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTrackByIdQuery, GetTrackByIdQueryVariables>(GetTrackByIdDocument, options);
        }
export function useGetTrackByIdSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetTrackByIdQuery, GetTrackByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetTrackByIdQuery, GetTrackByIdQueryVariables>(GetTrackByIdDocument, options);
        }
export type GetTrackByIdQueryHookResult = ReturnType<typeof useGetTrackByIdQuery>;
export type GetTrackByIdLazyQueryHookResult = ReturnType<typeof useGetTrackByIdLazyQuery>;
export type GetTrackByIdSuspenseQueryHookResult = ReturnType<typeof useGetTrackByIdSuspenseQuery>;
export type GetTrackByIdQueryResult = Apollo.QueryResult<GetTrackByIdQuery, GetTrackByIdQueryVariables>;
export const GetPresignedUrlDocument = gql`
    query GetPresignedUrl($fileName: String!, $month: Int!, $year: Int!, $uploadType: UploadType!, $dsp: RoyaltySourceProvider!) {
  getPreSignedUrl(
    fileName: $fileName
    month: $month
    year: $year
    uploadType: $uploadType
    dsp: $dsp
  )
}
    `;

/**
 * __useGetPresignedUrlQuery__
 *
 * To run a query within a React component, call `useGetPresignedUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPresignedUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPresignedUrlQuery({
 *   variables: {
 *      fileName: // value for 'fileName'
 *      month: // value for 'month'
 *      year: // value for 'year'
 *      uploadType: // value for 'uploadType'
 *      dsp: // value for 'dsp'
 *   },
 * });
 */
export function useGetPresignedUrlQuery(baseOptions: Apollo.QueryHookOptions<GetPresignedUrlQuery, GetPresignedUrlQueryVariables> & ({ variables: GetPresignedUrlQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPresignedUrlQuery, GetPresignedUrlQueryVariables>(GetPresignedUrlDocument, options);
      }
export function useGetPresignedUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPresignedUrlQuery, GetPresignedUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPresignedUrlQuery, GetPresignedUrlQueryVariables>(GetPresignedUrlDocument, options);
        }
export function useGetPresignedUrlSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetPresignedUrlQuery, GetPresignedUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPresignedUrlQuery, GetPresignedUrlQueryVariables>(GetPresignedUrlDocument, options);
        }
export type GetPresignedUrlQueryHookResult = ReturnType<typeof useGetPresignedUrlQuery>;
export type GetPresignedUrlLazyQueryHookResult = ReturnType<typeof useGetPresignedUrlLazyQuery>;
export type GetPresignedUrlSuspenseQueryHookResult = ReturnType<typeof useGetPresignedUrlSuspenseQuery>;
export type GetPresignedUrlQueryResult = Apollo.QueryResult<GetPresignedUrlQuery, GetPresignedUrlQueryVariables>;
export const GetStatementsRunsDocument = gql`
    query GetStatementsRuns($royaltyProvider: RoyaltySourceProvider!, $take: Int) {
  getRoyaltyStatementRuns(royaltyProvider: $royaltyProvider, take: $take) {
    periodStartDate
    periodEndDate
    fileName
    royaltySource
    processedAt
    createdAt
    id
  }
}
    `;

/**
 * __useGetStatementsRunsQuery__
 *
 * To run a query within a React component, call `useGetStatementsRunsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStatementsRunsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStatementsRunsQuery({
 *   variables: {
 *      royaltyProvider: // value for 'royaltyProvider'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useGetStatementsRunsQuery(baseOptions: Apollo.QueryHookOptions<GetStatementsRunsQuery, GetStatementsRunsQueryVariables> & ({ variables: GetStatementsRunsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStatementsRunsQuery, GetStatementsRunsQueryVariables>(GetStatementsRunsDocument, options);
      }
export function useGetStatementsRunsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStatementsRunsQuery, GetStatementsRunsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStatementsRunsQuery, GetStatementsRunsQueryVariables>(GetStatementsRunsDocument, options);
        }
export function useGetStatementsRunsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetStatementsRunsQuery, GetStatementsRunsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetStatementsRunsQuery, GetStatementsRunsQueryVariables>(GetStatementsRunsDocument, options);
        }
export type GetStatementsRunsQueryHookResult = ReturnType<typeof useGetStatementsRunsQuery>;
export type GetStatementsRunsLazyQueryHookResult = ReturnType<typeof useGetStatementsRunsLazyQuery>;
export type GetStatementsRunsSuspenseQueryHookResult = ReturnType<typeof useGetStatementsRunsSuspenseQuery>;
export type GetStatementsRunsQueryResult = Apollo.QueryResult<GetStatementsRunsQuery, GetStatementsRunsQueryVariables>;
export const GetPaymentsDocument = gql`
    query GetPayments($artistId: Int, $startDate: String, $endDate: String) {
  artistPayments(artistId: $artistId, startDate: $startDate, endDate: $endDate) {
    id
    artist {
      id
      name
    }
    title
    comment
    amount
    date
    createdBy {
      id
      email
    }
    createdAt
  }
}
    `;

/**
 * __useGetPaymentsQuery__
 *
 * To run a query within a React component, call `useGetPaymentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentsQuery({
 *   variables: {
 *      artistId: // value for 'artistId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useGetPaymentsQuery(baseOptions?: Apollo.QueryHookOptions<GetPaymentsQuery, GetPaymentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPaymentsQuery, GetPaymentsQueryVariables>(GetPaymentsDocument, options);
      }
export function useGetPaymentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPaymentsQuery, GetPaymentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPaymentsQuery, GetPaymentsQueryVariables>(GetPaymentsDocument, options);
        }
export function useGetPaymentsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetPaymentsQuery, GetPaymentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPaymentsQuery, GetPaymentsQueryVariables>(GetPaymentsDocument, options);
        }
export type GetPaymentsQueryHookResult = ReturnType<typeof useGetPaymentsQuery>;
export type GetPaymentsLazyQueryHookResult = ReturnType<typeof useGetPaymentsLazyQuery>;
export type GetPaymentsSuspenseQueryHookResult = ReturnType<typeof useGetPaymentsSuspenseQuery>;
export type GetPaymentsQueryResult = Apollo.QueryResult<GetPaymentsQuery, GetPaymentsQueryVariables>;
export const ListCollaboratorsDocument = gql`
    query ListCollaborators($listCollaboratorsId: Int) {
  listCollaborators(id: $listCollaboratorsId) {
    id
    name
    type
    active
    deletable
  }
}
    `;

/**
 * __useListCollaboratorsQuery__
 *
 * To run a query within a React component, call `useListCollaboratorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCollaboratorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCollaboratorsQuery({
 *   variables: {
 *      listCollaboratorsId: // value for 'listCollaboratorsId'
 *   },
 * });
 */
export function useListCollaboratorsQuery(baseOptions?: Apollo.QueryHookOptions<ListCollaboratorsQuery, ListCollaboratorsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListCollaboratorsQuery, ListCollaboratorsQueryVariables>(ListCollaboratorsDocument, options);
      }
export function useListCollaboratorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListCollaboratorsQuery, ListCollaboratorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListCollaboratorsQuery, ListCollaboratorsQueryVariables>(ListCollaboratorsDocument, options);
        }
export function useListCollaboratorsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ListCollaboratorsQuery, ListCollaboratorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListCollaboratorsQuery, ListCollaboratorsQueryVariables>(ListCollaboratorsDocument, options);
        }
export type ListCollaboratorsQueryHookResult = ReturnType<typeof useListCollaboratorsQuery>;
export type ListCollaboratorsLazyQueryHookResult = ReturnType<typeof useListCollaboratorsLazyQuery>;
export type ListCollaboratorsSuspenseQueryHookResult = ReturnType<typeof useListCollaboratorsSuspenseQuery>;
export type ListCollaboratorsQueryResult = Apollo.QueryResult<ListCollaboratorsQuery, ListCollaboratorsQueryVariables>;
export const ValidateRoyaltyRulesDocument = gql`
    query ValidateRoyaltyRules($artistRules: [ArtistRoyaltyRule!]!, $collaboratorRules: [CollaboratorRoyaltyRule!]!) {
  validateRoyaltyRules(
    artistRules: $artistRules
    collaboratorRules: $collaboratorRules
  ) {
    artistShares {
      id
      name
      amountEuro
    }
    collaboratorShares {
      id
      name
      amountEuro
    }
    pmeShare
    errors {
      key
      message
    }
    warnings {
      key
      message
    }
  }
}
    `;

/**
 * __useValidateRoyaltyRulesQuery__
 *
 * To run a query within a React component, call `useValidateRoyaltyRulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidateRoyaltyRulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidateRoyaltyRulesQuery({
 *   variables: {
 *      artistRules: // value for 'artistRules'
 *      collaboratorRules: // value for 'collaboratorRules'
 *   },
 * });
 */
export function useValidateRoyaltyRulesQuery(baseOptions: Apollo.QueryHookOptions<ValidateRoyaltyRulesQuery, ValidateRoyaltyRulesQueryVariables> & ({ variables: ValidateRoyaltyRulesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ValidateRoyaltyRulesQuery, ValidateRoyaltyRulesQueryVariables>(ValidateRoyaltyRulesDocument, options);
      }
export function useValidateRoyaltyRulesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ValidateRoyaltyRulesQuery, ValidateRoyaltyRulesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ValidateRoyaltyRulesQuery, ValidateRoyaltyRulesQueryVariables>(ValidateRoyaltyRulesDocument, options);
        }
export function useValidateRoyaltyRulesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ValidateRoyaltyRulesQuery, ValidateRoyaltyRulesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ValidateRoyaltyRulesQuery, ValidateRoyaltyRulesQueryVariables>(ValidateRoyaltyRulesDocument, options);
        }
export type ValidateRoyaltyRulesQueryHookResult = ReturnType<typeof useValidateRoyaltyRulesQuery>;
export type ValidateRoyaltyRulesLazyQueryHookResult = ReturnType<typeof useValidateRoyaltyRulesLazyQuery>;
export type ValidateRoyaltyRulesSuspenseQueryHookResult = ReturnType<typeof useValidateRoyaltyRulesSuspenseQuery>;
export type ValidateRoyaltyRulesQueryResult = Apollo.QueryResult<ValidateRoyaltyRulesQuery, ValidateRoyaltyRulesQueryVariables>;
export const ListUsersDocument = gql`
    query ListUsers {
  listUsers {
    id
    email
    clerkId
    role
    person {
      contractPerson {
        firstName
        lastName
      }
    }
  }
}
    `;

/**
 * __useListUsersQuery__
 *
 * To run a query within a React component, call `useListUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useListUsersQuery(baseOptions?: Apollo.QueryHookOptions<ListUsersQuery, ListUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListUsersQuery, ListUsersQueryVariables>(ListUsersDocument, options);
      }
export function useListUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListUsersQuery, ListUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListUsersQuery, ListUsersQueryVariables>(ListUsersDocument, options);
        }
export function useListUsersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ListUsersQuery, ListUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListUsersQuery, ListUsersQueryVariables>(ListUsersDocument, options);
        }
export type ListUsersQueryHookResult = ReturnType<typeof useListUsersQuery>;
export type ListUsersLazyQueryHookResult = ReturnType<typeof useListUsersLazyQuery>;
export type ListUsersSuspenseQueryHookResult = ReturnType<typeof useListUsersSuspenseQuery>;
export type ListUsersQueryResult = Apollo.QueryResult<ListUsersQuery, ListUsersQueryVariables>;
export const GetAssetsReferencesDocument = gql`
    query GetAssetsReferences($take: Int, $unprocessedOnly: Boolean) {
  assetReferences(take: $take, unprocessedOnly: $unprocessedOnly) {
    id
    isrc {
      isrc
    }
    assetTitle
    assetReference
    assetArtist
    incomeType
    source
    createdAt
    unpaidRoyalties {
      unmatchedTrack {
        id
      }
    }
  }
}
    `;

/**
 * __useGetAssetsReferencesQuery__
 *
 * To run a query within a React component, call `useGetAssetsReferencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssetsReferencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssetsReferencesQuery({
 *   variables: {
 *      take: // value for 'take'
 *      unprocessedOnly: // value for 'unprocessedOnly'
 *   },
 * });
 */
export function useGetAssetsReferencesQuery(baseOptions?: Apollo.QueryHookOptions<GetAssetsReferencesQuery, GetAssetsReferencesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAssetsReferencesQuery, GetAssetsReferencesQueryVariables>(GetAssetsReferencesDocument, options);
      }
export function useGetAssetsReferencesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAssetsReferencesQuery, GetAssetsReferencesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAssetsReferencesQuery, GetAssetsReferencesQueryVariables>(GetAssetsReferencesDocument, options);
        }
export function useGetAssetsReferencesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetAssetsReferencesQuery, GetAssetsReferencesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAssetsReferencesQuery, GetAssetsReferencesQueryVariables>(GetAssetsReferencesDocument, options);
        }
export type GetAssetsReferencesQueryHookResult = ReturnType<typeof useGetAssetsReferencesQuery>;
export type GetAssetsReferencesLazyQueryHookResult = ReturnType<typeof useGetAssetsReferencesLazyQuery>;
export type GetAssetsReferencesSuspenseQueryHookResult = ReturnType<typeof useGetAssetsReferencesSuspenseQuery>;
export type GetAssetsReferencesQueryResult = Apollo.QueryResult<GetAssetsReferencesQuery, GetAssetsReferencesQueryVariables>;