import { ViewError } from '@/components/errors/view-error';
import { ViewLoader } from '@/components/loaders/view-loader';
import { AdminHeader } from '@/components/partials/admin-header';
import {
  RoyaltyType,
  useGetTrackByIdQuery,
  useValidateRoyaltyRulesQuery,
} from '@/gql/generated-types';
import { cn } from '@/lib/utils';
import { ADMIN_EDIT_TRACK, ADMIN_RELEASES } from '@/services/router/routes';
import {
  TrackInfoItem,
  TrackRoyaltyInfoItem,
} from '@/views/admin/data-management/releases/components/track-info-item';
import { RoyaltyAnalysis } from '@/views/admin/data-management/releases/forms/components/royalty-analysis';
import { useParams } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';

export default function Track() {
  const { trackId, releaseId } = useParams({ strict: false });
  const { data, loading, error } = useGetTrackByIdQuery({
    fetchPolicy: 'cache-first',
    variables: { trackId: Number(trackId) },
  });

  const { data: validationData } = useValidateRoyaltyRulesQuery({
    fetchPolicy: 'cache-first',
    variables: {
      artistRules:
        data?.track?.trackArtistRoyalty.map(royalty => {
          return {
            artistId: royalty.artistId,
            royaltyPercentage: royalty?.royaltyPercentage,
            royaltyType: royalty?.royaltyType ?? RoyaltyType.ArtistShare,
          };
        }) ?? [],

      collaboratorRules:
        data?.track?.trackCollaboratorRoyalty.map(royalty => {
          return {
            collaboratorId: royalty.collaborator.id,
            royaltyPercentage: royalty?.royaltyPercentage,
            royaltyType: royalty?.royaltyType ?? RoyaltyType.ArtistShare,
          };
        }) ?? [],
    },
  });
  const { t } = useTranslation();

  if (error) {
    return (
      <div className="space-y-12">
        <ViewError />
      </div>
    );
  }

  if (loading) {
    return (
      <div className="space-y-12">
        <ViewLoader />
      </div>
    );
  }

  return (
    <div className="space-y-12">
      <AdminHeader
        title={data?.track?.name ?? ''}
        goBackLink={{
          link: `${ADMIN_RELEASES}/${releaseId}`,
          label: t('adminShared.back'),
        }}
        actionLink={{
          link: `${ADMIN_EDIT_TRACK}`,
          label: 'Muokkaa',
        }}
      />
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12">
        <TrackInfoItem
          label={t('trackPage.mainArtists')}
          value={data?.track?.trackArtists
            .filter(artist => artist.type === 'MAIN')
            .map(artist => artist?.artist.name)
            .join(', ')}
        />
        <TrackInfoItem
          label={t('trackPage.featArtists')}
          value={data?.track?.trackArtists
            .filter(artist => artist.type === 'FEATURED')
            .map(artist => artist?.artist.name)
            .join(', ')}
        />
        <TrackInfoItem
          label={t('trackPage.collaborators')}
          value={data?.track?.trackCollaborators
            .map(collaborator => collaborator?.collaborator.name)
            .join(', ')}
        />
        <TrackInfoItem
          label={'ISRC'}
          value={data?.track?.isrcs?.map(isrc => isrc?.isrc)[0]}
        />
        <TrackInfoItem
          label={t('trackPage.youtubeAssetReference')}
          value={data?.track?.youtubeAssetReference ?? '-'}
        />
        <TrackInfoItem
          label={t('trackPage.gramexLabelPercentage')}
          value={data?.track?.gramexLabelPercentage.toString() ?? '-'}
        />
        <TrackInfoItem
          label={t('trackPage.volume')}
          value={data?.track?.volume.toString() ?? '-'}
        />
        <TrackInfoItem
          label={t('trackPage.version')}
          value={data?.track?.version?.toString() ?? '-'}
        />
      </div>
      <div className="space-y-12">
        {data?.track?.trackArtistRoyalty &&
          data?.track?.trackArtistRoyalty.filter(artistRoyalty =>
            data.track?.trackArtists
              .filter(artist => artist.type === 'MAIN')
              .map(artist => artist.artistId)
              .includes(artistRoyalty.artistId),
          ).length > 0 && (
            <div className="space-y-12">
              <h4 className="h4">{t('trackPage.royaltyArtists')}</h4>
              <div>
                {data?.track?.trackArtistRoyalty
                  .filter(artistRoyalty =>
                    data.track?.trackArtists
                      .filter(artist => artist.type === 'MAIN')
                      .map(artist => artist.artistId)
                      .includes(artistRoyalty.artistId),
                  )
                  .map((royalty, index) => (
                    <div
                      className={cn(
                        'p-4 md:p-8 space-y-4',
                        index % 2 === 0 && 'bg-grey-darkest',
                      )}
                    >
                      <header>
                        <h4 className="font-bold text-xl">
                          {
                            data?.track?.trackArtists.find(
                              artist => artist.artistId === royalty.artistId,
                            )?.artist.name
                          }
                        </h4>
                        <h5 className="font-robotoMono text-bone/50">MAIN</h5>
                      </header>
                      <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
                        <TrackRoyaltyInfoItem
                          label={t('trackPage.advancedPayment')}
                          value={new Intl.NumberFormat('fi-FI', {
                            style: 'currency',
                            currency: 'EUR',
                          }).format(royalty.paymentAmount ?? 0)}
                        />
                        <TrackRoyaltyInfoItem
                          label={t('trackPage.recoupable')}
                          value={royalty?.recoupableAmount?.toString() ?? '0'}
                        />
                        <TrackRoyaltyInfoItem
                          label={t('trackPage.royaltyPct')}
                          value={royalty?.royaltyPercentage}
                        />
                        <TrackRoyaltyInfoItem
                          label={t('trackPage.royaltyType')}
                          value={
                            royalty?.royaltyType
                              ? t(`trackPage.${royalty.royaltyType}`)
                              : '-'
                          }
                        />
                        <TrackRoyaltyInfoItem
                          label={t('trackPage.recoupType')}
                          value={
                            royalty?.recoupableType !== null
                              ? t(`trackPage.${royalty.recoupableType}`)
                              : '-'
                          }
                        />
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          )}
        {data?.track?.trackArtistRoyalty &&
          data?.track?.trackArtistRoyalty.filter(artistRoyalty =>
            data.track?.trackArtists
              .filter(artist => artist.type === 'FEATURED')
              .map(artist => artist.artistId)
              .includes(artistRoyalty.artistId),
          ).length > 0 && (
            <div className="space-y-12">
              <h4 className="h4">{t('trackPage.royaltyFeat')}</h4>
              <div>
                {data?.track?.trackArtistRoyalty
                  .filter(artistRoyalty =>
                    data.track?.trackArtists
                      .filter(artist => artist.type === 'FEATURED')
                      .map(artist => artist.artistId)
                      .includes(artistRoyalty.artistId),
                  )
                  .map((royalty, index) => (
                    <div
                      className={cn(
                        'p-4 md:p-8 space-y-4',
                        index % 2 === 0 && 'bg-grey-darkest',
                      )}
                    >
                      <header>
                        <h4 className="font-bold text-xl">
                          {
                            data?.track?.trackArtists.find(
                              artist => artist.artistId === royalty.artistId,
                            )?.artist.name
                          }
                        </h4>
                        <h5 className="font-robotoMono text-bone/50">
                          FEATURING
                        </h5>
                      </header>
                      <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
                        <TrackRoyaltyInfoItem
                          label={t('trackPage.advancedPayment')}
                          value={new Intl.NumberFormat('fi-FI', {
                            style: 'currency',
                            currency: 'EUR',
                          }).format(royalty.paymentAmount ?? 0)}
                        />
                        <TrackRoyaltyInfoItem
                          label={t('trackPage.recoupable')}
                          value={royalty?.recoupableAmount?.toString() ?? '0'}
                        />
                        <TrackRoyaltyInfoItem
                          label={t('trackPage.royaltyPct')}
                          value={royalty?.royaltyPercentage}
                        />
                        <TrackRoyaltyInfoItem
                          label={t('trackPage.royaltyType')}
                          value={
                            royalty?.royaltyType
                              ? t(`trackPage.${royalty.royaltyType}`)
                              : '-'
                          }
                        />
                        <TrackRoyaltyInfoItem
                          label={t('trackPage.recoupType')}
                          value={
                            royalty?.recoupableType !== null
                              ? t(`trackPage.${royalty.recoupableType}`)
                              : '-'
                          }
                        />
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          )}
        {data?.track?.trackCollaboratorRoyalty &&
          data?.track?.trackCollaboratorRoyalty.length > 0 && (
            <div className="space-y-12">
              <h4 className="h4">{t('trackPage.royaltyCollab')}</h4>

              <div>
                {data?.track?.trackCollaboratorRoyalty.map((royalty, index) => (
                  <div
                    className={cn(
                      'p-4 md:p-8 space-y-4',
                      index % 2 === 0 && 'bg-grey-darkest',
                    )}
                  >
                    <header>
                      <h4 className="font-bold text-xl">
                        {
                          data.track?.trackCollaborators[index].collaborator
                            .name
                        }
                      </h4>
                    </header>
                    <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
                      <TrackRoyaltyInfoItem
                        label={t('trackPage.role')}
                        value={
                          royalty.collaborator.type
                            ? t(`trackPage.${royalty.collaborator.type}`)
                            : '-'
                        }
                      />
                      <TrackRoyaltyInfoItem
                        label={t('trackPage.advancedPayment')}
                        value={new Intl.NumberFormat('fi-FI', {
                          style: 'currency',
                          currency: 'EUR',
                        }).format(royalty.paymentAmount ?? 0)}
                      />
                      <TrackRoyaltyInfoItem
                        label={t('trackPage.recoupable')}
                        value={royalty?.recoupableAmount?.toString() ?? '0'}
                      />
                      <TrackRoyaltyInfoItem
                        label={t('trackPage.royaltyPct')}
                        value={royalty?.royaltyPercentage}
                      />
                      <TrackRoyaltyInfoItem
                        label={t('trackPage.royaltyType')}
                        value={
                          royalty?.royaltyType
                            ? t(`trackPage.${royalty.royaltyType}`)
                            : '-'
                        }
                      />
                      <TrackRoyaltyInfoItem
                        label={t('trackPage.recoupType')}
                        value={
                          royalty?.recoupableType !== null
                            ? t(`trackPage.${royalty.recoupableType}`)
                            : '-'
                        }
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
      </div>
      <div>
        <RoyaltyAnalysis
          data={validationData}
          fetchError={error}
          fetchLoading={loading}
        />
      </div>
    </div>
  );
}
