export const MONTHS = Array.from({ length: 12 }, (_, i) => {
  const d = new Date(new Date().getFullYear(), i, 1);

  return {
    label: d.toLocaleString('fi-FI', { month: 'long' }),
    value: i + 1,
  };
});

export function generateRoyaltyPeriodArray(
  start: number,
  split: number,
): number[] {
  const arr: number[] = [];
  let current = start;
  const actualSplit = 12 / split;
  // eslint-disable-next-line no-constant-condition
  while (true) {
    arr.push(current);
    current += actualSplit;
    if (current > 12) {
      current -= 12;
    }
    if (current === start) {
      arr.push(start);
      break;
    }
  }

  if (arr.length === split) {
    return arr.filter((value, index, self) => self.indexOf(value) === index);
  }
  return arr.filter((value, index, self) => self.indexOf(value) === index);
}

export function twoDecimalsIfNeeded(amount: number) {
  return amount % 1 !== 0 ? amount.toFixed(2) : amount;
}
