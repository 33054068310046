import * as React from 'react';

import { Button } from '@/components/ui/button';

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { MoreHorizontal } from 'lucide-react';
import { Link } from '@tanstack/react-router';
import { cn } from '@/lib/utils';
import { ADMIN_EDIT_ARTIST } from '@/services/router/routes';
import { useTranslation } from 'react-i18next';

export interface ArtistActionsProps {
  id: number;
  isDeletable?: boolean;
  onDeleteAction: (id: number) => void;
}

export function ArtistActions(props: ArtistActionsProps) {
  const [open, setOpen] = React.useState(false);
  const { t } = useTranslation();

  return (
    <>
      <DropdownMenu open={open} onOpenChange={setOpen}>
        <DropdownMenuTrigger asChild>
          <Button
            variant="ghost"
            size="iconSmall"
            className={cn(
              'border-0 focus:border-bone focus:border-1 focus:border',
              open && 'bg-grey-dark',
            )}
          >
            <span className="sr-only">
              {t('adminArtist.actionMenu.showActions')}
            </span>
            <MoreHorizontal />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent
          align="end"
          className="w-[200px] mt-2 bg-bone shadow-xl"
        >
          <DropdownMenuGroup>
            <DropdownMenuItem className="text-grey-darkest text-sm">
              <Link
                to={ADMIN_EDIT_ARTIST}
                params={{
                  artistId: props.id,
                }}
                className="w-full"
              >
                {t('adminArtist.actionMenu.edit')}
              </Link>
            </DropdownMenuItem>
            {props.isDeletable && (
              <DropdownMenuItem
                className="text-grey-darkest text-sm cursor-pointer"
                onClick={() => props.onDeleteAction(props.id)}
              >
                {t('adminArtist.actionMenu.delete')}
              </DropdownMenuItem>
            )}
          </DropdownMenuGroup>
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  );
}
