import { useGetStreamsForArtistSuspenseQuery } from '@/gql/generated-types';

interface OverAllStreamsProps {
  artistId: number;
  endDate: string;
  startDate: string;
}

export const OverAllStreams = (props: OverAllStreamsProps) => {
  const { data: streamData } = useGetStreamsForArtistSuspenseQuery({
    variables: {
      artistId: props.artistId,
      endDate: props.endDate,
      startDate: props.startDate,
    },
  });

  return (
    <h2 className="h2-mono">
      {new Intl.NumberFormat('fi-FI').format(streamData?.streamsForArtist ?? 0)}
    </h2>
  );
};
