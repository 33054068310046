import { SongCover } from '@/components/ui/song-cover';
import { useGetStreamsSuspenseQuery } from '@/gql/generated-types';
import { getObjectWithHighestStreamCount } from '../util';

import { ARTIST_STREAMS_SONG } from '@/services/router/routes';
import { Link } from '@tanstack/react-router';

interface SongProps {
  artistId: number;
  endDate: string;
  startDate: string;
}

export const Song = (props: SongProps) => {
  const { data: trackStreamsData } = useGetStreamsSuspenseQuery({
    variables: {
      artistId: props.artistId,
      endDate: props.endDate,
      startDate: props.startDate,
    },
  });

  const mostStreamedTrack = getObjectWithHighestStreamCount(trackStreamsData!);
  return (
    <Link
      to={ARTIST_STREAMS_SONG}
      params={{
        trackId: mostStreamedTrack?.trackId,
        artistId: props.artistId,
      }}
    >
      <SongCover
        cover={mostStreamedTrack?.trackCoverArt ?? ''}
        title={mostStreamedTrack?.trackName ?? ''}
        streamCount={new Intl.NumberFormat('fi-FI').format(
          mostStreamedTrack?.totalStreams,
        )}
        newRelease={false}
      />
    </Link>
  );
};
