import { ViewError } from '@/components/errors/view-error';
import { ViewLoader } from '@/components/loaders/view-loader';
import { AdminHeader } from '@/components/partials/admin-header';
import { Button, buttonVariants } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { useGetArtistListQuery } from '@/gql/generated-types';
import { Link, useSearch } from '@tanstack/react-router';
import { ChevronRight } from 'lucide-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ArtistActions } from './components/action-menu';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import { useMutation } from '@apollo/client';
import { DELETE_ARTIST } from '@/graphql/mutations';
import { toast } from '@/components/ui/use-toast';
import { cn } from '@/lib/utils';
import { Label } from '@/components/ui/label';
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group';
import { Checkbox } from '@/components/ui/checkbox';

type costCenterValues = 'PME Records' | 'OTHER';

export default function AdminArtists() {
  const { t } = useTranslation();
  const { name } = useSearch({ strict: false });
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState<number | null>(null);
  const [filtering, setFiltering] = useState<{
    releases: boolean;
    noReleases: boolean;
    costCenter: costCenterValues;
  }>({
    releases: true,
    noReleases: true,
    costCenter: 'PME Records',
  });

  const [searchTerm, setSearchTerm] = useState<string>(name ?? '');
  const { loading, error, data, refetch } = useGetArtistListQuery({
    fetchPolicy: 'no-cache',
  });

  const [deleteArtist] = useMutation(DELETE_ARTIST, {
    onError: _ => {
      toast({
        variant: 'destructive',
        title: t('adminArtistPage.deleteErrorTitle'),
        description: t('adminArtistPage.deleteErrorDescription'),
      });
    },
    onCompleted: _ => {
      refetch();
      toast({
        title: t('adminArtistPage.deleteSuccessTitle'),
        description: t('adminArtistPage.deleteSuccessDescription'),
      });
      setOpen(false);
      setSelected(null);
    },
  });

  if (error) {
    return <ViewError />;
  }

  const filteredArtistList = data?.listArtists?.filter(artist =>
    artist?.name?.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  const hideArtists = filteredArtistList
    ?.filter(obj => {
      if (filtering.noReleases && filtering.releases) {
        return true; // If both properties are true show everything
      } else if (filtering.noReleases) {
        return obj?.deletable;
      } else if (filtering.releases) {
        return !obj?.deletable;
      }
      return false; // If both properties are false, filter out everything
    })
    .filter(artist => {
      if (filtering.costCenter === 'PME Records')
        return artist?.costCenter === 'PME Records';
      else return artist?.costCenter !== 'PME Records';
    });

  return (
    <>
      <AdminHeader title={t('adminArtistPage.title')} />
      {loading && <ViewLoader />}
      {!loading && data && (
        <>
          <div className="space-y-4 lg:space-y-0 lg:flex justify-between items-end">
            <div className="space-y-4 lg:max-w-[25%] min-w-[25%] max-w-full">
              <Input
                type="text"
                value={searchTerm}
                placeholder={t('adminArtistPage.searchForArtist')}
                className="text-grey-darkest placeholder:text-grey-dark font-roboto border-0 px-2 w-full"
                onChange={event => setSearchTerm(event.target.value)}
              />
            </div>
            <div className="space-y-4 lg:max-w-[50%] max-w-full flex items-end space-x-4">
              <div className="space-y-4">
                <Label>{t('adminArtistPage.costCenter')}</Label>
                <div className="flex space-x-4 items-center">
                  <RadioGroup
                    defaultValue="option-one"
                    className="flex items-center"
                    value={filtering.costCenter}
                    onValueChange={value => {
                      setFiltering({
                        ...filtering,
                        costCenter: value as costCenterValues, // NOTE: Safe typecasting
                      });
                    }}
                  >
                    <div className="flex items-center space-x-2">
                      <RadioGroupItem value="PME Records" id="pme" />
                      <Label htmlFor="pme">PME Records</Label>
                    </div>
                    <div className="flex items-center space-x-2">
                      <RadioGroupItem value="OTHER" id="other" />
                      <Label htmlFor="other">Muut</Label>
                    </div>
                  </RadioGroup>
                </div>
              </div>
              <div className="space-y-4">
                <Label>{t('adminArtistPage.releases')}</Label>
                <div className="flex space-x-4 items-center">
                  <div className="space-x-2 flex items-center">
                    <Checkbox
                      id="original"
                      checked={filtering.releases}
                      onCheckedChange={() =>
                        setFiltering({
                          ...filtering,
                          releases: !filtering.releases,
                        })
                      }
                    />
                    <Label htmlFor="duplicateOf">
                      {t('adminArtistPage.releases')}
                    </Label>
                  </div>
                  <div className="space-x-2 flex items-center">
                    <Checkbox
                      id="duplicateOf"
                      checked={filtering.noReleases}
                      onCheckedChange={() =>
                        setFiltering({
                          ...filtering,
                          noReleases: !filtering.noReleases,
                        })
                      }
                    />
                    <Label htmlFor="duplicateOf">
                      {t('adminArtistPage.noReleases')}
                    </Label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-1  md:grid-cols-3">
            {!hideArtists?.length && (
              <div className="col-span-1 md:col-span-3">
                <h3 className="font-bold text-2xl">
                  {t('adminArtistPage.noResults')}
                </h3>
              </div>
            )}
            {hideArtists?.map((artist, index: number) => (
              <div
                key={index}
                style={{
                  backgroundImage: `url("${
                    artist?.imageUrl || '/assets/default_artist.png'
                  }")`,
                }}
                className="bg-cover bg-center p-5 min-h-[300px] flex items-end w-full"
              >
                <div
                  className={cn(
                    'rounded-[20px] bg-grey-darkest/80 p-4 flex justify-between items-center w-full',
                    artist?.deletable && 'bg-red-500/50',
                  )}
                >
                  <div className="basis-3/5 w-3/5">
                    <h3 className="font-bold text-2xl truncate block">
                      {artist?.name}
                      <br />
                      {artist?.costCenter}
                    </h3>
                  </div>
                  <div className="basis-2/5 justify-end w-2/5 text-right space-x-2">
                    <ArtistActions
                      isDeletable={artist?.deletable}
                      id={Number(artist?.id)}
                      onDeleteAction={id => {
                        setSelected(id);
                        setOpen(true);
                      }}
                    />
                    <Link
                      to={`/artist/${artist?.id}`}
                      arial-label={t('adminArtistPage.moveToArtistPage', {
                        artist: artist?.name,
                      })}
                      className={buttonVariants({
                        variant: 'default',
                        size: 'icon',
                      })}
                    >
                      <ChevronRight className="w-6 h-6" />
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <Dialog
            open={open}
            onOpenChange={open => {
              if (!open) {
                setSelected(null);
              }
              setOpen(open);
            }}
          >
            <DialogContent className="space-y-6 max-h-[800px] overflow-scroll">
              <DialogHeader>
                <DialogTitle className="text-xl font-medium">
                  {t('adminArtistPage.areYouSure')}
                </DialogTitle>
                <DialogDescription>
                  {t('adminArtistPage.deleteDescription')}
                </DialogDescription>
              </DialogHeader>

              <div className="flex justify-between">
                <Button
                  type="button"
                  size="sm"
                  variant="outline"
                  onClick={() => setOpen(false)}
                >
                  {t('adminArtistPage.cancel')}
                </Button>
                <Button
                  type="button"
                  size="sm"
                  variant="destructive"
                  onClick={() =>
                    deleteArtist({
                      variables: {
                        deleteArtistId: selected,
                      },
                    })
                  }
                >
                  {t('adminArtistPage.delete')}
                </Button>
              </div>
            </DialogContent>
          </Dialog>
        </>
      )}
    </>
  );
}
