import { ChangeEventHandler, useEffect, useState } from 'react';
import { Popover, PopoverContent, PopoverTrigger } from './popover';
import { SelectSingleEventHandler } from 'react-day-picker';
import { Button } from './button';
import { AlertCircle, CalendarIcon } from 'lucide-react';
import { Calendar } from './calendar';
import { format, isValid, parse } from 'date-fns';
import { Input } from './input';
import { useTranslation } from 'react-i18next';

type DayPickerProps = {
  onDateChange: (date: Date) => void;
  selectedDate: Date | string;
  dateTimeFormat?: string;
  allowedFrom?: Date | string;
};

export function DayPicker(props: DayPickerProps) {
  const { onDateChange, selectedDate, dateTimeFormat } = props;
  const dateFormat = dateTimeFormat ? dateTimeFormat : 'dd.MM.yyyy';
  const { t } = useTranslation();
  const initialDate = format(new Date(selectedDate), dateFormat);
  const [selected, setSelected] = useState<Date>(new Date(selectedDate));
  const [inputValue, setInputValue] = useState<string>(initialDate);
  const [isPopperOpen, setIsPopperOpen] = useState(false);
  const handleDaySelect: SelectSingleEventHandler = date => {
    if (date) {
      setSelected(date);
      setInputValue(format(date, dateFormat));
      onDateChange(date);
      setIsPopperOpen(false);
    } else {
      setInputValue('');
    }
  };

  // NOTE: this fixes bug where selectedDate prop comes late
  useEffect(() => {
    setSelected(new Date(selectedDate));
    setInputValue(format(new Date(selectedDate), dateFormat));
  }, [dateFormat, selectedDate]);

  function isValidDateString(str: string) {
    // Define the regular expression pattern
    const pattern = /^\d{1,2}\.\d{1,2}\.\d{4}$/;
    const [day, month, year] = str.split('.').map(Number);

    const date = new Date(year, month - 1, day);

    if (month - 1 > 12 || day > 31) {
      return false;
    }

    // Test the string against the pattern
    return pattern.test(str) && isValid(date);
  }

  const handleInputChange: ChangeEventHandler<HTMLInputElement> = e => {
    setInputValue(e.currentTarget.value);
    if (e.currentTarget.value.length === 10) {
      if (isValidDateString(e.currentTarget.value)) {
        const date = parse(e.currentTarget.value, dateFormat, new Date());
        if (props.allowedFrom && new Date(date) < new Date(props.allowedFrom)) {
          setSelected(new Date(props.allowedFrom));
          onDateChange(new Date(props.allowedFrom));
        } else {
          setSelected(date);
          onDateChange(date);
        }
      }
    }
  };

  const disabledDays = props.allowedFrom
    ? { before: new Date(props.allowedFrom) }
    : undefined;
  return (
    <>
      <div className="flex space-x-4">
        <Input
          placeholder="Syötä tai valitse päivämäärä"
          value={inputValue}
          onChange={handleInputChange}
          className="basis-2/3"
          maxLength={10}
        />
        <Popover
          open={isPopperOpen}
          onOpenChange={open => setIsPopperOpen(open)}
        >
          <PopoverTrigger asChild>
            <Button className="rounded-md h-10 text-sm w-full tracking-normal text-left basis-1/3">
              <CalendarIcon className="mr-2 h-4 w-4" />
            </Button>
          </PopoverTrigger>
          <PopoverContent className="bg-grey-darkest">
            <Calendar
              mode="single"
              initialFocus={isPopperOpen}
              selected={selected}
              defaultMonth={selected}
              onSelect={handleDaySelect}
              disabled={disabledDays}
            />
          </PopoverContent>
        </Popover>
      </div>
      {!isValidDateString(inputValue) && (
        <div className="text-xs text-orange-400 flex items-center">
          <AlertCircle className="h-3 w-3 mr-2" />
          {t('dayPicker.selectedDate', { date: format(selected, dateFormat) })}
        </div>
      )}
    </>
  );
}
