import {
  ReleaseTrack,
  TrackArtist,
  TrackCollaborator,
} from '@/services/store/releases/types';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormContainer } from './container';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
} from '@/components/ui/select';
import {
  ArtistRoyaltyRule,
  CollaboratorRoyaltyRule,
  RoyaltyType,
  useGetArtistContractsQuery,
  useValidateRoyaltyRulesLazyQuery,
} from '@/gql/generated-types';
import { Input } from '@/components/ui/input';
import { default as MultiSelect } from 'react-select';
import {
  DropdownIndicator,
  multiSelectStyles,
} from '@/styles/multi-select.styles';
import RoyaltySplitForm, {
  RoyaltySplitFormHandle,
} from './artist/royalty-split';
import CollaboratorForm, {
  CollaboratorRoyaltySplitFormHandle,
} from './collaborator/royalty-split';

import { cn, isDefined } from '@/lib/utils';
import { Button } from '@/components/ui/button';
import { NumberInput } from '@/components/ui/number-input';
import { Textarea } from '@/components/ui/textarea';
import { RadialCard } from '@/components/ui/radial-card';
import { RoyaltyAnalysis } from './components/royalty-analysis';

export type TrackFormProps = {
  edit?: boolean;
  artists: ArtistList;
  collaborators: ArtistList;
  values: ReleaseTrack;
  onSubmit: (values: ReleaseTrack) => void;
  errors: string[];
};

export function TrackForm(props: TrackFormProps) {
  const [trackFormData, setTrackFormData] = useState<ReleaseTrack>(
    props.values,
  );

  const { data } = useGetArtistContractsQuery({
    fetchPolicy: 'no-cache',
    variables: {
      artistId: Number(trackFormData.contractArtistId),
    },
  });

  // NOTE: must typecast due to TS inheritance
  const [refetch, { data: royaltyData, loading, error }] =
    useValidateRoyaltyRulesLazyQuery({
      fetchPolicy: 'network-only',
      variables: {
        artistRules:
          (props.values.trackArtists.map(a => {
            return {
              artistId: Number(a.artistId),
              royaltyPercentage: Number(a.royaltyRules?.royaltyPercentage),
              royaltyType: a.royaltyRules?.royaltyType ?? 'ARTIST_SHARE',
            };
          }) as unknown as ArtistRoyaltyRule) ?? [],
        collaboratorRules:
          (props.values.trackCollaborators.map(a => {
            return {
              collaboratorId: Number(a.collaboratorId),
              royaltyPercentage: Number(a.royaltyRules?.royaltyPercentage),
              royaltyType: a.royaltyRules?.royaltyType ?? 'ARTIST_SHARE',
            };
          }) as unknown as CollaboratorRoyaltyRule) ?? [],
      },
    });

  const { artists } = props;
  const { t } = useTranslation();

  const selectedArtistContract = data?.contracts?.find(
    contract => contract?.id === trackFormData.contractId,
  );

  function handleFieldOnChange(
    field: keyof Omit<ReleaseTrack, 'trackArtists'>,
    value: unknown,
  ) {
    setTrackFormData(prevState => ({
      ...prevState,
      [field]: value,
    }));
  }

  function handleCollaboratorFieldOnChange(value: TrackCollaborator[]) {
    setTrackFormData(prevState => ({
      ...prevState,
      trackCollaborators: value,
    }));
  }

  function handleArtistFieldOnChange(
    type: 'MAIN' | 'FEATURED',
    value: TrackArtist[],
  ) {
    const { trackArtists } = trackFormData;

    const otherArtists = trackArtists.filter(artist => artist.type !== type);

    const newState = [...otherArtists, ...value];

    setTrackFormData(prevState => ({
      ...prevState,
      trackArtists: newState,
    }));
  }

  const mainArtistRoyaltySplit = useRef<RoyaltySplitFormHandle>(null);
  const featArtistRoyaltySplit = useRef<RoyaltySplitFormHandle>(null);
  const collaboratorsRoyaltySplit =
    useRef<CollaboratorRoyaltySplitFormHandle>(null);

  function handleTrackCreate() {
    const mainRoyalty = mainArtistRoyaltySplit.current?.getFormData();
    const featRoyalty = featArtistRoyaltySplit.current?.getFormData();
    const collaboratorsRoyalty =
      collaboratorsRoyaltySplit.current?.getFormData();

    if (typeof mainRoyalty !== 'boolean' && typeof featRoyalty !== 'boolean') {
      const allArtist = [...(mainRoyalty ?? []), ...(featRoyalty ?? [])];
      const { trackArtists, id, youtubeAssetReference, ...rest } =
        trackFormData;
      const arstistData = allArtist.filter(isDefined);
      const track: ReleaseTrack = {
        ...rest,
        youtubeAssetReference:
          youtubeAssetReference && youtubeAssetReference.length > 0
            ? youtubeAssetReference
            : null,
        id: props.values.id
          ? props.values.id
          : Number(Math.random().toString().slice(2, 11)),
        trackArtists: arstistData,
        trackCollaborators:
          typeof collaboratorsRoyalty === 'boolean' ||
          typeof collaboratorsRoyalty === 'undefined'
            ? []
            : collaboratorsRoyalty.filter(isDefined),
      };

      props.onSubmit(track);
    }
  }

  function handleRoyaltyRulesValidation() {
    const mainRoyalty = mainArtistRoyaltySplit.current?.getFormData();
    const featRoyalty = featArtistRoyaltySplit.current?.getFormData();
    const collaboratorsRoyalty =
      collaboratorsRoyaltySplit.current?.getFormData();

    if (
      typeof mainRoyalty !== 'boolean' &&
      typeof featRoyalty !== 'boolean' &&
      typeof collaboratorsRoyalty !== 'boolean'
    ) {
      const main: ArtistRoyaltyRule[] =
        mainRoyalty?.map(a => {
          return {
            artistId: Number(a?.artistId),
            royaltyType: a?.royaltyRules?.royaltyType as RoyaltyType,
            royaltyPercentage: Number(a?.royaltyRules?.royaltyPercentage),
          };
        }) ?? [];

      const feat: ArtistRoyaltyRule[] =
        featRoyalty?.map(a => {
          return {
            artistId: Number(a?.artistId),
            royaltyType: a?.royaltyRules?.royaltyType as RoyaltyType,
            royaltyPercentage: Number(a?.royaltyRules?.royaltyPercentage),
          };
        }) ?? [];

      const collab: CollaboratorRoyaltyRule[] =
        collaboratorsRoyalty?.map(a => {
          return {
            collaboratorId: Number(a?.collaboratorId),
            royaltyType: a?.royaltyRules?.royaltyType as RoyaltyType,
            royaltyPercentage: Number(a?.royaltyRules?.royaltyPercentage),
          };
        }) ?? [];

      const combine = main?.concat(feat ?? []);

      refetch({
        variables: {
          artistRules: combine ?? [],
          collaboratorRules: collab,
        },
      });
    } else {
      return {
        error: true,
      };
    }
  }

  return (
    <>
      <div className="grid grid-cols-2 gap-10">
        <h4 className="h4 mb-0 col-span-2">{t('trackForm.basicInfo')}</h4>
        {!trackFormData.contractId && (
          <div className="col-span-2">
            <RadialCard variant="grey" className="text-center font-robotoMono">
              {t('trackForm.chooseContractError')}
            </RadialCard>
          </div>
        )}
        <FormContainer
          label={t('trackForm.contractArtist')}
          error={props.errors?.includes('contractArtistId')}
        >
          <Select
            value={trackFormData.contractArtistId.toString()}
            onValueChange={value =>
              handleFieldOnChange('contractArtistId', Number(value))
            }
          >
            <SelectTrigger className="w-full">
              {trackFormData?.contractArtistId &&
              trackFormData?.contractArtistId > 0 &&
              artists
                ? artists?.find(a => a.value === trackFormData.contractArtistId)
                    ?.label
                : t('adminShared.choose')}
            </SelectTrigger>
            <SelectContent className="max-h-[200px]">
              <SelectItem value="">
                {t('adminShared.emptySelection')}
              </SelectItem>
              {props.artists?.map((artist, index) => {
                if (!artist || !artist.value) return;
                return (
                  <SelectItem key={index} value={artist.value?.toString()}>
                    {artist.label}
                  </SelectItem>
                );
              })}
            </SelectContent>
          </Select>
        </FormContainer>
        {trackFormData.contractArtistId !== 0 && (
          <FormContainer
            label={t('trackForm.contract')}
            error={props.errors?.includes('contractId')}
          >
            <Select
              value={trackFormData.contractId.toString()}
              onValueChange={value =>
                handleFieldOnChange('contractId', Number(value))
              }
            >
              <SelectTrigger className="w-full">
                {trackFormData.contractId !== 0 && selectedArtistContract ? (
                  <>
                    {selectedArtistContract?.artist.name}{' '}
                    {new Date(
                      selectedArtistContract?.dateSigned,
                    ).toLocaleDateString('fi-FI')}{' '}
                    {selectedArtistContract?.type}
                  </>
                ) : (
                  'Valitse'
                )}
              </SelectTrigger>
              <SelectContent className="max-h-[200px]">
                {data?.contracts?.map((contract, index) => {
                  if (!contract) return null;
                  return (
                    <SelectItem key={index} value={contract?.id.toString()}>
                      <b>{contract?.artist.name}</b>{' '}
                      {new Date(contract?.dateSigned).toLocaleDateString(
                        'fi-FI',
                      )}{' '}
                      {contract?.type}
                    </SelectItem>
                  );
                })}
              </SelectContent>
            </Select>
          </FormContainer>
        )}
      </div>
      <div className="grid grid-cols-2 gap-x-4 gap-y-5">
        <h4 className="h4 col-span-2">{t('trackForm.metadata')}</h4>
        <FormContainer
          label={t('trackForm.trackTitle')}
          error={props.errors?.includes('name')}
        >
          <Input
            placeholder={t('trackForm.trackTitle')}
            autoComplete="off"
            value={trackFormData.name}
            onChange={e => handleFieldOnChange('name', e.target.value)}
          />
        </FormContainer>
        <FormContainer
          label={t('trackForm.mainArtists')}
          error={props.errors?.includes('trackArtists')}
        >
          <MultiSelect<ArtistList | unknown, true>
            components={{ DropdownIndicator }}
            classNames={multiSelectStyles}
            isDisabled={!trackFormData.contractId}
            value={trackFormData.trackArtists
              .filter(artist => artist.type === 'MAIN')
              .map(artist => {
                return {
                  value: artist.artistId,
                  label: artists?.find(a => a.value === artist.artistId)?.label,
                };
              })}
            placeholder={t('trackForm.chooseArtists')}
            onChange={value => {
              handleArtistFieldOnChange(
                'MAIN',
                value.map(v => {
                  return {
                    // @ts-expect-error ts-migrate(2339) FIXME: 'v' is of type 'unknown'
                    artistId: v.value,
                    type: 'MAIN',
                    royaltyRules: {
                      paymentAmount: 0,
                      recoupableAmount: 0,
                      recoupableType: 'NO_LIMIT',
                      royaltyPercentage:
                        selectedArtistContract?.royaltySharePercentage ?? 50,
                      royaltyType:
                        selectedArtistContract?.royaltyShareType ??
                        'ARTIST_SHARE',
                    },
                  };
                }),
              );
            }}
            isMulti={true}
            unstyled
            name="artistSelect"
            options={artists}
            hideSelectedOptions={false}
          />
        </FormContainer>
        <FormContainer label={t('trackForm.featuringArtists')}>
          <MultiSelect<ArtistList | unknown, true>
            components={{ DropdownIndicator }}
            classNames={multiSelectStyles}
            isDisabled={!trackFormData.contractId}
            value={trackFormData.trackArtists
              .filter(artist => artist.type === 'FEATURED')
              .map(artist => {
                return {
                  value: artist.artistId,
                  label: artists?.find(a => a.value === artist.artistId)?.label,
                };
              })}
            placeholder={t('trackForm.chooseArtists')}
            onChange={value => {
              handleArtistFieldOnChange(
                'FEATURED',
                value.map(v => {
                  return {
                    // @ts-expect-error ts-migrate(2339) FIXME: 'v' is of type 'unknown'
                    artistId: v.value,
                    type: 'FEATURED',
                    royaltyRules: {
                      paymentAmount: 0,
                      recoupableAmount: 0,
                      recoupableType: 'NO_LIMIT',
                      royaltyPercentage:
                        selectedArtistContract?.royaltySharePercentage ?? 50,
                      royaltyType:
                        selectedArtistContract?.royaltyShareType ??
                        'ARTIST_SHARE',
                    },
                  };
                }),
              );
            }}
            isMulti={true}
            unstyled
            name="artistSelect"
            options={artists}
            hideSelectedOptions={false}
          />
        </FormContainer>
        <FormContainer
          label="Collaborators"
          error={props.errors?.includes('trackCollaborators')}
        >
          <MultiSelect<ArtistList | unknown, true>
            components={{ DropdownIndicator }}
            classNames={multiSelectStyles}
            value={trackFormData.trackCollaborators.map(collaborator => {
              return {
                value: collaborator.collaboratorId,
                label: props.collaborators?.find(
                  a => a.value === collaborator.collaboratorId,
                )?.label,
              };
            })}
            placeholder={t('trackForm.chooseCollaborators')}
            onChange={value => {
              handleCollaboratorFieldOnChange(
                value.map(v => {
                  return {
                    // @ts-expect-error ts-migrate(2339) FIXME: 'v' is of type 'unknown'
                    collaboratorId: v.value,
                    // @ts-expect-error ts-migrate(2339) FIXME: 'v' is of type 'unknown'
                    role: v.role,
                    royaltyRules: {
                      paymentAmount: 0,
                      recoupableAmount: 0,
                      recoupableType: 'NO_LIMIT',
                      royaltyPercentage:
                        selectedArtistContract?.royaltySharePercentage ?? 50,
                      royaltyType:
                        selectedArtistContract?.royaltyShareType ??
                        'ARTIST_SHARE',
                    },
                  };
                }),
              );
            }}
            isMulti={true}
            unstyled
            name="collaboratorSelect"
            options={props.collaborators}
            hideSelectedOptions={false}
          />
        </FormContainer>
        <FormContainer label="ISRC" error={props.errors?.includes('isrc')}>
          <Input
            value={trackFormData.isrc}
            autoComplete="off"
            placeholder="ISRC"
            onChange={e => handleFieldOnChange('isrc', e.target.value)}
          />
        </FormContainer>
        <FormContainer
          label={t('trackForm.youtubeAssetReference')}
          error={props.errors?.includes('youtubeAssetReference')}
        >
          <Input
            placeholder={t('trackForm.youtubeAssetReference')}
            autoComplete="off"
            value={trackFormData.youtubeAssetReference ?? ''}
            onChange={e =>
              handleFieldOnChange('youtubeAssetReference', e.target.value)
            }
          />
        </FormContainer>
        <FormContainer
          label={t('trackForm.gramexLabelPercentage')}
          error={props.errors?.includes('gramexLabelPercentage')}
        >
          <NumberInput
            autoComplete="off"
            value={trackFormData.gramexLabelPercentage}
            onInputValueChange={e =>
              handleFieldOnChange('gramexLabelPercentage', e)
            }
            placeholder={t('trackForm.gramexLabelPercentage')}
          />
        </FormContainer>
        <FormContainer
          label={t('trackForm.volume')}
          error={props.errors?.includes('volume')}
        >
          <Select
            value={trackFormData.volume.toString()}
            onValueChange={e => handleFieldOnChange('volume', Number(e))}
          >
            <SelectTrigger>
              {trackFormData.volume > 0
                ? trackFormData.volume.toString()
                : t('adminShared.choose')}
            </SelectTrigger>

            <SelectContent>
              {Array.from({ length: 5 }, (_, i) => i + 1).map(item => (
                <SelectItem key={item} value={item.toString()}>
                  {item}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </FormContainer>
        <FormContainer
          label={t('trackForm.version')}
          error={props.errors?.includes('version')}
        >
          <Input
            autoComplete="off"
            value={trackFormData.version}
            onChange={e => handleFieldOnChange('version', e.target.value)}
            placeholder={t('trackForm.version')}
          />
        </FormContainer>
        <FormContainer
          label={t('trackForm.notes')}
          error={props.errors?.includes('notes')}
          className="col-span-2"
        >
          <Textarea
            autoComplete="off"
            value={trackFormData.notes}
            onChange={e => handleFieldOnChange('notes', e.target.value)}
            placeholder={t('trackForm.notes')}
            rows={5}
          />
        </FormContainer>
      </div>
      <div className={cn(trackFormData.trackArtists.length && 'space-y-12')}>
        <div>
          {trackFormData.trackArtists.filter(artist => artist.type === 'MAIN')
            .length > 0 && (
            <h4 className="h4">{t('trackForm.royaltySettingsArtists')}</h4>
          )}
          <RoyaltySplitForm
            artists={artists ?? []}
            selectedArtist={trackFormData.trackArtists
              .filter(a => a.type === 'MAIN')
              .map(a => {
                // NOTE: Fix types
                return {
                  royaltyRules: {
                    paymentAmount: a.royaltyRules?.paymentAmount ?? 0,
                    recoupableAmount: a.royaltyRules?.recoupableAmount ?? 0,
                    recoupableType: a.royaltyRules?.recoupableType ?? '',
                    royaltyPercentage: a.royaltyRules?.royaltyPercentage ?? 0,
                    royaltyType: a.royaltyRules?.royaltyType ?? '',
                  },
                  artistId: a.artistId,
                  type: a.type,
                };
              })}
            ref={mainArtistRoyaltySplit}
          />
        </div>

        <div>
          {trackFormData.trackArtists.filter(
            artist => artist.type === 'FEATURED',
          ).length > 0 && (
            <h4 className="h4">{t('trackForm.royaltySettingsFeat')}</h4>
          )}
          <RoyaltySplitForm
            artists={artists ?? []}
            selectedArtist={trackFormData.trackArtists
              .filter(a => a.type === 'FEATURED')
              .map(a => {
                return {
                  royaltyRules: {
                    paymentAmount: a.royaltyRules?.paymentAmount ?? 0,
                    recoupableAmount: a.royaltyRules?.recoupableAmount ?? 0,
                    recoupableType: a.royaltyRules?.recoupableType ?? '',
                    royaltyPercentage: a.royaltyRules?.royaltyPercentage ?? 0,
                    royaltyType: a.royaltyRules?.royaltyType ?? '',
                  },
                  artistId: a.artistId,
                  type: a.type,
                };
              })}
            ref={featArtistRoyaltySplit}
          />
        </div>
      </div>

      <div
        className={cn(trackFormData.trackCollaborators.length && 'space-y-12')}
      >
        <div>
          {trackFormData.trackCollaborators.length > 0 && (
            <h4 className="h4">{t('trackForm.royaltySettingsCollab')}</h4>
          )}
          <CollaboratorForm
            collaborators={props.collaborators ?? []}
            selectedArtist={trackFormData.trackCollaborators.map(a => {
              // NOTE: Fix types
              return {
                royaltyRules: {
                  paymentAmount: a.royaltyRules?.paymentAmount ?? 0,
                  recoupableAmount: a.royaltyRules?.recoupableAmount ?? 0,
                  recoupableType: a.royaltyRules?.recoupableType ?? '',
                  royaltyPercentage: a.royaltyRules?.royaltyPercentage ?? '50',
                  royaltyType: a.royaltyRules?.royaltyType ?? '',
                },
                collaboratorId: a.collaboratorId,
                role: a.role,
              };
            })}
            ref={collaboratorsRoyaltySplit}
          />
        </div>
      </div>
      <div className="space-y-6">
        <RoyaltyAnalysis
          data={royaltyData}
          onValidate={() => handleRoyaltyRulesValidation()}
          fetchError={error}
          fetchLoading={loading}
        />
      </div>
      <Button onClick={() => handleTrackCreate()}>
        {props.edit ? t('trackForm.saveChanges') : t('trackForm.createTrack')}
      </Button>
    </>
  );
}
