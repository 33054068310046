import { GetReleaseByIdQuery } from '@/gql/generated-types';
import { Release, ReleaseTrack } from '@/services/store/releases/types';

function alwaysString(s: string | undefined | null): string {
  if (s === null || typeof s === 'undefined') return '';
  return s;
}

// NOTE: Graphql gives me any..
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseTrack(tracks: any): ReleaseTrack[] {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return tracks.map((track: any) => {
    return {
      contractArtistId: Number(track.contract.artist.id),
      contractId: Number(track.contract.id),
      gramexLabelPercentage: Number(track.gramexLabelPercentage),
      id: Number(track.id),
      youtubeAssetReference: track.youtubeAssetReference ?? '',
      isrc: track.isrcs.length > 0 ? track.isrcs[0].isrc : [],
      name: track.name,
      order: Number(track.order),
      notes: track.notes ?? '',
      volume: Number(track.volume),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      trackCollaborators: track.trackCollaborators.map((c: any) => {
        const { collaborator, role } = c;
        const { id } = collaborator;
        const collaboratorRoyaltyRules = track.trackCollaboratorRoyalty.find(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (r: any) => r.collaborator.id === id,
        );
        return {
          collaboratorId: id,
          role,
          royaltyRules: {
            paymentAmount: collaboratorRoyaltyRules?.paymentAmount ?? 0,
            recoupableAmount: collaboratorRoyaltyRules?.recoupableAmount ?? 0,
            royaltyPercentage: collaboratorRoyaltyRules?.royaltyPercentage ?? 0,
            royaltyType: collaboratorRoyaltyRules?.royaltyType ?? '',
            recoupableType: collaboratorRoyaltyRules?.recoupableType ?? '',
          },
        };
      }),
      trackArtists:
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        track.trackArtists.map((artist: any) => {
          const artistRoyaltyRules = track.trackArtistRoyalty.find(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (r: any) => r.artistId === artist.artistId,
          );
          return {
            artistId: artist.artistId,
            type: artist.type,
            royaltyRules: {
              paymentAmount: artistRoyaltyRules?.paymentAmount ?? 0,
              recoupableAmount: artistRoyaltyRules?.recoupableAmount ?? 0,
              royaltyPercentage:
                artistRoyaltyRules?.royaltyPercentage.toString() ?? '0',
              royaltyType: artistRoyaltyRules?.royaltyType ?? '',
              recoupableType: artistRoyaltyRules?.recoupableType ?? '',
            },
          };
        }) ?? [],
    };
  });
}

export function parseReleaseToState(data: GetReleaseByIdQuery) {
  const rest = data.release;

  const releaseId = rest?.id ? { id: rest.id } : null;

  const parsedReleaseObj: Release = {
    ...releaseId,
    upcNumber: rest?.upc?.upcNumber,
    productType: alwaysString(rest?.productType),
    releaseType: alwaysString(rest?.releaseType),
    name: alwaysString(rest?.name),
    catalogNumber: alwaysString(rest?.catalogNumber),
    releaseDate: new Date(rest?.releaseDate),
    version: alwaysString(rest?.version),
    coverArt: alwaysString(rest?.coverArt),
    notes: rest?.notes ?? '',
    releaseArtists: rest?.releaseArtists?.map(artist => artist.id) ?? [],
    tracks: parseTrack(rest?.tracks),
  };

  return parsedReleaseObj;
}

export function mapByVolume(tracks: ReleaseTrack[]) {
  return tracks.reduce(
    (result, obj) => {
      // Use the volume property as the key
      const volume = obj.volume;

      // If the list for the current volume doesn't exist, create it
      if (!result[volume!]) {
        result[volume!] = [];
      }

      // Push the current object to the list for the current volume
      result[volume!].push(obj);

      return result;
    },
    {} as Record<string, ReleaseTrack[]>,
  );
}

// NOTE: Graphql gives me any..
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseSingularTrack(track: any): ReleaseTrack {
  return {
    releaseId: Number(track.release.id),
    contractArtistId: Number(track.contract.artist.id),
    contractId: Number(track.contract.id),
    gramexLabelPercentage: Number(track.gramexLabelPercentage),
    youtubeAssetReference: track.youtubeAssetReference ?? '',
    id: Number(track.id),
    isrc: track.isrcs.length > 0 ? track.isrcs[0].isrc : [],
    name: track.name,
    order: Number(track.order),
    volume: Number(track.volume),
    version: track.version ?? '',
    notes: track.notes ?? '',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    trackCollaborators: track.trackCollaborators.map((c: any) => {
      const { collaborator, role } = c;
      const { id } = collaborator;
      const collaboratorRoyaltyRules = track.trackCollaboratorRoyalty.find(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (r: any) => r.collaborator.id === id,
      );
      return {
        collaboratorId: id,
        role,
        royaltyRules: {
          paymentAmount: collaboratorRoyaltyRules?.paymentAmount ?? 0,
          recoupableAmount: collaboratorRoyaltyRules?.recoupableAmount ?? 0,
          royaltyPercentage:
            collaboratorRoyaltyRules?.royaltyPercentage.toString() ?? '0',
          royaltyType: collaboratorRoyaltyRules?.royaltyType ?? '',
          recoupableType: collaboratorRoyaltyRules?.recoupableType ?? '',
        },
      };
    }),
    trackArtists:
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      track.trackArtists.map((artist: any) => {
        const artistRoyaltyRules = track.trackArtistRoyalty.find(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (r: any) => r.artistId === artist.artistId,
        );
        return {
          artistId: artist.artistId,
          type: artist.type,
          royaltyRules: {
            paymentAmount: artistRoyaltyRules?.paymentAmount ?? 0,
            recoupableAmount: artistRoyaltyRules?.recoupableAmount ?? 0,
            royaltyPercentage:
              artistRoyaltyRules?.royaltyPercentage.toString() ?? '0',
            royaltyType: artistRoyaltyRules?.royaltyType ?? '',
            recoupableType: artistRoyaltyRules?.recoupableType ?? '',
          },
        };
      }) ?? [],
  };
}
