const ADMIN = '/admin' as const;
const ADMIN_ARTIST = `${ADMIN}/artists` as const;
const ADMIN_DATA_MANAGEMENT = `${ADMIN}/data-management` as const;
const ADMIN_RELEASES = `${ADMIN_DATA_MANAGEMENT}/releases` as const;
const ADMIN_RELEASE = `${ADMIN_RELEASES}/$releaseId` as const;
const ADMIN_CONTRACTS = `${ADMIN_DATA_MANAGEMENT}/contracts` as const;
const ADMIN_CONTRACT =
  `${ADMIN_DATA_MANAGEMENT}/contracts/$contractId` as const;
const ADMIN_CREATE_CONTRACT = `${ADMIN_CONTRACTS}/create` as const;
const ADMIN_CREATE_USER = `${ADMIN_DATA_MANAGEMENT}/create-user` as const;
const ADMIN_CREATE_ARTIST = `${ADMIN_DATA_MANAGEMENT}/create-artist` as const;
const ADMIN_EDIT_ARTIST =
  `${ADMIN_DATA_MANAGEMENT}/edit-artist/$artistId` as const;
const ADMIN_LOG_EXPENSE = `${ADMIN_DATA_MANAGEMENT}/log-expense` as const;
const ADMIN_CREATE_COLLABORATOR =
  `${ADMIN_DATA_MANAGEMENT}/create-collaborator` as const;
const ADMIN_EDIT_CONTRACT =
  `${ADMIN_DATA_MANAGEMENT}/contracts/edit/$contractId` as const;
const ADMIN_CREATE_RELEASE = `${ADMIN_RELEASES}/create` as const;
const ADMIN_CREATE_TRACK = `${ADMIN_CREATE_RELEASE}/track` as const;
const ADMIN_CREATE_TRACK_EDIT =
  `${ADMIN_CREATE_RELEASE}/track/$trackId` as const;
const ADMIN_EDIT_RELEASE = `${ADMIN_RELEASES}/edit/$releaseId` as const;
const ADMIN_EDIT_TRACK = `${ADMIN_RELEASES}/edit/track/$trackId` as const;
const ADMIN_UPLOAD_ROYALTY_STATEMENT =
  `${ADMIN_DATA_MANAGEMENT}/upload/royalty-statement` as const;
const ADMIN_TRACK = `${ADMIN_RELEASE}/$trackId` as const;
const ADMIN_PAYMENTS = `${ADMIN_DATA_MANAGEMENT}/payments` as const;
const ADMIN_CREATE_PAYMENT =
  `${ADMIN_DATA_MANAGEMENT}/payments/create` as const;
const ADMIN_COLLABORATORS = `${ADMIN_DATA_MANAGEMENT}/collaborators` as const;
const ADMIN_USERS = `${ADMIN_DATA_MANAGEMENT}/users` as const;

// NON MATCHED ROYALTIES
const ADMIN_NON_MATCHED = `${ADMIN_DATA_MANAGEMENT}/non-matched` as const;

const ARTIST = '/artist' as const;
const ARTIST_BASE = `${ARTIST}/$artistId` as const;
const ARTIST_BALANCE = `${ARTIST_BASE}/balance` as const;
const ARTIST_STREAMS = `${ARTIST_BASE}/streams` as const;
const ARTIST_STREAMS_SONG = `${ARTIST_STREAMS}/$trackId` as const;

export {
  ADMIN,
  ADMIN_ARTIST,
  ADMIN_CREATE_TRACK_EDIT,
  ADMIN_CONTRACTS,
  ADMIN_COLLABORATORS,
  ADMIN_CONTRACT,
  ADMIN_USERS,
  ADMIN_EDIT_ARTIST,
  ADMIN_CREATE_COLLABORATOR,
  ADMIN_PAYMENTS,
  ADMIN_NON_MATCHED,
  ADMIN_TRACK,
  ADMIN_CREATE_PAYMENT,
  ADMIN_EDIT_CONTRACT,
  ADMIN_UPLOAD_ROYALTY_STATEMENT,
  ADMIN_CREATE_ARTIST,
  ADMIN_CREATE_CONTRACT,
  ADMIN_EDIT_TRACK,
  ADMIN_CREATE_TRACK,
  ADMIN_CREATE_USER,
  ADMIN_DATA_MANAGEMENT,
  ADMIN_LOG_EXPENSE,
  ADMIN_RELEASE,
  ADMIN_RELEASES,
  ADMIN_EDIT_RELEASE,
  ADMIN_CREATE_RELEASE,
  ARTIST,
  ARTIST_BALANCE,
  ARTIST_BASE,
  ARTIST_STREAMS,
  ARTIST_STREAMS_SONG,
};
