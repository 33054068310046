import { buttonVariants } from '@/components/ui/button';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import { cn } from '@/lib/utils';
import { Calendar } from 'lucide-react';
import { PropsWithChildren } from 'react';

export type DateDropdownProps = PropsWithChildren & {
  startMonth: string;
  endMonth: string;
  endDay: number;
  open: boolean;
  onOpenChange: (val: boolean) => void;
};

export const DateDropdown = (props: DateDropdownProps) => {
  return (
    <div className="col-span-3 lg:col-span-1 text-left lg:text-right transition-all">
      <Popover open={props.open} onOpenChange={props.onOpenChange}>
        <PopoverTrigger
          className={cn(buttonVariants({ variant: 'default' }), 'text-xs')}
        >
          <Calendar className="mr-2 h-6 w-6" />
          {1}.{props.startMonth}
          {' - '}
          {props.endDay}.{props.endMonth}
        </PopoverTrigger>
        <PopoverContent className="w-80">{props.children}</PopoverContent>
      </Popover>
    </div>
  );
};
