import { ViewLoader } from '@/components/loaders/view-loader';
import { Button } from '@/components/ui/button';
import { Checkbox } from '@/components/ui/checkbox';
import { DayPicker } from '@/components/ui/day-picker';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { NumberInput } from '@/components/ui/number-input';
import { GetReleaseByIdQuery } from '@/gql/generated-types';
import { cn } from '@/lib/utils';
import { zodResolver } from '@hookform/resolvers/zod';
import { useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as z from 'zod';

export const promoFormSchema = z
  .object({
    upcNumber: z.number().min(1),
    catalogNumber: z.string().min(1),
    releaseDate: z.string(),
    tracks: z.array(
      z.object({
        id: z.number(),
        isrc: z.string(),
        name: z.string(),
        checked: z.boolean(),
      }),
    ),
  })
  .superRefine((input, context) => {
    input.tracks.map((track, index) => {
      if (track.checked) {
        if (track.isrc.length === 0) {
          context.addIssue({
            code: z.ZodIssueCode.custom,
            path: [index],
          });
        }
      }
    });
  });

type FormValues = z.infer<typeof promoFormSchema>;

export interface PromoFormReturnValues {
  upcNumber: number;
  catalogNumber: string;
  releaseId: number;
  releaseDate: string;
  tracks: { id: number; isrc: string }[];
}

interface PromoFormProps {
  loading?: boolean;
  values: GetReleaseByIdQuery | undefined;
  originalReleaseId: number;
  onCancel: () => void;
  onSubmit: (values: PromoFormReturnValues) => void;
}

export function PromoForm(props: PromoFormProps) {
  const { t } = useTranslation();
  const form = useForm<z.infer<typeof promoFormSchema>>({
    resolver: zodResolver(promoFormSchema),
    values: {
      upcNumber: 0,
      catalogNumber: '',
      releaseDate: props.values?.release?.releaseDate,
      tracks:
        props.values?.release?.tracks.map(track => {
          return {
            checked: true,
            name: track.name,
            id: track.id,
            isrc: '',
          };
        }) ?? [],
    },
  });

  const { fields, update } = useFieldArray({
    name: 'tracks',
    control: form.control,
  });

  if (props.loading) {
    return (
      <>
        <ViewLoader />
      </>
    );
  }

  const { errors } = form.formState;

  function handleCreateDuplicate(values: FormValues): void {
    const returnValues = {
      upcNumber: values.upcNumber,
      catalogNumber: values.catalogNumber,
      releaseId: props.originalReleaseId,
      releaseDate: values.releaseDate,
      tracks: values.tracks
        .filter(track => track.checked)
        .map(track => {
          return {
            id: track.id,
            isrc: track.isrc,
          };
        }),
    };

    props.onSubmit(returnValues);
  }

  return (
    <Form {...form}>
      <form
        className="space-y-6"
        onSubmit={form.handleSubmit(handleCreateDuplicate)}
      >
        <FormField
          control={form.control}
          name="upcNumber"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t('promoForm.upcNumber')}</FormLabel>
              <FormControl>
                <NumberInput
                  value={field.value}
                  onInputValueChange={value => field.onChange(value)}
                />
              </FormControl>
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="catalogNumber"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t('promoForm.cat')}</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="releaseDate"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t('promoForm.date')}</FormLabel>
              <FormControl>
                <DayPicker
                  onDateChange={value => field.onChange(value.toISOString())}
                  selectedDate={new Date(
                    field.value ?? new Date(),
                  ).toISOString()}
                />
              </FormControl>
            </FormItem>
          )}
        />

        <div>
          <h4 className="text-lg font-medium mb-6">{t('promoForm.tracks')}</h4>
          {fields.map((track, index) => (
            <div
              key={index}
              className={cn(
                'bg-grey-darkest px-4 py-2',
                index % 2 !== 0 && 'bg-grey-dark',
                // FIXME: Figure out how to avoid TS ERROR here
                // @ts-expect-error: Custom error set by superRefine will cause typescrit error
                errors && errors[index] && 'border-red-500 border-1 border',
              )}
            >
              <FormField
                key={index}
                control={form.control}
                name="tracks"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <div className="flex items-center justify-between space-x-2">
                        <Checkbox
                          className="h-4 w-4"
                          checked={field.value[index].checked}
                          onCheckedChange={() => {
                            update(index, {
                              ...field.value[index],
                              checked: !field.value[index].checked,
                            });
                          }}
                        />
                        <p className="basis-2/5">{track.name}</p>
                        <Input
                          className="basis-2/5"
                          placeholder="ISRC"
                          value={field.value[index].isrc}
                          onChange={e => {
                            update(index, {
                              ...field.value[index],
                              isrc: e.target.value,
                            });
                          }}
                        />
                      </div>
                    </FormControl>
                  </FormItem>
                )}
              />
            </div>
          ))}
        </div>
        <div className="flex justify-between space-x-2">
          <Button
            type="button"
            size="sm"
            variant="outline"
            onClick={() => props.onCancel()}
          >
            {t('promoForm.cancel')}
          </Button>
          <Button type="submit" size="sm">
            {t('promoForm.saveRelease')}
          </Button>
        </div>
      </form>
    </Form>
  );
}
