import { ViewError } from '@/components/errors/view-error';
import { CreateArtistForm } from '@/views/admin/data-management/users/forms/artist';
import { ViewLoader } from '@/components/loaders/view-loader';
import { AdminHeader } from '@/components/partials/admin-header';
import { useGetCompassPersonsQuery } from '@/gql/generated-types';
import { ADMIN_ARTIST } from '@/services/router/routes';
import { useMutation } from '@apollo/client';
import { CREATE_ARTIST } from '@/graphql/mutations';
import { toast } from '@/components/ui/use-toast';
import { useTranslation } from 'react-i18next';
import * as z from 'zod';
import { createArtistSchema } from './forms/schema';
import { useNavigate } from '@tanstack/react-router';
export default function CreateArtist() {
  const { loading, error, data } = useGetCompassPersonsQuery();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [createUser] = useMutation(CREATE_ARTIST, {
    onError: _ => {
      toast({
        variant: 'destructive',
        title: t('toast.genericErrorTitle'),
        description: t('toast.createErrorDescription', {
          item: t('toast.items.artistProfile'),
        }),
      });
    },
    onCompleted: data => {
      toast({
        title: t('toast.genericSaveSuccessTitle'),
        description: t('toast.genericSaveSuccessDescription', {
          item: t('toast.items.artistProfile'),
        }),
      });
      navigate({ to: ADMIN_ARTIST, search: { name: data.upsertArtist.name } });
    },
  });

  function handleSubmit(values: z.infer<typeof createArtistSchema>) {
    createUser({
      variables: {
        input: values,
      },
    });
  }

  if (error) {
    return <ViewError />;
  }

  return (
    <>
      <AdminHeader
        title={t('adminDM.createArtist.title')}
        goBackLink={{
          link: ADMIN_ARTIST,
          label: t('adminShared.back'),
        }}
      />

      {loading && <ViewLoader />}
      {!loading && data && (
        <CreateArtistForm data={data} onSubmit={handleSubmit} />
      )}
    </>
  );
}
