import { Button } from '@/components/ui/button';
import {
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  Table,
} from '@/components/ui/table';
import { GetPaymentsQuery } from '@/gql/generated-types';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

interface PaymentsTableProps {
  data: GetPaymentsQuery | undefined;
  onDelete: (id: number) => void;
  onInspect: (id: number) => void;
}

export const PaymentsTable = (props: PaymentsTableProps) => {
  const { t } = useTranslation();
  return (
    <>
      <Table className="border-collapse">
        <TableHeader>
          <TableRow className="text-left">
            <TableHead>{t('adminPayments.artist')}</TableHead>
            <TableHead>{t('adminPayments.date')}</TableHead>
            <TableHead>{t('adminPayments.sum')}</TableHead>
            <TableHead></TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {props.data?.artistPayments?.map((payment, index) => {
            if (!payment) return null;

            return (
              <TableRow variant={index % 2 === 0 ? 'odd' : null} key={index}>
                <TableCell>{payment?.artist.name}</TableCell>
                <TableCell>
                  {format(new Date(payment?.date), 'dd.MM.yyyy')}
                </TableCell>
                <TableCell>
                  {new Intl.NumberFormat('fi-FI', {
                    style: 'currency',
                    currency: 'EUR',
                  }).format(payment?.amount)}
                </TableCell>
                <TableCell className="text-right flex space-x-2 justify-end">
                  <Button
                    size="sm"
                    variant="outline"
                    onClick={() => props.onInspect(payment?.id)}
                  >
                    {t('adminPayments.view')}
                  </Button>
                  <Button
                    variant="destructive"
                    size="sm"
                    onClick={() => props.onDelete(payment?.id)}
                  >
                    {t('adminPayments.delete')}
                  </Button>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      {(props.data?.artistPayments?.length === 0 ||
        typeof props.data === 'undefined') && (
        <p className="text-center mx-4 w-full">
          {t('adminPayments.noResults')}
        </p>
      )}
    </>
  );
};
