import { Button } from '@/components/ui/button';
import { DayPicker } from '@/components/ui/day-picker';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { GetArtistListQuery } from '@/gql/generated-types';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import { createExpenseSchema, defaultValues } from './schema';

type CreateUserFormProps = {
  artists?: GetArtistListQuery;
  handleOnCreateExpense: (data: z.infer<typeof createExpenseSchema>) => void;
};

export function CreateExpenseForm({
  artists,
  handleOnCreateExpense,
}: CreateUserFormProps) {
  // NOTE: Disabled any type validation here, because React-Select doesn't export the correct types

  const { t } = useTranslation();
  const form = useForm<z.infer<typeof createExpenseSchema>>({
    resolver: zodResolver(createExpenseSchema),
    defaultValues,
  });

  const handleCreateExpense = async (
    data: z.infer<typeof createExpenseSchema>,
  ) => {
    form.reset();
    handleOnCreateExpense(data);
  };

  return (
    <div>
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(handleCreateExpense)}
          className="space-y-12"
        >
          <div className="grid-cols-1 lg:grid-cols-2 grid gap-x-12 gap-y-4">
            <div className="lg:col-span-2">
              <h4 className="h4">{t('expenseForm.basicInfo')}</h4>
            </div>
            <FormField
              control={form.control}
              name="artistId"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t('expenseForm.artist')}</FormLabel>
                  <Select
                    value={field.value}
                    onValueChange={e => {
                      field.onChange(e);
                    }}
                  >
                    <FormControl>
                      <SelectTrigger className="w-full">
                        {field.value === ''
                          ? t('expenseForm.selectArtist')
                          : artists?.listArtists?.find(
                              i => i?.id.toString() === field.value,
                            )?.name}
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent className="max-h-[200px]">
                      <SelectItem value="">
                        {t('adminShared.noSelection')}
                      </SelectItem>
                      {artists?.listArtists?.map((artist, index) => {
                        if (artist === null) return;
                        return (
                          <SelectItem key={index} value={artist.id.toString()}>
                            {artist.name}
                          </SelectItem>
                        );
                      })}
                    </SelectContent>
                  </Select>
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="amount"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t('expenseForm.sum')}</FormLabel>
                  <FormControl>
                    <Input
                      value={field.value}
                      autoComplete="off"
                      onChange={e => {
                        const pattern: RegExp = /[^0-9.,]/g;
                        const inputValue = e.target.value;
                        const parsedInputValue = inputValue.replace(
                          pattern,
                          '',
                        );
                        field.onChange(parsedInputValue);
                      }}
                    />
                  </FormControl>
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="description"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t('expenseForm.desc')}</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="category"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t('expenseForm.expenseType')}</FormLabel>
                  <Select
                    value={field.value}
                    onValueChange={e => {
                      field.onChange(e);
                    }}
                  >
                    <FormControl>
                      <SelectTrigger className="w-full">
                        <SelectValue placeholder="Valitse kategoria" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      <SelectItem value="VIDEO_PRODUCTION">
                        {t('expenseForm.VIDEO_PRODUCTION')}
                      </SelectItem>
                      <SelectItem value="MUSIC_PRODUCTION">
                        {t('expenseForm.MUSIC_PRODUCTION')}
                      </SelectItem>
                      <SelectItem value="MARKETING">
                        {t('expenseForm.MARKETING')}
                      </SelectItem>
                      <SelectItem value="OTHER">
                        {t('expenseForm.OTHER')}
                      </SelectItem>
                    </SelectContent>
                  </Select>
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="date"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t('expenseForm.expenseDate')}</FormLabel>
                  <FormControl>
                    <DayPicker
                      onDateChange={date => {
                        field.onChange(date);
                      }}
                      selectedDate={field.value}
                    />
                  </FormControl>
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="poNumber"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t('expenseForm.poNumber')}</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                </FormItem>
              )}
            />
          </div>

          <Button>{t('expenseForm.save')}</Button>
        </form>
      </Form>
    </div>
  );
}
