import AdminLayout from '@/admin-layout';
import { isUserAdmin } from '@/services/authentication/userRole';
import {
  initialReleaseData,
  releaseAtom,
} from '@/services/store/releases/releases';
import { useSession } from '@clerk/clerk-react';
import { ActiveSessionResource } from '@clerk/types';
import { useNavigate, useRouterState } from '@tanstack/react-router';
import { useAtom } from 'jotai';
import { PropsWithChildren } from 'react';

export function AdminProtection(props: PropsWithChildren) {
  const { session } = useSession();
  const navigate = useNavigate();
  const router = useRouterState();
  const isAdmin: boolean = isUserAdmin(session as ActiveSessionResource);

  if (!isAdmin && router.location.pathname.includes('admin')) {
    navigate({ to: '/' });
  }

  const [_, setRelease] = useAtom(releaseAtom);

  const isArtistMode =
    router.location.pathname.includes('/artist/') &&
    !router.location.pathname.includes('/admin/');

  // On admin set default release values if item is not present in storage
  if (!localStorage.getItem('release')) {
    setRelease(initialReleaseData);
  }

  if (!isAdmin) {
    return <div className="mb-10">{props.children}</div>;
  }

  return <AdminLayout artistMode={isArtistMode}>{props.children}</AdminLayout>;
}
