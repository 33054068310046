import { Input } from '@/components/ui/input';
import { Release, ReleaseTrack } from '@/services/store/releases/types';
import { FormContainer } from './container';
import { useTranslation } from 'react-i18next';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { DayPicker } from '@/components/ui/day-picker';
import { default as MultiSelect } from 'react-select';
import {
  DropdownIndicator,
  multiSelectStyles,
} from '@/styles/multi-select.styles';
import { useRef } from 'react';
import { mapByVolume } from '../util/formatting';
import { TrackContainer } from '../components/dnd/container';
import { Link } from '@tanstack/react-router';
import { Button, buttonVariants } from '@/components/ui/button';
import { Plus, Save, X } from 'lucide-react';
import { ADMIN_RELEASES } from '@/services/router/routes';
import { NumberInput } from '@/components/ui/number-input';
import { Textarea } from '@/components/ui/textarea';

export type ReleaseFormProps = {
  edit?: boolean;
  artists: ArtistList;
  values: Release;
  resetFunction?: () => void;
  tracks: ReleaseTrack[];
  onSubmit: () => void;
  disableDnd?: boolean;
  onFieldChange: (
    target: string,
    value: string | number | string[] | number[],
  ) => void;
  errors: string[] | null;
};

export function ReleaseForm(props: ReleaseFormProps) {
  const { t } = useTranslation();
  const { values } = props;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const artistSelect = useRef<any>(null);

  return (
    <>
      <div className="grid grid-cols-2 gap-10">
        <h4 className="h4 mb-0 col-span-2">{t('releaseForm.basicInfo')}</h4>
        <FormContainer
          label={t('releaseForm.releaseName')}
          error={props.errors?.includes('name')}
        >
          <Input
            autoComplete="off"
            placeholder={t('releaseForm.releaseName')}
            value={values?.name}
            onChange={e => props.onFieldChange('name', e.target.value)}
          />
        </FormContainer>
        <FormContainer
          label={t('releaseForm.artistsPossiblePlural')}
          error={props.errors?.includes('releaseArtists')}
        >
          <MultiSelect<ArtistList | unknown, true>
            ref={artistSelect}
            components={{ DropdownIndicator }}
            placeholder={t('releaseForm.chooseArtists')}
            classNames={multiSelectStyles}
            value={props.artists?.filter(artist =>
              values?.releaseArtists.includes(artist.value as never),
            )}
            onChange={value => {
              props.onFieldChange(
                'releaseArtists',
                // @ts-expect-error ts-migrate(2339) FIXME: 'artist' is of type 'unknown'
                value.map(artist => artist.value),
              );
            }}
            isMulti={true}
            unstyled
            name="artistSelect"
            options={props.artists}
            hideSelectedOptions={false}
          />
        </FormContainer>
      </div>
      <div className="grid grid-cols-2 gap-10">
        <h4 className="h4 mb-0 col-span-2">{t('releaseForm.metadata')}</h4>
        <FormContainer
          label={t('releaseForm.format')}
          error={props.errors?.includes('releaseType')}
        >
          <Select
            value={values?.releaseType}
            onValueChange={e => {
              props.onFieldChange('releaseType', e);
            }}
          >
            <SelectTrigger className="w-full">
              {values?.releaseType ? (
                <SelectValue placeholder={t('releaseForm.selectFormat')} />
              ) : (
                t('releaseForm.selectFormat')
              )}
            </SelectTrigger>

            <SelectContent>
              <SelectItem value="SINGLE">Single</SelectItem>
              <SelectItem value="EP">EP</SelectItem>
              <SelectItem value="ALBUM">Albumi</SelectItem>
            </SelectContent>
          </Select>
        </FormContainer>
        <FormContainer
          label={t('releaseForm.productType')}
          error={props.errors?.includes('productType')}
        >
          <Select
            value={values?.productType}
            onValueChange={e => {
              props.onFieldChange('productType', e);
            }}
          >
            <SelectTrigger className="w-full">
              {values?.productType ? (
                <SelectValue placeholder={t('releaseForm.selectProductType')} />
              ) : (
                t('releaseForm.selectProductType')
              )}
            </SelectTrigger>

            <SelectContent>
              <SelectItem value="DIGITAL">Digitaalinen</SelectItem>
              <SelectItem value="PHYSICAL_CD">CD</SelectItem>
              <SelectItem value="PHYSICAL_VINYL">Vinyyli</SelectItem>
            </SelectContent>
          </Select>
        </FormContainer>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-4 gap-10">
        <h4 className="h4 mb-0 col-span-1 lg:col-span-4">
          {t('releaseForm.identifiers')}
        </h4>
        <FormContainer
          label={t('releaseForm.cat')}
          className="col-span-4 lg:col-span-1"
          error={props.errors?.includes('catalogNumber')}
        >
          <Input
            autoComplete="off"
            placeholder={t('releaseForm.cat')}
            onChange={e => props.onFieldChange('catalogNumber', e.target.value)}
            value={values?.catalogNumber}
          />
        </FormContainer>
        <FormContainer
          label={t('releaseForm.upcNumber')}
          className="col-span-4 lg:col-span-1"
          error={props.errors?.includes('upcNumber')}
        >
          <NumberInput
            autoComplete="off"
            placeholder={t('releaseForm.upcNumber')}
            onInputValueChange={e => props.onFieldChange('upcNumber', e)}
            value={values?.upcNumber}
          />
        </FormContainer>
        <FormContainer
          label={t('releaseForm.releaseDateFormat')}
          className="col-span-4 lg:col-span-1"
          error={props.errors?.includes('releaseDate')}
        >
          <DayPicker
            onDateChange={date => {
              props.onFieldChange('releaseDate', new Date(date).toISOString());
            }}
            selectedDate={new Date(values?.releaseDate)}
          />
        </FormContainer>
        <FormContainer
          label={t('releaseForm.version')}
          className="col-span-4 lg:col-span-1"
          error={props.errors?.includes('version')}
        >
          <Input
            autoComplete="off"
            placeholder={t('releaseForm.version')}
            onChange={e => props.onFieldChange('version', e.target.value)}
            value={values?.version}
          />
        </FormContainer>
      </div>
      <div className="space-y-12 w-full">
        <FormContainer
          label={t('releaseForm.notes')}
          error={props.errors?.includes('notes')}
        >
          <Textarea
            value={values?.notes}
            placeholder={t('releaseForm.notes')}
            onChange={e => props.onFieldChange('notes', e.target.value)}
          ></Textarea>
        </FormContainer>
      </div>
      {props.tracks && props.tracks.length > 0 && (
        <div className="space-y-12">
          <div>
            <h4 className="h4 mb-0">{t('releaseForm.tracks')}</h4>
          </div>
          <div className="space-y-12 w-full">
            {Object.entries(mapByVolume(props.tracks)).map(
              ([volume, list], index) => {
                return (
                  <div className="space-y-6" key={index}>
                    <h4 className="h4 mb-0">Vol {volume}</h4>
                    <TrackContainer
                      edit={props.edit}
                      disableDnd={props.disableDnd}
                      tracks={list.sort(
                        (a, b) => Number(a.order) - Number(b.order),
                      )}
                      id={volume}
                      listOfArtist={props.artists}
                    />
                  </div>
                );
              },
            )}
          </div>
        </div>
      )}
      {!props.edit && (
        <div className="text-center">
          <Link
            to="/admin/data-management/releases/create/track"
            className={buttonVariants({ variant: 'outline', size: 'sm' })}
          >
            <Plus className="mr-2 w-4 h-4" />
            {t('releaseForm.addTrack')}
          </Link>
        </div>
      )}
      <div className="space-x-2 flex justify-between">
        <Button onClick={() => props.onSubmit()} disabled={false}>
          <Save className="w-4 h-4 mr-2" />
          {t('releaseForm.saveRelease')}
        </Button>

        <Link
          to={ADMIN_RELEASES}
          onClick={() => props.resetFunction!()}
          className={buttonVariants({
            variant: 'destructive',
          })}
        >
          <X className="w-4 h-4 mr-2" />
          {t('releaseForm.cancel')}
        </Link>
      </div>
    </>
  );
}
