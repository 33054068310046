import { atom } from 'jotai';
import { Release } from './types';

export const initialReleaseData: Release = {
  name: '',
  releaseArtists: [],
  coverArt: '',
  tracks: [],
  catalogNumber: '',
  productType: '',
  upcNumber: 0,
  version: '',
  releaseType: '',
  releaseDate: new Date(),
};

const atomWithLocalStorage = (key: string, initialValue: Release) => {
  const getInitialValue = () => {
    const item = localStorage.getItem(key);
    if (item !== null) {
      return JSON.parse(item);
    }
    return initialValue;
  };
  const baseAtom = atom(getInitialValue());
  const derivedAtom = atom(
    get => get(baseAtom),
    (get, set, update) => {
      const nextValue =
        typeof update === 'function' ? update(get(baseAtom)) : update;
      set(baseAtom, nextValue);
      localStorage.setItem(key, JSON.stringify(nextValue));
    },
  );
  return derivedAtom;
};

export const releaseAtom = atomWithLocalStorage('release', initialReleaseData);
