import { useTranslation } from 'react-i18next';
import { currentDate, pastDate } from '../util';
import { Suspense, useState } from 'react';
import { StreamCountGroupBy } from '@/gql/generated-types';
import { TrackListTable } from './components/track-table';
import { CalendarDropDown } from '../../../components/ui/calendar-dd';
import { format } from 'date-fns';
import { dateFrom, previousRangeLength } from './utils';
import { useParams } from '@tanstack/react-router';
import { StickyHeader } from '@/components/ui/sticky-header';
import { OverAllStreams } from './components/overall-streams';
import { Song } from '../shared/song';
import { Skeleton } from '@/components/ui/skeleton';
import { StreamChart } from '../shared/stream-chart';
import { CategoryChart } from './components/category-chart';
import { PlayLengthChart } from './components/play-length-chart';
import ErrorBoundary from '@/components/partials/error';

export default function ArtistStreams() {
  const { t } = useTranslation();
  const { artistId } = useParams({ strict: false });

  const [dateRange, setDateRange] = useState<{
    startDate: Date;
    endDate: Date;
  }>({
    startDate: pastDate,
    endDate: currentDate,
  });

  // TODO: Fix this whole page, must have suspense for usesuspensequery
  return (
    <ErrorBoundary>
      <div className="container space-y-20">
        <StickyHeader header={t('artistPages.streams.royaltyStreams')}>
          <CalendarDropDown
            onDateChange={value =>
              setDateRange({
                startDate: value.from!,
                endDate: value.to!,
              })
            }
            start={dateRange.startDate}
            end={dateRange.endDate}
          />
        </StickyHeader>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
          <div className="lg:flex items-end lg:col-span-2 space-y-6 lg:space-y-0 lg:space-x-5 order-2 md:order-3">
            <Suspense fallback={null}>
              <OverAllStreams
                artistId={Number(artistId)}
                endDate={dateRange.endDate.toISOString()}
                startDate={dateRange.startDate.toISOString()}
              />
            </Suspense>
            <p>{t('artistPages.streams.royaltyStreamsExplanation')}</p>
          </div>
        </div>
        <div className="grid lg:grid-cols-3 gap-12">
          <div className="lg:col-span-1">
            <h4 className="h4">{t('artistPages.streams.mostStreamedSong')}</h4>
            <Suspense fallback={<Skeleton className="w-full h-[400px]" />}>
              <Song
                artistId={Number(artistId)}
                endDate={dateRange.endDate.toISOString()}
                startDate={dateRange.startDate.toISOString()}
              />
            </Suspense>
          </div>
          <div className="lg:col-span-2">
            <div className="flex justify-end text-xs items-center space-x-2">
              <div>
                <span className="mr-2 inline-flex w-2 h-2  bg-[#D77EF5]"></span>
                {format(dateRange.startDate, 'dd.L')}
                {' - '}
                {format(dateRange.endDate, 'dd.L')}
              </div>
              <div>
                <span className="mr-2 inline-flex w-2 h-2 bg-[#FFCA10]"></span>
                {format(
                  dateFrom(
                    dateRange.startDate,
                    previousRangeLength(dateRange.startDate, dateRange.endDate),
                  ),
                  'dd.L',
                )}
                {' - '}
                {format(
                  dateFrom(
                    dateRange.endDate,
                    previousRangeLength(dateRange.startDate, dateRange.endDate),
                  ),
                  'dd.L',
                )}
              </div>
            </div>
            <Suspense fallback={<Skeleton className="w-full h-[423px]" />}>
              <StreamChart
                artistId={Number(artistId)}
                aspect={2}
                startDate={dateRange.startDate.toISOString()}
                endDate={dateRange.endDate.toISOString()}
                prevEndDate={new Date(
                  dateFrom(
                    dateRange.endDate,
                    previousRangeLength(dateRange.startDate, dateRange.endDate),
                  ),
                ).toISOString()}
                prevStartDate={new Date(
                  dateFrom(
                    dateRange.startDate,
                    previousRangeLength(dateRange.startDate, dateRange.endDate),
                  ),
                ).toISOString()}
              />
            </Suspense>
          </div>
        </div>
        <div className="space-y-6">
          <Suspense fallback={<Skeleton className="w-full h-96" />}>
            <TrackListTable
              endDate={dateRange.endDate.toISOString()}
              startDate={dateRange.startDate.toISOString()}
              artistId={Number(artistId)}
            />
          </Suspense>
        </div>
        <div className="grid lg:grid-cols-3 gap-10">
          <div className="grid md:grid-cols-2 gap-10 md:col-span-2">
            <div className="col-span-2">
              <h2 className="h2">{t('artistPages.streams.spotifyInsights')}</h2>
            </div>
            <div className="space-y-6">
              <h4 className="h4">
                {t('artistPages.streams.streamsGenderVariety')}
              </h4>
              <Suspense fallback={<Skeleton className="w-full h-[300px]" />}>
                <CategoryChart
                  artistId={Number(artistId)}
                  endDate={dateRange.endDate.toISOString()}
                  startDate={dateRange.startDate.toISOString()}
                  groupBy={StreamCountGroupBy.Gender}
                />
              </Suspense>
            </div>
            <div className="space-y-6">
              <h4 className="h4">{t('artistPages.streams.listenersAge')}</h4>
              <Suspense fallback={<Skeleton className="w-full h-[300px]" />}>
                <CategoryChart
                  artistId={Number(artistId)}
                  endDate={dateRange.endDate.toISOString()}
                  startDate={dateRange.startDate.toISOString()}
                  groupBy={StreamCountGroupBy.Age}
                />
              </Suspense>
            </div>
          </div>
          <div className="listen-bar-chart space-y-6 col-span-1">
            <h4 className="h4">
              {t('artistPages.streams.lengthOfSongsListened')}
            </h4>
            <Suspense fallback={<Skeleton className="w-full h-[300px]" />}>
              <PlayLengthChart
                artistId={Number(artistId)}
                endDate={dateRange.endDate.toISOString()}
                startDate={dateRange.startDate.toISOString()}
              />
            </Suspense>
            <p>{t('artistPages.streams.royaltyExplanation')}</p>
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
}
