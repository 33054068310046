type TrackInfoItemProps = {
  label: string;
  value: string | undefined;
};

type TrackRoyaltyInfoItemProps = {
  label: string;
  value: string | undefined | null;
};
/**
 * Display single piece of information from a track
 */
export const TrackInfoItem = (props: TrackInfoItemProps) => {
  const { label, value } = props;
  return (
    <div className="space-y-2">
      <h2 className="text-xl font-medium">{label}</h2>
      <p className="text-2xl font-light font-robotoMono">
        {!value || value.length === 0 ? '-' : value}
      </p>
    </div>
  );
};

export const TrackRoyaltyInfoItem = (props: TrackRoyaltyInfoItemProps) => {
  return (
    <div className="space-y-2">
      <h5 className="font-bold">{props.label}</h5>
      <p>{props.value}</p>
    </div>
  );
};
