import { SignOutButton, useSession } from '@clerk/clerk-react';
import { Link, useRouterState } from '@tanstack/react-router';
import { ArrowLeftRight } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { useGetArtistByEmailQuery } from '../../gql/generated-types';
import { ARTIST_PATH } from '../../lib/constants';
import { cn } from '../../lib/utils';
import { Avatar, AvatarFallback, AvatarImage } from '../ui/avatar';
import { Button, buttonVariants } from '../ui/button';
import { Popover, PopoverContent, PopoverTrigger } from '../ui/popover';
import { ArtistSheet } from './artist-sheet';
import { NavItem } from './nav-item';
import { useParams } from '@tanstack/react-router';

export function ArtistNav() {
  const { t } = useTranslation();
  const { session } = useSession();
  const { artistId } = useParams({ strict: false });
  const { loading, data } = useGetArtistByEmailQuery({
    variables: {
      email: session?.user?.primaryEmailAddress?.emailAddress as string,
    },
  });

  const state = useRouterState();

  const { loading: profilesLoading, data: profilesData } =
    useGetArtistByEmailQuery({
      variables: {
        email: session?.user?.primaryEmailAddress?.emailAddress as string,
      },
    });

  if (loading || profilesLoading) {
    return null;
  }

  return (
    <>
      <div className="lg:hidden">
        <ArtistSheet artistProfiles={profilesData} />
      </div>
      <div className="mr-4 hidden justify-self-end lg:flex">
        <nav className="flex items-center space-x-12 text-sm font-medium">
          {artistId && (
            <>
              <NavItem
                href={`${ARTIST_PATH}/${artistId}`}
                label={t('artistNav.frontpage')}
                active={
                  artistId !== '' &&
                  state.location.pathname === `/artist/${artistId}`
                }
              />
              <NavItem
                href={`${ARTIST_PATH}/${artistId}/streams`}
                label={t('artistNav.streams')}
                active={state.location.pathname.includes('streams')}
              />
              <NavItem
                href={`${ARTIST_PATH}/${artistId}/balance`}
                label={t('artistNav.balance')}
                active={state.location.pathname.includes('balance')}
              />
            </>
          )}
          <div className="w-[40px] h-[40px]">
            <Popover>
              <PopoverTrigger>
                <Avatar>
                  <AvatarImage src="" />
                  <AvatarFallback>
                    <div
                      className="rounded-full w-[40px] h-[40px] bg-bone bg-[length:56px_56px] bg-center"
                      style={{
                        backgroundImage: `url("/assets/default_artist.png")`,
                      }}
                    />
                  </AvatarFallback>
                </Avatar>
              </PopoverTrigger>
              <PopoverContent className="space-y-4 w-[300px]">
                <p className="text-center font-bold">
                  {data?.artistsByEmail?.map(artist => {
                    if (artist?.id === Number(artistId)) {
                      return artist?.name;
                    }
                    return null;
                  })}
                </p>
                {data?.artistsByEmail && data?.artistsByEmail?.length > 1 && (
                  <Link
                    to={ARTIST_PATH}
                    className={cn(
                      buttonVariants({ variant: 'outline' }),
                      'border-grey-darkest w-full text-grey-darkest bg-transparent focus:bg-transparent hover:bg-transparent focus:outline-1 focus:outline-dotted',
                    )}
                  >
                    <ArrowLeftRight className="w-4 h-4 mr-2" />
                    {t('artistNav.switch')}
                  </Link>
                )}
                <SignOutButton>
                  <Button variant="secondary" className="w-full">
                    {t('artistNav.logout')}
                  </Button>
                </SignOutButton>
              </PopoverContent>
            </Popover>
          </div>
        </nav>
      </div>
    </>
  );
}
