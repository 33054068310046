import { Columns } from './types';
import { ArrowUpDown, ChevronRight } from 'lucide-react';
import { cn } from '@/lib/utils';
import { TranslatedMessage } from '@/components/ui/translated-message';
import { Badge } from '@/components/ui/badge';
import { ReleaseActions } from '@/views/admin/data-management/releases/components/action-menu';
import { Link } from '@tanstack/react-router';
import { ADMIN_RELEASE } from '@/services/router/routes';
import { buttonVariants } from '@/components/ui/button';

function DuplicateOfBadge({
  duplicateOf,
}: {
  duplicateOf:
    | {
        __typename?: 'Release' | undefined;
        id: number;
      }
    | null
    | undefined;
}) {
  // Check if duplicateOf is not null or undefined and if it has the id key
  if (duplicateOf && duplicateOf.id) {
    return <Badge variant="duplicate">Duplicate</Badge>;
  } else {
    return null;
  }
}

function PromoOfBadge({
  promoOf,
}: {
  promoOf:
    | {
        __typename?: 'Release' | undefined;
        id: number;
      }
    | null
    | undefined;
}) {
  // Check if duplicateOf is not null or undefined and if it has the id key
  if (promoOf && promoOf.id) {
    return <Badge variant="promo">Promo</Badge>;
  } else {
    return null;
  }
}

export const cols: Columns[] = [
  {
    accessorKey: 'name',
    header: ({ column }) => {
      return (
        <span
          className={cn(
            column.getIsSorted() && 'text-yellow-dark',
            'flex items-center cursor-pointer',
          )}
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          <TranslatedMessage translationKey="releaseTable.releaseName" />
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </span>
      );
    },
    cell: ({ row }) => {
      return (
        <div className="max-w-[200px]">
          <DuplicateOfBadge duplicateOf={row.original.duplicateOf} />
          <PromoOfBadge promoOf={row.original.promoOf} />
          <div className="max-w-[200px]">{row.original.name}</div>
        </div>
      );
    },
  },
  {
    accessorKey: 'releaseArtists',
    filterFn: 'equalsString',
    header: ({ column }) => {
      return (
        <span
          className={cn(
            column.getIsSorted() && 'text-yellow-dark',
            'flex items-center cursor-pointer',
          )}
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          <TranslatedMessage translationKey="releaseTable.releaseArtists" />
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </span>
      );
    },
    cell: ({ row }) => {
      return <div className="max-w-[200px]">{row.original.releaseArtists}</div>;
    },
  },
  {
    accessorKey: 'releaseType',
    header: ({ column }) => {
      return (
        <span
          className={cn(
            column.getIsSorted() && 'text-yellow-dark',
            'flex items-center cursor-pointer',
          )}
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          <TranslatedMessage translationKey="releaseTable.releaseType" />
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </span>
      );
    },
    cell: ({ row }) => {
      return <span className="min-w-[100px]">{row.original?.releaseType}</span>;
    },
  },
  {
    accessorKey: 'releaseDate',
    sortingFn: 'datetime',
    header: ({ column }) => {
      return (
        <span
          className={cn(
            column.getIsSorted() && 'text-yellow-dark',
            'flex items-center cursor-pointer',
          )}
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          <TranslatedMessage translationKey="releaseTable.releaseDate" />
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </span>
      );
    },
    cell: ({ row }) => {
      return new Date(row.original.releaseDate).toLocaleDateString('fi-FI');
    },
  },
  {
    accessorKey: 'id',
    id: 'id',
    header: '',
    cell: ({ row, handleReleaseDuplicate, handleReleasePromo, artistId }) => {
      return (
        <div className="flex justify-end space-x-2 items-center">
          <Link
            to={ADMIN_RELEASE}
            params={{
              releaseId: row.original.id,
            }}
            search={{
              artistId,
            }}
            className={buttonVariants({
              variant: 'default',
              size: 'iconSmall',
            })}
          >
            <ChevronRight className="w-4 h-4" />
          </Link>
          <ReleaseActions
            artistId={artistId?.toString() ?? ''}
            onDuplicateAction={() => handleReleaseDuplicate(row.original.id)}
            onPromoAction={() => handleReleasePromo(row.original.id)}
            id={row.original.id}
            isPromoOrDuplicate={
              row.original.duplicateOf !== null || row.original.promoOf !== null
            }
          />
        </div>
      );
    },
  },
];
