import { Link } from '@tanstack/react-router';
import { ChevronLeft } from 'lucide-react';
import { Suspense, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { currentDate, pastDate } from '../util';
import { CalendarDropDown } from '../../../components/ui/calendar-dd';
import { StreamCountGroupBy } from '@/gql/generated-types';
import { format } from 'date-fns';
import { useParams } from '@tanstack/react-router';
import { dateFrom, previousRangeLength } from './utils';
import ErrorBoundary from '@/components/partials/error';
import { StickyHeader } from '@/components/ui/sticky-header';
import { StreamChart } from '../shared/stream-chart';
import { Skeleton } from '@/components/ui/skeleton';
import { CategoryChart } from './components/category-chart';
import { PlayLengthChart } from './components/play-length-chart';
import { DSPTable } from './components/dsp-table';
import { SongStats } from './components/song-stats';

export default function SongStreams() {
  const { t } = useTranslation();
  const { artistId, trackId } = useParams({ strict: false });
  const [dateRange, setDateRange] = useState<{
    startDate: Date;
    endDate: Date;
  }>({
    startDate: pastDate,
    endDate: currentDate,
  });

  return (
    <ErrorBoundary>
      <div className="container space-y-20">
        <StickyHeader
          backLink={
            <Link
              to={`/artist/${artistId}/streams`}
              className="inline-flex items-center hover:underline"
            >
              <ChevronLeft className="w-4 h-4 mr-2" />
              <span>{t('artistShared.back')}</span>
            </Link>
          }
        >
          <CalendarDropDown
            onDateChange={value =>
              setDateRange({
                startDate: value.from!,
                endDate: value.to!,
              })
            }
            start={dateRange.startDate}
            end={dateRange.endDate}
          />
        </StickyHeader>

        <Suspense fallback={<Skeleton className="w-full h-[400px]" />}>
          <SongStats
            artistId={Number(artistId)}
            endDate={dateRange.endDate.toISOString()}
            startDate={dateRange.startDate.toISOString()}
            trackId={Number(trackId)}
          />
        </Suspense>

        <div>
          <div className="flex justify-end text-xs items-center space-x-2 mb-2">
            <div>
              <span className="mr-2 inline-flex w-2 h-2 bg-[#D77EF5]"></span>
              {format(dateRange.startDate, 'dd.L')}
              {' - '}
              {format(dateRange.endDate, 'dd.L')}
            </div>
            <div>
              <span className="mr-2 inline-flex w-2 h-2 bg-[#FFCA10]"></span>
              {format(
                dateFrom(
                  dateRange.startDate,
                  previousRangeLength(dateRange.startDate, dateRange.endDate),
                ),
                'dd.L',
              )}
              {' - '}
              {format(
                dateFrom(
                  dateRange.endDate,
                  previousRangeLength(dateRange.startDate, dateRange.endDate),
                ),
                'dd.L',
              )}
            </div>
          </div>
          <Suspense fallback={<Skeleton className="w-full h-[400px]" />}>
            <StreamChart
              trackId={Number(trackId)}
              artistId={Number(artistId)}
              aspect={2}
              startDate={dateRange.startDate.toISOString()}
              endDate={dateRange.endDate.toISOString()}
              prevEndDate={new Date(
                dateFrom(
                  dateRange.endDate,
                  previousRangeLength(dateRange.startDate, dateRange.endDate),
                ),
              ).toISOString()}
              prevStartDate={new Date(
                dateFrom(
                  dateRange.startDate,
                  previousRangeLength(dateRange.startDate, dateRange.endDate),
                ),
              ).toISOString()}
            />
          </Suspense>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-12">
          <div className="space-y-12 order-1">
            <div className="space-y-6">
              <h4 className="h4">
                {t('artistPages.streams.streamsFromDifferentServices')}
              </h4>
              <Suspense fallback={<Skeleton className="w-full h-[400px]" />}>
                <DSPTable
                  artistId={Number(artistId)}
                  endDate={dateRange.endDate.toISOString()}
                  startDate={dateRange.startDate.toISOString()}
                  trackId={Number(trackId)}
                />
              </Suspense>
            </div>
          </div>
          <div className="space-y-12 order-3 md:order-2">
            <h2 className="h2">{t('artistPages.streams.spotifyInsights')}</h2>
            <div className="space-y-6">
              <h4 className="h4">
                {t('artistPages.streams.streamsGenderVariety')}
              </h4>

              <Suspense fallback={<Skeleton className="w-full h-[400px]" />}>
                <CategoryChart
                  artistId={Number(artistId)}
                  endDate={dateRange.endDate.toISOString()}
                  startDate={dateRange.startDate.toISOString()}
                  groupBy={StreamCountGroupBy.Gender}
                  trackId={Number(trackId)}
                />
              </Suspense>
            </div>
            <div className="space-y-6">
              <h4 className="h4">{t('artistPages.streams.listenersAge')}</h4>
              <Suspense fallback={<Skeleton className="w-full h-[400px]" />}>
                <CategoryChart
                  artistId={Number(artistId)}
                  endDate={dateRange.endDate.toISOString()}
                  startDate={dateRange.startDate.toISOString()}
                  groupBy={StreamCountGroupBy.Age}
                  trackId={Number(trackId)}
                />
              </Suspense>
            </div>
          </div>
          <div className="listen-bar-chart space-y-6 order-2 md:order-3">
            <h4 className="h4">
              {t('artistPages.streams.lengthOfSongsListened')}
            </h4>
            <Suspense fallback={<Skeleton className="w-full h-[400px]" />}>
              <PlayLengthChart
                artistId={Number(artistId)}
                endDate={dateRange.endDate.toISOString()}
                startDate={dateRange.startDate.toISOString()}
                trackId={Number(trackId)}
              />
            </Suspense>
            <p>{t('artistPages.streams.royaltyExplanation')}</p>
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
}
