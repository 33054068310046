import { gql } from '@apollo/client';

export const CREATE_RELEASE = gql`
  mutation UpsertRelease($input: UpsertReleaseInput!) {
    upsertRelease(input: $input) {
      id
      catalogNumber
      coverArt
      name
      productType
      promoAssets
      releaseDate
      releaseType
      tracks {
        isrcs {
          isrc
        }
        name
        trackArtists {
          artistId
        }
      }
      upc {
        id
        upcNumber
        activated
      }
      version
    }
  }
`;

export const CREATE_CONTRACT = gql`
  mutation UpsertArtistContract($input: UpsertArtistContractInput!) {
    upsertArtistContract(input: $input) {
      id
    }
  }
`;

export const CREATE_USER = gql`
  mutation UpsertUser($input: UpsertUserInput!) {
    upsertUser(input: $input) {
      id
    }
  }
`;

export const CREATE_ARTIST = gql`
  mutation UpserArtist($input: UpsertArtistInput!) {
    upsertArtist(input: $input) {
      id
      name
    }
  }
`;

export const CREATE_EXPENSE = gql`
  mutation UpsertExpense($input: UpsertExpenseInput!) {
    upsertExpense(input: $input) {
      id
    }
  }
`;

export const CREATE_TRACK = gql`
  mutation UpsertTrack($input: UpsertTrackInput!) {
    upsertTrack(input: $input) {
      id
      release {
        id
      }
    }
  }
`;

export const CREATE_DUPLICATE = gql`
  mutation CreateDuplicateRelease($input: CreateDuplicateReleaseInput!) {
    createDuplicateRelease(input: $input) {
      id
    }
  }
`;

export const CREATE_PROMO = gql`
  mutation CreatePromoRelease($input: CreatePromoReleaseInput!) {
    createPromoRelease(input: $input) {
      id
    }
  }
`;

export const CREATE_PAYMENT = gql`
  mutation CreatePayment($input: CreatePaymentInput!) {
    createPayment(input: $input) {
      id
      artist {
        id
      }
    }
  }
`;

export const DELETE_PAYMENT = gql`
  mutation DeletePayment($deletePaymentId: Int!) {
    deletePayment(id: $deletePaymentId) {
      id
    }
  }
`;

export const CREATE_COLLABORATOR = gql`
  mutation CreateCollaborator($input: UpsertCollaboratorInput!) {
    upsertCollaborator(input: $input) {
      id
      name
    }
  }
`;

export const DELETE_ARTIST = gql`
  mutation DeleteArtist($deleteArtistId: Int!) {
    deleteArtist(id: $deleteArtistId) {
      id
    }
  }
`;

export const DELETE_COLLABORATOR = gql`
  mutation DeleteCollaborator($deleteCollaboratorId: Int!) {
    deleteCollaborator(id: $deleteCollaboratorId) {
      id
    }
  }
`;

export const DELETE_CONTRACT = gql`
  mutation DeleteArtistContract($deleteArtistContractId: Int!) {
    deleteArtistContract(id: $deleteArtistContractId) {
      id
    }
  }
`;

export const DELETE_USER = gql`
  mutation DeleteUser($deleteUserId: Int!) {
    deleteUser(id: $deleteUserId) {
      id
    }
  }
`;

export const PROCESS_ROYALTIES = gql`
  mutation ProcessRoyalties {
    processRoyalties
  }
`;

export const UPDATE_UNMATCHED = gql`
  mutation UpdateUnmatchedTrack($input: UpdateUnmatchedTrackInput!) {
    updateUnmatchedTrack(input: $input) {
      id
    }
  }
`;
