import { Link } from '@tanstack/react-router';
import clsx from 'clsx';

interface NavItemProps {
  label: string;
  href: string;
  active?: boolean;
  subNavItem?: boolean;
  useA?: boolean;
}

type DummyNavItemProps = Omit<NavItemProps, 'href'>;

export const DummyNavItem = ({ label, active }: DummyNavItemProps) => {
  const navItemStyle = clsx(
    [
      'text-sm',
      'tracking-[3px]',
      'hover:text-yellow-dark',
      'focus:outline-dotted',
      'focus:outline-1',
      'focus:outline-bone/40',
    ],
    {
      'text-yellow-dark': active,
    },
  );

  return <span className={navItemStyle}>{label}</span>;
};

export const NavItem = ({
  label,
  href,
  active,
  subNavItem,
  useA,
}: NavItemProps) => {
  const navItemStyle = clsx(
    [
      'text-sm',
      'tracking-[3px]',
      'hover:text-yellow-dark',
      'focus:outline-dotted',
      'focus:outline-1',
      'focus:outline-bone/40',
    ],
    {
      'text-yellow-dark': active,
      'tracking-[1px]': subNavItem,
    },
  );

  if (useA) {
    return (
      <a href={href} className={navItemStyle}>
        {label}
      </a>
    );
  }

  return (
    <Link to={href} className={navItemStyle}>
      {label}
    </Link>
  );
};
