import { focusAtom } from 'jotai-optics';
import { releaseAtom } from './releases';
import { ReleaseTrack } from './types';

export const initialTrackData: ReleaseTrack = {
  contractArtistId: 0,
  contractId: 0,
  gramexLabelPercentage: 0,
  isrc: '',
  name: '',
  trackArtists: [],
  trackCollaborators: [],
  youtubeAssetReference: '',
  order: 0,
  volume: 1,
  version: '',
  notes: '',
};

export const tracksAtom = focusAtom(releaseAtom, optic => optic.prop('tracks'));
