import { BarChart as CustomBarChart } from '@/components/ui/bar-chart';
import {
  StreamCountGroupBy,
  useGetStreamsByCategorySuspenseQuery,
} from '@/gql/generated-types';

interface CategoryChartProps {
  artistId: number;
  endDate: string;
  startDate: string;
  groupBy: StreamCountGroupBy;
  trackId?: number;
}

export const CategoryChart = (props: CategoryChartProps) => {
  const { trackId } = props;
  const { data } = useGetStreamsByCategorySuspenseQuery({
    variables: {
      artistId: props.artistId,
      endDate: props.endDate,
      startDate: props.startDate,
      groupBy: props.groupBy,
      trackId,
    },
  });
  return (
    <CustomBarChart
      data={data?.streamCount as Array<[string, number | null]>}
    />
  );
};
