import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from '@/components/ui/form';
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
} from '@/components/ui/select';
import {
  RoyaltyPeriod,
  generateYearsArray,
  isDateBetween,
} from '@/views/artist/balance/util';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import * as z from 'zod';
import { MONTHS } from '@/views/admin/data-management/contracts/forms/util';
import { Button } from '@/components/ui/button';
import { useTranslation } from 'react-i18next';

type BalanceFormProps = {
  defaultValueIndex: number;
  values: RoyaltyPeriod[];
  onChangeDateRange: (value: number) => void;
};

const balanceFormSchema = z.object({
  year: z.number(),
  selectedDate: z.number(),
});

export function BalanceForm(props: BalanceFormProps) {
  const { t } = useTranslation();
  const form = useForm<z.infer<typeof balanceFormSchema>>({
    resolver: zodResolver(balanceFormSchema),
    defaultValues: {
      year: props.values.find(v => v.index === props.defaultValueIndex)?.start
        .year,
      selectedDate: props.defaultValueIndex,
    },
  });

  const listOfYears = generateYearsArray(
    new Date(
      props.values[0].start.year,
      props.values[0].start.month - 1,
      props.values[0].start.day,
    ),
  );

  const selectedYear = form.watch('year');

  const finalYear = props.values[props.values.length - 1].start.year;

  function handleSubmit(values: z.infer<typeof balanceFormSchema>) {
    props.onChangeDateRange(values.selectedDate);
  }
  return (
    <div>
      <Form {...form}>
        <form className="space-y-4" onSubmit={form.handleSubmit(handleSubmit)}>
          <FormField
            name="year"
            control={form.control}
            render={({ field }) => (
              <FormItem>
                <Select
                  defaultValue={field.value.toString()}
                  onValueChange={value => field.onChange(Number(value))}
                >
                  <FormControl>
                    <SelectTrigger>{field.value}</SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {listOfYears.map((year, index) => {
                      if (year > finalYear) return null;
                      return (
                        <SelectItem value={year.toString()} key={index}>
                          {year.toString()}
                        </SelectItem>
                      );
                    })}
                  </SelectContent>
                </Select>
              </FormItem>
            )}
          />
          <FormField
            name="selectedDate"
            control={form.control}
            render={({ field }) => (
              <FormItem>
                <RadioGroup
                  onValueChange={value => field.onChange(Number(value))}
                  defaultValue={field.value.toString()}
                  className="flex flex-col space-y-2"
                >
                  {props.values
                    .filter(a => a.start.year === selectedYear)
                    .map((value, i) => (
                      <FormItem
                        key={i}
                        className="flex items-center space-x-3 space-y-0"
                      >
                        <FormControl>
                          <RadioGroupItem value={value.index.toString()} />
                        </FormControl>
                        <FormLabel className="font-normal text-sm">
                          {value.start.day}.
                          {MONTHS[value.start.month - 1].label}{' '}
                          {value.start.year}
                          {' - '}
                          {value.end.day}.{MONTHS[value.end.month - 1].label}{' '}
                          {value.end.year}
                          <br />
                          <span className="text-xs font-bold">
                            {isDateBetween(
                              new Date(
                                value.start.year,
                                value.start.month - 1,
                                1,
                              ),
                              new Date(
                                value.end.year,
                                value.end.month - 1,
                                value.end.day,
                              ),
                              new Date(),
                            )
                              ? t('selectRoyaltyPeriodForm.open')
                              : t('selectRoyaltyPeriodForm.closed')}
                          </span>
                        </FormLabel>
                      </FormItem>
                    ))}
                </RadioGroup>
              </FormItem>
            )}
          />
          <Button
            variant="secondary"
            size="sm"
            className="!cursor-pointer !text-bone text-xs"
          >
            {t('selectRoyaltyPeriodForm.selectSeason')}
          </Button>
        </form>
      </Form>
    </div>
  );
}
