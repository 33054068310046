import { useParams } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';
import ArtistBalanceContent from './content';
import { useGetArtistContractsQuery } from '@/gql/generated-types';
import {
  GetLatestContractBySigningDate,
  checkForContractValidity,
  findObjectByDate,
  generateOptionsFromRoyaltyPeriods,
  generateYearsArray,
} from './util';
import { ViewError } from '@/components/errors/view-error';
import { ViewLoader } from '@/components/loaders/view-loader';
import { FaultyContract, NoContractError } from './components/faulty-contracts';

export default function ArtistRoyalties() {
  const { t } = useTranslation();
  const { artistId } = useParams({ strict: false });
  const { data, loading } = useGetArtistContractsQuery({
    variables: {
      artistId: Number(artistId),
    },
  });

  const artistRoyaltySeasons = GetLatestContractBySigningDate(data);

  if (!loading && data?.contracts?.length === 0) {
    return (
      <div className="container space-y-12">
        <NoContractError />
      </div>
    );
  }

  if (!loading && !checkForContractValidity(data?.contracts)) {
    return (
      <div className="container space-y-12">
        <FaultyContract />
      </div>
    );
  }

  if (loading) {
    return (
      <div className="container space-y-12">
        <ViewLoader />
      </div>
    );
  }

  if (artistRoyaltySeasons?.royaltyPeriod === undefined) {
    return (
      <div className="container">
        <ViewError />
      </div>
    );
  }

  const years = generateYearsArray(artistRoyaltySeasons?.dateSigned);
  const periods = generateOptionsFromRoyaltyPeriods(
    years,
    artistRoyaltySeasons?.royaltyPeriod.map(num => Math.round(num)) ?? [],
  );

  const defaultObject = findObjectByDate(periods, new Date());

  return (
    <div className="container space-y-20">
      <div className="items-center">
        <h1 className="h1 order-1">{t('artistPages.balance.title')}</h1>
      </div>

      <div className="w-full space-y-12 relative">
        <ArtistBalanceContent
          artistId={Number(artistId)}
          defaultValues={defaultObject}
          values={periods}
        />
      </div>
    </div>
  );
}
