import { RadialCard } from '@/components/ui/radial-card';
import { Skeleton } from '@/components/ui/skeleton';
import { Table, TableBody, TableCell, TableRow } from '@/components/ui/table';
import { GetArtistIncomeTotalsV2Query } from '@/gql/generated-types';
import { ApolloError } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { ErrorWhileFetchingDataForSeason } from './states';

type ArtistIncomeTotalsProps = {
  totals: GetArtistIncomeTotalsV2Query | undefined;
  loading: boolean;
  error: ApolloError | undefined;
};

function IncomeTotalsSkeleton() {
  return (
    <div className="space-y-2">
      <Skeleton className="w-full h-[50px]" />
      <Skeleton className="w-full h-[50px]" />
      <Skeleton className="w-full h-[50px]" />
    </div>
  );
}

export default function ArtistIncomeTotals(props: ArtistIncomeTotalsProps) {
  const { t } = useTranslation();
  const { error, loading, totals } = props;

  if (!totals || !totals.artistIncomeTotalsV2) {
    return null;
  }

  const {
    toplineRevenue,
    expenses: { total: totalExpenses },
    featuredArtistIncome,
    collaboratorIncome,
    pmeIncome,
    recoupments,
    artistIncome,
    payments,
  } = totals.artistIncomeTotalsV2;

  const subtotal =
    toplineRevenue - totalExpenses - featuredArtistIncome - collaboratorIncome;

  // Show a floor of 0 for the artist income
  const finalArtistIncome = Math.max(artistIncome - recoupments - payments, 0);

  return (
    <>
      <h4 className="h4">{t('artistPages.balance.seasonCalculation')}</h4>
      {loading && <IncomeTotalsSkeleton />}
      {error && <ErrorWhileFetchingDataForSeason />}
      {!loading && totals && (
        <>
          <Table className="border-collapse">
            <TableBody>
              <TableRow key="income">
                <TableCell>
                  {t('artistPages.balance.streamRevenueForSeason')}
                </TableCell>
                <TableCell className="text-right">
                  {new Intl.NumberFormat('fi-FI', {
                    style: 'currency',
                    currency: 'EUR',
                  }).format(toplineRevenue)}
                </TableCell>
              </TableRow>
              <TableRow key="expenses" variant="odd">
                <TableCell>
                  {t('artistPages.balance.expensesForSeason')}
                </TableCell>
                <TableCell className="text-right">
                  -{' '}
                  {new Intl.NumberFormat('fi-FI', {
                    style: 'currency',
                    currency: 'EUR',
                  }).format(totalExpenses)}
                </TableCell>
              </TableRow>
              <TableRow key="featuredArtistIncome">
                <TableCell>
                  {t('artistPages.balance.featuredArtistIncome')}
                </TableCell>
                <TableCell className="text-right">
                  -{' '}
                  {new Intl.NumberFormat('fi-FI', {
                    style: 'currency',
                    currency: 'EUR',
                  }).format(featuredArtistIncome)}
                </TableCell>
              </TableRow>
              <TableRow key="collaboratorIncome" variant="odd">
                <TableCell>
                  {t('artistPages.balance.collaboratorIncome')}
                </TableCell>
                <TableCell className="text-right">
                  -{' '}
                  {new Intl.NumberFormat('fi-FI', {
                    style: 'currency',
                    currency: 'EUR',
                  }).format(collaboratorIncome)}
                </TableCell>
              </TableRow>
              <TableRow key="subtotal" className="text-lime border-b">
                <TableCell>{t('artistPages.balance.subtotal')}</TableCell>
                <TableCell className="text-right">
                  {new Intl.NumberFormat('fi-FI', {
                    style: 'currency',
                    currency: 'EUR',
                  }).format(subtotal)}
                </TableCell>
              </TableRow>
              <TableRow key="pmeIncome" variant="odd">
                <TableCell>{t('artistPages.balance.pmeIncome')}</TableCell>
                <TableCell className="text-right">
                  -{' '}
                  {new Intl.NumberFormat('fi-FI', {
                    style: 'currency',
                    currency: 'EUR',
                  }).format(pmeIncome)}
                </TableCell>
              </TableRow>
              <TableRow key="recoupments">
                <TableCell>
                  {t('artistPages.balance.recoupableAdvancePayments')}
                </TableCell>
                <TableCell className="text-right">
                  -{' '}
                  {new Intl.NumberFormat('fi-FI', {
                    style: 'currency',
                    currency: 'EUR',
                  }).format(recoupments)}
                </TableCell>
              </TableRow>
              <TableRow key="payments">
                <TableCell>{t('artistPages.balance.madePayments')}</TableCell>
                <TableCell className="text-right">
                  -{' '}
                  {new Intl.NumberFormat('fi-FI', {
                    style: 'currency',
                    currency: 'EUR',
                  }).format(payments)}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </>
      )}
      {!loading && totals && (
        <RadialCard
          variant="grey"
          bodyClassName="text-center text-xs space-y-6"
        >
          <p className="font-bold">{t('artistPages.balance.situationToday')}</p>
          <p className="font-bold font-mono text-xl text-lime">
            {new Intl.NumberFormat('fi-FI', {
              style: 'currency',
              currency: 'EUR',
            }).format(finalArtistIncome)}
          </p>
        </RadialCard>
      )}
    </>
  );
}
