import { buttonVariants } from '@/components/ui/button';
import { RadialCard } from '@/components/ui/radial-card';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import { GetReleaseByIdQuery } from '@/gql/generated-types';
import { ADMIN_EDIT_TRACK, ADMIN_TRACK } from '@/services/router/routes';
import { Link } from '@tanstack/react-router';
import { AlertTriangle, ChevronRight, Pencil } from 'lucide-react';
import { mapByVolume, parseTrack } from '../util/formatting';
import { useTranslation } from 'react-i18next';

type TrackListProps = {
  trackList: GetReleaseByIdQuery;
};

export function TrackList(props: TrackListProps) {
  const { t } = useTranslation();
  const sortedTrackList = [...props.trackList.release!.tracks].sort(
    (a, b) => a.order - b.order,
  );

  const volumes = mapByVolume(parseTrack(sortedTrackList));

  return (
    <div className="block whitespace-nowrap overflow-x-auto w-full">
      {props.trackList!.release!.tracks.length === 0 && (
        <RadialCard className="text-center">
          <AlertTriangle className="mx-auto mb-4" />
          {t('adminReleases.trackList.noTracks', {
            name: props.trackList.release?.name,
          })}
        </RadialCard>
      )}
      {Object.entries(volumes).map(([vol, list], index) => (
        <div className="space-y-6 mb-12" key={index}>
          <h2 className="mb-6 font-robotoMono text-xl">Volume {vol}</h2>
          <Table className="border-collapse">
            <TableHeader>
              <TableRow>
                <TableHead className="text-left"></TableHead>
                <TableHead className="text-left">
                  {t('adminReleases.trackList.name')}
                </TableHead>
                <TableHead className="text-left">
                  {t('adminReleases.trackList.isrc')}
                </TableHead>
                <TableHead></TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {list?.map((track, index) => (
                <TableRow key={index} variant={index % 2 === 0 ? 'odd' : null}>
                  <TableCell>{track.order}</TableCell>
                  <TableCell>
                    <Link
                      to={ADMIN_TRACK}
                      className="underline hover:no-underline"
                      params={{
                        trackId: track.id,
                        releaseId: props.trackList.release!.id,
                      }}
                    >
                      {track.name}
                    </Link>
                  </TableCell>
                  <TableCell>{track.isrc}</TableCell>
                  <TableCell className="flex justify-end space-x-2">
                    <Link
                      to={`${ADMIN_EDIT_TRACK}`}
                      params={{
                        trackId: track.id,
                      }}
                      className={buttonVariants({
                        variant: 'outline',
                        size: 'sm',
                      })}
                    >
                      <Pencil className="w-4 h-4 mr-2" />
                      {t('adminReleases.trackList.edit')}
                    </Link>
                    <Link
                      to={ADMIN_TRACK}
                      params={{
                        releaseId: props.trackList.release!.id,
                        trackId: track.id,
                      }}
                      className={buttonVariants({
                        variant: 'default',
                        size: 'iconSmall',
                      })}
                    >
                      <ChevronRight className="w-4 h-4" />
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      ))}
    </div>
  );
}
