import { AdminHeader } from '@/components/partials/admin-header';
import { useGetArtistListQuery } from '@/gql/generated-types';
import { CreatePaymentForm } from '../forms/create';
import { useMutation } from '@apollo/client';
import { CREATE_PAYMENT } from '@/graphql/mutations';
import { toast } from '@/components/ui/use-toast';
import { useNavigate } from '@tanstack/react-router';
import { ADMIN_PAYMENTS } from '@/services/router/routes';
import { useTranslation } from 'react-i18next';

export default function CreatePayment() {
  const { data } = useGetArtistListQuery();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [createPayment] = useMutation(CREATE_PAYMENT, {
    onError: _ => {
      toast({
        variant: 'destructive',
        title: t('toast.genericErrorTitle'),
        description: t('toast.createErrorDescription', {
          item: t('toast.items.payment'),
        }),
      });
    },
    onCompleted: _ => {
      toast({
        title: t('toast.genericSaveSuccessTitle'),
        description: t('genericSaveSuccessDescription', {
          item: t('toast.items.payment'),
        }),
      });
      navigate({ to: ADMIN_PAYMENTS });
    },
  });
  return (
    <>
      <AdminHeader title={t('adminPayments.title')} />
      <CreatePaymentForm
        onSubmit={values =>
          createPayment({
            variables: {
              input: {
                artistId: values.artistId,
                amount: values.amount,
                title: values.title,
                comment: values.comment,
                date: values.date,
              },
            },
          })
        }
        artists={data?.listArtists}
      />
    </>
  );
}
