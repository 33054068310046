import { ActiveSessionResource, type Appearance } from '@clerk/types';
export function checkUserRole(session: ActiveSessionResource | null) {
  if (
    !session ||
    !session.user ||
    !session.user.publicMetadata ||
    !session.user.publicMetadata.role
  ) {
    return null;
  }

  if (session.user.publicMetadata.role === 'ADMIN') {
    return 'admin';
  }

  return 'artist';
}

export const clerkAppearance: Appearance = {
  elements: {
    footer: 'hidden',
    headerTitle: 'text-bone text-[28px] font-bold font-roboto',
    card: 'bg-transparent border-0 shadow-none text-bone',
    formFieldLabel: 'text-bone text-sm font-medium font-roboto text-xs',
    dividerRow: 'hidden',
    identityPreviewEditButton: 'text-grey-darkest cursor-pointer',
    identityPreview:
      'font-roboto rounded-full bg-white text-grey-darkest hover:bg-bone focus:bg-bone h-10 px-10 py-2 text-base font-medium tracking-[1.6px]',
    headerSubtitle: 'hidden',
    formHeaderTitle: 'text-bone font-roboto',
    formHeaderSubtitle: 'text-bone font-roboto',
    socialButtonsBlockButton__google:
      'rounded-full bg-white hover:bg-bone cursor-pointer',
    formResendCodeLink: 'text-yellow-dark font-roboto cursor-pointer',
    formButtonPrimary:
      'cursor-pointer font-roboto rounded-full bg-yellow-dark text-grey-darkest hover:bg-yellow-neon focus:bg-yellow-neon h-10 px-10 py-2 text-base font-medium tracking-[1.6px]',
  },
};

export const getClerkLocalizations = (locale: string) => {
  const localizations = {
    fi: {
      formFieldLabel__emailAddress: 'Sähköpostiosoite',
      formButtonPrimary: 'Jatka',
      socialButtonsBlockButton: 'Jatka {{provider|titleize}}lla',
      signIn: {
        start: {
          title: 'Kirjaudu sisään',
        },
        emailLink: {
          title: 'Katso sähköpostisi',
          subtitle: 'jatkaaksesi {{applicationName}}',
          formTitle: 'Verifiointilinkki',
          formSubtitle:
            'Käytä verifiointilinkkiä, joka on lähetetty sähköpostiisi',
          resendButton: 'Etkö vastaanottanut linkkiä? Lähetä uudelleen',
          unusedTab: {
            title: 'Voit sulkea välilehden',
          },
          verified: {
            title: 'Kirjautuminen onnistui',
            subtitle: 'Sinut uudelleenohjataan pian',
          },
          verifiedSwitchTab: {
            subtitle: 'Palaa alkuperäiseen välilehteen jatkaaksesi',
            titleNewTab: 'Kirjautuneena toisella välilehdellä',
            subtitleNewTab: 'Palaa uuteen välilehteen jatkaaksesi',
          },
          loading: {
            title: 'Kirjaudutaan sisään...',
            subtitle: 'Sinut uudelleenohjataan pian',
          },
          failed: {
            title: 'Verifiointilinkki on virheellinen',
            subtitle: 'Palaa alkuperäiseen välilehteen jatkaaksesi.',
          },
          expired: {
            title: 'Verifiointilinkki on vanhentunut',
            subtitle: 'Palaa alkuperäiseen välilehteen jatkaaksesi.',
          },
        },
      },
    },
  };

  return localizations[locale as keyof typeof localizations] ?? null;
};
