import { ViewError } from '@/components/errors/view-error';
import { ViewLoader } from '@/components/loaders/view-loader';
import { AdminHeader } from '@/components/partials/admin-header';
import { Input } from '@/components/ui/input';
import { useListUsersQuery } from '@/gql/generated-types';
import { useSearch } from '@tanstack/react-router';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UsersTable } from './components/table';
import { useMutation } from '@apollo/client';
import { DELETE_USER } from '@/graphql/mutations';
import { toast } from '@/components/ui/use-toast';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
export default function AdminUsers() {
  const { t } = useTranslation();
  const { name } = useSearch({ strict: false });
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState<number | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>(name ?? '');

  const { data, loading, error, refetch } = useListUsersQuery({});

  const [deleteUser] = useMutation(DELETE_USER, {
    onError: _ => {
      setOpen(false);
      setSelected(null);
      toast({
        variant: 'destructive',
        title: t('toast.genericErrorTitle'),
        description: t('toast.genericDeleteDescription', {
          item: t('toast.items.userProfile'),
        }),
      });
    },
    onCompleted: _ => {
      refetch();
      toast({
        title: t('toast.genericDeleteSuccessTitle'),
        description: t('toast.genericDeleteSuccessDescription', {
          item: t('toast.items.userProfile'),
        }),
      });
      setOpen(false);
      setSelected(null);
    },
  });

  if (error) {
    return <ViewError />;
  }

  return (
    <>
      <AdminHeader title={t('adminDM.users.title')} />
      {loading && <ViewLoader />}
      {!loading && data && (
        <>
          <div className="space-y-4 md:space-y-0">
            <div className="space-y-4 lg:max-w-[25%] max-w-full">
              <Input
                type="text"
                value={searchTerm}
                placeholder={t('adminDM.users.searchForUser')}
                className="text-grey-darkest placeholder:text-grey-dark font-roboto border-0 px-2 w-full"
                onChange={event => setSearchTerm(event.target.value)}
              />
            </div>
          </div>
          <div>
            <UsersTable
              data={data.listUsers?.filter(user => {
                const name =
                  user?.person?.contractPerson.firstName +
                  ' ' +
                  user?.person?.contractPerson.lastName;

                return name.toLowerCase().includes(searchTerm.toLowerCase());
              })}
              onDeleteAction={id => {
                setSelected(id);
                setOpen(true);
              }}
            />
          </div>
          <Dialog
            open={open}
            onOpenChange={open => {
              if (!open) {
                setSelected(null);
              }
              setOpen(open);
            }}
          >
            <DialogContent className="space-y-6 max-h-[800px] overflow-scroll">
              <DialogHeader>
                <DialogTitle className="text-xl font-medium">
                  {t('adminDM.users.deleteUser.dialogTitle')}
                </DialogTitle>
                <DialogDescription>
                  {t('adminDM.users.deleteUser.dialogDescription')}
                </DialogDescription>
              </DialogHeader>

              <div className="flex justify-between">
                <Button
                  type="button"
                  size="sm"
                  variant="outline"
                  onClick={() => setOpen(false)}
                >
                  {t('adminDM.users.deleteUser.cancel')}
                </Button>
                <Button
                  type="button"
                  size="sm"
                  variant="destructive"
                  onClick={() =>
                    deleteUser({
                      variables: {
                        deleteUserId: selected,
                      },
                    })
                  }
                >
                  {t('adminDM.users.deleteUser.delete')}
                </Button>
              </div>
            </DialogContent>
          </Dialog>
        </>
      )}
    </>
  );
}
