import {
  StreamCountGroupBy,
  useGetStreamsByCategorySuspenseQuery,
} from '@/gql/generated-types';
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';

interface PlayLengthChartProps {
  artistId: number;
  endDate: string;
  startDate: string;
  trackId?: number;
}

export const PlayLengthChart = (props: PlayLengthChartProps) => {
  const { trackId } = props;
  const { data: tracksQueryByPlayLength } =
    useGetStreamsByCategorySuspenseQuery({
      variables: {
        artistId: props.artistId,
        endDate: props.endDate,
        startDate: props.startDate,
        groupBy: StreamCountGroupBy.PlayLength,
        trackId,
      },
    });

  const tracksPlayLength = Object.entries(
    tracksQueryByPlayLength?.streamCount ?? {},
  )
    .map(entry => {
      return {
        name: entry[0]
          // Remove "streams" from the string
          .replace(new RegExp('streams', 'g'), '')
          // If Sub replace with <
          .replace(/Sub/g, '< ')
          // If Over replace with >
          .replace(/Over/g, '> '),
        uv: entry[1],
      };
    })
    .filter(entry => entry.name !== '__typename' && entry.name !== 'total');
  return (
    <ResponsiveContainer width="98%" height={400}>
      <BarChart data={tracksPlayLength} className="text-xs">
        <CartesianGrid vertical={false} className="opacity-10" />
        <XAxis dataKey="name" />
        <YAxis dataKey="uv" />
        <Bar dataKey="uv" barSize={20} />
      </BarChart>
    </ResponsiveContainer>
  );
};
