import { AdminHeader } from '@/components/partials/admin-header';
import { ReleaseTrack } from '@/services/store/releases/types';
import { useNavigate, useParams } from '@tanstack/react-router';
import { TrackForm } from '../forms/track';
import {
  useGetArtistListQuery,
  useGetTrackByIdQuery,
  useListCollaboratorsQuery,
} from '@/gql/generated-types';
import { ViewLoader } from '@/components/loaders/view-loader';
import { ViewError } from '@/components/errors/view-error';
import { parseSingularTrack } from '../util/formatting';
import { CREATE_TRACK } from '@/graphql/mutations';
import { toast } from '@/components/ui/use-toast';
import { useApolloClient, useMutation } from '@apollo/client/react';
import { SchemaType, validateReleaseFormValues } from '../util/validation';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ADMIN_TRACK } from '@/services/router/routes';
export function EditTrackView() {
  const { trackId } = useParams({ strict: false });
  const { t } = useTranslation();
  const { data: artistList } = useGetArtistListQuery();
  const { data: collaboratorList } = useListCollaboratorsQuery();
  const navigate = useNavigate();
  const [formErrors, setFormErrors] = useState<string[]>([]);
  const { data, loading, error } = useGetTrackByIdQuery({
    variables: {
      trackId: Number(trackId),
    },
    fetchPolicy: 'no-cache',
  });

  const client = useApolloClient();

  const [updateTrack] = useMutation(CREATE_TRACK, {
    onError: _ => {
      toast({
        variant: 'destructive',
        title: t('toast.genericErrorTitle'),
        description: t('toast.createErrorDescription', {
          item: t('toast.items.track'),
        }),
      });
    },
    onCompleted: data => {
      toast({
        title: t('toast.genericSaveSuccessTitle'),
        description: t('toast.genericSaveSuccessDescription', {
          item: t('toast.items.track'),
        }),
      });

      // RESET STORE TO REFRESH TRACK INFORMATION
      // NOTE: This is a temporary solution to refresh the track information
      // NOTE: This reset all stores which makes it kinda bad
      client.resetStore();

      navigate({
        to: ADMIN_TRACK,
        params: {
          trackId: data.upsertTrack.id,
          releaseId: data.upsertTrack.release.id,
        },
      });
    },
  });

  function updateTrackHandler(values: ReleaseTrack) {
    const { contractArtistId, trackArtists, trackCollaborators, ...rest } =
      values;
    const validate = validateReleaseFormValues({
      values: {
        ...rest,
        trackArtists,
        trackCollaborators,
      },
      schema: SchemaType.TRACK,
    });
    if (typeof validate !== 'boolean') {
      setFormErrors(Object.keys(validate));
    } else {
      setFormErrors([]);
      updateTrack({
        variables: {
          input: {
            ...rest,
            trackArtists: trackArtists.map(a => {
              const { royaltyRules, ...rest } = a;
              return {
                ...rest,
                royaltyRules: {
                  royaltyPercentage: Number(royaltyRules?.royaltyPercentage),
                  paymentAmount: royaltyRules?.paymentAmount,
                  royaltyType: royaltyRules?.royaltyType,
                  recoupableType: royaltyRules?.recoupableType,
                  recoupableAmount: royaltyRules?.recoupableAmount,
                },
              };
            }),
            trackCollaborators: trackCollaborators.map(a => {
              const { royaltyRules, ...rest } = a;
              return {
                ...rest,
                royaltyRules: {
                  royaltyPercentage: Number(royaltyRules?.royaltyPercentage),
                  paymentAmount: royaltyRules?.paymentAmount,
                  royaltyType: royaltyRules?.royaltyType,
                  recoupableType: royaltyRules?.recoupableType,
                  recoupableAmount: royaltyRules?.recoupableAmount,
                },
              };
            }),
          },
        },
      });
    }
  }

  if (error) {
    return <ViewError />;
  }

  const artistSelectObject = artistList?.listArtists?.map(artist => {
    return {
      value: artist?.id,
      label: artist?.name,
    };
  });

  const collaboratorSelectObject = collaboratorList?.listCollaborators?.map(
    collaborator => {
      return {
        value: collaborator?.id,
        label: collaborator?.name,
        role: collaborator?.type,
      };
    },
  );

  return (
    <>
      <AdminHeader title={t('adminDM.editTrack.title')} />
      {loading && <ViewLoader />}
      {!loading && data && (
        <TrackForm
          edit
          artists={artistSelectObject}
          collaborators={collaboratorSelectObject}
          values={parseSingularTrack(data?.track)}
          onSubmit={updateTrackHandler}
          errors={formErrors}
        />
      )}
    </>
  );
}
