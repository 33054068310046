import { GetStreamsByDateQuery } from '@/gql/generated-types';
import { format } from 'date-fns';

export const previousRangeLength = (start: Date, end: Date) =>
  getDaysDifference(start, end) + 1;

export function getDaysDifference(startDate: Date, endDate: Date) {
  const utcStartDate = Date.UTC(
    startDate.getFullYear(),
    startDate.getMonth(),
    startDate.getDate(),
  );
  const utcEndDate = Date.UTC(
    endDate.getFullYear(),
    endDate.getMonth(),
    endDate.getDate(),
  );

  const timeDifference = utcEndDate - utcStartDate;

  const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

  return daysDifference;
}

export function dateFrom(date: Date, decrement: number) {
  return new Date(date).setDate(new Date(date).getDate() - decrement);
}

export function generateChartData(streams: GetStreamsByDateQuery) {
  return streams?.getTotalStreamsByDate?.map((item, index) => {
    return {
      name: format(new Date(item?.date), 'dd.MM.yyyy'),
      streams: Number(item?.streams) ?? 0,
      position: index, // XAxisPosition - This is easier than handling everything date
    };
  });
}
