'use client';

import {
  RowData,
  SortingState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import { DataTableProps } from './types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

declare module '@tanstack/react-table' {
  interface CellContext<TData extends RowData, TValue> {
    handleReleaseDuplicate: (id: number) => void;
    handleReleasePromo: (id: number) => void;
    artistId: number | null;
  }
}

export function ReleaseTable<TData, TValue>({
  columns,
  data,
  artistId,
  handleReleasePromo,
  handleReleaseDuplicate,
}: DataTableProps<TData, TValue>) {
  const [rowSelection, setRowSelection] = useState({});
  const [sorting, setSorting] = useState<SortingState>([]);
  const table = useReactTable({
    data,
    columns,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    enableMultiRowSelection: false, // Disabled until I figure out how to handle rows that are disabled for missing pictures
    state: {
      rowSelection,
      sorting,
    },
  });

  const { t } = useTranslation();

  return (
    <div className="space-y-4">
      <Table className="border-collapse">
        <TableHeader>
          {table.getHeaderGroups().map(headerGroup => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map(header => {
                return (
                  <TableHead key={header.id} className="text-left">
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                  </TableHead>
                );
              })}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map((row, index) => (
              <TableRow
                key={row.id}
                variant={index % 2 === 0 ? 'odd' : null}
                data-state={row.getIsSelected() && 'selected'}
              >
                {row.getVisibleCells().map(cell => {
                  return (
                    <TableCell
                      key={cell.id}
                      className="p-4 align-middle [&:has([role=checkbox])]:pr-0"
                    >
                      {flexRender(cell.column.columnDef.cell, {
                        ...cell.getContext(),
                        handleReleaseDuplicate,
                        handleReleasePromo,
                        artistId,
                      })}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={columns.length} className="h-24 text-center">
                {t('releaseTable.noResults')}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
}
