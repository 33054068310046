import { ViewLoader } from '@/components/loaders/view-loader';
import { Button } from '@/components/ui/button';
import { DayPicker } from '@/components/ui/day-picker';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { NumberInput } from '@/components/ui/number-input';
import { GetReleaseByIdQuery } from '@/gql/generated-types';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as z from 'zod';

export const duplicateFormSchema = z.object({
  name: z.string().min(1),
  upcNumber: z.number().min(1),
  catalogNumber: z.string().min(1),
  releaseDate: z.string(),
});

type FormValues = z.infer<typeof duplicateFormSchema>;

export interface FormReturnValues extends Omit<FormValues, 'name'> {
  title: string;
  releaseId: number;
  releaseDate: string;
}

interface DuplicateFormProps {
  loading?: boolean;
  values: GetReleaseByIdQuery | undefined;
  originalReleaseId: number;
  onCancel: () => void;
  onSubmit: (values: FormReturnValues) => void;
}

export function DuplicateForm(props: DuplicateFormProps) {
  const { t } = useTranslation();
  const form = useForm<z.infer<typeof duplicateFormSchema>>({
    resolver: zodResolver(duplicateFormSchema),
    values: {
      name: props.values?.release?.name ?? '',
      upcNumber: 0,
      catalogNumber: '',
      releaseDate: props.values?.release?.releaseDate,
    },
  });

  if (props.loading) {
    return (
      <>
        <ViewLoader />
      </>
    );
  }

  function handleCreateDuplicate(values: FormValues): void {
    const returnValues = {
      upcNumber: values.upcNumber,
      catalogNumber: values.catalogNumber,
      title: values.name,
      releaseDate: values.releaseDate,
      releaseId: props.originalReleaseId,
    };

    props.onSubmit(returnValues);
  }

  return (
    <Form {...form}>
      <form
        className="space-y-6"
        onSubmit={form.handleSubmit(handleCreateDuplicate)}
      >
        <FormField
          control={form.control}
          name="name"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t('duplicateForm.name')}</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="upcNumber"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t('duplicateForm.upcNumber')}</FormLabel>
              <FormControl>
                <NumberInput
                  value={field.value}
                  onInputValueChange={value => field.onChange(value)}
                />
              </FormControl>
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="catalogNumber"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t('duplicateForm.cat')}</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="releaseDate"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t('duplicateForm.date')}</FormLabel>
              <FormControl>
                <DayPicker
                  onDateChange={value => field.onChange(value.toISOString())}
                  selectedDate={new Date(
                    field.value ?? new Date(),
                  ).toISOString()}
                />
              </FormControl>
            </FormItem>
          )}
        />
        <div className="flex justify-between space-x-2">
          <Button
            type="button"
            size="sm"
            variant="outline"
            onClick={() => props.onCancel()}
          >
            {t('duplicateForm.cancel')}
          </Button>
          <Button type="submit" size="sm">
            {t('duplicateForm.saveRelease')}
          </Button>
        </div>
      </form>
    </Form>
  );
}
