import { Img } from '@/components/ui/Img';
import { Button } from '@/components/ui/button';
import {
  EnhacedTableCell,
  EnhancedTable,
  EnhancedTableRow,
} from '@/components/ui/enhanced-table';
import { RadialCard } from '@/components/ui/radial-card';
import { Skeleton } from '@/components/ui/skeleton';
import { useGetArtistRevenueByTrackQuery } from '@/gql/generated-types';
import { cn, sumIncome } from '@/lib/utils';
import { format } from 'date-fns';
import { fi } from 'date-fns/locale';
import { AlertTriangle, ChevronDown, ChevronUp } from 'lucide-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorWhileFetchingDataForSeason } from './states';

type TrackListProps = {
  artistId: number;
  startDate: string;
  endDate: string;
};

export function TrackList(props: TrackListProps) {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);
  const { loading, data, error } = useGetArtistRevenueByTrackQuery({
    variables: {
      startDate: props.startDate,
      endDate: props.endDate,
      artistId: props.artistId,
    },
  });

  function TracksSkeleton() {
    return (
      <div className="space-y-2">
        <Skeleton className="w-full h-[50px]" />
        <Skeleton className="w-full h-[50px]" />
        <Skeleton className="w-full h-[50px]" />
      </div>
    );
  }

  const revenueList = data?.artistRevenueByTrack?.map(track =>
    Number(track?.revenue),
  );

  return (
    <>
      <h4 className="h4">{t('artistContracts.streamRevenue')}</h4>
      {loading && <TracksSkeleton />}
      {error && <ErrorWhileFetchingDataForSeason />}
      {!loading && data && (
        <>
          <EnhancedTable>
            {data?.artistRevenueByTrack?.map((item, index) => (
              <EnhancedTableRow
                role="row"
                key={item?.track?.id}
                variant={index % 2 !== 0 ? 'odd' : null}
                className={cn(
                  'overflow-hidden grid-cols-5 grid-flow-col',
                  index > 3 && !expanded && 'hidden',
                )}
              >
                <EnhacedTableCell className="justify-start px-0">
                  <Img
                    src={item?.track?.releaseCoverArt ?? ''}
                    alt={item?.track?.name ?? ''}
                    className="w-[56px]"
                    fallback={
                      <div className="w-[56px] h-[56px] bg-black z-10 relative flex items-center">
                        <AlertTriangle />
                      </div>
                    }
                  />
                </EnhacedTableCell>
                <EnhacedTableCell className="col-span-2 text-left justify-start">
                  <div className="text-sm block truncate w-full">
                    {item?.track?.name}
                  </div>
                </EnhacedTableCell>
                <EnhacedTableCell className="font-robotoMono text-lime text-sm flex col-span-2">
                  {new Intl.NumberFormat('fi-FI', {
                    style: 'currency',
                    currency: 'EUR',
                  }).format(item?.revenue)}
                </EnhacedTableCell>
              </EnhancedTableRow>
            ))}
          </EnhancedTable>
          {data.artistRevenueByTrack &&
            data.artistRevenueByTrack?.length > 4 && (
              <Button
                onClick={() => setExpanded(!expanded)}
                className="w-full"
                variant="secondary"
                size="sm"
              >
                {expanded
                  ? t('artistContracts.lessSongs')
                  : t('artistContracts.allSongs')}
                {expanded ? (
                  <ChevronUp className="h-6 w-6 ml-2" />
                ) : (
                  <ChevronDown className="h-6 w-6 ml-2" />
                )}
              </Button>
            )}
          <RadialCard variant="grey" bodyClassName="text-center space-y-6">
            <p className="font-bold text-xs">
              {t('artistContracts.streamRevenueBeforeExpenses')}{' '}
              {format(new Date(props.startDate), 'd.LLLL', { locale: fi })}{' '}
              {' - '}
              {format(new Date(props.endDate), 'd.LLLL', { locale: fi })}{' '}
              {t('artistContracts.fromSeason')}
            </p>
            <p className="font-bold font-mono text-xl text-lime">
              {new Intl.NumberFormat('fi-FI', {
                style: 'currency',
                currency: 'EUR',
              }).format(sumIncome(revenueList))}
            </p>
          </RadialCard>
        </>
      )}
    </>
  );
}
