import { cn } from '@/lib/utils';
import { useTranslation } from 'react-i18next';

export interface BarChartDataItem {
  label: string;
  value: number;
}

export type BarChartBarProps = BarChartDataItem & {
  totalValue: number;
};

export interface BarChartProps {
  data: BarChartDataItem[];
}

export const BarChartBar = ({ label, value, totalValue }: BarChartBarProps) => {
  const { t } = useTranslation();
  const percentageValue =
    value !== 0 && totalValue !== 0
      ? Math.trunc((value / totalValue) * 100)
      : 0;
  const valueLeftPosition = percentageValue >= 80 ? 90 : percentageValue + 5;
  const innerValueColorByPosition =
    percentageValue >= 80 ? 'text-grey-darkest' : 'text-white';

  return (
    <div>
      <p className="text-sm mb-2 capitalize">{t(`chartCats.${label}`)}</p>
      <div className="relative h-3.5 w-full bg-grey-darkest">
        <span
          className={cn(
            'absolute z-20 top-[-1px] text-xs',
            innerValueColorByPosition,
          )}
          style={{
            left: `${valueLeftPosition}%`,
            color: innerValueColorByPosition,
          }}
        >
          {percentageValue}%
        </span>
        <div
          className="absolute z-10 h-3.5 bg-purple-80"
          style={{ width: `${percentageValue}%` }}
        >
          {''}
        </div>
      </div>
    </div>
  );
};

export const BarChart = ({
  data,
}: {
  data: Array<[string, number | null]>;
}) => {
  const entries = Object.entries(data).filter(
    array => !array.some(element => element === '__typename'),
  );

  const totalValue = entries.find(array =>
    array.some(element => element === 'total'),
  );

  const values = entries.filter(
    array => !array.some(element => element === 'total'),
  );

  return (
    <div className="w-full space-y-6">
      {values.map((entry, index) => (
        <BarChartBar
          key={index}
          label={entry[0]}
          value={entry[1] ? Number(entry[1]) : 0}
          totalValue={Number(totalValue ? totalValue[1] : 0)}
        />
      ))}
    </div>
  );
};
