import RootLayout from '@/layout';
import NotFound from '@/views/404';
import Admin from '@/views/admin';
import AdminArtists from '@/views/admin/artists';
import CreateContract from '@/views/admin/data-management/contracts/create';
import CreateExpense from '@/views/admin/data-management/expenses/create';
import Releases from '@/views/admin/data-management/releases';
import Release from '@/views/admin/data-management/releases/release';
import CreateArtist from '@/views/admin/data-management/users/create-artist';
import CreateUser from '@/views/admin/data-management/users/create-user';
import Artist from '@/views/artist';
import ArtistRoyalties from '@/views/artist/balance';
import ArtistDashboard from '@/views/artist/dashboard';
import SongStreams from '@/views/artist/streams/song';
import ArtistStreams from '@/views/artist/streams';
import {
  RootRoute,
  Route,
  RouteComponent,
  Router,
} from '@tanstack/react-router';
import App from '../../App';
import {
  ADMIN,
  ADMIN_ARTIST,
  ADMIN_COLLABORATORS,
  ADMIN_CONTRACT,
  ADMIN_CONTRACTS,
  ADMIN_CREATE_ARTIST,
  ADMIN_CREATE_COLLABORATOR,
  ADMIN_CREATE_CONTRACT,
  ADMIN_CREATE_PAYMENT,
  ADMIN_CREATE_RELEASE,
  ADMIN_CREATE_TRACK,
  ADMIN_CREATE_TRACK_EDIT,
  ADMIN_CREATE_USER,
  ADMIN_EDIT_ARTIST,
  ADMIN_EDIT_CONTRACT,
  ADMIN_EDIT_RELEASE,
  ADMIN_EDIT_TRACK,
  ADMIN_LOG_EXPENSE,
  ADMIN_NON_MATCHED,
  ADMIN_PAYMENTS,
  ADMIN_RELEASE,
  ADMIN_RELEASES,
  ADMIN_TRACK,
  ADMIN_UPLOAD_ROYALTY_STATEMENT,
  ADMIN_USERS,
  ARTIST,
  ARTIST_BALANCE,
  ARTIST_BASE,
  ARTIST_STREAMS,
  ARTIST_STREAMS_SONG,
} from './routes';
import Contracts from '@/views/admin/data-management/contracts';
import Contract from '@/views/admin/data-management/contracts/contract';
import { CreateReleaseView } from '@/views/admin/data-management/releases/create';
import { EditReleaseView } from '@/views/admin/data-management/releases/edit';
import { CreateTrackView } from '@/views/admin/data-management/releases/create-track';
import { EditNonSavedTrackView } from '@/views/admin/data-management/releases/create-track/edit';
import { EditTrackView } from '@/views/admin/data-management/releases/edit-track';
import { UploadRoyaltyStatementView } from '@/views/admin/data-management/statement';
import EditContract from '@/views/admin/data-management/contracts/edit';
import Payments from '@/views/admin/data-management/payments';
import CreatePayment from '@/views/admin/data-management/payments/create';
import CreateCollaborator from '@/views/admin/data-management/users/create-collaborator';
import EditArtist from '@/views/admin/data-management/users/edit-artist';

import AdminCollaborators from '@/views/admin/artists/collaborators';
import AdminUsers from '@/views/admin/data-management/users';

import NonMatched from '@/views/admin/non-matched';
import Track from '@/views/admin/data-management/releases/track';

const rootRoute = new RootRoute({
  component: RootLayout,
});

function routeWithRoot(
  path: string,
  Component: RouteComponent<NonNullable<unknown>> | undefined,
) {
  return new Route({
    getParentRoute: () => rootRoute,
    path,
    component: Component,
  });
}

// Admin routes
const adminIndex = routeWithRoot(ADMIN, Admin);
const adminArtists = routeWithRoot(ADMIN_ARTIST, AdminArtists);
const adminReleases = routeWithRoot(ADMIN_RELEASES, Releases);
const adminRelease = routeWithRoot(ADMIN_RELEASE, Release);
const adminCreateContract = routeWithRoot(
  ADMIN_CREATE_CONTRACT,
  CreateContract,
);
const adminEditContract = routeWithRoot(ADMIN_EDIT_CONTRACT, EditContract);
const adminLogExpense = routeWithRoot(ADMIN_LOG_EXPENSE, CreateExpense);
const adminCreateArtist = routeWithRoot(ADMIN_CREATE_ARTIST, CreateArtist);
const adminEditArtist = routeWithRoot(ADMIN_EDIT_ARTIST, EditArtist);
const adminCreateCollaborator = routeWithRoot(
  ADMIN_CREATE_COLLABORATOR,
  CreateCollaborator,
);
const adminCreateUser = routeWithRoot(ADMIN_CREATE_USER, CreateUser);
const adminContracts = routeWithRoot(ADMIN_CONTRACTS, Contracts);
const adminContract = routeWithRoot(ADMIN_CONTRACT, Contract);
const adminCreateRelease = routeWithRoot(
  ADMIN_CREATE_RELEASE,
  CreateReleaseView,
);
const adminUsers = routeWithRoot(ADMIN_USERS, AdminUsers);
const adminCreateTrack = routeWithRoot(ADMIN_CREATE_TRACK, CreateTrackView);
const adminCreateTrackEdit = routeWithRoot(
  ADMIN_CREATE_TRACK_EDIT,
  EditNonSavedTrackView,
);
const adminEditRelease = routeWithRoot(ADMIN_EDIT_RELEASE, EditReleaseView);
const adminEditTrack = routeWithRoot(ADMIN_EDIT_TRACK, EditTrackView);
const adminUploadRoyaltyStatement = routeWithRoot(
  ADMIN_UPLOAD_ROYALTY_STATEMENT,
  UploadRoyaltyStatementView,
);
const adminCollaborators = routeWithRoot(
  ADMIN_COLLABORATORS,
  AdminCollaborators,
);
const adminPayments = routeWithRoot(ADMIN_PAYMENTS, Payments);
const adminCreatePayment = routeWithRoot(ADMIN_CREATE_PAYMENT, CreatePayment);
const adminNonMatched = routeWithRoot(ADMIN_NON_MATCHED, NonMatched);
const adminTrack = routeWithRoot(ADMIN_TRACK, Track);

// Artist routes
const artistIndex = routeWithRoot(ARTIST, Artist);
const artistDashboard = routeWithRoot(ARTIST_BASE, ArtistDashboard);
const artistBalance = routeWithRoot(ARTIST_BALANCE, ArtistRoyalties);
const artistStreams = routeWithRoot(ARTIST_STREAMS, ArtistStreams);
const artistSongStreams = routeWithRoot(ARTIST_STREAMS_SONG, SongStreams);

// Generic routes
const notFound = routeWithRoot('*', NotFound);
const indexRoute = routeWithRoot('/', App);

const adminRoutes = [
  adminTrack,
  adminIndex,
  adminArtists,
  adminReleases,
  adminCreateTrackEdit,
  adminCreateCollaborator,
  adminUsers,
  adminCreatePayment,
  adminEditArtist,
  adminCollaborators,
  adminNonMatched,
  adminCreateTrack,
  adminEditTrack,
  adminRelease,
  adminPayments,
  adminEditContract,
  adminCreateContract,
  adminUploadRoyaltyStatement,
  adminCreateUser,
  adminCreateArtist,
  adminEditRelease,
  adminCreateRelease,
  adminContracts,
  adminContract,
  adminLogExpense,
];

const artistRoutes = [
  artistIndex,
  artistDashboard,
  artistBalance,
  artistStreams,
  artistSongStreams,
];

const routeTree = rootRoute.addChildren([
  indexRoute,
  notFound,
  ...artistRoutes,
  ...adminRoutes,
]);

export const router = new Router({ routeTree });
