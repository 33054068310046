import { Button } from '@/components/ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Switch } from '@/components/ui/switch';
import {
  GetArtistProfileQuery,
  GetCompassPersonsQuery,
} from '@/gql/generated-types';
import {
  DropdownIndicator,
  multiSelectStyles,
} from '@/styles/multi-select.styles';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { default as MultiSelect, MultiValue } from 'react-select';
import { z } from 'zod';
import { parseArtistFromGqlToForm } from './util';
import { createArtistSchema } from './schema';
type CreateArtistFormProps = {
  data: GetCompassPersonsQuery;
  artist?: GetArtistProfileQuery;
  onSubmit: (values: z.infer<typeof createArtistSchema>) => void;
  edit?: boolean;
};

type PersonsList =
  | {
      value: number | undefined;
      label: string;
    }[]
  | undefined;

export function CreateArtistForm({
  data,
  onSubmit,
  artist,
  edit,
}: CreateArtistFormProps) {
  const { t } = useTranslation();
  const form = useForm<z.infer<typeof createArtistSchema>>({
    resolver: zodResolver(createArtistSchema),
    defaultValues: parseArtistFromGqlToForm(artist),
  });

  const contactPersonsMappedList: PersonsList = data?.persons?.map(person => {
    return {
      label: `${person?.contractPerson.firstName} ${person?.contractPerson.lastName}`,
      value: person?.id,
    };
  });

  const handleCreateArtist = async (
    data: z.infer<typeof createArtistSchema>,
  ) => {
    onSubmit(data);
  };

  return (
    <div>
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(handleCreateArtist)}
          className="space-y-12"
        >
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-12 gap-y-4">
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t('createArtistForm.name')}</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="costCenter"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t('createArtistForm.costCenter')}</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="persons"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t('createArtistForm.contractPerson')}</FormLabel>
                  <FormControl>
                    <MultiSelect<
                      typeof contactPersonsMappedList | unknown,
                      true
                    >
                      placeholder={t('createArtistForm.selectContractPerson')}
                      components={{ DropdownIndicator }}
                      classNames={multiSelectStyles}
                      value={field.value.map(person => {
                        return {
                          label: contactPersonsMappedList?.find(
                            p => Number(p.value) === person,
                          )?.label,
                          value: person,
                        };
                      })}
                      onChange={(
                        value: MultiValue<
                          typeof contactPersonsMappedList | unknown
                        >,
                      ) => {
                        // @ts-expect-error ts-migrate(2339) FIXME: 'person' is of type 'unknown'
                        field.onChange(value.map(person => person.value));
                      }}
                      isMulti={true}
                      unstyled
                      name="artistSelect"
                      options={contactPersonsMappedList}
                      hideSelectedOptions={false}
                    />
                  </FormControl>
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="active"
              render={({ field }) => (
                <FormItem className="flex items-center space-y-0 space-x-4 mt-4">
                  <FormLabel>{t('createArtistForm.active')}</FormLabel>
                  <FormControl>
                    <Switch
                      checked={field.value}
                      onCheckedChange={field.onChange}
                    />
                  </FormControl>
                </FormItem>
              )}
            />
          </div>
          <Button>
            {!edit
              ? t('createArtistForm.createArtistSaveButton')
              : t('createArtistForm.editArtistSaveButton')}
          </Button>
        </form>
      </Form>
    </div>
  );
}
