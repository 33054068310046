import { cn } from '@/lib/utils';
import { Ref, createRef, forwardRef, useImperativeHandle } from 'react';
import RoyaltySplitItem from './royalty-split-item';
import { TrackArtist } from '@/services/store/releases/types';

export type RoyaltySplitFormProps = {
  selectedArtist: {
    royaltyRules: {
      paymentAmount: number;
      recoupableAmount: number;
      royaltyPercentage: number;
      royaltyType: string;
      recoupableType?: string;
    };
    type: string;
    artistId: number;
  }[];
  artists: ArtistList;
};

export type RoyaltySplitFormHandle = {
  getFormData: () => false | (TrackArtist | undefined)[];
};

export type RoyaltyFormItemHandle = {
  getFormData: () => TrackArtist | false;
};

const RoyaltySplitForm = (
  props: RoyaltySplitFormProps,
  ref: Ref<RoyaltySplitFormHandle>,
) => {
  const itemRefs = props.selectedArtist?.map(() =>
    createRef<RoyaltyFormItemHandle>(),
  );

  useImperativeHandle(
    ref,
    () => {
      return {
        getFormData() {
          const isError = props.selectedArtist.map((_, i) => {
            const data = itemRefs[i]!.current!.getFormData();

            if (typeof data === 'boolean') {
              return 'error';
            }

            return null;
          });

          if (!isError.includes('error')) {
            return props.selectedArtist
              .map((_, i) => {
                const data = itemRefs[i]!.current!.getFormData();
                if (typeof data !== 'boolean') {
                  return data;
                }
              })
              .filter(i => i !== undefined);
          } else {
            return false;
          }
        },
      };
    },
    [itemRefs, props.selectedArtist],
  );

  return (
    <div>
      {props.selectedArtist?.map((a, index) => {
        return (
          <div
            className={cn(index % 2 === 0 ? 'bg-grey-darkest' : 'bg-black')}
            key={index}
          >
            <RoyaltySplitItem
              ref={itemRefs[index]}
              selectedArtist={a}
              artistName={
                props.artists?.find(b => b.value === a.artistId)?.label ?? ''
              }
            />
          </div>
        );
      })}
    </div>
  );
};

export default forwardRef(RoyaltySplitForm);
