import { AdminHeader } from '@/components/partials/admin-header';
import { ReleaseForm } from '../forms/release';
import { useAtom } from 'jotai/react';
import {
  initialReleaseData,
  releaseAtom,
} from '@/services/store/releases/releases';
import { tracksAtom } from '@/services/store/releases/tracks';
import { useGetArtistListQuery } from '@/gql/generated-types';
import { Release, ReleaseTrack } from '@/services/store/releases/types';
import { CREATE_RELEASE } from '@/graphql/mutations';
import { useMutation } from '@apollo/client';
import { toast } from '@/components/ui/use-toast';
import { useNavigate } from '@tanstack/react-router';
import { useEffect, useState } from 'react';
import { SchemaType, validateReleaseFormValues } from '../util/validation';
import { useTranslation } from 'react-i18next';

export function CreateReleaseView() {
  const [release, setRelease] = useAtom(releaseAtom);
  const [tracks] = useAtom(tracksAtom);
  const navigate = useNavigate();
  const [formErrors, setFormErrors] = useState<string[]>([]);
  const { t } = useTranslation();
  const { data: artistList } = useGetArtistListQuery();
  // If release edit is not cleared before entering, clear all values
  useEffect(() => {
    if (release?.id) {
      setRelease(initialReleaseData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const artistSelectObject = artistList?.listArtists?.map(artist => {
    return {
      value: artist?.id,
      label: artist?.name,
    };
  });

  const [createRelease] = useMutation(CREATE_RELEASE, {
    onError: _ => {
      toast({
        variant: 'destructive',
        title: t('toast.genericErrorTitle'),
        description: t('toast.createErrorDescription', {
          item: t('toast.items.release'),
        }),
      });
    },
    onCompleted: data => {
      toast({
        title: t('toast.genericSaveSuccessTitle'),
        description: t('toast.genericSaveSuccessDescription', {
          item: t('toast.items.release'),
        }),
      });
      setRelease(initialReleaseData);
      navigate({
        to: `/admin/data-management/releases/${data.upsertRelease.id}`,
      });
    },
  });

  function createReleaseHandler() {
    // Validate data, return errors

    // No errors mutate
    const { upcNumber, ...rest } = release;
    const rel = {
      ...rest,
      // Remove throw away id from tracks if not in edit mode
      tracks: tracks.map((track: ReleaseTrack, index: number) => {
        const {
          id,
          contractArtistId,
          trackArtists,
          order,
          trackCollaborators,
          ...restTrack
        } = track;

        return {
          ...restTrack,
          order: index + 1,
          trackArtists: trackArtists.map(a => {
            const { royaltyRules, ...rest } = a;
            return {
              ...rest,
              royaltyRules: {
                royaltyPercentage: Number(royaltyRules?.royaltyPercentage),
                paymentAmount: royaltyRules?.paymentAmount,
                royaltyType: royaltyRules?.royaltyType,
                recoupableType: royaltyRules?.recoupableType,
                recoupableAmount: royaltyRules?.recoupableAmount,
              },
            };
          }),
          trackCollaborators: trackCollaborators.map(a => {
            const { royaltyRules, ...rest } = a;
            return {
              ...rest,
              royaltyRules: {
                royaltyPercentage: Number(royaltyRules?.royaltyPercentage),
                paymentAmount: royaltyRules?.paymentAmount,
                royaltyType: royaltyRules?.royaltyType,
                recoupableType: royaltyRules?.recoupableType,
                recoupableAmount: royaltyRules?.recoupableAmount,
              },
            };
          }),
        };
      }),
      upcNumber: Number(upcNumber),
    };

    const { tracks: relTrack, releaseDate, ...validationRel } = rel;

    const validate = validateReleaseFormValues({
      values: {
        releaseDate: new Date(releaseDate),
        ...validationRel,
      },
      schema: SchemaType.RELEASE,
    });

    // If validate doesnt return boolean, its an error
    if (typeof validate !== 'boolean') {
      setFormErrors(Object.keys(validate));
    } else {
      setFormErrors([]);
      createRelease({
        variables: {
          input: rel,
        },
      });
    }
  }

  return (
    <div className="container space-y-12">
      <AdminHeader title={t('adminDM.createRelease.title')} />
      <div className="space-y-12">
        {!release.id && (
          <ReleaseForm
            values={release}
            artists={artistSelectObject}
            errors={formErrors}
            tracks={tracks}
            resetFunction={() => setRelease(initialReleaseData)}
            onSubmit={() => createReleaseHandler()}
            onFieldChange={(field, value) =>
              setRelease((prev: Release) => ({
                ...prev,
                [field]: value,
              }))
            }
          />
        )}
      </div>
    </div>
  );
}
