import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';
import * as React from 'react';

import { cn } from '@/lib/utils';

const buttonVariants = cva(
  [
    'inline-flex',
    'uppercase',
    'rounded-full',
    'font-roboto',
    'items-center',
    'justify-center',
    'transition-colors',
    'focus-visible:outline-none',
    'disabled:pointer-events-none',
    'disabled:opacity-50',
  ],
  {
    variants: {
      variant: {
        default:
          'bg-yellow-dark hover:bg-yellow-neon focus:bg-yellow-neon !text-grey-darkest',
        destructive: 'bg-red-500 text-slate-50 hover:bg-red-500/90',
        outline:
          'border border-bone bg-grey-darkest hover:bg-grey-dark focus:bg-grey-dark text-bone',
        secondary:
          'bg-grey-black hover:bg-grey-dark focus:bg-grey-dark text-bone',
        ghost: 'bg-transparent text-bone hover:bg-grey-dark focus:bg-grey-dark',
        link: 'bg-transparent text-bone hover:text-yellow-dark rounded-none px-0',
      },
      size: {
        default: 'h-10 px-5  py-2 text-base font-medium tracking-[1.6px]',
        sm: 'h-9 font-medium px-4 tracking-[1.5px] text-xss',
        icon: 'h-10 w-10',
        iconSmall: 'h-8 w-8',
        link: 'h-10 px-0 py-2 text-base font-medium tracking-[1.6px]',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  },
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : 'button';
    return (
      <Comp
        className={cn(className, buttonVariants({ variant, size }))}
        ref={ref}
        {...props}
      />
    );
  },
);
Button.displayName = 'Button';

export { Button, buttonVariants };
