import { GetStreamsQuery } from '@/gql/generated-types';
import { format } from 'date-fns';

// Base selection dates used in multiple views
export const currentDate = new Date(
  new Date().setDate(new Date().getDate() - 5),
);
export const pastDate = new Date(
  new Date(currentDate).setDate(currentDate.getDate() - 30),
);

// Prevous range end date: - 1 days from pastDate
export const previousRangeEnd = new Date(
  new Date(pastDate).setDate(pastDate.getDate() - 1),
);
// Previous range start date: - 30 days from previousRangeEnd
export const previousRangeStart = new Date(
  new Date(previousRangeEnd).setDate(previousRangeEnd.getDate() - 30),
);

// Get stream item with highest streamCount, if all are 0 or there is a tie, return the first one
export function getObjectWithHighestStreamCount(items: GetStreamsQuery) {
  return items?.streamsForArtistByTrack?.reduce((acc, obj) => {
    const currentCount = obj?.totalStreams || 0;
    const highestCount = acc?.totalStreams || 0;

    if (
      currentCount > highestCount ||
      (currentCount === highestCount && acc === null)
    ) {
      return obj;
    }

    return acc;
  }, null);
}

export function parseDashboardYAxisLabel(label: string) {
  if (Number(label) >= 1000000) {
    return (Number(label) / 1000000).toFixed(0) + 'M';
  } else if (Number(label) >= 1000) {
    return (Number(label) / 1000).toFixed(0) + 'K';
  } else {
    return Number(label).toString();
  }
}

export function parseXAxisLabel(label: string, start: string, end: string) {

  if (Number(label) === 0) {
    return format(new Date(start), 'dd.MM.yyyy');
  }

  return format(new Date(end), 'dd.MM.yyyy');
}
