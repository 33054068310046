import { ViewError } from '@/components/errors/view-error';
import { ViewLoader } from '@/components/loaders/view-loader';
import { useGetArtistByEmailQuery } from '@/gql/generated-types';
import { checkUserRole } from '@/services/authentication/clerk';
import { useSession } from '@clerk/clerk-react';
import { ActiveSessionResource } from '@clerk/types';
import { useNavigate } from '@tanstack/react-router';

export default function Artist() {
  const { session } = useSession();
  const userRole = checkUserRole(session as ActiveSessionResource);
  const navigate = useNavigate();
  if (!session && userRole === 'artist') {
    navigate({ to: '/' });
  }

  const { error, loading, data } = useGetArtistByEmailQuery({
    variables: {
      email: session?.user?.primaryEmailAddress?.emailAddress as string,
    },
  });

  if (data?.artistsByEmail?.length === 1) {
    const artistId = data?.artistsByEmail[0]?.id as number;
    navigate({ to: `/artist/${artistId}` });
  }

  if (loading) {
    return (
      <div
        className={
          'flex flex-col items-center p-24 max-w-[800px] container space-y-12'
        }
      >
        <ViewLoader />
      </div>
    );
  }

  if (error) {
    <div
      className={
        'flex flex-col items-center p-24 max-w-[800px] container space-y-12'
      }
    >
      <ViewError />
    </div>;
  }

  return (
    <main
      className={
        'flex flex-col items-center p-24 max-w-[800px] container space-y-12'
      }
    >
      {data?.artistsByEmail &&
        data?.artistsByEmail?.length > 1 &&
        data.artistsByEmail.map(artist => (
          <div className="max-w-[600px] w-full" key={artist?.id}>
            <a href={`/artist/${artist?.id}`}>
              <div className="rounded-[20px] overflow-hidden w-full flex items-center space-between h-30 bg-grey-darkest hover:bg-grey-darkest/50">
                <div className="basis-2/5 overflow-hidden">
                  <img
                    width="300"
                    height="300"
                    src={artist?.imageUrl ?? '/assets/mock_3.png'}
                    alt="cover"
                    className="object-contain"
                  />
                </div>
                <div className="space-y-4 p-8 basis-3/5">
                  <h3 className="text-2xl font-bold">{artist?.name}</h3>
                </div>
              </div>
            </a>
          </div>
        ))}
    </main>
  );
}
