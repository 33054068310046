import { buttonVariants } from '@/components/ui/button';
import { RadialCard } from '@/components/ui/radial-card';
import { useGetArtistProfileQuery } from '@/gql/generated-types';
import { cn } from '@/lib/utils';
import { Link, useParams } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';
import {
  currentDate,
  pastDate,
  previousRangeEnd,
  previousRangeStart,
} from '../util';
import { format } from 'date-fns';
import { Suspense } from 'react';
import { StreamChart } from '../shared/stream-chart';
import { StreamBox } from './components/stream-box';
import { ViewError } from '@/components/errors/view-error';
import { BoxSkeleton } from './components/box-skeleton';
import { Skeleton } from '@/components/ui/skeleton';
import { Song } from '../shared/song';
import { ARTIST_BALANCE } from '@/services/router/routes';

export default function ArtistDashboard() {
  const { t } = useTranslation();
  const { artistId } = useParams({ strict: false });
  const { data, error, loading } = useGetArtistProfileQuery({
    variables: {
      artistId: Number(artistId),
    },
  });

  if (error) {
    return (
      <div className=" space-y-12 container">
        <ViewError />
      </div>
    );
  }

  return (
    <>
      <div className="hidden md:block md:absolute top-0 z-[-1] w-full max-h-[600px] min-h-[400px] overflow-hidden">
        <img
          src={data?.artist?.imageUrl ?? ''}
          className="relative z-[-1] object-cover w-full"
        />
        <div className="absolute bottom-0 w-full z-[1] h-[200px] bg-gradient-to-t from-black"></div>
      </div>
      <div className="mt-[140px] md:mt-[200px] space-y-12 container max-w-[1040px]">
        {loading && <Skeleton className="w-full h-[75px]" />}
        <h1 className="h1 text-center">{data?.artist?.name}</h1>
        <div className="grid grid-col-1 md:grid-cols-2 gap-12">
          <div className="col-span-1 md:col-span-2">
            <Suspense fallback={<BoxSkeleton />}>
              <StreamBox
                artistId={Number(artistId)}
                startDate={pastDate.toISOString()}
                endDate={currentDate.toISOString()}
              />
            </Suspense>
          </div>
          <div>
            <h4 className="h4">
              {t('artistPages.dashboard.mostStreamedSong')}
            </h4>
            <Suspense fallback={<Skeleton className="w-full h-[400px]" />}>
              <Song
                artistId={Number(artistId)}
                startDate={pastDate.toISOString()}
                endDate={currentDate.toISOString()}
              />
            </Suspense>
          </div>
          <div className="flex flex-col space-y-4">
            <h4 className="h4">
              {t('artistPages.dashboard.streamComparison')}
            </h4>
            <div className="flex justify-start text-xs items-center space-x-2">
              <div>
                <span className="mr-2 inline-flex w-2 h-2 bg-[#D77EF5]"></span>
                {format(pastDate, 'dd.MM')}
                {' - '}
                {format(currentDate, 'dd.MM')}
              </div>
              <div>
                <span className="mr-2 inline-flex w-2 h-2 bg-[#FFCA10]"></span>
                {format(previousRangeStart, 'dd.MM')}
                {' - '}
                {format(previousRangeEnd, 'dd.MM')}
              </div>
            </div>
            <Suspense fallback={<Skeleton className="w-full h-[400px]" />}>
              <StreamChart
                artistId={Number(artistId)}
                startDate={pastDate.toISOString()}
                endDate={currentDate.toISOString()}
                prevEndDate={previousRangeEnd.toISOString()}
                prevStartDate={previousRangeStart.toISOString()}
              />
            </Suspense>
          </div>
          <div className="col-span-1 md:col-span-2">
            <RadialCard
              variant="orange"
              bodyClassName="lg:flex space-y-6 lg:space-y-0 items-center justify-between"
            >
              <Link
                to={ARTIST_BALANCE}
                params={{
                  artistId,
                }}
              >
                <h2 className="h2">
                  {' '}
                  {t('artistPages.dashboard.balanceTitle')}
                </h2>
              </Link>

              <p className="font-robotoMono">
                {t('artistPages.dashboard.billingSeason')}
              </p>
              <Link
                to={ARTIST_BALANCE}
                params={{
                  artistId,
                }}
                className={cn(buttonVariants({ variant: 'default' }))}
              >
                {t('artistPages.dashboard.showMoreCTA')}
              </Link>
            </RadialCard>
          </div>
        </div>
      </div>
    </>
  );
}
