import { forwardRef } from 'react';
import { SortableInnerItemProps } from './types';
import { cn } from '@/lib/utils';
import {
  EnhacedTableCell,
  EnhancedTableRow,
} from '@/components/ui/enhanced-table';
import { GripHorizontal, Pencil, X } from 'lucide-react';
import { Button, buttonVariants } from '@/components/ui/button';
import { ADMIN_CREATE_TRACK_EDIT } from '@/services/router/routes';
import { Link } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';

export const Item = forwardRef<HTMLTableRowElement, SortableInnerItemProps>(
  (
    { item, isDragging, listOfArtists, onDelete, edit, disabledDnd, ...props },
    ref,
  ) => {
    const trackArtists = item!.trackArtists!.map(artist => {
      return listOfArtists?.find(a => a.value === artist.artistId)?.label;
    });
    const { t } = useTranslation();
    return (
      <EnhancedTableRow
        className={cn(
          'h-[56px] grid grid-cols-tracksList overflow-x-hidden',
          isDragging ? 'grabbing opacity-70' : 'grab opacity-1',
        )}
        variant={props.index % 2 === 0 ? 'odd' : null}
        ref={ref}
        {...props}
      >
        <EnhacedTableCell className="justify-start text-left">
          <span>
            {props.index + 1}. {item.name}
          </span>
        </EnhacedTableCell>
        <EnhacedTableCell className="justify-start text-left pl-0">
          <span>{trackArtists?.map(artist => artist).join(', ')} </span>
        </EnhacedTableCell>
        <EnhacedTableCell className="text-center">{item.isrc}</EnhacedTableCell>
        <EnhacedTableCell className="text-right justify-end space-x-2">
          {!edit && (
            <Link
              to={`${ADMIN_CREATE_TRACK_EDIT}`}
              params={{
                trackId: item.id,
              }}
              className={buttonVariants({ variant: 'outline', size: 'sm' })}
            >
              <Pencil className="w-3 h-3 mr-2" />
              {t('trackForm.edit')}
            </Link>
          )}
          {/* NOTE: Deleting a track while editing release does not work */}
          {!edit && (
            <Button
              variant="destructive"
              size="sm"
              onClick={() => onDelete(item)}
            >
              <X className="w-3 h-3 mr-2" />
              {t('trackForm.delete')}
            </Button>
          )}
          <span>
            {!disabledDnd && <GripHorizontal className="text-bone ml-2" />}
          </span>
        </EnhacedTableCell>
      </EnhancedTableRow>
    );
  },
);
