import { AdminHeader } from '@/components/partials/admin-header';
import { Label } from '@/components/ui/label';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
} from '@/components/ui/select';
import {
  useGetArtistListQuery,
  useGetReleaseByIdLazyQuery,
  useGetReleasesQuery,
} from '@/gql/generated-types';

import { X } from 'lucide-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormDialog } from './components/form-dialog';
import { DuplicateForm, FormReturnValues } from './forms/duplicate';
import { useMutation } from '@apollo/client';
import { CREATE_DUPLICATE, CREATE_PROMO } from '@/graphql/mutations';
import { toast } from '@/components/ui/use-toast';
import { useNavigate, useSearch } from '@tanstack/react-router';
import { ADMIN_CREATE_RELEASE, ADMIN_RELEASES } from '@/services/router/routes';
import { PromoFormReturnValues, PromoForm } from './forms/promo';
import { Checkbox } from '@/components/ui/checkbox';
import { ViewLoader } from '@/components/loaders/view-loader';
import { Button } from '@/components/ui/button';
import { ReleaseTable } from '@/components/data-tables/releases/table';
import { cols } from '@/components/data-tables/releases/columns';

interface ObjectToFilter {
  duplicateOf: boolean;
  promoOf: boolean;
  original: boolean;
}

export default function Releases() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { artistId } = useSearch({ strict: false });
  const [dialogState, setDialogState] = useState<{
    duplicate: boolean;
    promo: boolean;
  }>({
    duplicate: false,
    promo: false,
  });

  const [selectedArtist, setSelectedArtist] = useState<string>(artistId ?? '');
  const [filtering, setFiltering] = useState<ObjectToFilter>({
    duplicateOf: false,
    promoOf: false,
    original: false,
  });

  const { data, loading } = useGetReleasesQuery({
    variables: {
      artistId: Number(selectedArtist),
    },
    fetchPolicy: 'no-cache',
  });

  const [refetch, { data: selectedRelease }] = useGetReleaseByIdLazyQuery({
    fetchPolicy: 'no-cache',
  });

  const { data: artistList } = useGetArtistListQuery();

  const [createDuplicate] = useMutation(CREATE_DUPLICATE, {
    onError(_) {
      toast({
        variant: 'destructive',
        title: t('toast.genericErrorTitle'),
        description: t('toast.createDuplicateErrorDescription'),
      });
    },
    onCompleted(data) {
      toast({
        title: t('toast.genericSaveSuccessTitle'),
        description: t('toast.genericSaveSuccessDescription', {
          item: t('toast.items.duplicate'),
        }),
      });
      navigate({ to: `${ADMIN_RELEASES}/${data.createDuplicateRelease.id}` });
    },
  });

  const [createPromo] = useMutation(CREATE_PROMO, {
    onError(_) {
      toast({
        variant: 'destructive',
        title: t('toast.genericErrorTitle'),
        description: t('toast.createErrorDescription', {
          item: t('toast.items.promo'),
        }),
      });
    },
    onCompleted(data) {
      toast({
        title: t('toast.genericSaveSuccessTitle'),
        description: t('toast.genericSaveSuccessDescription', {
          item: t('toast.items.promo'),
        }),
      });
      navigate({ to: `${ADMIN_RELEASES}/${data.createPromoRelease.id}` });
    },
  });

  function handleCreateDuplicate(values: FormReturnValues) {
    createDuplicate({
      variables: {
        input: values,
      },
    });
  }

  function handleCreatePromo(values: PromoFormReturnValues) {
    createPromo({
      variables: {
        input: values,
      },
    });
  }

  const filteredRelease = data?.releases?.filter(
    rel =>
      (!filtering.original ||
        rel?.duplicateOf !== null ||
        rel?.promoOf !== null) &&
      (!filtering.duplicateOf || rel?.duplicateOf === null) &&
      (!filtering.promoOf || rel?.promoOf === null),
  );

  return (
    <>
      <AdminHeader
        title={t('adminReleases.title')}
        actionLink={{
          link: ADMIN_CREATE_RELEASE,
          label: t('adminReleases.create'),
        }}
      />
      <div className="flex justify-between w-full items-center">
        <div className="max-w-[35%] w-full space-y-4">
          <Label>{t('adminReleases.selectArtist')}</Label>
          <div className="flex space-x-2 items-center">
            <Select
              value={selectedArtist.toString()}
              onValueChange={value => {
                if (value === '') {
                  setSelectedArtist('');
                } else {
                  setSelectedArtist(value);
                  navigate({
                    search: { artistId: JSON.parse(value) },
                  });
                }
              }}
            >
              <SelectTrigger>
                {selectedArtist
                  ? artistList?.listArtists?.find(
                      artist =>
                        artist?.id.toString() === selectedArtist.toString(),
                    )?.name
                  : t('adminReleases.selectArtist')}
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="">{t('allArtists')}</SelectItem>
                {artistList?.listArtists?.map(artist => {
                  if (artist === null || artist.id === null) return;

                  return (
                    <SelectItem
                      value={artist.id.toString()}
                      key={artist.id.toString()}
                    >
                      {artist?.name}
                    </SelectItem>
                  );
                })}
              </SelectContent>
            </Select>
            {selectedArtist !== null && (
              <Button
                size="icon"
                variant="link"
                onClick={() => {
                  setSelectedArtist('');
                  navigate({
                    search: {},
                  });
                }}
              >
                <X className="w-4 h-4" />
              </Button>
            )}
          </div>
        </div>
        <div className="space-y-4">
          <Label>{t('adminReleases.hideReleases')}</Label>
          <div className="flex space-x-4 items-center">
            <div className="space-x-2 flex items-center">
              <Checkbox
                id="original"
                checked={filtering.original}
                onCheckedChange={() =>
                  setFiltering({
                    ...filtering,
                    original: !filtering.original,
                  })
                }
              />
              <Label htmlFor="duplicateOf">{t('adminReleases.original')}</Label>
            </div>
            <div className="space-x-2 flex items-center">
              <Checkbox
                id="duplicateOf"
                checked={filtering.duplicateOf}
                onCheckedChange={() =>
                  setFiltering({
                    ...filtering,
                    duplicateOf: !filtering.duplicateOf,
                  })
                }
              />
              <Label htmlFor="duplicateOf">Duplicate</Label>
            </div>
            <div className="space-x-2 flex items-center">
              <Checkbox
                checked={filtering.promoOf}
                id="promoOf"
                onCheckedChange={() =>
                  setFiltering({
                    ...filtering,
                    promoOf: !filtering.promoOf,
                  })
                }
              />
              <Label htmlFor="promoOf">Promo</Label>
            </div>
          </div>
        </div>
      </div>
      {loading && <ViewLoader />}
      {filteredRelease && (
        <ReleaseTable
          handleReleaseDuplicate={id => {
            setDialogState({
              promo: false,
              duplicate: true,
            });
            // TODO: Load release info by id
            refetch({
              variables: {
                releaseId: id,
              },
            });
          }}
          handleReleasePromo={id => {
            setDialogState({
              promo: true,
              duplicate: false,
            });
            // TODO: Load release info by id
            refetch({
              variables: {
                releaseId: id,
              },
            });
          }}
          artistId={artistId}
          columns={cols}
          data={filteredRelease.map(release => {
            return {
              id: Number(release?.id),
              name: release?.name ?? '',
              releaseType: release?.releaseType,
              releaseArtists:
                release?.releaseArtists.map(i => i.name).join(', ') ?? '',
              releaseDate: release?.releaseDate,
              promoOf: release?.promoOf,
              duplicateOf: release?.duplicateOf,
            };
          })}
        />
      )}
      <FormDialog
        open={dialogState.duplicate}
        type="duplicate"
        title={t('adminReleases.createDuplicate')}
        onOpenChangeAction={open => {
          setDialogState({
            promo: false,
            duplicate: open,
          });
        }}
      >
        <DuplicateForm
          loading={loading}
          onCancel={() =>
            setDialogState({
              promo: false,
              duplicate: false,
            })
          }
          values={selectedRelease}
          originalReleaseId={Number(selectedRelease?.release?.id)}
          onSubmit={values => handleCreateDuplicate(values)}
        />
      </FormDialog>
      <FormDialog
        type="promo"
        title={t('adminReleases.createPromo', {
          release: selectedRelease?.release?.name,
        })}
        open={dialogState.promo}
        onOpenChangeAction={open => {
          setDialogState({
            promo: open,
            duplicate: false,
          });
        }}
      >
        <PromoForm
          values={selectedRelease}
          originalReleaseId={Number(selectedRelease?.release?.id)}
          onCancel={() =>
            setDialogState({
              promo: false,
              duplicate: false,
            })
          }
          onSubmit={values => handleCreatePromo(values)}
        />
      </FormDialog>
    </>
  );
}
