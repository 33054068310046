import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import { PropsWithChildren, useEffect, useState } from 'react';

interface FormDialogProps extends PropsWithChildren {
  subtitle?: string;
  onSubmitAction?: () => void;
  onCancelAction?: () => void;
  onOpenChangeAction: (open: boolean) => void;
  open: boolean;
  loading?: boolean;
  type: 'duplicate' | 'promo';
  title: string;
}

const FormDialog = (props: FormDialogProps) => {
  const [open, setOpen] = useState<boolean>(props.open);

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  return (
    <Dialog
      onOpenChange={open => {
        props.onOpenChangeAction(open);
        setOpen(open);
      }}
      open={open}
    >
      <DialogContent className="space-y-6 max-h-[800px] overflow-scroll">
        <DialogHeader>
          <DialogTitle className="text-xl font-medium">
            {props.title}
          </DialogTitle>
          {props.subtitle && (
            <DialogDescription>{props.subtitle}</DialogDescription>
          )}
        </DialogHeader>

        <div>{props.children}</div>
      </DialogContent>
    </Dialog>
  );
};

export { FormDialog };
