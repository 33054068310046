import * as z from 'zod';
import { zodInputStringPipe } from '../../../../../components/forms/util';

export const contractFormSchema = z.object({
  id: z.number().optional(),
  artistId: zodInputStringPipe(z.number()),
  dateSigned: z.date(),
  lengthTracks: z.number(),
  masterOwnership: z.number().min(0).max(100),
  nonCompete: z.boolean().optional(),
  //royaltyPeriod: z.array(z.string()).optional(),
  royaltyPeriodLength: z.number(),
  royaltyPeriodStartMonth: z.string(),
  royaltySharePercentage: z.string(),
  type: z.string(),
  producerGramexShare: z.number().min(0).max(100),
  numberOfOptions: z.number(),
  copyrightC: z.string().optional(),
  notes: z.string().optional(),
  copyrightP: z.string().optional(),
  royaltyShareType: z.string(),
});

// TODO: Figure out how to check the values against the schema for better DX
export const defaultValues = {
  artistId: '',
  dateSigned: new Date(), // UTC TO DB
  lengthTracks: 0,
  masterOwnership: 0,
  nonCompete: false,
  //royaltyPeriod: ['1', '2'], // ['UTC START DATE' , 'UTC END DATE']
  royaltySharePercentage: '50',
  numberOfOptions: 0,
  royaltyShareType: 'ARTIST_SHARE',
  type: '',
  copyrightC: '',
  copyrightP: '',
  royaltyPeriodLength: 0,
  royaltyPeriodStartMonth: '1',
  producerGramexShare: 0,
  notes: '',
};
