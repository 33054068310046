import { ViewError } from '@/components/errors/view-error';
import { CreateExpenseForm } from '@/views/admin/data-management/expenses/forms/form';
import { ViewLoader } from '@/components/loaders/view-loader';
import { AdminHeader } from '@/components/partials/admin-header';
import { useGetArtistListQuery } from '@/gql/generated-types';
import { useMutation } from '@apollo/client';
import { CREATE_EXPENSE } from '@/graphql/mutations';
import { toast } from '@/components/ui/use-toast';
import { useTranslation } from 'react-i18next';

export default function CreateExpense() {
  const { data, loading, error } = useGetArtistListQuery();
  const { t } = useTranslation();
  const [createExpense] = useMutation(CREATE_EXPENSE, {
    onError: _ => {
      toast({
        variant: 'destructive',
        title: t('toast.genericErrorTitle'),
        description: t('toast.createErrorDescription', {
          item: t('toast.items.expense'),
        }),
      });
    },
    onCompleted: _ => {
      toast({
        title: t('toast.genericSaveSuccessTitle'),
        description: t('toast.genericSaveSuccessDescription', {
          item: t('toast.items.expense'),
        }),
      });
    },
  });

  if (error) {
    return <ViewError />;
  }

  return (
    <>
      <AdminHeader title={t('adminDM.createExpense.title')} />
      {loading && <ViewLoader />}
      {!loading && data && (
        <CreateExpenseForm
          artists={data}
          handleOnCreateExpense={data => {
            const { artistId, ...rest } = data;
            createExpense({
              variables: {
                input: {
                  ...rest,
                  artistId: Number(artistId),
                },
              },
            });
          }}
        />
      )}
    </>
  );
}
