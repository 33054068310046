import * as React from 'react';
import { cva, type VariantProps } from 'class-variance-authority';
import { cn } from '@/lib/utils';

const radialCardVariants = cva('', {
  variants: {
    variant: {
      green: 'radial-background-green',
      default: 'radial-background',
      purple: 'radial-background-purple',
      grey: 'radial-background-grey',
      orange: 'radial-background-orange',
    },
  },
  defaultVariants: {
    variant: 'default',
  },
});

export interface RadialCardProps
  extends React.HTMLAttributes<HTMLDivElement>,
  VariantProps<typeof radialCardVariants> {
  bodyClassName?: string;
}

function RadialCard({
  className,
  children,
  variant,
  bodyClassName,
  ...props
}: RadialCardProps) {
  return (
    <div
      className={cn(
        ['relative', 'rounded-[20px]', 'overflow-hidden', 'bg-grey-black/70'],
        className,
      )}
      {...props}
    >
      <div className={cn(bodyClassName, 'p-6 h-full')}>{children}</div>
      <div className={radialCardVariants({ variant })}>
        <div></div>
        <div></div>
      </div>
    </div>
  );
}

export { RadialCard, radialCardVariants };
