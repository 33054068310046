import { Loader2 } from 'lucide-react';
import { useTranslation } from 'react-i18next';

export function ViewLoader() {
  const { t } = useTranslation();
  return (
    <div className="flex items-center">
      <Loader2 className="animate-spin mr-4" /> {t('viewLoader.loading')}
    </div>
  );
}
