import { useEffect, useState } from 'react';
import {
  DndContext,
  closestCenter,
  TouchSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
  DragStartEvent,
  DragOverlay,
  MeasuringStrategy,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { TrackContainerProps } from './types';
import { SortableItem } from './sortable';
import { Item } from './item';
import {
  EnhacedTableHeading,
  EnhancedTable,
  EnhancedTableHeader,
  EnhancedTableRow,
} from '@/components/ui/enhanced-table';

import { useAtom } from 'jotai';
import { Release, ReleaseTrack } from '@/services/store/releases/types';
import { tracksAtom } from '@/services/store/releases/tracks';
import { releaseAtom } from '@/services/store/releases/releases';
import { useTranslation } from 'react-i18next';

export function TrackContainer(props: TrackContainerProps) {
  const { tracks, listOfArtist } = props;
  const { t } = useTranslation();
  const [activeItem, setActiveItem] = useState<ReleaseTrack>();
  const [items, setItems] = useState<ReleaseTrack[] | null>(tracks);
  const [releaseTracks, setTracks] = useAtom(tracksAtom);
  const [_, setRelease] = useAtom(releaseAtom);
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        // Random number, fixes bug where single click moves item down by 1
        distance: 5,
      },
    }),
    useSensor(TouchSensor),
  );

  useEffect(() => {
    setItems(tracks);
  }, [tracks]);

  const handleDragStart = (event: DragStartEvent) => {
    const { active } = event;
    setActiveItem(items!.find(item => item.order === active.id));
  };

  const measuringConfig = {
    droppable: {
      strategy: MeasuringStrategy.WhileDragging,
    },
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (!over) return;

    const activeItem = items!.find(item => item.order === active.id);
    const overItem = items!.find(item => item.order === over.id);

    if (!activeItem || !overItem) {
      return;
    }

    const activeIndex = items!.findIndex(item => item.order === active.id);
    const overIndex = items!.findIndex(item => item.order === over.id);

    // NOTE: We do not change the order value for the component local state
    // as it is not necessary, we use index and store the correct order jotai state
    // The component local state is only used to visualize the order.
    // INDEX + 1 === ORDER
    if (activeIndex !== overIndex) {
      setItems(prev => arrayMove<ReleaseTrack>(prev!, activeIndex, overIndex));
    }

    // Get the volume where track belongs
    const volumeToManipulate = activeItem.volume;

    // Filter out tracks of that volume
    const clonedItems = releaseTracks.filter(
      (track: ReleaseTrack) => track.volume !== volumeToManipulate,
    );

    // Take the last component state and move the track to correct position
    // And change the order value of the tracks in selected volume based on array index
    const manipulatedItemsList = arrayMove<ReleaseTrack>(
      items!,
      activeIndex,
      overIndex,
    ).map((item, index) => {
      const { order, ...rest } = item;

      return {
        ...rest,
        order: index + 1,
      };
    });

    // Save the release without changing other volumes
    setRelease((prev: Release) => ({
      ...prev,
      tracks: [...manipulatedItemsList!, ...clonedItems],
    }));

    setActiveItem(undefined);
  };

  const handleDragCancel = () => {
    setActiveItem(undefined);
  };

  const handleItemDeletion = (track: ReleaseTrack) => {
    // TODO: Item deletion
    const tracksList: ReleaseTrack[] = releaseTracks.filter(
      (item: ReleaseTrack) => item.id !== track.id,
    );

    setTracks(tracksList);
  };

  if (props.disableDnd) {
    return (
      <div className="w-full">
        <EnhancedTable className="border-collapse w-full text-left">
          <EnhancedTableHeader>
            <EnhancedTableRow className="grid grid-cols-tracksList">
              <EnhacedTableHeading className="text-left justify-start">
                {t('trackForm.name')}
              </EnhacedTableHeading>
              <EnhacedTableHeading className="text-left justify-start pl-0">
                {t('trackForm.artists')}
              </EnhacedTableHeading>
              <EnhacedTableHeading>{t('trackForm.isrc')}</EnhacedTableHeading>
              <EnhacedTableHeading></EnhacedTableHeading>
            </EnhancedTableRow>
          </EnhancedTableHeader>
          {items!.map((item, index) => (
            <SortableItem
              edit={props.edit}
              disableDnd={true}
              index={index}
              key={(item?.volume ?? 0) + index}
              item={item}
              listOfArtists={listOfArtist}
              onDelete={track => handleItemDeletion(track)}
            />
          ))}
        </EnhancedTable>
      </div>
    );
  }

  return (
    <div className="w-full">
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        onDragCancel={handleDragCancel}
        measuring={measuringConfig}
      >
        <SortableContext
          items={items!.map(i => i!.order!)}
          strategy={verticalListSortingStrategy}
        >
          <EnhancedTable className="border-collapse w-full text-left">
            <EnhancedTableHeader>
              <EnhancedTableRow className="grid grid-cols-tracksList">
                <EnhacedTableHeading className="text-left justify-start">
                  {t('trackForm.name')}
                </EnhacedTableHeading>
                <EnhacedTableHeading className="text-left justify-start pl-0">
                  {t('trackForm.artists')}
                </EnhacedTableHeading>
                <EnhacedTableHeading>{t('trackForm.isrc')}</EnhacedTableHeading>
                <EnhacedTableHeading></EnhacedTableHeading>
              </EnhancedTableRow>
            </EnhancedTableHeader>
            {items!.map((item, index) => (
              <SortableItem
                edit={props.edit}
                index={index}
                key={(item?.volume ?? 0) + index}
                item={item}
                listOfArtists={listOfArtist}
                onDelete={track => handleItemDeletion(track)}
              />
            ))}
          </EnhancedTable>
        </SortableContext>

        <DragOverlay style={{ transformOrigin: '0 0 ', width: '100%' }}>
          {activeItem ? (
            <Item
              edit={props.edit}
              index={activeItem.order!}
              listOfArtists={listOfArtist}
              item={activeItem}
              isDragging
              onDelete={track => handleItemDeletion(track)}
            />
          ) : null}
        </DragOverlay>
      </DndContext>
    </div>
  );
}
