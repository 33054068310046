import { gql } from '@apollo/client';

export const GET_ARTIST_PROFILES = gql`
  query GetArtistByEmail($email: String!) {
    artistsByEmail(email: $email) {
      name
      id
      imageUrl
    }
  }
`;

export const GET_ARTIST_PROFILE = gql`
  query GetArtistProfile($artistId: Int!) {
    artist(id: $artistId) {
      active
      costCenter
      imageUrl
      id
      name
      persons {
        id
      }
    }
  }
`;

export const GET_ARTISTS_LIST = gql`
  query GetArtistList {
    listArtists {
      active
      costCenter
      id
      name
      imageUrl
      deletable
      persons {
        id
        user {
          email
          id
        }
      }
    }
  }
`;

export const GET_COMPASS_PERSONS = gql`
  query GetCompassPersons {
    persons {
      id
      contractPerson {
        lastName
        firstName
      }
    }
  }
`;
export const GET_ARTIST_INCOME_TOTALS = gql`
  query GetArtistIncomeTotals(
    $artistId: Int!
    $startDate: String
    $endDate: String
  ) {
    artistIncomeTotals(
      artistId: $artistId
      startDate: $startDate
      endDate: $endDate
    ) {
      expenses
      income
      pmeShare
    }
  }
`;

export const GET_ARTIST_INCOME_TOTALS_V2 = gql`
  query GetArtistIncomeTotalsV2(
    $artistId: Int!
    $startDate: String
    $endDate: String
  ) {
    artistIncomeTotalsV2(
      artistId: $artistId
      startDate: $startDate
      endDate: $endDate
    ) {
      toplineRevenue
      artistIncome
      collaboratorIncome
      featuredArtistIncome
      pmeIncome
      expenses {
        total
        details {
          id
          amount
          description
        }
      }
      payments
      recoupments
    }
  }
`;

export const GET_ARTIST_BALANCE = gql`
  query GetArtistBalance($artistId: Int!) {
    artistBalance(artistId: $artistId) {
      artistId
      balance
    }
  }
`;

export const GET_ARTIST_REVENUE_BY_TRACK = gql`
  query GetArtistRevenueByTrack(
    $artistId: Int!
    $startDate: String
    $endDate: String
  ) {
    artistRevenueByTrack(
      artistId: $artistId
      startDate: $startDate
      endDate: $endDate
    ) {
      revenue
      track {
        id
        name
        releaseCoverArt
      }
    }
  }
`;

export const GET_ARTIST_INCOME_BY_TRACK = gql`
  query GetArtistIncomesByTrack(
    $artistId: Int!
    $startDate: String
    $endDate: String
  ) {
    artistIncomesByTrack(
      artistId: $artistId
      startDate: $startDate
      endDate: $endDate
    ) {
      income
      track {
        id
        name
        releaseCoverArt
      }
    }
  }
`;

export const GET_ARTIST_EXPENSES = gql`
  query GetArtistExpenseDetails(
    $artistId: Int!
    $startDate: String
    $endDate: String
  ) {
    artistExpenseDetails(
      artistId: $artistId
      startDate: $startDate
      endDate: $endDate
    ) {
      amount
      category
    }
  }
`;

export const GET_ARTIST_CONTRACTS = gql`
  query GetArtistContracts($artistId: Int!) {
    contracts(artistId: $artistId) {
      id
      dateSigned
      royaltyPeriod
      royaltySharePercentage
      royaltyShareType
      deletable
      type
      artist {
        name
      }
    }
  }
`;

export const GET_RELEASES_BY_ID = gql`
  query GetReleases($artistId: Int) {
    releases(artistId: $artistId) {
      catalogNumber
      coverArt
      id
      name
      releaseType
      releaseDate
      duplicateOf {
        id
      }
      promoOf {
        id
      }
      releaseArtists {
        name
      }
    }
  }
`;

export const GET_RELEASE_BY_ID = gql`
  query GetReleaseById($releaseId: Int!) {
    release(id: $releaseId) {
      id
      name
      duplicateOf {
        id
      }
      promoOf {
        id
      }
      notes
      tracks {
        id
        name
        notes
        youtubeAssetReference
        masterAudio
        gramexLabelPercentage
        volume
        order
        version
        isrcs {
          id
          isrc
        }
        trackArtists {
          id
          artistId
          type
        }
        contract {
          id
          artist {
            id
          }
        }
        trackCollaborators {
          role
          id
          collaborator {
            id
            name
          }
        }
        trackCollaboratorRoyalty {
          id
          collaborator {
            type
            id
          }
          paymentAmount
          recoupableAmount
          royaltyPercentage
          royaltyType
          recoupableType
        }
        trackArtistRoyalty {
          id
          artistId
          paymentAmount
          recoupableAmount
          royaltyPercentage
          recoupableType
          royaltyType
        }
      }
      catalogNumber
      coverArt
      productType
      promoAssets
      releaseDate
      releaseType
      upc {
        upcNumber
      }
      version
      releaseArtists {
        id
        name
        active
        costCenter
        imageUrl
      }
    }
  }
`;

export const GET_ARTIST_OVERVIEW = gql`
  query GetArtistOverview($costCenter: String) {
    artistOverview(costCenter: $costCenter) {
      artistId
      artist {
        name
      }
      artistName
      costCenter
      latestReleaseDate
      nextReleaseDate
      over30secStreamsLast30Days
      totalStreamsLast30Days
      incomeYTD
      tracksFromContract
      contractLength
    }
  }
`;

export const GET_CONTRACTS = gql`
  query GetContracts($artistId: Int, $contractId: Int) {
    contracts(artistId: $artistId, contractId: $contractId) {
      id
      type
      lengthTracks
      numberOfOptions
      masterOwnership
      producerGramexShare
      copyrightP
      copyrightC
      notes
      royaltySharePercentage
      royaltyShareType
      deletable
      royaltyPeriod
      nonCompete
      dateSigned
      artist {
        name
        id
      }
    }
  }
`;

export const GET_STREAM_FOR_ARTIST = gql`
  query GetStreamsForArtist(
    $artistId: Int!
    $startDate: String
    $endDate: String
  ) {
    streamsForArtist(
      artistId: $artistId
      startDate: $startDate
      endDate: $endDate
    )
  }
`;

export const GET_STREAMS = gql`
  query GetStreams(
    $artistId: Int!
    $endDate: String
    $startDate: String
    $trackId: Int
  ) {
    streamsForArtistByTrack(
      artistId: $artistId
      endDate: $endDate
      startDate: $startDate
      trackId: $trackId
    ) {
      streamsByDsp {
        dspName
        dspId
        count
      }
      totalStreams
      trackCoverArt
      trackId
      trackName
    }
  }
`;

export const GET_STREAMS_BY_CATEGORY = gql`
  query GetStreamsByCategory(
    $artistId: Int!
    $trackId: Int
    $startDate: String
    $endDate: String
    $groupBy: StreamCountGroupBy
  ) {
    streamCount(
      artistId: $artistId
      trackId: $trackId
      startDate: $startDate
      endDate: $endDate
      groupBy: $groupBy
    ) {
      ... on ByAge {
        total
        streams0to17
        streams18to22
        streams23to27
        streams28to34
        streams35to44
        streams45to59
        streams60to150
        streamsUnknown
      }
      ... on ByGender {
        total
        male
        female
        other
        unknown
      }
      ... on ByPlayLength {
        total
        streamsSub30
        streamsOver30
      }
      ... on ByDsp {
        total
        amazon
        deezer
        itunes
        soundcloud
        spotify
        youtube
        youtube_music
      }
    }
  }
`;

export const GET_STREAM_BY_DATE = gql`
  query GetStreamsByDate(
    $artistId: Int!
    $trackId: Int
    $startDate: String
    $endDate: String
  ) {
    getTotalStreamsByDate(
      artistId: $artistId
      trackId: $trackId
      startDate: $startDate
      endDate: $endDate
    ) {
      date
      streams
    }
  }
`;

export const GET_TRACK_BY_ID = gql`
  query GetTrackById($trackId: Int!) {
    track(id: $trackId) {
      id
      name
      youtubeAssetReference
      masterAudio
      gramexLabelPercentage
      volume
      order
      notes
      version
      release {
        id
      }
      isrcs {
        isrc
      }
      trackArtists {
        artistId
        type
        artist {
          name
        }
      }
      trackCollaborators {
        role
        id
        collaborator {
          id
          name
        }
      }
      contract {
        id
        artist {
          id
        }
      }
      trackCollaboratorRoyalty {
        id
        collaborator {
          type
          name
          id
        }
        paymentAmount
        recoupableAmount
        royaltyPercentage
        royaltyType
        recoupableType
      }
      trackArtistRoyalty {
        id
        artistId
        royaltyType
        paymentAmount
        recoupableAmount
        royaltyPercentage
        recoupableType
        artist {
          name
          id
        }
      }
    }
  }
`;

export const GET_PRESIGNED_URL = gql`
  query GetPresignedUrl(
    $fileName: String!
    $month: Int!
    $year: Int!
    $uploadType: UploadType!
    $dsp: RoyaltySourceProvider!
  ) {
    getPreSignedUrl(
      fileName: $fileName
      month: $month
      year: $year
      uploadType: $uploadType
      dsp: $dsp
    )
  }
`;

export const GET_STATEMENT_RUNS = gql`
  query GetStatementsRuns(
    $royaltyProvider: RoyaltySourceProvider!
    $take: Int
  ) {
    getRoyaltyStatementRuns(royaltyProvider: $royaltyProvider, take: $take) {
      periodStartDate
      periodEndDate
      fileName
      royaltySource
      processedAt
      createdAt
      id
    }
  }
`;

export const GET_PAYMENTS = gql`
  query GetPayments($artistId: Int, $startDate: String, $endDate: String) {
    artistPayments(
      artistId: $artistId
      startDate: $startDate
      endDate: $endDate
    ) {
      id
      artist {
        id
        name
      }
      title
      comment
      amount
      date
      createdBy {
        id
        email
      }
      createdAt
    }
  }
`;

export const GET_COLLABORATOR = gql`
  query ListCollaborators($listCollaboratorsId: Int) {
    listCollaborators(id: $listCollaboratorsId) {
      id
      name
      type
      active
      deletable
    }
  }
`;

export const VALIDATE_ROYALTIES = gql`
  query ValidateRoyaltyRules(
    $artistRules: [ArtistRoyaltyRule!]!
    $collaboratorRules: [CollaboratorRoyaltyRule!]!
  ) {
    validateRoyaltyRules(
      artistRules: $artistRules
      collaboratorRules: $collaboratorRules
    ) {
      artistShares {
        id
        name
        amountEuro
      }
      collaboratorShares {
        id
        name
        amountEuro
      }
      pmeShare
      errors {
        key
        message
      }
      warnings {
        key
        message
      }
    }
  }
`;

export const LIST_USERS = gql`
  query ListUsers {
    listUsers {
      id
      email
      clerkId
      role
      person {
        contractPerson {
          firstName
          lastName
        }
      }
    }
  }
`;

export const GET_UNMATCHED = gql`
  query GetAssetsReferences($take: Int, $unprocessedOnly: Boolean) {
    assetReferences(take: $take, unprocessedOnly: $unprocessedOnly) {
      id
      isrc {
        isrc
      }
      assetTitle
      assetReference
      assetArtist
      incomeType
      source
      createdAt
      unpaidRoyalties {
        unmatchedTrack {
          id
        }
      }
    }
  }
`;
