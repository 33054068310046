import { SignOutButton, useSession } from '@clerk/clerk-react';
import * as SheetPrimitive from '@radix-ui/react-dialog';
import { Link, useNavigate, useRouter } from '@tanstack/react-router';
import { Menu, X } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GetArtistByEmailQuery } from '../../gql/generated-types';
import { ARTIST_PATH } from '../../lib/constants';
import { Button } from '../ui/button';
import { Label } from '../ui/label';
import { useParams } from '@tanstack/react-router';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../ui/select';
import { Sheet, SheetContent, SheetFooter, SheetTrigger } from '../ui/sheet';

export const ArtistSheet = ({
  artistProfiles,
}: {
  artistProfiles: GetArtistByEmailQuery | undefined;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { session } = useSession();
  const [open, setOpen] = useState(false);
  const router = useRouter();
  const { artistId } = useParams({ strict: false });
  useEffect(() => {
    setOpen(false);
  }, [router.state.location.pathname]);

  return (
    <Sheet open={open}>
      <SheetTrigger>
        <Button
          onClick={() => setOpen(true)}
          size="icon"
          variant="ghost"
          asChild
          className="text-yellow-dark"
        >
          <Menu className="w-8 h-8 text-yellow-dark" />
        </Button>
      </SheetTrigger>
      <SheetContent className="w-full bg-yellow-dark">
        <SheetPrimitive.Close
          onClick={() => setOpen(false)}
          className="absolute top-10 right-8 focus:outline-dotted focus:outline-1"
          autoFocus={true}
        >
          <X className="h-8 w-8 text-grey-black" />
          <span className="sr-only">{t('artistNav.close')}</span>
        </SheetPrimitive.Close>
        <div className="flex flex-col h-10 mt-3">
          {/* Logo */}
          <img
            src="/assets/pme-logo-dark.svg"
            width="164"
            height="28"
            alt="PME Compass"
          />
          <div className="mt-[90px]">
            {session && artistId && (
              <nav className="flex flex-col items-start space-y-12 text-xl font-medium">
                <Link
                  className="text-grey-darkest focus:outline-dotted focus:outline-1"
                  to={`${ARTIST_PATH}/${artistId}`}
                >
                  {t('artistNav.frontpage')}
                </Link>
                <Link
                  to={`${ARTIST_PATH}/${artistId}/streams`}
                  className="text-grey-darkest focus:outline-dotted font-bold focus:outline-1"
                >
                  {t('artistNav.streams')}
                </Link>
                <Link
                  to={`${ARTIST_PATH}/${artistId}/balance`}
                  className="text-grey-darkest focus:outline-dotted font-bold focus:outline-1"
                >
                  {t('artistNav.balance')}
                </Link>
              </nav>
            )}
          </div>
        </div>
        <SheetFooter>
          <div className="w-full space-y-4">
            {artistId &&
              artistProfiles?.artistsByEmail &&
              artistProfiles?.artistsByEmail?.length > 1 &&
              artistProfiles?.artistsByEmail?.length && (
                <>
                  <Label className="text-grey-darkest" htmlFor="profilesSwitch">
                    Artistiprofiilit
                  </Label>
                  <Select
                    defaultValue={artistId.toString()}
                    onValueChange={value => {
                      if (value === artistId.toString()) return;

                      navigate({ to: `/artist/${value}` });
                    }}
                  >
                    <SelectTrigger
                      className="w-full rounded-full text-grey-darkest"
                      id="profilesSwitch"
                    >
                      <SelectValue placeholder={t('artistNav.selectProfile')} />
                    </SelectTrigger>
                    <SelectContent>
                      {artistProfiles.artistsByEmail.map(artist => {
                        return (
                          <SelectItem
                            value={artist?.id.toString() || ''}
                            key={artist?.id}
                          >
                            {artist?.name}
                          </SelectItem>
                        );
                      })}
                    </SelectContent>
                  </Select>
                </>
              )}
            <SignOutButton>
              <Button variant="secondary" className="w-full">
                {t('artistNav.logout')}
              </Button>
            </SignOutButton>
          </div>
        </SheetFooter>
      </SheetContent>
    </Sheet>
  );
};
