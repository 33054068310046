import { AdminHeader } from '@/components/partials/admin-header';
import { useGetPaymentsQuery } from '@/gql/generated-types';
import { PaymentsTable } from './components/payments-table';
import { useMutation } from '@apollo/client';
import { DELETE_PAYMENT } from '@/graphql/mutations';
import { toast } from '@/components/ui/use-toast';
import { ADMIN_CREATE_PAYMENT } from '@/services/router/routes';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import { useEffect, useState } from 'react';
import { Button } from '@/components/ui/button';
import { ArtistSelect } from '../../shared/artist-select';
import * as SheetPrimitive from '@radix-ui/react-dialog';
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
} from '@/components/ui/sheet';
import { format } from 'date-fns';
import { X } from 'lucide-react';
import { CalendarDropDown } from '@/components/ui/calendar-dd';
import { useTranslation } from 'react-i18next';

export default function Payments() {
  const [paymentState, setPaymentState] = useState<{
    inspect: boolean;
    open: boolean;
    payment: number | null;
  }>({
    inspect: false,
    open: false,
    payment: null,
  });
  const [dateRange, setDateRange] = useState<{
    start: Date | null;
    end: Date | null;
  }>({
    start: new Date(new Date().setMonth(new Date().getMonth() - 6)),
    end: new Date(),
  });
  const [artist, setArtist] = useState<number | null>(null);
  const { data, refetch } = useGetPaymentsQuery({
    variables: {
      artistId: artist,
      startDate: dateRange.start?.toISOString(),
      endDate: dateRange.end?.toISOString(),
    },
  });
  const [deletePayment] = useMutation(DELETE_PAYMENT, {
    onError: _ => {
      toast({
        variant: 'destructive',
        title: t('toast.genericErrorTitle'),
        description: t('toast.genericDeleteteDescription', {
          item: t('toast.items.payment'),
        }),
      });
    },
    onCompleted: _ => {
      refetch();
      toast({
        title: t('toast.genericDeleteSuccessTitle'),
        description: t('toast.genericDeleteSuccessDescription', {
          item: t('toast.items.payment'),
        }),
      });
      setPaymentState({
        payment: null,
        open: false,
        inspect: false,
      });
    },
  });

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [artist, dateRange]);

  const { t } = useTranslation();
  return (
    <>
      <AdminHeader
        title={t('adminPayments.title')}
        actionLink={{
          link: ADMIN_CREATE_PAYMENT,
          label: t('adminPayments.newPayment'),
        }}
      />
      <div className="md:flex lg:space-x-10 space-y-10 md:space-y-0 items-end justify-between">
        <div className="w-full md:w-1/2 lg:w-1/3">
          <ArtistSelect
            onSelect={val => setArtist(Number(val))}
            value={artist?.toString()}
          />
        </div>
        <div className="text-center md:text-right w-full md:w-1/2 lg:w-1/3">
          <CalendarDropDown
            onDateChange={value =>
              setDateRange({
                start: value.from!,
                end: value.to!,
              })
            }
            start={dateRange.start ?? new Date()}
            end={dateRange.end ?? new Date()}
          />
        </div>
      </div>
      <PaymentsTable
        onDelete={id =>
          setPaymentState({
            payment: id,
            open: true,
            inspect: false,
          })
        }
        onInspect={id =>
          setPaymentState({
            payment: id,
            open: false,
            inspect: true,
          })
        }
        data={data}
      />

      <Dialog
        open={paymentState.open}
        onOpenChange={state => {
          if (!state) {
            setPaymentState({
              payment: null,
              open: state,
              inspect: false,
            });
          } else {
            setPaymentState({
              ...paymentState,
              open: state,
            });
          }
        }}
      >
        <DialogContent>
          <DialogHeader>
            <DialogTitle>{t('adminPayments.paymentDeleteTitle')}</DialogTitle>
            <DialogDescription>
              {t('adminPayments.paymentDeleteWarning')}
            </DialogDescription>
          </DialogHeader>
          <DialogFooter className="flex justify-between">
            <Button
              size="sm"
              variant="ghost"
              onClick={() =>
                setPaymentState({
                  payment: null,
                  open: false,
                  inspect: false,
                })
              }
            >
              {t('adminPayments.cancel')}
            </Button>
            <Button
              variant="destructive"
              size="sm"
              onClick={() => {
                deletePayment({
                  variables: {
                    deletePaymentId: paymentState.payment,
                  },
                });
              }}
            >
              {t('adminPayments.delete')}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
      <Sheet
        open={paymentState.inspect}
        onOpenChange={open => {
          setPaymentState({
            inspect: open,
            payment: open ? paymentState.payment : null,
            open: false,
          });
        }}
      >
        <SheetContent className="bg-white min-w-[50%] md:min-w-[30%] text-grey-darkest">
          <SheetPrimitive.Close
            onClick={() =>
              setPaymentState({
                ...paymentState,
                inspect: false,
                payment: null,
              })
            }
            className="absolute top-4 right-8 focus:outline-dotted focus:outline-1"
            autoFocus={true}
          >
            <X className="h-8 w-8 text-grey-black" />
            <span className="sr-only">{t('adminPayments.close')}</span>
          </SheetPrimitive.Close>
          <div>
            {data?.artistPayments
              ?.filter(payment => payment?.id === paymentState?.payment)
              .map(payment => (
                <>
                  <SheetHeader className="text-left mb-4">
                    <SheetTitle className="text-xl">
                      {payment?.title}
                    </SheetTitle>
                    <SheetDescription>{payment?.comment}</SheetDescription>
                  </SheetHeader>
                  <div className="space-y-4">
                    <p>
                      <strong>{t('adminPayments.date')}</strong>
                      <br />
                      {format(new Date(payment?.date), 'dd.MM.yyyy')}
                    </p>
                    <p>
                      <strong>{t('adminPayments.artist')}</strong>
                      <br />
                      {payment?.artist.name}
                    </p>
                    <p>
                      <strong>{t('adminPayments.createdBy')}</strong>
                      <br />
                      {payment?.createdBy.email}
                    </p>
                    <p>
                      <strong>{t('adminPayments.createdAt')}</strong>
                      <br />
                      {format(new Date(payment?.createdAt), 'dd.MM.yyyy')}
                    </p>
                    <p>
                      <strong>{t('adminPayments.sum')}</strong>
                      <br />
                      {new Intl.NumberFormat('fi-FI', {
                        style: 'currency',
                        currency: 'EUR',
                      }).format(payment?.amount)}
                    </p>
                  </div>
                </>
              ))}
          </div>
        </SheetContent>
      </Sheet>
    </>
  );
}
