import { buttonVariants } from '@/components/ui/button';
import { RadialCard } from '@/components/ui/radial-card';
import { useGetStreamsForArtistSuspenseQuery } from '@/gql/generated-types';
import { cn } from '@/lib/utils';
import { ARTIST_STREAMS } from '@/services/router/routes';
import { Link } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';

interface StreamBoxProps {
  artistId: number;
  endDate: string;
  startDate: string;
}

export const StreamBox = (props: StreamBoxProps) => {
  const { t } = useTranslation();

  const { data: streamData } = useGetStreamsForArtistSuspenseQuery({
    variables: {
      artistId: props.artistId,
      endDate: props.endDate,
      startDate: props.startDate,
    },
  });

  return (
    <RadialCard variant="purple" bodyClassName="space-y-4">
      <p className="font-robotoMono font-medium text-sm text-yellow-dark">
        {t('artistPages.dashboard.previous30days')}
      </p>
      <div className="lg:flex space-y-6 lg:space-y-0 items-center justify-between">
        <div className="space-y-3">
          <Link
            to={ARTIST_STREAMS}
            params={{
              artistId: props.artistId,
            }}
          >
            <h2 className="h2">{t('artistPages.dashboard.royaltyStreams')}</h2>
          </Link>
        </div>{' '}
        <p className="numerical">
          {streamData && streamData?.streamsForArtist
            ? new Intl.NumberFormat('fi-FI').format(
                Number(streamData?.streamsForArtist),
              )
            : 0}
        </p>
        <Link
          to={ARTIST_STREAMS}
          params={{
            artistId: props.artistId,
          }}
          className={cn(buttonVariants({ variant: 'default' }))}
        >
          {t('artistPages.dashboard.showMoreCTA')}
        </Link>
      </div>
    </RadialCard>
  );
};
