import { Component, ReactNode } from 'react';
import { ViewError } from '../errors/view-error';

interface Props {
  children?: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public render() {
    if (this.state.hasError) {
      return (
        <div className="container space-y-12">
          <ViewError />
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
