import * as z from 'zod';

export const createExpenseSchema = z.object({
  amount: z
    .string()
    .transform(val => Number(`${val}`.replace(',', '.')))
    .pipe(z.number()),
  artistId: z.string().min(1), // NOTE: Changed to number on submit
  category: z.enum([
    'MARKETING',
    'MUSIC_PRODUCTION',
    'OTHER',
    'VIDEO_PRODUCTION',
  ]),
  date: z.date(),
  description: z.string().min(1),
  poNumber: z.string().min(1),
  taggedCollaborators: z.array(z.number()).optional().nullable(),
});

// TODO: Figure out how to check the values against the schema for better DX
export const defaultValues: z.infer<typeof createExpenseSchema> = {
  amount: 0,
  artistId: '',
  date: new Date(),
  description: '',
  poNumber: '',
  taggedCollaborators: null,
  category: 'MARKETING',
};
