import { Button } from '@/components/ui/button';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import { ListUsersQuery } from '@/gql/generated-types';
import { useTranslation } from 'react-i18next';

interface UsersTableProps {
  data: ListUsersQuery['listUsers'] | undefined;
  onDeleteAction: (id: number) => void;
}

export const UsersTable = (props: UsersTableProps) => {
  const { t } = useTranslation();
  return (
    <Table className="border-collapse">
      <TableHeader>
        <TableRow className="text-left">
          <TableHead>{t('usersPage.name')}</TableHead>
          <TableHead>{t('usersPage.role')}</TableHead>
          <TableHead>{t('usersPage.email')}</TableHead>
          <TableHead></TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {props.data?.map((user, index) => (
          <TableRow variant={index % 2 === 0 ? 'odd' : null} key={index}>
            <TableCell>
              {user?.person?.contractPerson.firstName}{' '}
              {user?.person?.contractPerson.lastName}
            </TableCell>
            <TableCell>{user?.role}</TableCell>
            <TableCell>{user?.email}</TableCell>
            <TableCell className="text-right">
              <Button
                variant="destructive"
                size="sm"
                onClick={() => props.onDeleteAction(Number(user?.id))}
              >
                {t('usersPage.delete')}
              </Button>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
