import { ViewError } from '@/components/errors/view-error';
import { MONTHS } from '@/views/admin/data-management/contracts/forms/util';
import { ViewLoader } from '@/components/loaders/view-loader';
import { AdminHeader } from '@/components/partials/admin-header';
import { Label } from '@/components/ui/label';
import { useGetContractsQuery } from '@/gql/generated-types';
import {
  ADMIN_CONTRACTS,
  ADMIN_CREATE_CONTRACT,
} from '@/services/router/routes';
import { useParams } from '@tanstack/react-router';
import { format } from 'date-fns';
import { fi } from 'date-fns/locale';
import { useTranslation } from 'react-i18next';

export default function Contract() {
  const { t } = useTranslation();
  const { contractId } = useParams({ strict: false });
  const { data, loading, error } = useGetContractsQuery({
    variables: {
      contractId: Number(contractId),
    },
    fetchPolicy: 'no-cache',
  });

  if (loading) {
    return <ViewLoader />;
  }

  if (error) {
    return <ViewError />;
  }

  return (
    <>
      {data &&
        !loading &&
        data?.contracts?.map((contract, index) => (
          <div key={index} className="space-y-12">
            <AdminHeader
              title={t('adminContracts.singularContractTitle', {
                contract: contract?.artist.name ?? '',
              })}
              actionLink={{
                link: ADMIN_CREATE_CONTRACT,
                label: t('adminContracts.createContract'),
              }}
              goBackLink={{
                link: ADMIN_CONTRACTS,
                label: t('adminShared.back'),
              }}
            />
            <div className="max-w-[50%] space-y-12">
              <div className="grid grid-cols-2 gap-x-4 gap-y-5">
                <div className="col-span-2">
                  <h4 className="h4 mb-0">{t('contractForm.basicInfo')}</h4>
                </div>
                <div className="col-span-2 space-y-2">
                  <Label>{t('contractForm.artistName')}</Label>
                  <div className="bg-grey-darkest p-2">
                    {contract?.artist.name}
                  </div>
                </div>
                <div className="space-y-2 col-span-2">
                  <Label>{t('contractForm.type')}</Label>
                  <div className="bg-grey-darkest p-2">{contract?.type}</div>
                </div>
              </div>
              <div className="grid grid-cols-2 gap-x-4 gap-y-5">
                <div className="col-span-2">
                  <h4 className="h4 mb-0">{t('contractForm.contract')}</h4>
                </div>
                <div className="space-y-2 col-span-2">
                  <Label>{t('contractForm.dateSigned')}</Label>
                  <div className="bg-grey-darkest p-2">
                    {format(new Date(contract?.dateSigned), 'dd.MM.yyyy', {
                      locale: fi,
                    })}
                  </div>
                </div>
                <div className="space-y-2 col-span-2">
                  <Label>{t('contractForm.nonCompete')}</Label>
                  <div className="bg-grey-darkest p-2">
                    {contract?.nonCompete
                      ? t('contractForm.yes')
                      : t('contractForm.no')}
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-2 gap-x-4 gap-y-5">
                <div className="col-span-2">
                  <h4 className="h4 mb-0">
                    {t('contractForm.contractLength')}
                  </h4>
                </div>
                <div className="space-y-2">
                  <Label>{t('contractForm.releases')}</Label>
                  <div className="bg-grey-darkest p-2">
                    {contract?.lengthTracks}
                  </div>
                </div>
                <div className="space-y-2">
                  <Label>{t('contractForm.options')}</Label>
                  <div className="bg-grey-darkest p-2">
                    {contract?.numberOfOptions}
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-2 gap-x-4 gap-y-5">
                <div className="col-span-2">
                  <h4 className="h4 mb-0">
                    {t('contractForm.masterOwnership')}
                  </h4>
                </div>
                <div className="space-y-2 col-span-2">
                  <Label>{t('contractForm.masterOwnership')} (PME)</Label>
                  <div className="bg-grey-darkest p-2">
                    {contract?.masterOwnership} %
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-2 gap-x-4 gap-y-5">
                <div className="col-span-2">
                  <h4 className="h4 mb-0">{t('contractForm.gramex')}</h4>
                </div>
                <div className="space-y-2 col-span-2">
                  <Label>{t('contractForm.gramexLabelPercentage')}</Label>
                  <div className="bg-grey-darkest p-2">
                    {contract?.producerGramexShare} %
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-2 gap-x-4 gap-y-5">
                <div className="col-span-2">
                  <h4 className="h4 mb-0">{t('contractForm.releaseLabel')}</h4>
                </div>
                <div className="space-y-2 col-span-2">
                  <Label>&copy; {t('contractForm.copyrightC')}</Label>
                  <div className="bg-grey-darkest p-2">
                    {contract?.copyrightC}
                  </div>
                </div>
                <div className="space-y-2 col-span-2">
                  <Label>&copy; {t('contractForm.copyrightP')}</Label>
                  <div className="bg-grey-darkest p-2">
                    {contract?.copyrightP}
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-3 gap-x-4 gap-y-5">
                <div className="col-span-3">
                  <h4 className="h4 mb-0">
                    {t('contractForm.royaltySharePercentage')}
                  </h4>
                </div>
                <div className="space-y-2 col-span-1">
                  <Label>{t('contractForm.royaltySharePercentage')}</Label>
                  <div className="bg-grey-darkest p-2">
                    {contract?.royaltySharePercentage}
                  </div>
                </div>
                <div className="space-y-2 col-span-2">
                  <Label>{t('contractForm.royaltyShareType')}</Label>
                  <div className="bg-grey-darkest p-2">
                    {t(`contractForm.${contract?.royaltyShareType}` ?? '')}
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-3 gap-x-4 gap-y-5">
                <div className="col-span-3">
                  <h4 className="h4 mb-0">{t('contractForm.royaltyPeriod')}</h4>
                </div>
                <div className="space-y-2 col-span-1">
                  <Label>{t('contractForm.royaltyPeriodCount')}</Label>
                  <div className="bg-grey-darkest p-2">
                    {contract?.royaltyPeriod.length}
                  </div>
                </div>
                <div className="space-y-2 col-span-1">
                  <Label>{t('contractForm.royaltyPeriodMonthLength')}</Label>
                  <div className="bg-grey-darkest p-2">
                    {12 / Number(contract?.royaltyPeriod.length)}
                  </div>
                </div>
                <div className="space-y-2 col-span-1">
                  <Label>{t('contractForm.startOfPeriod')}</Label>
                  <div className="bg-grey-darkest p-2">
                    {MONTHS.filter(
                      month => month.value === contract?.royaltyPeriod[0],
                    ).map(e => e.label)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
    </>
  );
}
