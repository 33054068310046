import { ViewError } from '@/components/errors/view-error';
import { ViewLoader } from '@/components/loaders/view-loader';
import { AdminHeader } from '@/components/partials/admin-header';
import { useGetCompassPersonsQuery } from '@/gql/generated-types';
import { ADMIN_ARTIST, ADMIN_COLLABORATORS } from '@/services/router/routes';
import { CreateCollaboratorForm } from './forms/collaborator';
import { useMutation } from '@apollo/client';
import { CREATE_COLLABORATOR } from '@/graphql/mutations';
import { toast } from '@/components/ui/use-toast';
import { useNavigate } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';

export default function CreateCollaborator() {
  const { loading, error, data } = useGetCompassPersonsQuery();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [createCollaborator] = useMutation(CREATE_COLLABORATOR, {
    onError: _ => {
      toast({
        variant: 'destructive',
        title: t('toast.genericErrorTitle'),
        description: t('toast.genericErrorDescription', {
          item: t('toast.items.collaborator'),
        }),
      });
    },
    onCompleted: data => {
      toast({
        title: t('toast.genericSaveSuccessTitle'),
        description: t('toast.genericSaveSuccessDescription', {
          item: t('toast.items.collaborator'),
        }),
      });
      navigate({
        to: ADMIN_COLLABORATORS,
        search: { name: data.upsertCollaborator.name },
      });
    },
  });

  if (error) {
    return <ViewError />;
  }

  return (
    <>
      <AdminHeader
        title={t('adminDM.createCollaborator.title')}
        goBackLink={{
          link: ADMIN_ARTIST,
          label: t('adminShared.back'),
        }}
      />

      {loading && <ViewLoader />}
      {!loading && data && (
        <CreateCollaboratorForm
          data={data}
          onSubmit={value =>
            createCollaborator({
              variables: {
                input: value,
              },
            })
          }
        />
      )}
    </>
  );
}
