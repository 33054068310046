import { AdminHeader } from '@/components/partials/admin-header';
import { useGetReleaseByIdQuery } from '@/gql/generated-types';
import { ADMIN_RELEASES } from '@/services/router/routes';
import { useParams, useSearch } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';
import { TrackList } from './components/track-list';
import { Badge } from '@/components/ui/badge';
import { format } from 'date-fns';
import { ViewLoader } from '@/components/loaders/view-loader';

function ReleaseInfo({
  label,
  value,
}: {
  label: string;
  value: string | number | undefined;
}) {
  return (
    <div className="space-y-2">
      <h2 className="text-xl font-medium">{label}</h2>
      <p className="text-2xl font-light font-robotoMono">{value}</p>
    </div>
  );
}

export default function Release() {
  const { t } = useTranslation();
  const { releaseId } = useParams({ strict: false });
  const { artistId } = useSearch({ strict: false });
  const { data, loading } = useGetReleaseByIdQuery({
    variables: {
      releaseId: Number(releaseId),
    },
  });

  if (loading) {
    return <ViewLoader />;
  }

  return (
    <>
      <AdminHeader
        badge={
          data?.release?.duplicateOf ? (
            <Badge variant="duplicate" size="large">
              {t('releaseShared.duplicate')}
            </Badge>
          ) : data?.release?.promoOf ? (
            <Badge variant="promo" size="large">
              {t('releaseShared.promo')}
            </Badge>
          ) : null
        }
        title={data!.release!.name}
        goBackLink={{
          link: artistId
            ? `${ADMIN_RELEASES}?artistId=${artistId}`
            : `${ADMIN_RELEASES}`,
          label: t('adminShared.back'),
        }}
        actionLink={
          data?.release?.duplicateOf
            ? {
                label: t('adminReleases.original'),
                link: `${ADMIN_RELEASES}/${data.release?.duplicateOf.id}`,
              }
            : data?.release?.promoOf
              ? {
                  label: t('adminReleases.original'),
                  link: `${ADMIN_RELEASES}/${data.release?.promoOf.id}`,
                }
              : undefined
        }
      />
      <div className="space-y-12">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10 border-b-1 border-grey-dark/20 border-b pb-12">
          <ReleaseInfo
            label={t('releaseShared.artistsPossiblePlural')}
            value={data?.release?.releaseArtists
              .map(artist => artist.name)
              .join(', ')}
          />
          <ReleaseInfo
            label={t('releaseShared.releaseType')}
            value={data?.release?.releaseType?.toString()}
          />
          <ReleaseInfo
            label={t('releaseShared.productType')}
            value={data?.release?.productType?.toString()}
          />
          <ReleaseInfo
            label={t('releaseShared.upcNumber')}
            value={data?.release?.upc?.upcNumber}
          />
          <ReleaseInfo
            label={t('releaseShared.catalogNumber')}
            value={data?.release?.catalogNumber}
          />
          <ReleaseInfo
            label={t('releaseShared.releaseDate')}
            value={format(new Date(data?.release?.releaseDate), 'dd.MM.yyyy')}
          />
          <ReleaseInfo
            label={t('releaseShared.version')}
            value={data?.release?.version?.toString()}
          />
        </div>
        <TrackList trackList={data!} />
      </div>
    </>
  );
}
