import { GetArtistProfileQuery } from '@/gql/generated-types';
import * as z from 'zod';
import { createArtistSchema, defaultValues } from './schema';

export function parseArtistFromGqlToForm(
  values: GetArtistProfileQuery | undefined,
): z.infer<typeof createArtistSchema> {
  if (!values) {
    return defaultValues;
  }

  const { artist } = values;

  if (!artist) {
    return defaultValues;
  }

  return {
    active: artist.active,
    id: artist.id,
    costCenter: artist.costCenter,
    name: artist.name,
    persons: artist.persons.map(p => p.id),
  };
}
