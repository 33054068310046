import { AdminHeader } from '@/components/partials/admin-header';
import { ADMIN_CREATE_RELEASE } from '@/services/router/routes';
import { TrackForm } from '../forms/track';
import { initialTrackData, tracksAtom } from '@/services/store/releases/tracks';
import {
  useGetArtistListQuery,
  useListCollaboratorsQuery,
} from '@/gql/generated-types';
import { ReleaseTrack } from '@/services/store/releases/types';
import { useAtom } from 'jotai';
import { useNavigate } from '@tanstack/react-router';
import { SchemaType, validateReleaseFormValues } from '../util/validation';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export function CreateTrackView() {
  const navigate = useNavigate();
  const { data: artistList } = useGetArtistListQuery();
  const { data: collaboratorList } = useListCollaboratorsQuery();
  const { t } = useTranslation();
  const [_, setTracks] = useAtom(tracksAtom);
  const [formErrors, setFormErrors] = useState<string[]>([]);
  const artistSelectObject = artistList?.listArtists?.map(artist => {
    return {
      value: artist?.id,
      label: artist?.name,
    };
  });

  const collaboratorSelectObject = collaboratorList?.listCollaborators?.map(
    artist => {
      return {
        value: artist?.id,
        label: artist?.name,
        role: artist?.type,
      };
    },
  );

  function handleTrackCreate(values: ReleaseTrack) {
    const validation = validateReleaseFormValues({
      values,
      schema: SchemaType.TRACK,
    });

    if (typeof validation !== 'boolean') {
      setFormErrors(Object.keys(validation));
    } else {
      setFormErrors([]);

      setTracks((prev: ReleaseTrack[]) => [...prev, values]);
      navigate({ to: ADMIN_CREATE_RELEASE });
    }
  }

  return (
    <div className="space-y-12 container">
      <AdminHeader
        title={t('adminDM.createTrack.title')}
        goBackLink={{
          label: t('adminShared.back'),
          link: ADMIN_CREATE_RELEASE,
        }}
      />
      <TrackForm
        collaborators={collaboratorSelectObject}
        artists={artistSelectObject}
        values={initialTrackData}
        onSubmit={handleTrackCreate}
        errors={formErrors}
      />
    </div>
  );
}
