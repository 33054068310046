import React from 'react';
import { cn } from '@/lib/utils';

export interface NumberInputProps
  extends React.InputHTMLAttributes<Omit<HTMLInputElement, 'value'>> {
  value: number;
  onInputValueChange: (value: number) => void;
}

/**
 * Text input that takes number as value and handles it as string to avoid common number input pitfalls.
 * Native onChange prop is replaced with onInputValueChange
 */
const NumberInput = React.forwardRef<HTMLInputElement, NumberInputProps>(
  ({ className, value, onChange, onInputValueChange, ...props }, ref) => {
    function handleInputValueChange(e: React.ChangeEvent<HTMLInputElement>) {
      const inputValue = e.target.value;
      const pattern: RegExp = /[^0-9.]/g;

      const parsedInputValue = inputValue.replace(pattern, '');
      onInputValueChange(Number(parsedInputValue));
    }

    return (
      <input
        type="text"
        onChange={handleInputValueChange}
        value={value ?? ''}
        className={cn(
          'flex h-10 w-full rounded-md text-grey-darkest border border-slate-200 bg-white px-3 py-2 text-sm  file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-grey-darkest focus:outline outline-2 focus:outline-purple disabled:cursor-not-allowed disabled:opacity-50',
          className,
        )}
        ref={ref}
        {...props}
      />
    );
  },
);
NumberInput.displayName = 'NumberInput';

export { NumberInput };
