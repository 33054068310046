import { zodInputStringPipe } from '@/components/forms/util';
import * as z from 'zod';

interface ValidationArgs {
  values: unknown;
  schema: SchemaType;
}

export enum SchemaType {
  RELEASE,
  TRACK,
  ROYALTY,
}

export const releaseValidationSchema = z
  .object({
    name: z.string().min(1),
    catalogNumber: z.string().min(1),
    releaseType: z.string().min(1),
    productType: z.string().min(1),
    releaseDate: z.date(),
    id: z.number().optional(),
    upcNumber: z.number().min(1),
    version: z.string().optional(),
    coverArt: z.string().optional(),
    releaseArtists: z.array(z.number()).min(1),
    notes: z.string().optional(),
  })
  .strict();

export const releaseTrackValidationSchema = z.object({
  name: z.string().min(1),
  id: z.number().optional(),
  contractId: z.number().min(1),
  gramexLabelPercentage: z.number().min(0),
  youtubeAssetReference: z.string().optional().nullable(),
  isrc: z.string().min(1),
  volume: z.number().min(1),
  trackArtists: z.array(z.any()).min(1),
  notes: z.string().optional(),
});

export const trackArtistRoyaltyValidationSchema = z.object({
  paymentAmount: z.number().min(0),
  recoupableAmount: z.number().min(0),
  royaltyPercentage: zodInputStringPipe(
    z.number().multipleOf(0.01).min(0).max(100),
  ),
  royaltyType: z.string(),
});

function selectSchema(schema: SchemaType) {
  switch (schema) {
    case SchemaType.RELEASE:
      return releaseValidationSchema;
    case SchemaType.TRACK:
      return releaseTrackValidationSchema;
    case SchemaType.ROYALTY:
      return trackArtistRoyaltyValidationSchema;
    default:
      throw new Error('No schema type prodived');
  }
}

/**
 * Validate parts of release with zod safeParse
 */
export function validateReleaseFormValues(args: ValidationArgs) {
  const selectedSchema = selectSchema(args.schema);
  const validationfn = selectedSchema.safeParse(args.values);

  if (!validationfn.success) {
    return validationfn.error.format();
  }

  return validationfn.success;
}
