import { ViewError } from '@/components/errors/view-error';
import { ViewLoader } from '@/components/loaders/view-loader';
import { Button } from '@/components/ui/button';
import { ValidateRoyaltyRulesQuery } from '@/gql/generated-types';
import { ApolloError } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { Cell, Pie, PieChart, ResponsiveContainer } from 'recharts';

interface RoyaltyAnalysisProps {
  data: ValidateRoyaltyRulesQuery | undefined;
  onValidate?: () => void;
  fetchLoading: boolean;
  fetchError: ApolloError | undefined;
}

const COLORS = [
  '#0000FF', // Blue
  '#87CEEB', // Sky Blue
  '#008000', // Green
  '#008080', // Teal
  '#FFA500', // Orange
  '#FFFF00', // Yellow
  '#FF0000', // Red
  '#8B0000', // Dark Red
];

export const RoyaltyAnalysis = (props: RoyaltyAnalysisProps) => {
  const { t } = useTranslation();

  return (
    <div className="bg-grey-darkest p-8 space-y-4">
      <div className="flex justify-between items-center">
        <h4 className="text-lg font-medium">{t('trackForm.royaltySplit')}</h4>
        {props.onValidate && (
          <Button size="sm" onClick={() => props.onValidate!()}>
            {t('trackForm.validate')}
          </Button>
        )}
      </div>
      {props.fetchLoading && <ViewLoader />}
      {props.fetchError && <ViewError />}
      {(() => {
        if (props.data?.validateRoyaltyRules) {
          const artistData =
            props.data.validateRoyaltyRules?.artistShares.map(a => {
              return {
                name: a?.name,
                value: Number(a?.amountEuro),
              };
            }) ?? [];
          const collabData =
            props.data.validateRoyaltyRules?.collaboratorShares.map(c => {
              return {
                name: c?.name,
                value: Number(c?.amountEuro),
              };
            }) ?? [];

          const data = artistData?.concat(collabData, [
            {
              name: 'PME',
              value: Number(props.data.validateRoyaltyRules?.pmeShare),
            },
          ]);

          if (data) {
            return (
              <div className="grid grid-cols-1 md:grid-cols-2 gap-10 p-4 px-8 bg-black">
                <div>
                  {props.data?.validateRoyaltyRules &&
                    props.data?.validateRoyaltyRules?.errors.length > 0 && (
                      <ul>
                        {props.data.validateRoyaltyRules.errors.map(
                          (e, index) => (
                            <li key={index}>
                              <b>{t('trackForm.error')}:</b>{' '}
                              {t(`trackForm.${e?.key}` ?? '')}
                            </li>
                          ),
                        )}
                      </ul>
                    )}
                  {props.data?.validateRoyaltyRules &&
                    props.data?.validateRoyaltyRules?.warnings.length > 0 && (
                      <ul>
                        {props.data.validateRoyaltyRules.warnings.map(
                          (e, index) => (
                            <li key={index}>
                              <b>{t('trackForm.warning')}:</b>{' '}
                              {t(`trackForm.${e?.key}` ?? '')}
                            </li>
                          ),
                        )}
                      </ul>
                    )}
                  {props.data.validateRoyaltyRules?.errors.length === 0 && (
                    <ResponsiveContainer width="100%" height={320}>
                      <PieChart width={400} height={320}>
                        <Pie
                          data={data}
                          cx="50%"
                          className="outline-none"
                          cy="50%"
                          outerRadius={100}
                          fill="#8884d8"
                          labelLine={false}
                          dataKey="value"
                        >
                          {data.map((_, index) => (
                            <Cell
                              className="outline-0"
                              key={`cell-${index}`}
                              fill={COLORS[index % COLORS.length]}
                            />
                          ))}
                        </Pie>
                      </PieChart>
                    </ResponsiveContainer>
                  )}
                </div>
                <div>
                  <ul>
                    {data.map((entry, index) => (
                      <li
                        key={index}
                        className="border-b p-4 flex items-center last:border-0"
                      >
                        <div
                          className="w-4 h-4 mr-2 block"
                          style={{
                            backgroundColor: `${COLORS[index % COLORS.length]}`,
                          }}
                        >
                          &nbsp;
                        </div>
                        <span className="font-bold mr-2">{entry.name}</span>{' '}
                        {entry.value}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            );
          }
        }
      })()}
    </div>
  );
};
