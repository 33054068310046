import { AdminHeader } from '@/components/partials/admin-header';
import { ReleaseForm } from '../forms/release';
import { useNavigate, useParams, useSearch } from '@tanstack/react-router';
import { GET_RELEASE_BY_ID } from '@/graphql/queries';
import { useMutation, useQuery } from '@apollo/client/react';
import { ViewLoader } from '@/components/loaders/view-loader';
import {
  initialReleaseData,
  releaseAtom,
} from '@/services/store/releases/releases';
import { useAtom } from 'jotai/react';
import { parseReleaseToState } from '../util/formatting';
import { Release, ReleaseTrack } from '@/services/store/releases/types';
import { tracksAtom } from '@/services/store/releases/tracks';
import { useGetArtistListQuery } from '@/gql/generated-types';
import { CREATE_RELEASE } from '@/graphql/mutations';
import { toast } from '@/components/ui/use-toast';
import { useState } from 'react';
import { ViewError } from '@/components/errors/view-error';
import { validateReleaseFormValues, SchemaType } from '../util/validation';
import { ADMIN_RELEASE } from '@/services/router/routes';
import { useTranslation } from 'react-i18next';

export function EditReleaseView() {
  const [release, setRelease] = useAtom(releaseAtom);
  const [dataReady, setDataReady] = useState<boolean>(false);
  const [formErrors, setFormErrors] = useState<string[]>([]);
  const [tracks] = useAtom(tracksAtom);
  const { t } = useTranslation();
  const { artistId: artistIdSearchParam } = useSearch({ strict: false });
  const navigate = useNavigate();
  const { releaseId } = useParams({ strict: false });
  const {
    data: releaseData,
    loading,
    error,
  } = useQuery(GET_RELEASE_BY_ID, {
    variables: {
      releaseId: Number(releaseId),
    },
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      setRelease(parseReleaseToState(data));
      setDataReady(true);
    },
  });

  const { data: artistList } = useGetArtistListQuery();

  const [createRelease] = useMutation(CREATE_RELEASE, {
    onError: _ => {
      toast({
        variant: 'destructive',
        title: t('toast.genericErrorTitle'),
        description: t('toast.createErrorDescription', {
          item: t('toast.items.release'),
        }),
      });
    },
    onCompleted: data => {
      toast({
        title: t('toast.genericSaveSuccessTitle'),
        description: t('toast.genericSaveSuccessDescription', {
          item: t('toast.items.release'),
        }),
      });
      setRelease(initialReleaseData);
      navigate({
        to: ADMIN_RELEASE,
        params: {
          releaseId: data.upsertRelease.id,
        },
        search: {
          artistId: artistIdSearchParam,
        },
      });
    },
  });

  if (loading) {
    return <ViewLoader />;
  }

  if (error) {
    return <ViewError />;
  }

  const artistSelectObject = artistList?.listArtists?.map(artist => {
    return {
      value: artist?.id,
      label: artist?.name,
    };
  });

  function createReleaseHandler() {
    // Validate data, return errors
    //

    // No errors mutate
    const { upcNumber, ...rest } = release;
    const rel = {
      ...rest,
      // Remove throw away id from tracks if not in edit mode
      tracks: tracks.map((track: ReleaseTrack) => {
        const { id, contractArtistId, ...restTrack } = track;

        return {
          ...restTrack,
        };
      }),
      upcNumber: Number(upcNumber),
    };
    const { tracks: relTrack, releaseDate, ...validationRel } = rel;

    const validate = validateReleaseFormValues({
      values: {
        // Date is stored as IsoString and validation requires Date
        releaseDate: new Date(releaseDate),
        ...validationRel,
      },
      schema: SchemaType.RELEASE,
    });
    // If validate doesnt return boolean, its an error
    if (typeof validate !== 'boolean') {
      setFormErrors(Object.keys(validate));
    } else {
      setFormErrors([]);
      createRelease({
        variables: {
          input: {
            releaseDate: new Date(releaseDate),
            ...validationRel,
          },
        },
      });
    }
  }

  const artistIdQuery = artistIdSearchParam
    ? `?artistId=${artistIdSearchParam}`
    : '';

  return (
    <>
      <AdminHeader
        title={t('adminDM.editRelease.title')}
        subTitle={releaseData?.release?.name}
        goBackLink={{
          label: t('adminShared.back'),
          link: `/admin/data-management/releases/${release.id}${artistIdQuery}`,
        }}
      />
      <div className="space-y-12">
        {dataReady && (
          <ReleaseForm
            values={release}
            artists={artistSelectObject}
            errors={formErrors}
            tracks={tracks}
            edit
            disableDnd
            resetFunction={() => setRelease(initialReleaseData)}
            onSubmit={() => createReleaseHandler()}
            onFieldChange={(field, value) =>
              setRelease((prev: Release) => ({
                ...prev,
                [field]: value,
              }))
            }
          />
        )}
      </div>
    </>
  );
}
