import * as React from 'react';

import { Button } from '@/components/ui/button';

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { MoreHorizontal } from 'lucide-react';
import { Link } from '@tanstack/react-router';
import { cn } from '@/lib/utils';
import { ADMIN_EDIT_RELEASE } from '@/services/router/routes';
import { useTranslation } from 'react-i18next';

export interface ReleaseActionsProps {
  onDuplicateAction?: () => void;
  onPromoAction?: () => void;
  onDeleteAction?: () => void;
  id: number;
  isPromoOrDuplicate?: boolean;
  artistId: string | null;
}

export function ReleaseActions(props: ReleaseActionsProps) {
  const [open, setOpen] = React.useState(false);
  const { t } = useTranslation();

  return (
    <>
      <DropdownMenu open={open} onOpenChange={setOpen}>
        <DropdownMenuTrigger asChild>
          <Button
            variant="ghost"
            size="iconSmall"
            className={cn(
              'border-0 focus:border-bone focus:border-1 focus:border',
              open && 'bg-grey-dark',
            )}
          >
            <>
              <span className="sr-only">{t('adminReleases.showMore')}</span>
              <MoreHorizontal />
            </>
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent
          align="end"
          className="w-[200px] mt-2 bg-bone shadow-xl"
        >
          <DropdownMenuGroup>
            <DropdownMenuItem className="text-grey-darkest text-sm">
              <Link
                to={ADMIN_EDIT_RELEASE}
                params={{
                  releaseId: props.id,
                }}
                search={{
                  artistId: props.artistId,
                }}
                className="w-full"
              >
                {t('adminReleases.edit')}
              </Link>
            </DropdownMenuItem>
            {!props.isPromoOrDuplicate && (
              <>
                <DropdownMenuSeparator className="bg-grey-darkest/10" />
                <DropdownMenuItem
                  className="text-grey-darkest text-sm cursor-pointer"
                  onClick={props.onDuplicateAction}
                >
                  {t('adminReleases.duplicate')}
                </DropdownMenuItem>
                <DropdownMenuItem
                  className="text-grey-darkest text-sm cursor-pointer"
                  onClick={props.onPromoAction}
                >
                  {t('adminReleases.promo')}
                </DropdownMenuItem>
              </>
            )}
          </DropdownMenuGroup>
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  );
}
