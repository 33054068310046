import { useGetStreamsByDateSuspenseQuery } from '@/gql/generated-types';
import { generateChartData } from '../streams/utils';
import { Line, LineChart, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import { parseDashboardYAxisLabel, parseXAxisLabel } from '../util';
import { RadialCard } from '@/components/ui/radial-card';
import { useTranslation } from 'react-i18next';

interface StreamChartProps {
  artistId: number;
  startDate: string;
  endDate: string;
  prevEndDate: string;
  prevStartDate: string;
  aspect?: number;
  trackId?: number;
}

export const StreamChart = (props: StreamChartProps) => {
  const { trackId } = props;
  const { t } = useTranslation();
  const { data: dateData } = useGetStreamsByDateSuspenseQuery({
    variables: {
      artistId: props.artistId,
      endDate: props.endDate,
      startDate: props.startDate,
      trackId,
    },
  });

  const { data: previousDateRangeData } = useGetStreamsByDateSuspenseQuery({
    variables: {
      artistId: props.artistId,
      endDate: props.prevEndDate,
      startDate: props.prevStartDate,
      trackId,
    },
  });

  const songs = [
    {
      name: 'AllStreamsOld',
      stroke: '#FFCA10',
      data: generateChartData(previousDateRangeData),
    },
    {
      name: 'AllStreams',
      stroke: '#D77EF5',
      data: generateChartData(dateData),
    },
  ];

  if (songs[0].data?.length === 0 && songs[1].data?.length === 0) {
    return (
      <RadialCard variant="purple" className="mt-4 text-center font-robotoMono">
        {t('artistShared.noData')}
      </RadialCard>
    );
  }
  return (
    <div>
      <ResponsiveContainer
        width="99%"
        minWidth={0}
        aspect={props.aspect ?? 1.2}
        className="flex flex-col"
      >
        <LineChart className="text-xs">
          <XAxis
            dataKey="position"
            type="number"
            tickLine={false}
            allowDuplicatedCategory={false}
            axisLine={true}
            tickCount={2}
            tickFormatter={tick =>
              parseXAxisLabel(tick, props.startDate, props.endDate)
            }
          />
          <YAxis
            tickLine={false}
            axisLine={true}
            dataKey="streams"
            tickFormatter={tick => parseDashboardYAxisLabel(tick)}
          />
          {songs.map((s, i) => (
            <Line
              key={i}
              type="monotone"
              data={s.data}
              name={s.name}
              dataKey="streams"
              strokeWidth={1}
              isAnimationActive={false}
              stroke={s.stroke}
              dot={false}
            />
          ))}
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};
