import { MONTHS } from '@/views/admin/data-management/contracts/forms/util';
import { useGetArtistIncomeTotalsV2Query } from '@/gql/generated-types';
import { useLayoutEffect, useRef, useState } from 'react';
import { ExpensesTotal } from './components/expenses-total';
import ArtistIncomeTotals from './components/income-totals';
import { TrackList } from './components/track-list';
import { RoyaltyPeriod, getDateAfter100Days, isDateBetween } from './util';
import { BalanceForm } from '@/components/forms/balance-royalty/form';
import { useTranslation } from 'react-i18next';
import { RadialCard } from '@/components/ui/radial-card';
import { DateDropdown } from './components/date-dropdown';
import { format } from 'date-fns';
import { cn } from '@/lib/utils';

type ArtistBalanceContentProps = {
  artistId: number;
  defaultValues: number | null;
  values: RoyaltyPeriod[];
};

export default function ArtistBalanceContent(props: ArtistBalanceContentProps) {
  const { t } = useTranslation();
  const [dateRangeOpen, setDateRangeOpen] = useState(false);
  const dateObject = props.values.find(v => v.index === props.defaultValues);

  // Use UTC date and format as yyyy-MM-dd to avoid sending times to the server.
  // See also the other setter down in this component.
  const [selectedDateRangeObject, setSelectedDateRangeObject] = useState({
    startDate: format(
      Date.UTC(
        dateObject!.start.year,
        dateObject!.start.month - 1,
        dateObject!.start.day,
      ),
      'yyyy-MM-dd',
    ),
    endDate: format(
      Date.UTC(
        dateObject!.end.year,
        dateObject!.end.month - 1,
        dateObject!.end.day,
      ),
      'yyyy-MM-dd',
    ),
    index: props.defaultValues, // Store index just in case
  });

  const {
    error: totalIncomeError,
    loading: totalIncomeLoading,
    data: totalIncome,
  } = useGetArtistIncomeTotalsV2Query({
    variables: {
      artistId: props.artistId,
      startDate: selectedDateRangeObject.startDate,
      endDate: selectedDateRangeObject.endDate,
    },
  });

  const startMonth =
    MONTHS[
      (props.values.find(v => v.index === selectedDateRangeObject.index)?.start
        .month ?? 0) - 1
    ].label;

  const endDay = props.values.find(
    v => v.index === selectedDateRangeObject.index,
  )?.end.day;

  const endMonth =
    MONTHS[
      (props.values.find(v => v.index === selectedDateRangeObject.index)?.end
        ?.month ?? 0) - 1
    ].label;

  const ref = useRef<HTMLDivElement>(null);
  const header = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    const top = ref.current?.getBoundingClientRect().top;

    const onScroll = () => {
      if (top && top < window.scrollY) {
        ref.current?.classList.add('pt-2');
        header.current?.classList.add('hidden');
      } else {
        ref.current?.classList.remove('pt-2');
        header.current?.classList.remove('hidden');
      }
    };

    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  const adminContainer = document.getElementById('adminMain');
  return (
    <div className="grid col-span-1 lg:grid-cols-3 gap-12 relative">
      <div
        className={cn(
          'grid col-span-1 lg:col-span-3 items-center grid-cols-3 mb-12 sticky z-50 top-0',
          adminContainer && 'static',
        )}
        ref={ref}
      >
        <div className="col-span-3 lg:col-span-2">
          <h2 className="h2 mb-5 lg:mb-0" ref={header}>
            {t('artistContracts.reportForRoyaltySeason')}
          </h2>
        </div>
        <DateDropdown
          startMonth={startMonth}
          endMonth={endMonth}
          endDay={Number(endDay)}
          open={dateRangeOpen}
          onOpenChange={setDateRangeOpen}
        >
          <BalanceForm
            defaultValueIndex={selectedDateRangeObject.index!}
            values={props.values}
            onChangeDateRange={value => {
              const dateObject = props.values.find(v => v.index === value);
              setSelectedDateRangeObject({
                startDate: format(
                  Date.UTC(
                    dateObject!.start.year,
                    dateObject!.start.month - 1,
                    dateObject!.start.day,
                  ),
                  'yyyy-MM-dd',
                ),
                endDate: format(
                  Date.UTC(
                    dateObject!.end.year,
                    dateObject!.end.month - 1,
                    dateObject!.end.day,
                  ),
                  'yyyy-MM-dd',
                ),
                index: value,
              });
              setDateRangeOpen(false);
            }}
          />
        </DateDropdown>
      </div>
      <div className="col-span-3">
        <div className="col-span-3 w-full">
          <RadialCard variant="purple" className="text-center">
            <p>
              {t('artistContracts.selectedRoyaltyPeriod')}
              <b>
                {' '}
                {1}.{startMonth}
                {' - '}
                {endDay}.{endMonth}
              </b>{' '}
              {isDateBetween(
                new Date(selectedDateRangeObject.startDate),
                new Date(selectedDateRangeObject.endDate),
                new Date(),
              )
                ? t('artistContracts.seasonIsOpen')
                : t('artistContracts.seasonIsOver')}
            </p>
            <p className="font-bold">
              {t('artistContracts.seasonIsBillableWhen')}{' '}
              {format(
                getDateAfter100Days(new Date(selectedDateRangeObject.endDate)),
                'dd.MM.yyyy',
              )}
            </p>
          </RadialCard>
        </div>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-3 w-full space-y-12 col-span-3 gap-12 admin-balance-cols">
        <div className="space-y-6">
          <ArtistIncomeTotals
            totals={totalIncome}
            loading={totalIncomeLoading}
            error={totalIncomeError}
          />
        </div>
        <div className="space-y-6">
          <TrackList
            artistId={props.artistId}
            startDate={selectedDateRangeObject.startDate}
            endDate={selectedDateRangeObject.endDate}
          />
        </div>
        <div className="space-y-6">
          <ExpensesTotal
            artistId={props.artistId}
            startDate={selectedDateRangeObject.startDate}
            endDate={selectedDateRangeObject.endDate}
          />
        </div>
      </div>
    </div>
  );
}
