import * as z from 'zod';

export const zodInputStringPipe = (zodPipe: z.ZodTypeAny) =>
  z
    .string()
    .transform(value => (value === '' ? null : value))
    .nullable()
    .refine(value => value === null || !isNaN(Number(value)), {
      message: 'Invalid Number',
    })
    .transform(value => (value === null ? '' : Number(value)))
    .pipe(zodPipe);
