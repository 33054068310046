import { useTranslation } from 'react-i18next';
import { Alert, AlertDescription, AlertTitle } from '../ui/alert';

export function ViewError() {
  const { t } = useTranslation();
  return (
    <Alert variant="destructive" className="p-10">
      <AlertTitle className="text-2xl">{t('viewError.errorTitle')}</AlertTitle>
      <AlertDescription>{t('viewError.errorDesc')}</AlertDescription>
    </Alert>
  );
}
