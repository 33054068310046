import { useSession } from '@clerk/clerk-react';
import { ActiveSessionResource } from '@clerk/types';
import { Navigate } from '@tanstack/react-router';
import { checkUserRole } from './services/authentication/clerk';

function App() {
  const { session } = useSession();
  const userRole = checkUserRole(session as ActiveSessionResource);

  if (userRole === 'artist') {
    return <Navigate to="/artist/" />;
  }

  if (userRole === 'admin') {
    return <Navigate to="/admin/" />;
  }

  return null;
}

export default App;
