import { Button } from '@/components/ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import {
  SelectContent,
  SelectItem,
  Select,
  SelectTrigger,
} from '@/components/ui/select';
import { Switch } from '@/components/ui/switch';
import {
  CollaboratorRole,
  GetCompassPersonsQuery,
} from '@/gql/generated-types';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';
type CreateArtistFormProps = {
  data: GetCompassPersonsQuery | undefined;
  onSubmit: (values: z.infer<typeof createCollaboratorSchema>) => void;
};

type PersonsList =
  | {
      value: number | undefined;
      label: string;
    }[]
  | undefined;

const createCollaboratorSchema = z.object({
  active: z.boolean(),
  costCenter: z.string().min(1),
  type: z.string().min(1),
  name: z.string().min(1),
  personId: z.number().min(1),
});

export function CreateCollaboratorForm({
  data,
  onSubmit,
}: CreateArtistFormProps) {
  const { t } = useTranslation();
  const form = useForm<z.infer<typeof createCollaboratorSchema>>({
    resolver: zodResolver(createCollaboratorSchema),
    defaultValues: {
      active: true,
      costCenter: '',
      name: '',
      type: CollaboratorRole.Producer,
      personId: 0,
    },
  });

  const contactPersonsMappedList: PersonsList = data?.persons?.map(person => {
    return {
      label: `${person?.contractPerson.firstName} ${person?.contractPerson.lastName}`,
      value: person?.id,
    };
  });

  function handleCreateCollaborator(
    values: z.infer<typeof createCollaboratorSchema>,
  ) {
    onSubmit(values);
  }
  return (
    <div>
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(handleCreateCollaborator)}
          className="space-y-12"
        >
          <div className="grid grid-cols-1 md:grid-cols-2 gap-x-12 gap-y-4">
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t('createCollaboratorForm.name')}</FormLabel>
                  <FormControl>
                    <Input {...field} autoComplete="off" />
                  </FormControl>
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="costCenter"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    {t('createCollaboratorForm.costCenter')}
                  </FormLabel>
                  <FormControl>
                    <Input {...field} autoComplete="off" />
                  </FormControl>
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="type"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t('createCollaboratorForm.role')}</FormLabel>
                  <Select
                    value={field.value}
                    onValueChange={value => field.onChange(value)}
                  >
                    <FormControl>
                      <SelectTrigger>{t(field.value)}</SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      <SelectItem value={CollaboratorRole.Producer}>
                        {t('PRODUCER')}
                      </SelectItem>
                      <SelectItem value={CollaboratorRole.Songwriter}>
                        {t('SONGWRITER')}
                      </SelectItem>
                      <SelectItem value={CollaboratorRole.ThirdParty}>
                        {t('THIRD_PARTY')}
                      </SelectItem>
                    </SelectContent>
                  </Select>
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="personId"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    {t('createCollaboratorForm.contractPerson')}
                  </FormLabel>
                  <Select
                    value={field.value.toString()}
                    onValueChange={value => field.onChange(Number(value))}
                  >
                    <FormControl>
                      <SelectTrigger>
                        {contactPersonsMappedList?.find(
                          person => person.value === Number(field.value),
                        )?.label ??
                          t('createCollaboratorForm.selectContractPerson')}
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {contactPersonsMappedList?.map(person => (
                        <SelectItem value={person.value?.toString() ?? ''}>
                          {person.label}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="active"
              render={({ field }) => (
                <FormItem className="flex items-center space-y-0 space-x-4 mt-4">
                  <FormLabel>{t('createCollaboratorForm.active')}</FormLabel>
                  <FormControl>
                    <Switch
                      checked={field.value}
                      onCheckedChange={field.onChange}
                    />
                  </FormControl>
                </FormItem>
              )}
            />
          </div>
          <Button>{t('createCollaboratorForm.createCollaborator')}</Button>
        </form>
      </Form>
    </div>
  );
}
