import { useGetStreamsSuspenseQuery } from '@/gql/generated-types';
import { useTranslation } from 'react-i18next';

interface SongStatProps {
  artistId: number;
  endDate: string;
  startDate: string;
  trackId?: number;
}

export const SongStats = (props: SongStatProps) => {
  const { artistId, trackId, endDate, startDate } = props;
  const { t } = useTranslation();

  const { data } = useGetStreamsSuspenseQuery({
    variables: {
      artistId,
      trackId,
      startDate,
      endDate,
    },
  });

  const trackStreamData = data?.streamsForArtistByTrack![0];
  return (
    <div className="grid grid-cols-1 md:grid-cols-5 gap-12 items-center relative">
      <div className="md:col-span-2 order-1 md:order-3 gradient-bottom relative">
        <img
          src={trackStreamData?.trackCoverArt ?? '/assets/default_cover.png'}
          alt={trackStreamData?.trackName ?? ''}
          className="w-full h-auto"
        />
        <div className="md:col-span-3 md:order-4 space-y-6 items-center absolute bottom-6 order-2 left-6 md:hidden">
          <h1 className="h1-small-mobile">{trackStreamData?.trackName}</h1>
          <h4 className="h4">
            {t('artistPages.streams.streamsFromDifferentServices')}
          </h4>
          <h2 className="h2-mono">
            {Intl.NumberFormat('fi-FI').format(trackStreamData?.totalStreams) ??
              0}
          </h2>
        </div>
      </div>
      <div className="md:col-span-3 hidden md:block md:order-4 space-y-6 items-center  order-2">
        <h1 className="h1-small">{trackStreamData?.trackName}</h1>
        <h4 className="h4">
          {t('artistPages.streams.streamsFromDifferentServices')}
        </h4>
        <h2 className="h2-mono">
          {Intl.NumberFormat('fi-FI').format(trackStreamData?.totalStreams) ??
            0}
        </h2>
      </div>
    </div>
  );
};
