import { Button } from '@/components/ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
} from '@/components/ui/select';
import { Switch } from '@/components/ui/switch';
import { GetArtistListQuery, GetContractsQuery } from '@/gql/generated-types';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import { contractFormSchema, defaultValues } from './schema';
import {
  MONTHS,
  generateRoyaltyPeriodArray,
  twoDecimalsIfNeeded,
} from './util';
import { DayPicker } from '@/components/ui/day-picker';

import { Label } from '@/components/ui/label';
import { NumberInput } from '@/components/ui/number-input';
import { Textarea } from '@/components/ui/textarea';

export interface ContractSubmitValues {
  id?: number;
  royaltyPeriod: number[];
  dateSigned: string | null;
  type: string;
  royaltyShareType: string;
  artistId?: number;
  lengthTracks?: number;
  masterOwnership?: number;
  nonCompete?: boolean | undefined;
  royaltySharePercentage?: string;
  producerGramexShare?: number;
  numberOfOptions?: number;
  copyrightC?: string | undefined;
  copyrightP?: string | undefined;
}

type CreateContractFormProps = {
  artists?: GetArtistListQuery;
  edit?: boolean;
  contract?: GetContractsQuery;
  handleCreateContract: (contract: ContractSubmitValues) => void;
};

function mapContractToValues(
  contracts?: GetContractsQuery,
  artist?: GetArtistListQuery,
): z.infer<typeof contractFormSchema> | undefined {
  if (!artist || !contracts) return;

  if (contracts.contracts === null || contracts.contracts === undefined) return;

  const contract = contracts.contracts[0];
  return {
    id: contract?.id,
    artistId: contract?.artist.id.toString(),
    dateSigned: new Date(contract?.dateSigned),
    lengthTracks: contract?.lengthTracks ?? 0,
    nonCompete: contract?.nonCompete ?? false,
    masterOwnership: contract?.masterOwnership ?? 0,
    royaltyPeriodLength: contract?.royaltyPeriod.length ?? 0,
    notes: contract?.notes ?? '',
    royaltyPeriodStartMonth: contract?.royaltyPeriod[0].toString() ?? '1',
    royaltySharePercentage: contract?.royaltySharePercentage
      ? contract?.royaltySharePercentage
      : '0',
    type: contract?.type.toString() ?? '', // NOTE: gql enum vs zod string
    producerGramexShare: contract?.producerGramexShare ?? 0,
    numberOfOptions: contract?.numberOfOptions ?? 0,
    copyrightC: contract?.copyrightC ?? '',
    copyrightP: contract?.copyrightP ?? '',
    royaltyShareType: contract?.royaltyShareType.toString() ?? '',
  };
}

export function CreateContractForm(props: CreateContractFormProps) {
  const { t } = useTranslation();
  const form = useForm<z.infer<typeof contractFormSchema>>({
    resolver: zodResolver(contractFormSchema),
    values:
      props.edit && props.contract
        ? mapContractToValues(props.contract, props.artists)
        : defaultValues,
    defaultValues,
  });

  const royaltyPeriodLength = form.watch('royaltyPeriodLength');
  const numberOfOptions = form.watch('numberOfOptions');
  const trackCount = form.watch('lengthTracks');

  function handleCreateContract(values: z.infer<typeof contractFormSchema>) {
    const {
      dateSigned,
      artistId,
      royaltyPeriodLength,
      royaltyPeriodStartMonth,
      masterOwnership,
      ...rest
    } = values;

    props.handleCreateContract({
      ...rest,
      artistId: Number(artistId),
      masterOwnership,
      royaltyPeriod: generateRoyaltyPeriodArray(
        Number(royaltyPeriodStartMonth),
        royaltyPeriodLength,
      ),
      dateSigned: dateSigned ? new Date(dateSigned).toISOString() : null,
    });
  }

  return (
    <div className="space-y-12 md:max-w-[75%]">
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(handleCreateContract)}
          className="space-y-12"
        >
          <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-5">
            <div className="col-span-2">
              <h4 className="h4 mb-0">{t('contractForm.basicInfo')}</h4>
            </div>
            {props.edit && (
              <div className="space-y-2">
                <Label>{t('contractForm.artistName')}</Label>
                <div className="bg-grey-darkest p-2">
                  {
                    props.artists?.listArtists?.find(
                      i => i?.id === Number(form.getValues('artistId')),
                    )?.name
                  }
                </div>
              </div>
            )}
            {!props.edit && (
              <FormField
                name="artistId"
                control={form.control}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t('contractForm.artist')}</FormLabel>
                    <Select
                      value={field.value}
                      onValueChange={e => {
                        field.onChange(e);
                      }}
                    >
                      <FormControl>
                        <SelectTrigger className="w-full">
                          {field.value === ''
                            ? t('contractForm.selectArtist')
                            : props.artists?.listArtists?.find(
                                i => i?.id.toString() === field.value,
                              )?.name}
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent className="max-h-[200px]">
                        <SelectItem value="">
                          {t('adminShared.emptySelection')}
                        </SelectItem>
                        {props.artists?.listArtists?.map((artist, index) => {
                          if (artist === null) return;
                          return (
                            <SelectItem
                              key={index}
                              value={artist.id.toString()}
                            >
                              {artist.name}
                            </SelectItem>
                          );
                        })}
                      </SelectContent>
                    </Select>
                  </FormItem>
                )}
              />
            )}
            <FormField
              name="type"
              control={form.control}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t('contractForm.contractType')}</FormLabel>
                  <Select
                    value={field.value}
                    onValueChange={e => {
                      field.onChange(e);
                    }}
                  >
                    <FormControl>
                      <SelectTrigger className="w-full">
                        {field.value === ''
                          ? t('contractForm.selectContractType')
                          : t(field.value)}
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent className="max-h-[200px]">
                      <SelectItem value="">
                        {t('adminShared.emptySelection')}
                      </SelectItem>
                      <SelectItem value="MASTER">{t('MASTER')}</SelectItem>
                      <SelectItem value="LICENSING">
                        {t('LICENSING')}
                      </SelectItem>
                      <SelectItem value="JOINT_VENTURE">
                        {t('JOINT_VENTURE')}
                      </SelectItem>
                      <SelectItem value="OTHER">{t('OTHER')}</SelectItem>
                    </SelectContent>
                  </Select>
                </FormItem>
              )}
            />
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-5">
            <div className="col-span-2">
              <h4 className="h4 mb-0">{t('contractForm.contract')}</h4>
            </div>
            <FormField
              control={form.control}
              name="dateSigned"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t('contractForm.dateSigned')}</FormLabel>
                  <FormControl>
                    <DayPicker
                      onDateChange={date => field.onChange(date)}
                      selectedDate={field.value ? field.value : new Date()}
                    />
                  </FormControl>
                </FormItem>
              )}
            />
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-x-4 gap-y-5">
            <div className="col-span-3">
              <h4 className="h4 mb-0">{t('contractForm.lengthOfContract')}</h4>
            </div>
            <FormField
              control={form.control}
              name="lengthTracks"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t('contractForm.amountOfReleases')}</FormLabel>
                  <FormControl>
                    <NumberInput
                      value={field.value}
                      onInputValueChange={value => field.onChange(value)}
                      placeholder={t('contractForm.amountOfReleases')}
                      autoComplete="off"
                    />
                  </FormControl>
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="numberOfOptions"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t('contractForm.numberOfOptions')}</FormLabel>
                  <FormControl>
                    <NumberInput
                      value={field.value}
                      onInputValueChange={value => field.onChange(value)}
                      placeholder={t('contractForm.numberOfOptions')}
                      autoComplete="off"
                    />
                  </FormControl>
                </FormItem>
              )}
            />
            <div className="mt-4 pt-[22px]">
              {t('contractForm.amount', {
                count: trackCount + trackCount * numberOfOptions,
              })}
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-x-4 gap-y-5">
            <div className="col-span-3">
              <h4 className="h4 mb-0">{t('contractForm.masterOwnership')}</h4>
            </div>
            <FormField
              control={form.control}
              name="masterOwnership"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    {t('contractForm.masterOwnership')} (PME)
                  </FormLabel>
                  <FormControl>
                    <NumberInput
                      value={field.value}
                      onInputValueChange={value => field.onChange(value)}
                      placeholder={`${t('contractForm.masterOwnership')} %`}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-x-4 gap-y-5">
            <div className="col-span-3">
              <h4 className="h4 mb-0">{t('contractForm.gramexProducer')}</h4>
            </div>
            <FormField
              control={form.control}
              name="producerGramexShare"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t('contractForm.gramexProducer')}</FormLabel>
                  <FormControl>
                    <NumberInput
                      value={field.value}
                      onInputValueChange={value => field.onChange(value)}
                      placeholder={t('contractForm.gramexProducer')}
                    />
                  </FormControl>
                </FormItem>
              )}
            />
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-x-4 gap-y-5">
            <div className="col-span-3">
              <h4 className="h4 mb-0">{t('contractForm.copyright')}</h4>
            </div>
            <FormField
              control={form.control}
              name="copyrightC"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t('contractForm.copyrightC')}</FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      placeholder={t('contractForm.copyrightC')}
                    />
                  </FormControl>
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="copyrightP"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t('contractForm.copyrightP')}</FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      placeholder={t('contractForm.copyrightP')}
                    />
                  </FormControl>
                </FormItem>
              )}
            />
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-x-4 gap-y-5">
            <div className="col-span-3">
              <h4 className="h4 mb-0">
                {t('contractForm.royaltySharePercentage')}
              </h4>
            </div>
            <FormField
              control={form.control}
              name="royaltySharePercentage"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    {t('contractForm.royaltySharePercentage')}
                  </FormLabel>
                  <FormControl>
                    <Input
                      autoComplete="off"
                      type="number"
                      onWheel={event => event.currentTarget.blur()}
                      value={field.value}
                      onChange={e => field.onChange(e.target.value)}
                      placeholder={t('contractForm.royaltySharePercentage')}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="royaltyShareType"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t('contractForm.royaltyShareType')}</FormLabel>
                  <Select
                    value={field.value}
                    onValueChange={e => {
                      field.onChange(e);
                    }}
                  >
                    <FormControl>
                      <SelectTrigger className="w-full">
                        {t(`contractForm.${field.value}`)}
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent className="max-h-[200px]">
                      <SelectItem value="ACCOUNTING_PRICE">
                        {t('contractForm.ACCOUNTING_PRICE')}
                      </SelectItem>
                      <SelectItem value="ARTIST_SHARE">
                        {t('contractForm.ARTIST_SHARE')}
                      </SelectItem>
                    </SelectContent>
                  </Select>
                </FormItem>
              )}
            />
          </div>
          <div className="grid grid-cols-4 gap-x-4 gap-y-5">
            <div className="col-span-4">
              <h4 className="h4 mb-0">{t('contractForm.royaltyPeriod')}</h4>
            </div>
            <FormField
              control={form.control}
              name="royaltyPeriodLength"
              render={({ field }) => (
                <FormItem className="col-span-1">
                  <FormLabel>{t('contractForm.royaltyPeriodCount')}</FormLabel>
                  <FormControl>
                    <NumberInput
                      value={field.value}
                      onInputValueChange={e => field.onChange(e)}
                      placeholder={t('contractForm.royaltyPeriodCount')}
                      autoComplete="off"
                    />
                  </FormControl>
                </FormItem>
              )}
            />
            <div className="space-y-4 col-span-1">
              <FormLabel>{t('contractForm.lengthOfPeriod')}</FormLabel>
              <p>
                {royaltyPeriodLength && royaltyPeriodLength
                  ? twoDecimalsIfNeeded(12 / royaltyPeriodLength)
                  : 12}
              </p>
            </div>
            <FormField
              name="royaltyPeriodStartMonth"
              control={form.control}
              render={({ field }) => (
                <FormItem className="col-span-2">
                  <FormLabel>
                    {t('contractForm.startOfRoyaltyPeriod')}
                  </FormLabel>
                  <Select
                    value={field.value}
                    onValueChange={e => {
                      field.onChange(e);
                    }}
                  >
                    <FormControl>
                      <SelectTrigger className="w-full">
                        {field.value === ''
                          ? t('contractForm.selectStartOfPeriod')
                          : MONTHS.find(
                              i => i?.value.toString() === field.value,
                            )?.label}
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent className="max-h-[200px]">
                      <SelectItem value="">
                        {t('adminShared.emptySelection')}
                      </SelectItem>
                      {MONTHS.map((month, index) => {
                        if (month === null) return;
                        return (
                          <SelectItem
                            className="capitalize"
                            key={index}
                            value={month.value.toString()}
                          >
                            {month.label}
                          </SelectItem>
                        );
                      })}
                    </SelectContent>
                  </Select>
                </FormItem>
              )}
            />
          </div>
          <FormField
            control={form.control}
            name="nonCompete"
            render={({ field }) => (
              <FormItem className="flex items-center space-x-4 space-y-0">
                <Switch
                  checked={field.value}
                  onCheckedChange={field.onChange}
                />
                <FormLabel>{t('contractForm.nonCompete')}</FormLabel>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="notes"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t('contractForm.notes')}</FormLabel>
                <Textarea {...field} />
              </FormItem>
            )}
          />
          <Button type="submit" className="my-10">
            {t('contractForm.saveContract')}
          </Button>
        </form>
      </Form>
    </div>
  );
}
