import { Img } from '@/components/ui/Img';
import { Button } from '@/components/ui/button';
import {
  EnhacedTableCell,
  EnhacedTableHeading,
  EnhancedTable,
  EnhancedTableHeader,
  EnhancedTableRow,
} from '@/components/ui/enhanced-table';
import { useGetStreamsSuspenseQuery } from '@/gql/generated-types';
import { Link } from '@tanstack/react-router';
import { ChevronDown, ChevronUp } from 'lucide-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface TrackListTableProps {
  endDate: string;
  startDate: string;
  artistId: number;
}

export function TrackListTable(props: TrackListTableProps) {
  const [visibleRows, setVisibleRows] = useState(10);
  const { t } = useTranslation();
  const { data } = useGetStreamsSuspenseQuery({
    variables: {
      artistId: props.artistId,
      endDate: props.endDate,
      startDate: props.startDate,
    },
  });
  return (
    <div className="space-y-6">
      <EnhancedTable>
        <EnhancedTableHeader
          role="row"
          aria-rowindex={1}
          className="grid-cols-songListEnhancedTableMobile lg:grid-cols-songListEnhancedTable"
        >
          <EnhacedTableHeading
            aria-colindex={1}
            className="text-left justify-start pl-0"
          >
            {t('artistPages.streams.song')}
          </EnhacedTableHeading>
          <EnhacedTableHeading className="hidden lg:flex" aria-colindex={2}>
            Spotify
          </EnhacedTableHeading>
          <EnhacedTableHeading className="hidden lg:flex" aria-colindex={3}>
            Deezer
          </EnhacedTableHeading>
          <EnhacedTableHeading className="hidden lg:flex" aria-colindex={4}>
            Amazon
          </EnhacedTableHeading>
          <EnhacedTableHeading className="hidden lg:flex" aria-colindex={5}>
            SoundCloud
          </EnhacedTableHeading>
          <EnhacedTableHeading className="hidden lg:flex" aria-colindex={6}>
            YouTube
          </EnhacedTableHeading>
          <EnhacedTableHeading className="hidden lg:flex" aria-colindex={7}>
            YouTube Music
          </EnhacedTableHeading>
          <EnhacedTableHeading className="hidden lg:flex" aria-colindex={8}>
            Apple Music
          </EnhacedTableHeading>
          <EnhacedTableHeading aria-colindex={9} className="flex">
            {t('artistPages.streams.overAll')}
          </EnhacedTableHeading>
        </EnhancedTableHeader>
        {data?.streamsForArtistByTrack
          ?.slice(0, visibleRows)
          .map((item, index) => (
            <Link
              to={`/artist/${props.artistId}/streams/${item?.trackId}`}
              key={index}
              title={item?.trackName ?? ''}
            >
              <EnhancedTableRow
                key={index}
                variant={index % 2 !== 0 ? 'odd' : null}
                role="row"
                className="grid-cols-songListEnhancedTableMobile lg:grid-cols-songListEnhancedTable"
              >
                <EnhacedTableCell className="text-left justify-start pl-0 text-sm min-w-[156px]">
                  <Img
                    src={item?.trackCoverArt ?? '/assets/default_cover.png'}
                    alt={item?.trackName ?? ''}
                    className="w-[56px]"
                    fallback={
                      <img
                        alt={item?.trackName ?? ''}
                        src="/assets/default_cover.png"
                        className="w-[56px]"
                      />
                    }
                  />
                  <div className="pl-2 min-w-[100px] truncate">
                    {item?.trackName}
                  </div>
                </EnhacedTableCell>
                <EnhacedTableCell className="hidden lg:flex">
                  {new Intl.NumberFormat('fi-FI').format(
                    item?.streamsByDsp?.find(
                      item => item?.dspName === 'spotify',
                    )?.count ?? 0,
                  )}
                </EnhacedTableCell>
                <EnhacedTableCell className="hidden lg:flex">
                  {new Intl.NumberFormat('fi-FI').format(
                    item?.streamsByDsp?.find(item => item?.dspName === 'deezer')
                      ?.count ?? 0,
                  )}
                </EnhacedTableCell>
                <EnhacedTableCell className="hidden lg:flex">
                  {new Intl.NumberFormat('fi-FI').format(
                    item?.streamsByDsp?.find(item => item?.dspName === 'amazon')
                      ?.count ?? 0,
                  )}
                </EnhacedTableCell>
                <EnhacedTableCell className="hidden lg:flex">
                  {new Intl.NumberFormat('fi-FI').format(
                    item?.streamsByDsp?.find(
                      item => item?.dspName === 'soundcloud',
                    )?.count ?? 0,
                  )}
                </EnhacedTableCell>
                <EnhacedTableCell className="hidden lg:flex">
                  {new Intl.NumberFormat('fi-FI').format(
                    item?.streamsByDsp?.find(
                      item => item?.dspName === 'youtube',
                    )?.count ?? 0,
                  )}
                </EnhacedTableCell>
                <EnhacedTableCell className="hidden lg:flex">
                  {new Intl.NumberFormat('fi-FI').format(
                    item?.streamsByDsp?.find(
                      item => item?.dspName === 'youtube_music',
                    )?.count ?? 0,
                  )}
                </EnhacedTableCell>
                <EnhacedTableCell className="hidden lg:flex">
                  {new Intl.NumberFormat('fi-FI').format(
                    item?.streamsByDsp?.find(item => item?.dspName === 'itunes')
                      ?.count ?? 0,
                  )}
                </EnhacedTableCell>
                <EnhacedTableCell className="flex">
                  {new Intl.NumberFormat('fi-FI').format(item?.totalStreams) ??
                    0}
                </EnhacedTableCell>
              </EnhancedTableRow>
            </Link>
          ))}
      </EnhancedTable>

      {data?.streamsForArtistByTrack &&
        data?.streamsForArtistByTrack?.length > 10 && (
          <div className="w-full text-center">
            <Button
              size="sm"
              variant="outline"
              className="mx-auto justify-self-center flex"
              onClick={() => {
                if (
                  visibleRows < (data?.streamsForArtistByTrack?.length ?? 0)
                ) {
                  setVisibleRows(visibleRows + 10);
                } else {
                  setVisibleRows(10);
                }
              }}
            >
              {visibleRows < data?.streamsForArtistByTrack!.length
                ? t('artistPages.streams.showMore')
                : t('artistPages.streams.showLess')}
              {visibleRows < data?.streamsForArtistByTrack!.length ? (
                <ChevronDown className="ml-2 h-4 w-4" />
              ) : (
                <ChevronUp className="ml-2 h-4 w-4" />
              )}
            </Button>
          </div>
        )}
    </div>
  );
}
