import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface IsrcFormProps {
  onSubmit: (isrc: string) => void;
}

export const IsrcForm = (props: IsrcFormProps) => {
  const [isrc, setIsrc] = useState<string>('');
  const { t } = useTranslation();

  const handleIsrcSubmit = () => {
    props.onSubmit(isrc);
  };

  return (
    <div className="flex space-x-2 items-center min-w-[300px]">
      <Input value={isrc} onChange={e => setIsrc(e.target.value)} />
      <Button
        onClick={() => handleIsrcSubmit()}
        disabled={isrc.length === 0}
        size="sm"
      >
        {t('nonMatched.save')}
      </Button>
    </div>
  );
};
