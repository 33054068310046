import { GetContractsQuery } from '@/gql/generated-types';
import { Button, buttonVariants } from '@/components/ui/button';
import {
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  Table,
} from '@/components/ui/table';
import { format } from 'date-fns';
import { Link } from '@tanstack/react-router';
import { ADMIN_CONTRACT, ADMIN_EDIT_CONTRACT } from '@/services/router/routes';
import { ChevronRight } from 'lucide-react';
import { useTranslation } from 'react-i18next';
interface ContractTableProps {
  data: GetContractsQuery['contracts'];
  onDeleteAction: (id: number) => void;
}

export const ContractTable = (props: ContractTableProps) => {
  const { t } = useTranslation();
  if (props.data?.length === 0) {
    return <p>{t('adminContracts.noResults')}</p>;
  }

  return (
    <Table className="border-collapse">
      <TableHeader>
        <TableRow className="text-left">
          <TableHead>{t('adminContracts.artist')}</TableHead>
          <TableHead>{t('adminContracts.signingDate')}</TableHead>
          <TableHead>{t('adminContracts.contractLength')}</TableHead>
          <TableHead></TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {props.data?.map((contract, index) => (
          <TableRow variant={index % 2 === 0 ? 'odd' : null} key={index}>
            <TableCell>{contract?.artist.name}</TableCell>
            <TableCell>
              {format(new Date(contract?.dateSigned), 'dd.MM.yyyy')}
            </TableCell>
            <TableCell>
              {Number(contract?.lengthTracks) +
                Number(contract?.lengthTracks) *
                  Number(contract?.numberOfOptions)}
            </TableCell>
            <TableCell className="text-right flex space-x-2 items-center justify-end">
              {contract?.deletable && (
                <Button
                  variant="destructive"
                  size="sm"
                  onClick={() => props.onDeleteAction(contract?.id)}
                >
                  {t('adminContracts.delete')}
                </Button>
              )}
              <Link
                to={ADMIN_EDIT_CONTRACT}
                params={{
                  contractId: contract?.id,
                }}
                aria-label={`Muokkaa sopimusta`}
                className={buttonVariants({
                  variant: 'outline',
                  size: 'sm',
                })}
              >
                {t('adminContracts.edit')}
              </Link>
              <Link
                to={ADMIN_CONTRACT}
                params={{
                  contractId: contract?.id,
                }}
                className={buttonVariants({
                  variant: 'default',
                  size: 'icon',
                })}
              >
                <span className="sr-only">{t('adminContracts.goTo')}</span>
                <ChevronRight className="w-6 h-6" />
              </Link>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
