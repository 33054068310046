import { Button } from '@/components/ui/button';
import { DayPicker } from '@/components/ui/day-picker';
import {
  FormControl,
  FormField,
  Form,
  FormItem,
  FormLabel,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
} from '@/components/ui/select';
import { GetArtistListQuery } from '@/gql/generated-types';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as z from 'zod';

export const paymentsSchema = z.object({
  artistId: z.number().min(1),
  title: z.string().optional(),
  comment: z.string().optional(),
  amount: z
    .string()
    .transform(val => Number(`${val}`.replace(',', '.')))
    .pipe(z.number()),
  date: z.date(),
});

const defaultValues = {
  artistId: 0,
  title: '',
  comment: '',
  amount: 0,
  date: new Date(),
};

export interface CreatePaymentFormProps {
  onSubmit: (values: z.infer<typeof paymentsSchema>) => void;
  artists?: GetArtistListQuery['listArtists'];
}

export const CreatePaymentForm = (props: CreatePaymentFormProps) => {
  const { t } = useTranslation();
  const form = useForm<z.infer<typeof paymentsSchema>>({
    resolver: zodResolver(paymentsSchema),
    defaultValues,
  });

  function handleSubmit(values: z.infer<typeof paymentsSchema>) {
    props.onSubmit(values);
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(handleSubmit)} className="space-y-12">
        <div className="grid-cols-1 md:grid-cols-2 grid gap-x-12 gap-y-4">
          <FormField
            control={form.control}
            name="artistId"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t('paymentsForm.artist')}</FormLabel>
                <Select
                  value={field.value.toString()}
                  onValueChange={e => {
                    field.onChange(Number(e));
                  }}
                >
                  <FormControl>
                    <SelectTrigger className="w-full">
                      {field.value === 0
                        ? t('paymentsForm.selectArtist')
                        : props.artists?.find(
                            i => i?.id.toString() === field.value.toString(),
                          )?.name}
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent className="max-h-[200px]">
                    <SelectItem value="">
                      {t('adminShared.emptySelection')}
                    </SelectItem>
                    {props.artists?.map((artist, index) => {
                      if (artist === null) return;
                      return (
                        <SelectItem key={index} value={artist.id.toString()}>
                          {artist.name}
                        </SelectItem>
                      );
                    })}
                  </SelectContent>
                </Select>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="amount"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t('paymentsForm.sum')}</FormLabel>
                <FormControl>
                  <Input
                    value={field.value}
                    autoComplete="off"
                    onChange={e => {
                      const pattern: RegExp = /[^0-9.,]/g;
                      const inputValue = e.target.value;
                      const parsedInputValue = inputValue.replace(pattern, '');
                      field.onChange(parsedInputValue);
                    }}
                  />
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="title"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t('paymentsForm.title')}</FormLabel>
                <FormControl>
                  <Input {...field} autoComplete="off" />
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="comment"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t('paymentsForm.desc')}</FormLabel>
                <FormControl>
                  <Input {...field} autoComplete="off" />
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="date"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t('paymentsForm.date')}</FormLabel>
                <FormControl>
                  <DayPicker
                    onDateChange={date => {
                      field.onChange(date);
                    }}
                    selectedDate={field.value}
                  />
                </FormControl>
              </FormItem>
            )}
          />
        </div>
        <Button>{t('paymentsForm.savePayment')}</Button>
      </form>
    </Form>
  );
};
