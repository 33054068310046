import { AdminHeader } from '@/components/partials/admin-header';
import {
  RoyaltySourceProvider,
  UploadType,
  useGetPresignedUrlQuery,
  useGetStatementsRunsLazyQuery,
} from '@/gql/generated-types';
import { SubmitFormResponse, UploadRoyaltyStatementForm } from './forms/upload';
import { ViewLoader } from '@/components/loaders/view-loader';
import { toast } from '@/components/ui/use-toast';
import {
  Table,
  TableHeader,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
} from '@/components/ui/table';
import { useState } from 'react';
import { Loader2 } from 'lucide-react';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

export function UploadRoyaltyStatementView() {
  const [uploading, setUploadingState] = useState(false);
  const { refetch: fetchMore } = useGetPresignedUrlQuery({
    skip: true,
  });

  const { t } = useTranslation();
  const [refetch, { data: statementsData, loading: statementsLoading }] =
    useGetStatementsRunsLazyQuery({});

  function handleDSPSelection(dsp: RoyaltySourceProvider) {
    refetch({
      variables: {
        royaltyProvider: dsp,
        take: 10,
      },
    });
  }

  async function handleStatementUpload(values: SubmitFormResponse) {
    setUploadingState(true);
    const preSignedUrl = await fetchMore({
      fileName: values.fileName.name,
      year: values.year,
      month: values.month,
      uploadType: UploadType.Royalty,
      dsp: values.dsp as RoyaltySourceProvider,
    });

    if (!preSignedUrl.data.getPreSignedUrl) {
      toast({
        variant: 'destructive',
        title: t('toast.genericErrorTitle'),
        description: t('toast.uploadPreSignErrorDescription'),
      });

      return;
    }

    const response = await fetch(preSignedUrl.data.getPreSignedUrl, {
      method: 'PUT',
      body: values.fileName,
      headers: {
        'content-type': 'application/octet-stream',
      },
    });

    if (!response.ok) {
      toast({
        variant: 'destructive',
        title: t('toast.genericErrorTitle'),
        description: t('toast.uploadErrorDescription', {
          fileName: values.fileName.name,
        }),
      });
      setUploadingState(false);
    } else {
      toast({
        variant: 'default',
        title: t('toast.uploadSuccessTitle'),
        description: t('toast.uploadSuccessDescription'),
      });
      setUploadingState(false);
    }
  }

  return (
    <>
      <AdminHeader title={t('adminDM.statement.title')} />
      <div className="space-y-12">
        {uploading && <Loader2 className="animate-spin" />}
        {!uploading && (
          <UploadRoyaltyStatementForm
            onSubmit={handleStatementUpload}
            onDspSelect={dsp => {
              // handling string instead of gql enum is easier
              handleDSPSelection(dsp as RoyaltySourceProvider);
            }}
          />
        )}
        <div>{statementsLoading && <ViewLoader />}</div>
        {statementsData?.getRoyaltyStatementRuns &&
          statementsData?.getRoyaltyStatementRuns.length > 0 && (
            <Table className="border-collapse">
              <TableHeader>
                <TableRow>
                  <TableHead>{t('adminDM.statement.file')}</TableHead>
                  <TableHead>{t('adminDM.statement.season')}</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {statementsData.getRoyaltyStatementRuns?.map(data => (
                  <TableRow>
                    <TableCell>{data?.fileName}</TableCell>
                    <TableCell>
                      {format(new Date(data?.periodStartDate), 'dd.MM.yyyy')} -{' '}
                      {format(new Date(data?.periodEndDate), 'dd.MM.yyyy')}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
      </div>
    </>
  );
}
