import { Table, TableBody, TableCell, TableRow } from '@/components/ui/table';
import {
  StreamCountGroupBy,
  useGetStreamsByCategorySuspenseQuery,
} from '@/gql/generated-types';

interface DSPTableProps {
  artistId: number;
  trackId?: number;
  endDate: string;
  startDate: string;
}

export const DSPTable = (props: DSPTableProps) => {
  const { trackId, artistId, startDate, endDate } = props;

  const { data: tracksQueryByDSP } = useGetStreamsByCategorySuspenseQuery({
    variables: {
      artistId,
      trackId,
      startDate,
      endDate,
      groupBy: StreamCountGroupBy.Dsp,
    },
  });

  const mapDspName = (key: string) => {
    switch (key.toLowerCase()) {
      case 'spotify':
        return 'Spotify';
      case 'deezer':
        return 'Deezer';
      case 'amazon':
        return 'Amazon';
      case 'soundcloud':
        return 'SoundCloud';
      case 'youtube':
        return 'YouTube';
      case 'youtube_music':
        return 'YouTube Music';
      case 'itunes':
        return 'Apple Music';
      default:
        return key;
    }
  };

  return (
    <Table className="border-collapse">
      <TableBody>
        {Object.entries(tracksQueryByDSP?.streamCount ?? {})
          .filter(item => item[0] !== '__typename' && item[0] !== 'total')
          .map((item, index) => (
            <TableRow key={index} variant={index % 2 === 0 ? 'odd' : null}>
              <TableCell>{mapDspName(item[0])}</TableCell>
              <TableCell className="text-right">
                {Intl.NumberFormat('fi-FI').format(Number(item[1])) ?? 0}
              </TableCell>
            </TableRow>
          ))}
      </TableBody>
    </Table>
  );
};
