import { useTranslation } from 'react-i18next';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
} from '@/components/ui/select';
import { useGetArtistListQuery } from '@/gql/generated-types';
import { Label } from '@/components/ui/label';

interface ArtistSelectProps {
  onSelect: (artist: string) => void;
  value: string | undefined;
}

export const ArtistSelect = (props: ArtistSelectProps) => {
  const { t } = useTranslation();
  const { data: artistList } = useGetArtistListQuery();
  return (
    <div className="space-y-4 w-full min-w-full">
      <Label>{t('adminShared.selectArtist')}</Label>
      <Select
        value={props.value ?? ''}
        onValueChange={value => {
          props.onSelect(value);
        }}
      >
        <SelectTrigger>
          {props.value
            ? artistList?.listArtists?.find(
                artist => artist?.id.toString() === props.value,
              )?.name
            : t('adminShared.selectArtist')}
        </SelectTrigger>
        <SelectContent>
          <SelectItem value="">{t('adminShared.allArtists')}</SelectItem>
          {artistList?.listArtists?.map((artist, index) => (
            <SelectItem value={artist!.id.toString()} key={index}>
              {artist?.name}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </div>
  );
};
