import { useTranslation } from 'react-i18next';
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';

export const NoContractError = () => {
  const { t } = useTranslation();
  return (
    <Alert variant="destructive" className="p-10">
      <AlertTitle className="text-2xl">
        {t('artistContracts.noContractErrorTitle')}
      </AlertTitle>
      <AlertDescription>
        {t('artistContracts.noContractErrorDesc')}
      </AlertDescription>
    </Alert>
  );
};

export const FaultyContract = () => {
  const { t } = useTranslation();
  return (
    <Alert variant="destructive" className="p-10">
      <AlertTitle className="text-2xl">
        {t('artistContracts.faultyContractErrorTitle')}
      </AlertTitle>
      <AlertDescription>
        {t('artistContracts.faultyContractErrorDesc')}
      </AlertDescription>
    </Alert>
  );
};
