import clsx from 'clsx';
import { ChevronDown } from 'lucide-react';
import {
  ClassNamesConfig,
  DropdownIndicatorProps,
  components,
} from 'react-select';

export const DropdownIndicator = (props: DropdownIndicatorProps) => {
  return (
    <components.DropdownIndicator {...props}>
      <ChevronDown className="h-4 w-4 opacity-50" />
    </components.DropdownIndicator>
  );
};

export const multiSelectStyles: ClassNamesConfig = {
  control: ({ isDisabled }) =>
    clsx(
      isDisabled && 'opacity-50',
      'bg-white px-2 text-sm text-grey-darkest focus:outline-purple focus:outline-2 rounded-md !min-h-[40px]',
    ),
  menu: () => 'bg-white rounded-md mt-1 text-sm',
  option: ({ isFocused, isSelected }) =>
    clsx(
      isFocused && 'bg-slate-100',
      isSelected && 'bg-yellow-dark',
      'text-sm text-grey-darkest focus:bg-slate-100 focus:text-grey-darkest rounded-md py-2.5 pl-8 pr-2 cursor-default',
    ),
  noOptionsMessage: () => 'text-grey-dark text-sm',
  multiValue: () => 'bg-grey-darkest text-bone m-1 rounded-md py-1 px-2',
};
