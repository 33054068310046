import { useGetArtistProfileQuery } from '@/gql/generated-types';
import { cn } from '@/lib/utils';
import {
  ADMIN_ARTIST,
  ARTIST_BALANCE,
  ARTIST_BASE,
  ARTIST_STREAMS,
} from '@/services/router/routes';
import { Link, useParams, useRouterState } from '@tanstack/react-router';
import { X } from 'lucide-react';
import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

interface AdminArtistNavItemProps extends PropsWithChildren {
  active?: boolean;
  to: string;
  params?: Record<string, string>;
}

export const AdminArtistNavItem = (props: AdminArtistNavItemProps) => {
  return (
    <Link
      to={props.to}
      params={props.params}
      className={cn(
        'hover:text-yellow-dark',
        props.active && 'text-yellow-dark',
      )}
    >
      {props.children}
    </Link>
  );
};

export const AdminArtistNav = () => {
  const { artistId } = useParams({ strict: false });
  const { t } = useTranslation();
  const state = useRouterState();
  const { data, loading } = useGetArtistProfileQuery({
    variables: {
      artistId: Number(artistId),
    },
  });
  return (
    <div className="bg-grey-darkest md:h-12 md:min-h-12 md:flex items-center text-base">
      <div className="p-2 bg-yellow-dark text-grey-darkest flex items-center px-4 md:h-full w-full md:w-auto">
        {loading && '...'}
        {!loading && data && (
          <>
            {data?.artist?.name}
            <Link to={ADMIN_ARTIST}>
              <X className="w-4 h-4 ml-4 text-grey-darkest" />
            </Link>
          </>
        )}
      </div>
      <nav className="flex space-x-6 md:mx-6 md:h-12 items-center bg-grey-darkest w-full md:w-auto px-4 py-2 md:p-0">
        <AdminArtistNavItem
          active={
            state.location.pathname.includes('/artist/') &&
            !state.location.pathname.includes('/admin') &&
            !state.location.pathname.includes('/streams') &&
            !state.location.pathname.includes('/balance')
          }
          to={ARTIST_BASE}
          params={{
            artistId,
          }}
        >
          {t('adminNav.artistNav.frontpage')}
        </AdminArtistNavItem>
        <AdminArtistNavItem
          active={
            state.location.pathname.includes('/artist/') &&
            state.location.pathname.includes('/streams') &&
            !state.location.pathname.includes('/admin')
          }
          to={ARTIST_STREAMS}
          params={{
            artistId,
          }}
        >
          {t('adminNav.artistNav.streams')}
        </AdminArtistNavItem>
        <AdminArtistNavItem
          active={
            state.location.pathname.includes('/artist/') &&
            state.location.pathname.includes('/balance') &&
            !state.location.pathname.includes('/admin')
          }
          to={ARTIST_BALANCE}
          params={{
            artistId,
          }}
        >
          {t('adminNav.artistNav.balance')}
        </AdminArtistNavItem>
      </nav>
    </div>
  );
};
