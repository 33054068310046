import { Link, useRouterState } from '@tanstack/react-router';
import { PropsWithChildren, useEffect, useState } from 'react';
import { Sheet, SheetContent } from './components/ui/sheet';
import { Button } from './components/ui/button';
import { AdminSideNav } from './components/partials/admin-side-nav';
import { Menu, X } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { AdminArtistNav } from './components/partials/admin-artist-nav';
import { cn } from './lib/utils';

interface AdminLayoutProps extends PropsWithChildren {
  artistMode: boolean;
}

export default function AdminLayout(props: AdminLayoutProps) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const router = useRouterState();

  useEffect(() => {
    setOpen(false);
  }, [router.location.pathname]);
  return (
    <div className="flex flex-col lg:flex-row flex-nowrap relative overflow-hidden h-full w-full">
      <div className="w-full flex lg:hidden justify-between px-9 py-5">
        <Link to="/admin">
          <img src="/assets/pme-logo.svg" alt={t('adminNav.logoAlt')} />
        </Link>

        <Sheet onOpenChange={open => setOpen(open)} open={open}>
          <Button
            onClick={() => setOpen(!open)}
            size="icon"
            variant="ghost"
            asChild
            aria-label={t('adminNav.open')}
            className="text-yellow-dark lg:hidden cursor-pointer"
          >
            <Menu className="w-8 h-8 text-yellow-dark" />
          </Button>
          <SheetContent
            className="bg-grey-darkest p-0 overflow-y-scroll"
            side="right"
          >
            <Button
              className="absolute right-9 top-9 bg-grey-darkest"
              size="iconSmall"
              variant="ghost"
              onClick={() => setOpen(false)}
            >
              <X className="h-8 w-8 text-white" />
              <span className="sr-only">{t('adminNav.close')}</span>
            </Button>

            <AdminSideNav />
          </SheetContent>
        </Sheet>
      </div>
      <div className="xl:w-96 h-full bg-grey-darkest lg:flex hidden border-r border-black">
        <AdminSideNav />
      </div>

      <div
        className="overflow-hidden overflow-y-scroll relative flex-grow h-full w-full flex flex-col z-50"
        id="adminMain"
      >
        {props.artistMode && <AdminArtistNav />}
        <div className={cn(props.artistMode ? 'admin-artist' : 'admin')}>
          {props.children}
        </div>
      </div>
    </div>
  );
}
