import { Button } from '@/components/ui/button';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import { ListCollaboratorsQuery } from '@/gql/generated-types';
import { useTranslation } from 'react-i18next';

interface CollaboratorTableProps {
  data: ListCollaboratorsQuery['listCollaborators'] | undefined;
  onDeleteAction: (id: number) => void;
}

export const CollaboratorTable = (props: CollaboratorTableProps) => {
  const { t } = useTranslation();

  if (props.data?.length === 0 || typeof props.data === 'undefined') {
    return <p>{t('adminArtist.collaboratorTable.noResults')}</p>;
  }

  return (
    <Table className="border-collapse">
      <TableHeader>
        <TableRow className="text-left">
          <TableHead>{t('adminArtist.collaboratorTable.name')}</TableHead>
          <TableHead>{t('adminArtist.collaboratorTable.type')}</TableHead>
          <TableHead></TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {props.data?.map((collab, index) => (
          <TableRow variant={index % 2 === 0 ? 'odd' : null} key={index}>
            <TableCell>{collab?.name}</TableCell>
            <TableCell>
              {t(`adminArtist.collaboratorTable.${collab?.type}` ?? '')}
            </TableCell>
            <TableCell className="text-right">
              {collab?.deletable && (
                <Button
                  variant="destructive"
                  size="sm"
                  onClick={() => props.onDeleteAction(Number(collab?.id))}
                >
                  {t('adminArtist.collaboratorTable.delete')}
                </Button>
              )}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
