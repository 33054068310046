import { ActiveSessionResource } from '@clerk/types';
import { checkUserRole } from './clerk';

export function isUserAdmin(session: ActiveSessionResource | null | undefined) {
  const userRole = checkUserRole(session as ActiveSessionResource);

  if (userRole === 'admin') {
    return true;
  }

  return false;
}
