import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Item } from './item';
import { SortableItemProps } from './types';

export const SortableItem = ({
  item,
  onDelete,
  edit,
  disableDnd,
  ...props
}: SortableItemProps) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id: item!.order!,
      // Disable animation, creates visual bugs on certain browsers
      transition: null,
    });

  const styles = {
    transform: CSS.Transform.toString(transform),
    transition: transition || undefined,
    cursor: disableDnd ? 'default' : 'pointer',
  };

  return (
    <Item
      item={item}
      ref={setNodeRef}
      disabledDnd={disableDnd}
      style={styles}
      onDelete={track => onDelete(track)}
      edit={edit}
      {...props}
      {...attributes}
      {...listeners}
    />
  );
};
