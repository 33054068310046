import { ViewError } from '@/components/errors/view-error';
import { CreateArtistForm } from '@/views/admin/data-management/users/forms/artist';
import { ViewLoader } from '@/components/loaders/view-loader';
import { AdminHeader } from '@/components/partials/admin-header';
import {
  useGetArtistProfileQuery,
  useGetCompassPersonsQuery,
} from '@/gql/generated-types';
import { ADMIN_ARTIST } from '@/services/router/routes';
import { useNavigate, useParams } from '@tanstack/react-router';
import { createArtistSchema } from './forms/schema';
import { toast } from '@/components/ui/use-toast';
import { useMutation } from '@apollo/client';
import { CREATE_ARTIST } from '@/graphql/mutations';
import * as z from 'zod';
import { useTranslation } from 'react-i18next';
export default function EditArtist() {
  const { t } = useTranslation();
  const { artistId } = useParams({ strict: false });
  const navigate = useNavigate();
  const { loading, error, data } = useGetCompassPersonsQuery();
  const {
    data: artist,
    loading: artistLoading,
    error: artistError,
  } = useGetArtistProfileQuery({
    variables: {
      artistId: Number(artistId),
    },
  });

  const [createUser] = useMutation(CREATE_ARTIST, {
    onError: _ => {
      toast({
        variant: 'destructive',
        title: t('toast.genericErrorTitle'),
        description: t('toast.createErrorDescription', {
          item: t('toast.items.artistProfile'),
        }),
      });
    },
    onCompleted: _ => {
      toast({
        title: t('toast.genericSaveSuccessTitle'),
        description: t('toast.genericSaveSuccessDescription', {
          item: t('toast.items.artistProfile'),
        }),
      });

      navigate({ to: ADMIN_ARTIST });
    },
  });

  function handleSubmit(values: z.infer<typeof createArtistSchema>) {
    createUser({
      variables: {
        input: values,
      },
    });
  }

  if (error || artistError) {
    return <ViewError />;
  }

  return (
    <>
      <AdminHeader
        title={t('adminDM.editArtist.title')}
        goBackLink={{
          link: ADMIN_ARTIST,
          label: t('adminShared.back'),
        }}
      />

      {(loading || artistLoading) && <ViewLoader />}
      {!loading && !artistLoading && artist && data && (
        <CreateArtistForm
          data={data}
          onSubmit={handleSubmit}
          artist={artist}
          edit
        />
      )}
    </>
  );
}
