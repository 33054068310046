import { Label } from '@/components/ui/label';
import { cn } from '@/lib/utils';
import { PropsWithChildren } from 'react';

export type FormContainerProps = PropsWithChildren & {
  label: string;
  className?: string;
  error?: boolean;
};

export function FormContainer(props: FormContainerProps) {
  return (
    <div
      className={cn(
        'space-y-2',
        props.className,
        props.error && 'text-red-500',
      )}
    >
      <Label>{props.label}</Label>
      {props.children}
    </div>
  );
}
