import { Button } from '@/components/ui/button';
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import {
  SelectContent,
  SelectItem,
  SelectTrigger,
} from '@/components/ui/select';
import { GetArtistListQuery } from '@/gql/generated-types';
import {
  DropdownIndicator,
  multiSelectStyles,
} from '@/styles/multi-select.styles';
import { zodResolver } from '@hookform/resolvers/zod';
import { Select } from '@radix-ui/react-select';
import { InfoIcon } from 'lucide-react';
import { useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { default as MultiSelect, MultiValue } from 'react-select';
import { z } from 'zod';
import { createUserSchema, userFormDefaultValues } from './schema';

type CreateUserFormProps = {
  artists?: GetArtistListQuery;
  handleOnCreateUser: (values: z.infer<typeof createUserSchema>) => void;
};

type ArtistList =
  | {
      value: number | undefined;
      label: string | undefined;
    }[]
  | undefined;

export function CreateUserForm({
  artists,
  handleOnCreateUser,
}: CreateUserFormProps) {
  // NOTE: Disabled any type validation here, because React-Select doesn't export the correct types
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const artistSelect = useRef<any>(null);
  const { t } = useTranslation();
  const form = useForm<z.infer<typeof createUserSchema>>({
    resolver: zodResolver(createUserSchema),
    defaultValues: userFormDefaultValues,
  });

  const handleCreateUser = async (data: z.infer<typeof createUserSchema>) => {
    handleOnCreateUser(data);
  };

  const artistSelectObject: ArtistList = artists?.listArtists?.map(artist => {
    return {
      value: artist?.id,
      label: artist?.name,
    };
  });

  return (
    <div>
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(handleCreateUser)}
          className="space-y-12"
        >
          <div className="grid-cols-1 lg:grid-cols-2 grid gap-x-12 gap-y-4">
            <div className="lg:col-span-2">
              <h4 className="h4">{t('createUserForm.basicInfo')}</h4>
            </div>
            <FormField
              name="firstName"
              control={form.control}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t('createUserForm.firstName')}</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                </FormItem>
              )}
            />
            <FormField
              name="lastName"
              control={form.control}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t('createUserForm.lastName')}</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                </FormItem>
              )}
            />
            <FormField
              name="telephone"
              control={form.control}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t('createUserForm.phone')}</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                </FormItem>
              )}
            />
            <FormField
              name="role"
              control={form.control}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t('createUserForm.role')}</FormLabel>
                  <Select
                    value={field.value}
                    onValueChange={e => {
                      field.onChange(e);
                    }}
                  >
                    <FormControl>
                      <SelectTrigger className="w-full">
                        {field.value === 'ARTIST' ? 'Artisti' : 'Admin'}
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent className="max-h-[200px]">
                      <SelectItem value="ARTIST">
                        {t('createUserForm.ARTIST')}
                      </SelectItem>
                      <SelectItem value="ADMIN">
                        {t('createUserForm.ADMIN')}
                      </SelectItem>
                    </SelectContent>
                  </Select>
                </FormItem>
              )}
            />
            <FormField
              name="email"
              control={form.control}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t('email')}</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormDescription className="text-bone flex items-center p-4 rounded-lg bg-grey-dark/30">
                    <InfoIcon className="mr-2" />
                    {t('createUserForm.emailUseDescription')}
                  </FormDescription>
                </FormItem>
              )}
            />
          </div>

          <div className="grid-cols-1 md:grid-cols-2 grid gap-x-12 gap-y-4">
            <div className="col-span-2">
              <h4 className="h4">{t('createUserForm.artistInfo')}</h4>
            </div>
            <FormField
              control={form.control}
              name="artistIds"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t('createUserForm.artistProfile')}</FormLabel>
                  <FormControl>
                    <MultiSelect<typeof artistSelectObject | unknown, true>
                      ref={artistSelect}
                      placeholder={t('createUserForm.selectArtistProfile')}
                      components={{ DropdownIndicator }}
                      classNames={multiSelectStyles}
                      defaultValue={artistSelectObject?.filter(artist =>
                        artists?.listArtists?.includes(artist.value as never),
                      )}
                      onChange={(
                        value: MultiValue<typeof artistSelectObject | unknown>,
                      ) => {
                        // @ts-expect-error ts-migrate(2339) FIXME: 'artist' is of type 'unknown'
                        field.onChange(value.map(artist => artist.value));
                      }}
                      isMulti={true}
                      unstyled
                      name="artistSelect"
                      options={artistSelectObject}
                      hideSelectedOptions={false}
                    />
                  </FormControl>
                </FormItem>
              )}
            />
            <FormField
              name="gramexNumber"
              control={form.control}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t('createUserForm.gramexNumber')}</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>

          <Button>{t('createUserForm.createUserSaveButton')}</Button>
        </form>
      </Form>
    </div>
  );
}
