import { useSession } from '@clerk/clerk-react';
import { ActiveSessionResource } from '@clerk/types';
import { Link, useParams, useRouter } from '@tanstack/react-router';
import { checkUserRole } from '../../services/authentication/clerk';
import { ArtistNav } from './artist-nav';
import { useEffect, useState } from 'react';

export function Nav() {
  const { session } = useSession();
  const userRole = checkUserRole(session as ActiveSessionResource);
  const router = useRouter();
  const { artistId } = useParams({ strict: false });
  const [path, setPath] = useState<string | null>(null);

  useEffect(() => {
    setPath(router.state.location.pathname);
  }, [router.state.location.pathname]);

  if (userRole === 'admin') return null;

  // Logic:
  // 1. If artist & in artist profile -> profile dashboard
  // 2. Artist & not in profile -> profile selection
  // 3. Admin
  const logoLinkLocation =
    userRole === 'artist' && artistId
      ? `/artist/${artistId}`
      : userRole === 'artist' && !artistId
        ? '/artist'
        : '/admin';
  return (
    <header className="w-full mt-10">
      <div className="container flex h-10 items-center justify-between">
        {path !== '/' && (
          <>
            <Link to={logoLinkLocation}>
              <img src="/assets/pme-logo.svg" alt="PME Compass" />
            </Link>

            {session && (
              <>
                {userRole === 'artist' &&
                  path?.includes('/artist') &&
                  path !== '/artist' && <ArtistNav />}
              </>
            )}
          </>
        )}
      </div>
    </header>
  );
}
