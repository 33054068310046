import { ViewError } from '@/components/errors/view-error';
import { ViewLoader } from '@/components/loaders/view-loader';
import { AdminHeader } from '@/components/partials/admin-header';
import { Input } from '@/components/ui/input';
import { useGetContractsQuery } from '@/gql/generated-types';
import { ADMIN_CREATE_CONTRACT } from '@/services/router/routes';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ContractTable } from './components/table';
import { useMutation } from '@apollo/client';
import { DELETE_CONTRACT } from '@/graphql/mutations';
import { toast } from '@/components/ui/use-toast';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
export default function Contracts() {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState<number | null>(null);
  const { data, loading, error, refetch } = useGetContractsQuery({});

  const [deleteContract] = useMutation(DELETE_CONTRACT, {
    onError: _ => {
      toast({
        variant: 'destructive',
        title: t('toast.genericErrorTitle'),
        description: t('toast.contractDeleteErrorDescription'),
      });
    },
    onCompleted: _ => {
      refetch();
      toast({
        title: t('toast.genericDeleteSuccessTitle'),
        description: t('toast.genericDeleteSuccessDescription', {
          item: t('toast.items.contract'),
        }),
      });
      setOpen(false);
      setSelected(null);
    },
  });

  if (error) {
    return <ViewError />;
  }

  const filteredContractsList = data?.contracts?.filter(contracts =>
    contracts?.artist?.name?.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  return (
    <>
      <AdminHeader
        title={t('adminContracts.title')}
        actionLink={{
          link: ADMIN_CREATE_CONTRACT,
          label: t('adminContracts.createContract'),
        }}
      />
      <div className="space-y-4 md:space-y-0">
        <div className="space-y-4 lg:max-w-[25%] max-w-full">
          <Input
            type="text"
            placeholder={t('adminContracts.searchForArtist')}
            className="text-grey-darkest placeholder:text-grey-dark font-roboto border-0 px-2 w-full"
            onChange={event => setSearchTerm(event.target.value)}
          />
        </div>
      </div>
      {loading && <ViewLoader />}
      {!loading && data && (
        <div className="block whitespace-nowrap overflow-x-auto w-full">
          <ContractTable
            data={filteredContractsList ?? []}
            onDeleteAction={id => {
              setSelected(id);
              setOpen(true);
            }}
          />
        </div>
      )}
      <Dialog
        open={open}
        onOpenChange={open => {
          if (!open) {
            setSelected(null);
          }
          setOpen(open);
        }}
      >
        <DialogContent className="space-y-6 max-h-[800px] overflow-scroll">
          <DialogHeader>
            <DialogTitle className="text-xl font-medium">
              {t('adminContracts.dialogTitle')}
            </DialogTitle>
            <DialogDescription>
              {t('adminContracts.dialogDescription')}
            </DialogDescription>
          </DialogHeader>

          <div className="flex justify-between">
            <Button
              type="button"
              size="sm"
              variant="outline"
              onClick={() => setOpen(false)}
            >
              {t('adminContracts.cancel')}
            </Button>
            <Button
              type="button"
              size="sm"
              variant="destructive"
              onClick={() =>
                deleteContract({
                  variables: {
                    deleteArtistContractId: selected,
                  },
                })
              }
            >
              {t('adminContracts.delete')}
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}
