import { cva, type VariantProps } from 'class-variance-authority';
import { cn } from '@/lib/utils';
import { forwardRef, HTMLAttributes } from 'react';

const EnhancedTable = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement>
>(({ className, children, ...props }, ref) => {
  return (
    <div role="grid" {...props} ref={ref} className={cn(className)}>
      {children}
    </div>
  );
});

EnhancedTable.displayName = 'EnhancedTable';

const EnhancedTableHeader = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement>
>(({ className, children, ...props }, ref) => {
  return (
    <div
      {...props}
      className={cn(
        'h-[56px] grid grid-cols-enhancedTable gap-x-0.5 align-middle',
        className,
      )}
      ref={ref}
    >
      {children}
    </div>
  );
});
EnhancedTableHeader.displayName = 'EnhancedTableHeader';

const EnhacedTableHeading = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement>
>(({ className, children, ...props }, ref) => {
  return (
    <div
      {...props}
      className={cn(
        'font-robotoMono text-center px-4 text-grey-light text-[10px] tracking-[1.5px] flex items-center justify-center',
        className,
      )}
      ref={ref}
    >
      {children}
    </div>
  );
});
EnhacedTableHeading.displayName = 'EnhancedTableHeading';

const enhancedTableRowVariants = cva('text-bone', {
  variants: {
    variant: {
      odd: 'bg-grey-darkest',
    },
  },
});

export interface EnhancedTableRowProps
  extends HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof enhancedTableRowVariants> {}

const EnhancedTableRow = forwardRef<HTMLDivElement, EnhancedTableRowProps>(
  ({ className, children, variant, ...props }, ref) => {
    return (
      <div
        {...props}
        className={cn(
          'grid gap-x-0.5 align-middle h-[56px]',
          enhancedTableRowVariants({ variant }),
          className,
        )}
        ref={ref}
      >
        {children}
      </div>
    );
  },
);
EnhancedTableRow.displayName = 'EnhancedTableRow';

const EnhacedTableCell = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement>
>(({ className, children, ...props }, ref) => {
  return (
    <div
      {...props}
      className={cn(
        'text-center px-4 text-grey-light flex justify-center items-center',
        className,
      )}
      ref={ref}
    >
      {children}
    </div>
  );
});
EnhacedTableCell.displayName = 'EnhacedTableCell';

export {
  EnhancedTable,
  EnhancedTableHeader,
  EnhacedTableCell,
  EnhacedTableHeading,
  EnhancedTableRow,
};
