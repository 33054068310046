import { ViewError } from '@/components/errors/view-error';
import { ViewLoader } from '@/components/loaders/view-loader';
import { AdminHeader } from '@/components/partials/admin-header';
import { useGetAssetsReferencesQuery } from '@/gql/generated-types';
import { RoyaltyTable } from './components/table';
import { Button } from '@/components/ui/button';
import { useMutation } from '@apollo/client';
import { PROCESS_ROYALTIES, UPDATE_UNMATCHED } from '@/graphql/mutations';
import { toast } from '@/components/ui/use-toast';
import { Loader2 } from 'lucide-react';
import { useTranslation } from 'react-i18next';

export default function NonMatched() {
  const { t } = useTranslation();
  const { data, loading, error, refetch } = useGetAssetsReferencesQuery({
    fetchPolicy: 'no-cache',
    variables: {
      unprocessedOnly: true,
    },
  });

  const [processRoyalties, { loading: processing }] =
    useMutation(PROCESS_ROYALTIES);

  const [updateUnmatchedTrack] = useMutation(UPDATE_UNMATCHED, {
    onError: _ => {
      toast({
        variant: 'destructive',
        title: t('nonMatched.toastErrorTitle'),
        description: t('nonMatched.toastErrorDescription'),
      });
    },
    onCompleted: _ => {
      toast({
        title: t('nonMatched.toastSuccessTitle'),
        description: '',
      });
      refetch();
    },
  });

  if (error) {
    return <ViewError />;
  }

  function handleRoyaltyProcessing() {
    toast({
      title: t('nonMatched.toastStartedTitle'),
      description: t('nonMatched.toastStartedDescription'),
    });

    processRoyalties();
  }

  return (
    <>
      <AdminHeader title={t('nonMatched.title')} />
      {loading && <ViewLoader />}
      {data && !loading && (
        <div className="space-y-6">
          <div>
            <Button
              onClick={() => handleRoyaltyProcessing()}
              disabled={processing}
            >
              {processing && <Loader2 className="animate-spin" />}
              {t('nonMatched.processRoyalties')}
            </Button>
          </div>
          <RoyaltyTable
            data={data.assetReferences}
            onSubmit={values =>
              updateUnmatchedTrack({
                variables: {
                  input: values,
                },
              })
            }
          />
        </div>
      )}
    </>
  );
}
