import * as z from 'zod';

export const createArtistSchema = z.object({
  id: z.number().optional(),
  active: z.boolean(),
  costCenter: z.string().min(1),
  imageUrl: z.string().optional(),
  name: z.string().min(1),
  persons: z.array(z.number().min(1)),
});

export const defaultValues: z.infer<typeof createArtistSchema> = {
  active: true,
  costCenter: '',
  imageUrl: '',
  name: '',
  persons: [],
};

export const createUserSchema = z.object({
  artistIds: z.array(z.number()),
  email: z.string().email(),
  firstName: z.string().min(1),
  lastName: z.string().min(1),
  role: z.enum(['ADMIN', 'ARTIST']),
  telephone: z.string(),
  gramexNumber: z.string().optional(),
});

export const userFormDefaultValues: z.infer<typeof createUserSchema> = {
  artistIds: [],
  email: '',
  firstName: '',
  lastName: '',
  role: 'ARTIST',
  telephone: '',
  gramexNumber: '',
};
