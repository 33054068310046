import { RadialCard } from '@/components/ui/radial-card';
import { AlertOctagon, AlertTriangle } from 'lucide-react';
import { useTranslation } from 'react-i18next';

export function NoDataFoundForSeason() {
  const { t } = useTranslation();
  return (
    <RadialCard className="text-center">
      <AlertTriangle className="mx-auto mb-4" />
      {t('artistContracts.noDataFoundForSeason')}
    </RadialCard>
  );
}

export function ErrorWhileFetchingDataForSeason() {
  const { t } = useTranslation();
  return (
    <RadialCard className="text-center">
      <AlertOctagon className="mx-auto mb-4" />
      {t('artistContracts.errorWhileFetching')}
    </RadialCard>
  );
}
