import * as React from 'react';
import { cva, type VariantProps } from 'class-variance-authority';

import { cn } from '@/lib/utils';

const badgeVariants = cva(
  'inline-flex uppercase align-self-center items-center rounded-full font-extrabold transition-colors focus:outline-none',
  {
    variants: {
      variant: {
        default: 'bg-lime text-grey-darkest',
        duplicate: 'bg-yellow-dark text-grey-darkest',
        promo: 'bg-purple-500 text-bone',
      },
      size: {
        default: 'px-[8px] pt-[2px] tracking-[1.6px] text-[8px] leading-[12px]',
        large: 'px-8 py-1 text-sm',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  },
);

export interface BadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof badgeVariants> {}

function Badge({ className, variant, size, ...props }: BadgeProps) {
  return (
    <div
      className={cn(badgeVariants({ variant, size }), className)}
      {...props}
    />
  );
}

export { Badge, badgeVariants };
