import { Button } from '@/components/ui/button';
import { DayPicker } from '@/components/ui/day-picker';
import { Label } from '@/components/ui/label';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import { cn } from '@/lib/utils';
import { format } from 'date-fns';
import { fi } from 'date-fns/locale';
import { CalendarIcon, Info } from 'lucide-react';
import { useState } from 'react';
import { DateRange } from 'react-day-picker';
import { useTranslation } from 'react-i18next';

type CalendarDropDownProps = {
  onDateChange: (range: DateRange) => void;
  start: Date;
  end: Date;
};

export function CalendarDropDown(props: CalendarDropDownProps) {
  const [dateRange, setDateRange] = useState<{
    from: Date;
    to: Date;
  }>({
    from: props.start,
    to: props.end,
  });
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  return (
    <>
      <Popover
        defaultOpen={open}
        onOpenChange={value => setOpen(value)}
        open={open}
      >
        <PopoverTrigger asChild>
          <Button className={cn('justify-start text-left')}>
            <CalendarIcon className="mr-2 h-4 w-4" />
            {format(new Date(dateRange.from!), 'dd.MM.yyyy', {
              locale: fi,
            })}
            {' - '}
            {format(new Date(dateRange.to!), 'dd.MM.yyyy', {
              locale: fi,
            })}
          </Button>
        </PopoverTrigger>
        <PopoverContent
          className="w-auto p-4 bg-grey-darkest space-y-4"
          align="end"
        >
          <div className="space-y-1">
            <Label className="text-bone">{t('dayPicker.start')}</Label>
            <DayPicker
              onDateChange={val =>
                setDateRange({ ...dateRange, from: new Date(val) })
              }
              selectedDate={dateRange.from}
            />
          </div>
          <div className="space-y-1">
            <Label className="text-bone">{t('dayPicker.end')}</Label>
            <DayPicker
              onDateChange={val =>
                setDateRange({ ...dateRange, to: new Date(val) })
              }
              allowedFrom={new Date(dateRange.from)}
              selectedDate={dateRange.to}
            />
          </div>
          <Button
            className="w-full"
            size="sm"
            onClick={() => {
              props.onDateChange(dateRange);
              setOpen(false);
            }}
          >
            {t('dayPicker.select')}
          </Button>
          <p className="text-bone text-xs border border-1 border-grey-dark p-2 rounded-[10px] flex items-center">
            <Info className="w-4 h-4 mr-2" />
            {t('dayPicker.datesInFormat')}
          </p>
        </PopoverContent>
      </Popover>
    </>
  );
}
